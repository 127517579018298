import React, { useEffect } from "react";
import AuthenticationRoutes from "./routes/AuthenticationRoutes";
import UnAuthenticationRoutes from "./routes/UnAuthenticationRoutes";
import { BrowserRouter as Router,Routes, useParams } from "react-router-dom";
import Header from "./container/Header/Header";
import FooterText from "./components/FooterText/FooterText";
import { useSelector } from "react-redux";
import Cookies from 'universal-cookie';
import { ToastContainer } from "react-toastify";

const App = () => {

  const {email,token } = useSelector(state => state.userState);
  // if (true) {
  //   console.log = function no_console() { };
  // }
  return (
    <>
    <ToastContainer />
      {email && token ? (<><Router><Header /><FooterText/><AuthenticationRoutes /></Router></>) :  <UnAuthenticationRoutes />}
    </>
  );
}

export default App;
