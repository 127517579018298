import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import { Grid } from "@mui/material";
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import { images } from '../../../../config/images';
import Checkbox from '@mui/material/Checkbox';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
// import Stack from '@mui/material/Stack';
import { useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
    FormControlLabel,
    FormGroup,
    TextField,
} from "@mui/material";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Switch from "@mui/material/Switch";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { makeStyles } from '@material-ui/core/styles';
import ReportManagementReportList from './ReportManagementReportList';
import ReportManagementUserList from './ReportManagementUserList';
import dayjs, { Dayjs } from "dayjs";
import './ReportMangementConfiguration.css';
import { getReportConfigure } from '../service';

const useStyles = makeStyles({
    root: {
        '& .MuiInputBase-input': {
            padding: '8px 10px', // set the desired padding here
        },
    },
});

const ReportManagementConfiguration = () => {
    const navigate = useNavigate();
    const param = useParams();
   
    const [activeClass, setActiveClass] = useState("Report");
    const [selectDeviceId, setSelectDeviceId] = useState([]);
    const [selectEmailSchedule, setSelectEmailSchedule] = useState("Daily");
    const [selectAGG, setSelectAGG] = useState("1");
    const [selectDate, setSelectTime] = useState(null);
    const [selectDeviceType, setSelectDevicetype] = useState("");
    const [selectUserId, setSelectUserId] = useState([])
    const [departmentId, setDepartmentId] = useState("");
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [selectUserListObj, setSelectUserListObj] = useState([]);
    const [isConfigure, setConfigure] = useState(false);
    const [reportName, setReportName] = useState("")
    const[reportData,setReportData] = useState([])
    const [configureDeviceData, setConfigureDeviceData] = useState([])

    // date format
    const dateFormat = "YYYY-MM-DD";
    const timeFormat = "HH:mm:ss";
    const selectDateFormat = selectDate != null ? dayjs(selectDate).format(dateFormat) : "";
    const selectTimeFormat = selectDate ? dayjs(selectDate).format(timeFormat) : "";

    console.log("selectDateFormat", selectDateFormat);
    console.log("selectTimeFormat", selectTimeFormat);
    console.log("selectedRoles", selectedRoles)

    useEffect(() => {
        if (selectEmailSchedule == "Daily") {
            setSelectAGG("1")
        }

        if (selectEmailSchedule == "Weekly") {
            setSelectAGG("5")
        }

        if (selectEmailSchedule == "Monthly") {
            setSelectAGG("15")
        }

        if (selectEmailSchedule == "Years") {
            setSelectAGG("24")
        }
    }, [selectEmailSchedule]);


    useEffect(() => {
            handleConfigureReportApi();
    }, [])

    function convertToISOFormat(date, time) {
        const [year, month, day] = date.split('-');
        const [hours, minutes, seconds] = time.split(':');

        const isoDateTime = new Date(year, month - 1, day, hours, minutes, seconds).toISOString();

        return isoDateTime;
    }
    
    const handleConfigureReportApi = async () => {
        const paramReq = {
            report: param.reportId
        }
        const resp = await getReportConfigure(paramReq);
        if (resp.status == 200 || resp.status == 201 && resp.data.payload) {
            console.log("resprespresprespresp", resp.data.payload);
            setSelectDeviceId(resp.data.payload.device.length ? resp.data.payload.device.map((e) => e.id) : []);
            setSelectEmailSchedule(resp.data.payload.email_schedule ? resp.data.payload.email_schedule : "Daily");
            setSelectAGG(resp.data.payload.agg ? resp.data.payload.agg : "1");
            const convertedDateTime = resp.data.payload.configuration_date && resp.data.payload.configuration_time ? convertToISOFormat(resp.data.payload.configuration_date, resp.data.payload.configuration_time) : null;
            const DateReFormat = convertedDateTime ?  dayjs(convertedDateTime) : null;
            setSelectTime(DateReFormat);
            setSelectDevicetype(resp.data.payload.report_type ? resp.data.payload.report_type : "");
            setSelectUserListObj(resp.data.payload.user.length ? [
                {
                    departmentId: resp.data.payload.department ? resp.data.payload.department : "",
                    role: resp.data.payload.role ? resp.data.payload.role : "",
                    user: resp.data.payload.user.length ? resp.data.payload.user.map((row) => row.id) : []
                }
            ] : [])
            // setSelectUserId(resp.data.payload.user.length ? resp.data.payload.user.map((row) => row.id) : [])
            setDepartmentId(resp.data.payload.department ? resp.data.payload.department : "");
            setConfigure(resp.data.payload.user.length ? true : false);
            setReportName(resp.data.payload.report_name)
            setReportData(resp.data.payload)
            setConfigureDeviceData(resp.data.payload.device.length ? resp.data.payload.device : [])
        }
    }
 
    return (
        <>
            <div className='containerWrap'>
                <Container maxWidth="xxl">
                    <div className='www'>
                        <div className="deviceManagementHeader-wrap">
                            <div className='deviceManagementTitle-wrap'>
                                <KeyboardBackspaceIcon onClick={() => navigate('/reportmanagement')} className='backArrow' /> <Typography variant="h5" gutterBottom style={{ margin: '0', paddingLeft: '10px', color: '#222D39', fontSize: '16px', fontWeight: '500', cursor: 'pointer' }} onClick={() => navigate('/reportmanagement')}>
                                    {reportName} Configuration
                                </Typography>
                            </div>
                        </div>
                        <div className="d-flex pt-2">
                            <button
                                className={`${activeClass == "Report" ? "device-tab" : "user-tab"
                                    }`}
                                onClick={() => setActiveClass("Report")}
                            >
                                Report
                            </button>
                            <button
                                className={`${activeClass == "User" ? "device-tab" : "user-tab"
                                    }`}
                                onClick={() => {
                                    if (selectDeviceId.length) {
                                        setActiveClass("User")
                                    } 
                                }
                                }
                            >
                                User
                            </button>
                        </div>
                    </div>
                    {activeClass == "Report" ? (
                        <>
                            <Container maxWidth="xxl pt-2">
                                <ReportManagementReportList
                                    selectDeviceId={selectDeviceId}
                                    setSelectDeviceId={setSelectDeviceId}
                                    selectEmailSchedule={selectEmailSchedule}
                                    setSelectEmailSchedule={setSelectEmailSchedule}
                                    selectAGG={selectAGG}
                                    setSelectAGG={setSelectAGG}
                                    selectDate={selectDate}
                                    setSelectTime={setSelectTime}
                                    selectDeviceType={selectDeviceType}
                                    setSelectDevicetype={setSelectDevicetype}
                                    setActiveClass={setActiveClass}
                                    reportData={reportData}
                                    configureDeviceData={configureDeviceData}
                                    setConfigureDeviceData={setConfigureDeviceData}
                                />
                            </Container>
                        </>
                    ) : (
                        <>
                            <Container maxWidth="xxl">
                                    <ReportManagementUserList
                                        activeClass={activeClass}
                                        selectDeviceId={selectDeviceId}
                                        selectAGG={selectAGG}
                                        selectEmailSchedule={selectEmailSchedule}
                                        selectDateFormat={selectDateFormat}
                                        selectTimeFormat={selectTimeFormat}
                                        selectUserId={selectUserId}
                                        setSelectUserId={setSelectUserId}
                                        departmentId={departmentId}
                                        setDepartmentId={setDepartmentId}
                                        selectedRoles={selectedRoles}
                                        setSelectedRoles={setSelectedRoles}
                                        selectDeviceType={selectDeviceType}
                                        selectUserListObj={selectUserListObj}
                                        setSelectUserListObj={setSelectUserListObj}
                                        isConfigure={isConfigure}
                                    />
                            </Container>
                        </>
                    )}
                </Container>
            </div>
            {/* =============================================================== */}

        </>
    )
}

export default ReportManagementConfiguration;