import React, { useEffect } from "react";
import { cubejsApi } from "../../../../Cubejs/CubejsApiWrapper";
import { geTagAllTimeMinMax } from "../service";



const OverViewGraphData = ({ selectTagData, setTagMinMaxValue,  vmsTagSelect}) => {

    useEffect(() => {
        const fetchData = async () => {
            if (selectTagData.length) {
                // const minMaxvalue = await cubejsApi.load({
                //     "measures": [
                //         "DashboardTaghistory.minValue",
                //         "DashboardTaghistory.maxValue"
                //     ],
                //     "filters": [
                //         {
                //             "member": "DashboardTaghistory.latestTagName",
                //             "operator": "contains",
                //             "values": selectTagData.map((row) => row.tagName)
                //         }
                //     ],
                //     "dimensions": [
                //         "DashboardTaghistory.latestTagName"
                //     ]
                // });

                // if (minMaxvalue.tablePivot().length) {
                //     const arraySetData = minMaxvalue.tablePivot().map((row) => {
                //         return ({
                //             tagName: row["DashboardTaghistory.latestTagName"],
                //             minValue: row["DashboardTaghistory.minValue
                //             maxValue: row["DashboardTaghistory.maxValue"]
                //         })
                //     })
                //     console.log("arraySetData", arraySetData);
                //     setTagMinMaxValue(arraySetData)
                // }

                const param = {
                    tag_id: selectTagData.map((row) => row.tag_id)
                }
                try {
                    const resp = await geTagAllTimeMinMax(param);
                    if (resp.status == 200 || resp.status == 201 && resp.data.payload.length) {
                        console.log("resprespresp", resp.data.payload);
                        setTagMinMaxValue(resp.data.payload)
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        };

        // imidiatlty function call
        fetchData();


        const intervalId = setInterval(fetchData, 15000); // Call fetchData every 15 seconds

        return () => {
            clearInterval(intervalId); // Clear interval on component unmount
        };
    }, [vmsTagSelect]);
}

export default OverViewGraphData;