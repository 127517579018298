import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { PieChartShimmer } from "../components/Shimmer/PieChartShimmer/PieChartShimmer";
import { images } from "../config"; // Assuming you have a configuration file for images

export function VmsPOPDownTimePieChart({
  AvailabilityPOPPieChartData,
  AvailabilityPOP1PieChartData,
  shimmer
}) {

  const deviceNames = AvailabilityPOPPieChartData?.deviceData
      ?.map((row) => row.device__device_name)
      .concat(
        AvailabilityPOP1PieChartData?.deviceData?.map(
          (row) => row.device__device_name
        )
      )
  || [];
  
  const deviceDownTimes = [
    AvailabilityPOPPieChartData?.deviceData?.map((row) => row.down_time)[0] || 0,
    AvailabilityPOP1PieChartData?.deviceData?.map((row) => row.down_time)[0] || 0,
  ];

  const data = {
    labels: deviceNames,
    seriesData: deviceDownTimes.map(value => Math.abs(value)),  // Use absolute values for chart display
    originalData: deviceDownTimes,  // Keep original values for tooltip
    colors: ["#C0255F", "#E66595"],
  };

  const options = {
    chart: {
      type: 'pie',
      height: 230,
      width: 230,
      backgroundColor: 'transparent',
    },
    title: {
      text: ''
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      formatter: function () {
        const total = this.series.data.reduce((acc, point) => acc + Math.abs(point.originalY), 0); // Use absolute values for total
        const percentage = ((Math.abs(this.point.originalY) / total) * 100).toFixed(2) + '%';
        return `<div style ="font-size : 12px">
                  <b>${this.point.name}</b><br/>
                  <span style="color:${this.point.color}">\u25A0</span> ${this.point.originalY} (${percentage})
                </div>`;
      }
    },
    plotOptions: {
      pie: {
        innerSize: '70%',
        dataLabels: {
          enabled: false,
        }
      }
    },
    series: [{
      name: "# of Votes",
      colorByPoint: true,
      data: data.seriesData.map((value, index) => ({
        name: data.labels[index],
        y: value,  // Display absolute value in chart
        color: data.colors[index],
        originalY: data.originalData[index],  // Store original value for tooltip
      })),
    }],
    credits: {
      enabled: false
    }
  };

  // Check if all data points are zero
  const allZeros = data.seriesData.every(value => value === 0);

  return (
    <div style={{ width: "100%", height: "195px" }} className="doughnut-chart1">
      {!shimmer ? (
        <>
          {!allZeros ? (
            <HighchartsReact highcharts={Highcharts} options={options} />
          ) : (
            <img src={images.noDataFoundImg} className="notFoundDataImg not-found" alt="" />
          )}
        </>
      ) : (
        <PieChartShimmer size="195px" position="relative" />
      )}

      <p className="doughnut-chartText" style={{ left: "75%" }}>
        {((AvailabilityPOPPieChartData?.deviceTotal?.total_down_time || 0) + (AvailabilityPOP1PieChartData?.deviceTotal?.total_down_time || 0))}
      </p>
      <p className="doughnut-chartText-unit" style={{ left: "77%", top: "49%" }}>
        {AvailabilityPOPPieChartData?.deviceTotal?.total_down_time ? "hr" : ""}
      </p>
    
    </div>
  );
}
