import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const initialState = {
analyticsDataId : [],
analyticsDataDeviceColor : [],
analyticsProductionPOPId :[],
analyticsDataProductionPOPColor :[],
energyCostCOCId :[],
energyCostDeviceColors : [],
energyCostPOPId :[],
energyCostDevicePOPColors : [],
productionStartDateCOC : null,
productionEndDateCOC : null,
productionStartDatePOP : null,
productionEndDatePOP : null,
productionStartDatePOP1 : null,
productionEndDatePOP1 : null,
energyCostStartDateCOC : null,
energyCostEndDateCOC : null,
energyCostStartDatePOP : null,
energyCostEndDatePOP : null,
energyCostStartDatePOP1 : null,
energyCostEndDatePOP1 : null,
configurationSummary : false,
summaryStartDate: dayjs().subtract(30, 'day'),
summaryEndDate: dayjs()
}

const analyticsDataSlice = createSlice({
    name: "Analytics",
    initialState,
    reducers: {
        setAnalyticsData(state, { payload }) {
            return { ...state, ...payload };
        },
        clearAnalyticsData(state) {
            return initialState;
        },
    },
});

export const {
    setAnalyticsData,
    clearAnalyticsData,
} = analyticsDataSlice.actions;

export default analyticsDataSlice.reducer;


