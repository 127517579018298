import React, { useEffect, useState } from "react";
import { images } from "../../../../config";
import Slider from "react-slick";
import { Card, Grid, TextField } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SearchIcon from "@material-ui/icons/Search";
import TagGroup from "./TagGroup";
import TagGroupDetail from "./TagGroupDetail";
import { useParams } from "react-router-dom";
import {
  getDeviceAlarmStatus,
  getTagDevice,
  getTagGroups,
} from "../../services";
import Mqqtdata from "../../../ProcessDashboard/Mqqtdata";
import OverViewMachineMqttData from "../mqttDataMachineDashboard/OverViewMachineMqttData";
import { useDispatch, useSelector } from "react-redux";
import { setSmartTagReportData } from "../Slice";
import Loader from "../../../../components/Loader/Loader";

const CompactDeviceTag = () => {

  const [deviceData, setDeviceData] = useState([]);
  const [deviceId, setDeviceId] = useState("");
  const [getTag, setTag] = useState({});
  const [getTagData, setTagData] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [topics, setTopics] = useState([]);
  const [mqttTagPreviousvalue, setMqttTagPreviousvalue] = useState([]);
  const [tagStatus, setTagStatus] = useState([]);
  const [sliderRealTimeTagData, setSliderRealTimeTagData] = useState([]);
  const [tagListDataGroupAndList, setTagListDataGroupAndList] = useState([]);
  const { activeClassTab } = useSelector((state) => state.smartTagReportSlice);
  const [isLoading, setLoading] = useState(false);
  const [deviceAlarmStatus, setDeviceAlarmStatus] = useState([]);


  const param = useParams();
  const dispatch = useDispatch();


  useEffect(() => {
    if (mqttTagPreviousvalue.length) {
      const averagCalTagvalue = mqttTagPreviousvalue.map((row) => {
        const avgCal = ((row.curValue - row.prevValue) / row.prevValue) * 100;
        return {
          dvName: row.dvName,
          statusValue: isFinite(avgCal) ? avgCal : 0.0,
        };
      });

      setTagStatus(averagCalTagvalue);
    }
  }, [mqttTagPreviousvalue]);

  useEffect(() => {
    handleImageClick();
    handleImageDetailClick();
  }, []);

  const handleImageClick = () => {
    dispatch(setSmartTagReportData({ activeClassTab: "Compact" }));
  };

  const handleImageDetailClick = () => {
    dispatch(setSmartTagReportData({ activeClassTab: "Detail" }));
  };

  useEffect(() => {
    if (deviceId) {
      getDeviceTagGroup();
    }
  }, [deviceId]);

  const getTagDateAndTime = () => {
    return new Date().toLocaleString("en-US", {
      timeZone: "Asia/Kolkata",
      hour12: false,
    });
  };

  const getNotificationIconColor = (priority) => {
    switch (priority) {
      case "HIGH":
        return "#e31e24";
      case "MEDIUM":
        return "#ffa500";
      case "LOW":
        return "#ebeb27";
      default:
        return "gray";
    }
  };

  const getDeviceTagGroup = async () => {
    const params = {
      plant_id: param.plantid,
      device_id: deviceId,
    };
    try {
      setLoading(true);
      const resp = await getTagGroups(params);
      if (resp.status == 200 || (resp.status == 201 && resp.data.payload)) {
        setLoading(false);
        setTag(resp.data.payload.group_data);
        setTagData(resp.data.payload.tags_data);

        // create structure change in API
        const tagListData1 = Object.keys(resp.data.payload.group_data).map(
          (groupKey) => {
            const groupData = resp.data.payload.group_data[groupKey];

            const tags = groupData[Object.keys(groupData)];

            const tagDataList = tags.tags.map((e) => {
              const obj = {
                dvName: e.device_tag ? e.device_tag || "-" : "-",
                tagName: e.tag_name,
                machineUID: e.device__machine__uid,
              };
              return obj;
            });
            return tagDataList;
          }
        );
        const tagListDataCombinedArray1 = tagListData1.reduce((acc, curr) => {
          return acc.concat(curr);
        }, []);

        const defaultTagListData = resp.data.payload.tags_data[0]?.tags?.map(
          (e) => {
            const obj = {
              dvName: e.device_tag ? e.device_tag || "-" : "-",
              tagName: e.tag_name,
              machineUID: e.device__machine__uid,
            };
            return obj;
          }
        );

        setTagListDataGroupAndList(
          tagListDataCombinedArray1.concat(defaultTagListData)
        );

        const tagObj = Object.keys(resp.data.payload.group_data).map(
          (groupKey) => {
            const groupData = resp.data.payload.group_data[groupKey];

            const tags = groupData[Object.keys(groupData)];

            const tagDataList = tags.tags.map((e) => {
              const obj = {
                dvName: e.device_tag ? e.device_tag || "-" : "-",
                prevValue: 0,
                curValue: 0,
                dateAndTime: getTagDateAndTime(),
              };
              return obj;
            });
            console.log("tagDataList", tagDataList);
            return tagDataList;
          }
        );
        const combinedArray = tagObj.reduce((acc, curr) => {
          return acc.concat(curr);
        }, []);

        const defaultTagData = resp.data.payload.tags_data[0]?.tags?.map(
          (e) => {
            const obj = {
              dvName: e.device_tag ? e.device_tag || "-" : "-",
              prevValue: 0,
              curValue: 0,
              dateAndTime: getTagDateAndTime(),
            };
            return obj;
          }
        );
        setMqttTagPreviousvalue(combinedArray.concat(defaultTagData));
      }
    } catch (error) {
      setLoading(false);
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    handleDeviceAlarmStatus();
  }, []);

  const handleDeviceAlarmStatus = async () => {
    const params = {
      machine: param.machineid,
    };
    try {
      const resp = await getDeviceAlarmStatus(params);
      if (resp.status == 200 || resp.status == 201) {
        setDeviceAlarmStatus(resp.data.payload);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 3,

    responsive: [
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    getTagDeviceData();
  }, []);

  const getTagDeviceData = async () => {
    const params = {
      plant_id: param.plantid,
      machine: param.machineid,
    };
    try {
      setLoading(true);
      const resp = await getTagDevice(params);
      if (resp.status == 200 || resp.status == 201) {
        setLoading(false);
        setDeviceData(resp.data.payload);
      }
    } catch (error) {
      setLoading(false);
      console.log("handlesubmit", error);
    }
  };

  const handleDeviceClick = (id, topic) => {
    setDeviceId(id);
    setTopics(["Hikar/DeviceUID/" + topic])
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  console.log("activeClassTabactiveClassTab", activeClassTab);
  return (
    <>
      <div className="page-wraper">
        {topics.length ? (
          <OverViewMachineMqttData
            topics={topics}
            tagListDataGroupAndList={tagListDataGroupAndList}
            setMqttTagPreviousvalue={setMqttTagPreviousvalue}
            mqttTagPreviousvalue={mqttTagPreviousvalue}
            setSliderRealTimeTagData={setSliderRealTimeTagData}
          />
        ) : null}
        <Slider {...sliderSettings} className="my-slider-class">
          {deviceData.map((e) => {
            const matchedNotification = deviceAlarmStatus.find(
              (notif) => notif.id === e.id
            );
            return (
              <Card
                className="machineoverView-title"
                onClick={() => handleDeviceClick(e.id, e.machine_uid)}
              >
                <div className="d-flex justify-content-between">
                  <div>
                    <h6 style={{ fontSize: "18px" }}>
                      <b>{e.device_name}</b>
                    </h6>
                  </div>
                  <div className="d-flex gap-1">
                    <NotificationsIcon
                      style={{
                        color: getNotificationIconColor(
                          matchedNotification?.alarm_priority
                        ),
                      }}
                    />
                    {e.is_sub ? (
                      <img
                        src={images.cloudActiveImg}
                        alt=""
                        className="triangle-image"
                      />
                    ) : (
                      <img
                        src={images.cloudImg}
                        alt=""
                        className="triangle-image"
                      />
                    )}
                  </div>
                </div>
                <div>
                  <div>
                    <p style={{ fontSize: "14px" }} className="mb-1">
                      {e.device_description}
                    </p>
                  </div>
                </div>
              </Card>
            );
          })}
        </Slider>
        <div className="pt-3">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={10} md={10} lg={11} xl={11}>
              <TextField
                variant="outlined"
                style={{ width: "100%", backgroundColor: "white" }}
                // className="search-textfield"
                placeholder="Search tag name"
                value={searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: <SearchIcon />,
                  inputProps: {
                    style: { padding: "9px" },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2} md={2} lg={1} xl={1}>
              <div className="d-flex gap-2">
                {activeClassTab === "Compact" ? (
                  <>
                    <img
                      src={images.compactViewIcon}
                      alt=""
                      onClick={handleImageClick}
                      style={{ cursor: "pointer" }}
                    />
                    <img
                      src={images.detailViewIcon}
                      alt=""
                      onClick={handleImageDetailClick}
                      style={{ cursor: "pointer" }}
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={images.compact}
                      alt=""
                      onClick={handleImageClick}
                      style={{ cursor: "pointer" }}
                    />
                    <img
                      src={images.detail}
                      alt=""
                      onClick={handleImageDetailClick}
                      style={{ cursor: "pointer" }}
                    />
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="pt-3">
          {activeClassTab === "Compact" ? (
            <TagGroup
              deviceId={deviceId}
              getTag={getTag}
              searchQuery={searchQuery}
              mqttTagData={sliderRealTimeTagData}
              getTagData={getTagData}
              getDeviceTagGroup={getDeviceTagGroup}
            />
          ) : (
            <TagGroupDetail
              getTag={getTag}
              searchQuery={searchQuery}
              getTagData={getTagData}
              getDeviceTagGroup={getDeviceTagGroup}
              setMqttTagPreviousvalue={setMqttTagPreviousvalue}
              mqttTagPreviousvalue={mqttTagPreviousvalue}
              tagStatus1={tagStatus}
              sliderRealTimeTagData={sliderRealTimeTagData}
            />
          )}
        </div>
      </div>
      {isLoading && <Loader />}
    </>
  );
};

export default CompactDeviceTag;
