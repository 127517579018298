import React from "react";
import { useEffect } from "react";
import Mqqtdata from "../../../../../ProcessDashboard/Mqqtdata";
import { useState } from "react";

const OverViewmqttData = ({
    deviceData,
    unitySelectTag,
    selectTagData,
    setTagVMSData,
    setCombineChartData,
    setLineGraphData1,
    setLineGraphData2,
    setLineGraphData3,
    setLineGraphData4,
    setMqttTagPreviousvalue,
    tagListDataForDevice,
    setSendRealTimeDataModel
}) => {

    const [topics, setTopics] = useState([]);
    const [mqttTagData, setMqttTagData] = useState([]);

    console.log("mqttTagDatamqttTagData", topics, mqttTagData);
    //topic 
    useEffect(() => {
        const vmsTopic = ['Hikar/DeviceUID/' + deviceData.machine_uid];
        setTopics(vmsTopic)
    }, [])


    useEffect(() => {
        const mergedArray = [].concat(...Object.values(mqttTagData))
        if (selectTagData.length && mergedArray.length && unitySelectTag == "") {

            const getTagTime = () => {
                return new Date().toLocaleTimeString('en-US', {
                    timeZone: 'Asia/Kolkata',
                    hour12: false,
                });
            };

            const filteredArray = mergedArray.filter(obj => {
                const device = obj.device__uid;
                const deviceTag = obj.device_tag;
                return selectTagData.some(item => item.device__uid === device && item.device_tag === deviceTag);
            });

            setTagVMSData(filteredArray);

            setMqttTagPreviousvalue((prev) => {
                const prevValue = [...prev].map((row) => {
                    const findTag = filteredArray.find((f) => f.device_tag == row.tagName);
                    const obj = {
                        tagName: findTag?.device_tag || "-",
                        prevValue: row.curValue,
                        curValue: findTag?.tagValue || 0
                    }
                    return obj
                })
                return prevValue;
            })

            setCombineChartData((prev) => {

                const filteredArray1 = mergedArray.filter(obj => {
                    const device = obj.device__uid;
                    const deviceTag = obj.device_tag;
                    return selectTagData[0].device__uid == device && selectTagData[0].device_tag == deviceTag
                }).length ? mergedArray.filter(obj => {
                    const device = obj.device__uid;
                    const deviceTag = obj.device_tag;
                    return selectTagData[0].device__uid == device && selectTagData[0].device_tag == deviceTag
                }) : [prev[0][prev[0].length - 1] ? prev[0][prev[0].length - 1] : { tagValue: 0 }];

                const filteredArray2 = mergedArray.filter(obj => {
                    const device = obj.device__uid;
                    const deviceTag = obj.device_tag;
                    return selectTagData[1]?.device__uid == device && selectTagData[1]?.device_tag == deviceTag
                }).length ? mergedArray.filter(obj => {
                    const device = obj.device__uid;
                    const deviceTag = obj.device_tag;
                    return selectTagData[1]?.device__uid == device && selectTagData[1]?.device_tag == deviceTag
                }) : [prev[1][prev[1].length - 1] ? prev[1][prev[1].length - 1] : { tagValue: 0 }];


                const filteredArray3 = mergedArray.filter(obj => {
                    const device = obj.device__uid;
                    const deviceTag = obj.device_tag;
                    return selectTagData[2]?.device__uid == device && selectTagData[2]?.device_tag == deviceTag
                }).length ? mergedArray.filter(obj => {
                    const device = obj.device__uid;
                    const deviceTag = obj.device_tag;
                    return selectTagData[2]?.device__uid == device && selectTagData[2]?.device_tag == deviceTag
                }) : [prev[2][prev[2].length - 1] ? prev[2][prev[2].length - 1] : { tagValue: 0 }];

                const filteredArray4 = mergedArray.filter(obj => {
                    const device = obj.device__uid;
                    const deviceTag = obj.device_tag;
                    return selectTagData[3]?.device__uid == device && selectTagData[3]?.device_tag == deviceTag
                }).length ? mergedArray.filter(obj => {
                    const device = obj.device__uid;
                    const deviceTag = obj.device_tag;
                    return selectTagData[3]?.device__uid == device && selectTagData[3]?.device_tag == deviceTag
                }) : [prev[3][prev[3].length - 1] ? prev[3][prev[3].length - 1] : { tagValue: 0 }];


                // Create a shallow copy of the previous state
                const zeroIndx = [...prev];

                // Create a shallow copy of the inner array you want to modify (assuming it's an array)
                const innerArrayCopy = [...zeroIndx[0]];
                const innerArrayCopy1 = [...zeroIndx[1]];
                const innerArrayCopy2 = [...zeroIndx[2]];
                const innerArrayCopy3 = [...zeroIndx[3]];

                // // Push the new object into the inner array copy
                // if (selectTagData[0]) {
                //     innerArrayCopy.push({ ...filteredArray1[0], tagTime: getTagTime() });
                //     setLineGraphData1((prev) => [...prev, { ...filteredArray1[0], tagTime: getTagTime() }])
                // }

                // if (selectTagData[1]) {
                //     innerArrayCopy1.push({ ...filteredArray2[0], tagTime: getTagTime() });
                //     setLineGraphData2((prev) => [...prev, { ...filteredArray2[0], tagTime: getTagTime() }])
                // }

                // if (selectTagData[2]) {
                //     innerArrayCopy2.push({ ...filteredArray3[0], tagTime: getTagTime() });
                //     setLineGraphData3((prev) => [...prev, { ...filteredArray3[0], tagTime: getTagTime() }])
                // }

                // if (selectTagData[3]) {
                //     innerArrayCopy3.push({ ...filteredArray4[0], tagTime: getTagTime() });
                //     setLineGraphData4((prev) => [...prev, { ...filteredArray4[0], tagTime: getTagTime() }])

                // }

                // ========================new code======================================

                // Push the new object into the inner array copy
                if (selectTagData[0]) {
                    if (innerArrayCopy[innerArrayCopy.length - 1]?.tagTime != getTagTime()) {
                        innerArrayCopy.push({ ...filteredArray[0], tagTime: getTagTime() });
                        // setLineGraphData1((prev) => [...prev, { ...filteredArray1[0], tagTime: getTagTime() }])

                        setLineGraphData1((prev) => {
                            // Create a shallow copy of the previous state
                            const indexCopy = [...prev];
                            //==========================new add code==============================
                            if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
                              indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
                            }
                      
                            return [...indexCopy, { ...filteredArray1[0], tagTime: getTagTime() }]
                          });
                    }

                }

                if (selectTagData[1]) {
                    if (innerArrayCopy1[innerArrayCopy1.length - 1]?.tagTime != getTagTime()) {
                        innerArrayCopy1.push({ ...filteredArray2[0], tagTime: getTagTime() });
                        // setLineGraphData2((prev) => [...prev, { ...filteredArray2[0], tagTime: getTagTime() }])

                        setLineGraphData2((prev) => {
                            // Create a shallow copy of the previous state
                            const indexCopy = [...prev];
                            //==========================new add code==============================
                            if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
                              indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
                            }
                      
                            return [...indexCopy, { ...filteredArray2[0], tagTime: getTagTime() }]
                          });
                    }

                }

                if (selectTagData[2]) {
                    if (innerArrayCopy2[innerArrayCopy2.length - 1]?.tagTime != getTagTime()) {
                        innerArrayCopy2.push({ ...filteredArray3[0], tagTime: getTagTime() });
                        // setLineGraphData3((prev) => [...prev, { ...filteredArray3[0], tagTime: getTagTime() }])
                        setLineGraphData3((prev) => {
                            // Create a shallow copy of the previous state
                            const indexCopy = [...prev];
                            //==========================new add code==============================
                            if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
                              indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
                            }
                      
                            return [...indexCopy, { ...filteredArray3[0], tagTime: getTagTime() }]
                          });
                    }

                }

                if (selectTagData[3]) {
                    if (innerArrayCopy3[innerArrayCopy3.length - 1]?.tagTime != getTagTime()) {
                        innerArrayCopy3.push({ ...filteredArray4[0], tagTime: getTagTime() });
                        // setLineGraphData4((prev) => [...prev, { ...filteredArray4[0], tagTime: getTagTime() }])
                        setLineGraphData4((prev) => {
                            // Create a shallow copy of the previous state
                            const indexCopy = [...prev];
                            //==========================new add code==============================
                            if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
                              indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
                            }
                      
                            return [...indexCopy, { ...filteredArray4[0], tagTime: getTagTime() }]
                          });
                    }
                }

                //==========================new add code==============================
                if (innerArrayCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
                    innerArrayCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
                }

                if (innerArrayCopy1.length >= process.env.REACT_APP_GRAPH_LENGTH) {
                    innerArrayCopy1.shift(); // Remove oldest entry if exceeds MAX_LENGTH
                }

                if (innerArrayCopy2.length >= process.env.REACT_APP_GRAPH_LENGTH) {
                    innerArrayCopy2.shift(); // Remove oldest entry if exceeds MAX_LENGTH
                }

                if (innerArrayCopy3.length >= process.env.REACT_APP_GRAPH_LENGTH) {
                    innerArrayCopy3.shift(); // Remove oldest entry if exceeds MAX_LENGTH
                }


                zeroIndx[0] = innerArrayCopy;
                zeroIndx[1] = innerArrayCopy1;
                zeroIndx[2] = innerArrayCopy2;
                zeroIndx[3] = innerArrayCopy3;

                return zeroIndx;

            });

            // setMqttTagData([])
        }

    }, [mqttTagData])

    useEffect(() => {
        if (tagListDataForDevice.length) {
            const mergedArray = [].concat(...Object.values(mqttTagData));
            const filteredArray = mergedArray.map((obj) => {
                const device = obj.device__uid;
                const deviceTag = obj.device_tag;

                const tagFind = tagListDataForDevice.find((item) => item.device__uid == device && item.device_tag == deviceTag)
                return {
                    tag_name: tagFind ? tagFind.tag_name : "",
                    // tagListData.find(
                    //   (item) =>
                    //     item.device__uid == device && item.device_tag == deviceTag
                    // )?.tag_name || "",
                    mqtt_tag_name: deviceTag,
                    tag_value: tagFind?.signal_type ? ["AI", "AO"].includes(tagFind.signal_type) ? obj.tagValue : obj.tagValue == "1" ? tagFind.high_range : tagFind.low_range : obj.tagValue,
                    alarm: tagFind?.setpoint_hi && tagFind?.setpoint_lo ? tagFind?.setpoint_hi < obj.tagValue || tagFind?.setpoint_lo > obj.tagValue ? "true" : "false" : "false"
                };
            });
            setSendRealTimeDataModel(filteredArray.filter((f) => f.tag_name != ""))
        }
    }, [mqttTagData])


    return (
        <>
            {topics.length ? <Mqqtdata topics={topics} setMqttTagData={setMqttTagData} overViewTagData={selectTagData} /> : null}
        </>
    )
}

export default OverViewmqttData;