import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { images } from "../../../config/images";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { BarChart } from "./BarChart";
import { useDispatch, useSelector } from "react-redux";
import { getOverviewAnalyticsEnergyChartData } from "./services";
import dayjs from "dayjs";
import { BarChartPOP } from "./BarChartPOP";
import { setOverviewData } from "./Slice/OverViewAnalyticsSlice";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import Container from "@mui/material/Container";
import { toast } from "react-toastify";

const ZoomInPOPChart = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDate1, setStartDate1] = useState(null);
  const [endDate1, setEndDate1] = useState(null);
  const [zoomChartData, setZoomChartData] = useState([]);
  const [zoomChartData1, setZoomChartData1] = useState([]);
  const [average, setAverage] = useState(false);
  const [openDeviceId, setOpenDeviceId] = useState(null);
  const [isShimmer,setShimmer] = useState(false)
  // const [showTimePop, setShowTime] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const param = useParams();
  const navigate = useNavigate();

  const data = [
    { label: 'kW', value: '12345.67' },
    { label: 'kVA', value: '12345.67' },
    { label: 'PF', value: '12345.67' },
    { label: 'V1-V2', value: '12345.67' },
    { label: 'V2-V3', value: '12345.67' },
    { label: 'V3-V1', value: '12345.67' },
    { label: 'A1', value: '12345.67' },
    { label: 'kWH', value: '12345.67' },
    { label: 'kVAH', value: '12345.67' },
  ];

  const toggleShowAll = (id) => {
    setOpenDeviceId(openDeviceId === id ? null : id);
  };
  const isOpen = openDeviceId === zoomChartData[0]?.device_id
  const isOpen1 = openDeviceId === zoomChartData1[0]?.device_id

  const {
    startDatePopRe,
    endDatePopRe,
    startDatePopRe1,
    endDatePopRe1,
    deviceIdPOPRedux,
    deviceUnitPOPRedux,
    devicePOPColors,
    showTimePop,
  } = useSelector((state) => state.overViewState);
  const MAX_DATE_RANGE_DAYS = 365; // Maximum duration of one year in days

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const dispatch = useDispatch();

  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";
  const startTimeFormat11 = startDatePopRe
    ? dayjs(startDatePopRe).format(timeFormat)
    : "";
  const endTimeFormat11 = endDatePopRe
    ? dayjs(endDatePopRe).format(timeFormat)
    : "";
  const startTimeFormat12 = startDatePopRe1
    ? dayjs(startDatePopRe1).format(timeFormat)
    : "";
  const endTimeFormat12 = endDatePopRe
    ? dayjs(endDatePopRe).format(timeFormat)
    : "";

  const startDateReFormatPOP = startDatePopRe ? dayjs(startDatePopRe) : null;
  const endDateReFormatPOP = endDatePopRe ? dayjs(endDatePopRe) : null;
  const startDateReFormatPOP1 = startDatePopRe1 ? dayjs(startDatePopRe1) : null;
  const endDateReFormatPOP1 = endDatePopRe1 ? dayjs(endDatePopRe1) : null;

  const startTimeFormat =
    startDatePopRe != null ? dayjs(startDatePopRe).format(dateFormat) : "";
  const formatEndDate =
    endDatePopRe != null ? dayjs(endDatePopRe).format(dateFormat) : "";
  const startTimeFormat1 =
    startDatePopRe != null ? dayjs(startDatePopRe1).format(dateFormat) : "";
  const formatEndDate1 =
    endDatePopRe != null ? dayjs(endDatePopRe1).format(dateFormat) : "";

  const findColor =
    zoomChartData.length &&
    devicePOPColors.length &&
    devicePOPColors.find((e) => e.deviceId == zoomChartData[0].device_id);

  const findPlantTagUnit = deviceUnitPOPRedux.length ? deviceUnitPOPRedux.filter((f) => f.plantId == param.id) : [];

  const getOverviewAnalyticsEnergyChartApi = async () => {

    const findPlantByDevice = deviceIdPOPRedux.length ? deviceIdPOPRedux.filter((f) => f.plantId == param.id) : []
    const findPlantUnit = deviceUnitPOPRedux.length ? deviceUnitPOPRedux.filter((f) => f.plantId == param.id) : [];
    const paramRequestData = {
      device_id: findPlantByDevice.map((row) => row.deviceID),
      tag_unit: findPlantUnit && findPlantUnit.length > 0 ? findPlantUnit[0]["tagUnit"] : null,
      start_date: `${startTimeFormat} 00:00:00`,
      end_date: `${formatEndDate} 23:59:59`,
    };
    if (showTimePop) {
      paramRequestData["start_date"] = `${startTimeFormat} ${startTimeFormat11}`;
      paramRequestData["end_date"] = `${formatEndDate} ${endTimeFormat11}`;
    }
    try {
      setShimmer(true)
      const resp = await getOverviewAnalyticsEnergyChartData(paramRequestData);

      if (resp.status == 200) {
        setShimmer(false)
        setZoomChartData(resp.data.payload);
      }
      console.log("resp", resp);
    } catch (error) {
      setShimmer(false)
      console.log("error", error);
    }
  };

  useEffect(() => {
    getOverviewAnalyticsEnergyChartApi();
  }, [startTimeFormat, formatEndDate, showTimePop, startTimeFormat11, endTimeFormat11]);

  const getOverviewAnalyticsEnergyChartApi1 = async () => {
    const findPlantByDevice = deviceIdPOPRedux.length ? deviceIdPOPRedux.filter((f) => f.plantId == param.id) : []
    const findPlantUnit = deviceUnitPOPRedux.length ? deviceUnitPOPRedux.filter((f) => f.plantId == param.id) : [];
    const paramRequestData = {
      device_id: findPlantByDevice.map((row) => row.deviceID),
      tag_unit: findPlantUnit && findPlantUnit.length > 0 ? findPlantUnit[0]["tagUnit"] : null,
      start_date: `${startTimeFormat1} 00:00:00`,
      end_date: `${formatEndDate1} 23:59:59`,
    };
    if (showTimePop) {
      paramRequestData["start_date"] = `${startTimeFormat1} ${startTimeFormat12}`;
      paramRequestData["end_date"] = `${formatEndDate1} ${endTimeFormat12}`;
    }
    try {
      setShimmer(true)
      const resp = await getOverviewAnalyticsEnergyChartData(paramRequestData);

      if (resp.status == 200) {
        setShimmer(false)
        setZoomChartData1(resp.data.payload);
      }
      console.log("resp", resp);
    } catch (error) {
      setShimmer(false)
      console.log("error", error);
    }
  };

  useEffect(() => {
    getOverviewAnalyticsEnergyChartApi1();
  }, [startTimeFormat1, formatEndDate1, startTimeFormat12, endTimeFormat12, showTimePop]);

  const handleToggleTime = () => {
    dispatch(setOverviewData({ showTimePop: !showTimePop }));
    // setShowTime((prevShowTime) => !prevShowTime);
  };
  const handlePOPStartDate = (newValue) => {
    const durationInDays = endDateReFormatPOP
      ? endDateReFormatPOP.diff(newValue, "days")
        ? endDateReFormatPOP.diff(newValue, "days") > 0
          ? endDateReFormatPOP.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(setOverviewData({ startDatePopRe: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDateReFormatPOP && newValue.isBefore(endDateReFormatPOP)) {
      dispatch(setOverviewData({ startDatePopRe: newValue }));
    } else if (!endDateReFormatPOP) {
      dispatch(setOverviewData({ startDatePopRe: newValue }));
    } else if (
      endDateReFormatPOP &&
      newValue.isSame(endDateReFormatPOP, "day")
    ) {
      // Check for time validation if showTime is true
      if (
        showTimePop &&
        (newValue.hour() > endDateReFormatPOP.hour() ||
          (newValue.hour() === endDateReFormatPOP.hour() &&
            newValue.minute() >= endDateReFormatPOP.minute()))
      ) {
        dispatch(setOverviewData({ startDatePopRe: null }));
        notifyError("Start time must be before end time");
      } else {
        dispatch(setOverviewData({ startDatePopRe: newValue }));
      }
    } else {
      dispatch(setOverviewData({ startDatePopRe: null }));
      notifyError("End date must be greater than start date");
    }
    // dispatch(setOverviewData({ startDatePopRe: newValue }));
  };

  const handlePOPEndDate = (newValue) => {
    const durationInDays = startDateReFormatPOP
      ? startDateReFormatPOP.diff(newValue, "days")
        ? startDateReFormatPOP.diff(newValue, "days") > 0
          ? startDateReFormatPOP.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(setOverviewData({ endDatePopRe: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDateReFormatPOP && newValue.isAfter(startDateReFormatPOP)) {
      dispatch(setOverviewData({ endDatePopRe: newValue }));
    } else if (!startDateReFormatPOP) {
      dispatch(setOverviewData({ endDatePopRe: newValue }));
    } else if (
      startDateReFormatPOP &&
      newValue.isSame(startDateReFormatPOP, "day")
    ) {
      // Check for time validation if showTime is true
      if (
        showTimePop &&
        (newValue.hour() < startDateReFormatPOP.hour() ||
          (newValue.hour() === startDateReFormatPOP.hour() &&
            newValue.minute() <= startDateReFormatPOP.minute()))
      ) {
        dispatch(setOverviewData({ endDatePopRe: null }));
        notifyError("End time must be after start time");
      } else {
        dispatch(setOverviewData({ endDatePopRe: newValue }));
      }
    } else {
      dispatch(setOverviewData({ endDatePopRe: null }));
      notifyError("Start date must be less than end date");
    }
  };
  const handlePOP1StartDate = (newValue) => {
    const durationInDays = endDateReFormatPOP1
      ? endDateReFormatPOP1.diff(newValue, "days")
        ? endDateReFormatPOP1.diff(newValue, "days") > 0
          ? endDateReFormatPOP1.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(setOverviewData({ startDatePopRe1: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDateReFormatPOP1 && newValue.isBefore(endDateReFormatPOP1)) {
      dispatch(setOverviewData({ startDatePopRe1: newValue }));
    } else if (!endDateReFormatPOP1) {
      dispatch(setOverviewData({ startDatePopRe1: newValue }));
    } else if (
      endDateReFormatPOP1 &&
      newValue.isSame(endDateReFormatPOP1, "day")
    ) {
      // Check for time validation if showTime is true
      if (
        showTimePop &&
        (newValue.hour() > endDateReFormatPOP1.hour() ||
          (newValue.hour() === endDateReFormatPOP1.hour() &&
            newValue.minute() >= endDateReFormatPOP1.minute()))
      ) {
        dispatch(setOverviewData({ startDatePopRe1: null }));
        notifyError("Start time must be before end time");
      } else {
        dispatch(setOverviewData({ startDatePopRe1: newValue }));
      }
    } else {
      dispatch(setOverviewData({ startDatePopRe1: null }));
      notifyError("End date must be greater than start date");
    }
  };

  const handlePOP1EndDate = (newValue) => {
    const durationInDays = startDateReFormatPOP1
      ? startDateReFormatPOP1.diff(newValue, "days")
        ? startDateReFormatPOP1.diff(newValue, "days") > 0
          ? startDateReFormatPOP1.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(setOverviewData({ endDatePopRe1: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDateReFormatPOP1 && newValue.isAfter(startDateReFormatPOP1)) {
      dispatch(setOverviewData({ endDatePopRe1: newValue }));
    } else if (!startDateReFormatPOP1) {
      dispatch(setOverviewData({ endDatePopRe1: newValue }));
    } else if (
      startDateReFormatPOP1 &&
      newValue.isSame(startDateReFormatPOP1, "day")
    ) {
      // Check for time validation if showTime is true
      if (
        showTimePop &&
        (newValue.hour() < startDateReFormatPOP1.hour() ||
          (newValue.hour() === startDateReFormatPOP1.hour() &&
            newValue.minute() <= startDateReFormatPOP1.minute()))
      ) {
        dispatch(setOverviewData({ endDatePopRe1: null }));
        notifyError("End time must be after start time");
      } else {
        dispatch(setOverviewData({ endDatePopRe1: newValue }));
      }
    } else {
      dispatch(setOverviewData({ endDatePopRe1: null }));
      notifyError("Start date must be less than end date");
    }
  };


  return (
    <>
      <Container maxWidth="xxl" className="mt-3 mb-5">
        <div className="page-header page-header-analytics">
          <div
            className="page-back-btn"
            onClick={() =>
              navigate(
                `/overView-analytics/company/${param.companyId}/plant/${param.id}`
              )
            }
          >
            <ArrowBackIcon />
            <span>Overview - Analytics</span>
          </div>
        </div>
        <div style={{ marginTop: "36px" }}>
          <Box className="overview-zoom-box">
            <Grid container spacing={2}>
              <Grid item md={10} sm={6} xs={6}>
                <div className="pt-3 zoom-bar-chart" style={{ paddingLeft: "20px" }}>
                  <h6>
                    <b>Energy Bar Chart - Period over Period</b>
                  </h6>
                </div>
              </Grid>
              <Grid item md={2} sm={6} xs={6}>
                <div className="d-flex gap-3 zoom-out-ico">
                  <div
                    style={{ marginTop: "6px" }}
                    onClick={() =>
                      navigate(
                        `/overView-analytics/company/${param.companyId}/plant/${param.id}`
                      )
                    }
                  >
                    <img src={images.zoomOut} />
                  </div>
                  {/* <h6 className="AGG-dropdown">AGG.</h6>
                  <Box sx={{ minWidth: 200 }} style={{ float: "right" }}>
                    <select
                      className="form-input-class"
                      style={{ padding: "14px 8px", borderRadius: "4px" }}
                      id="selectedClass"
                      required
                    >
                      <option value="none" selected disabled hidden>
                        15 Min
                      </option>
                      <option value="1">15 Min</option>
                      <option value="2">30 Min</option>
                      <option value="3">Hourly</option>
                      <option value="4">Daily</option>
                      <option value="5">Weekly</option>
                    </select>
                  </Box> */}

                  <p className="average-toggle avg-toggle" style={{ marginTop: "8px" }}>
                    Average
                  </p>
                  <Switch
                    className="average-toggle-switch"
                    checked={average}
                    onChange={(e) => setAverage(e.target.checked)}
                    sx={{
                      "& .MuiSwitch-thumb": {
                        bgcolor: "#828282",
                      },
                      "& .MuiSwitch-track": {
                        bgcolor: "#D9D9E5 !important",
                      },
                      "& .Mui-checked": {
                        "& .MuiSwitch-thumb": {
                          bgcolor: "#50C878",
                        },
                        "& .MuiSwitch-track": {
                          bgcolor: "#CEE6E1 !important",
                        },
                      },
                    }}
                  />
                </div>
              </Grid>
            </Grid>

            <div className="p-4">
              <BarChartPOP
                average={average}
                zoomChartData={zoomChartData}
                zoomChartData1={zoomChartData1}
                startTimeFormat1={startTimeFormat1}
                formatEndDate1={formatEndDate1}
                startTimeFormat={startTimeFormat}
                formatEndDate={formatEndDate}
                devicePOPColors={devicePOPColors}
                isShimmer={isShimmer}
              />
            </div>
            <div
              style={{background: "#F5F5F5" }}
              className="grid-overview-data zoom-partition-div"
            >
              <div style={{ display: "flex", justifyContent: "space-between" }} className="zoom-unit-title">
                <div style={{ marginBottom: "20px" }} className="energy-div">
                  <h6 style={{ marginLeft: "20px", marginTop: "30px" }}>
                    <b>Energy [{findPlantTagUnit.length ? findPlantTagUnit[0]["tagUnit"] : ""}]</b>
                  </h6>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end", padding: '10px' }} className="toggle-avg">
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      value="top"
                      control={<Switch color="primary" checked={showTimePop} />}
                      label="Time"
                      labelPlacement="top"
                      onChange={handleToggleTime}
                    />
                  </FormGroup>
                  {showTimePop ? (
                    // <>
                    //   <Box
                    //     style={{
                    //       width: "100%",
                    //       marginTop: "14px",
                    //       marginRight: "9px",
                    //     }}
                    //   >
                    //     <LocalizationProvider dateAdapter={AdapterDayjs}>
                    //       <DateTimePicker
                    //         label="Start Date"
                    //         viewRenderers={{
                    //           hours: renderTimeViewClock,
                    //           minutes: renderTimeViewClock,
                    //           seconds: renderTimeViewClock,
                    //         }}
                    //         className="custom-datepicker datepicker-color"
                    //         value={startDateReFormatPOP}
                    //         onChange={(newValue) =>
                    //           dispatch(
                    //             setOverviewData({ startDatePopRe: newValue })
                    //           )
                    //         }
                    //       />
                    //     </LocalizationProvider>
                    //   </Box>
                    //   <Box
                    //     style={{
                    //       width: "100%",
                    //       marginTop: "14px",
                    //       marginRight: "9px",
                    //     }}
                    //   >
                    //     <LocalizationProvider dateAdapter={AdapterDayjs}>
                    //       <DateTimePicker
                    //         viewRenderers={{
                    //           hours: renderTimeViewClock,
                    //           minutes: renderTimeViewClock,
                    //           seconds: renderTimeViewClock,
                    //         }}
                    //         label="End Date"
                    //         className="custom-datepicker datepicker-color"
                    //         value={endDateReFormatPOP}
                    //         onChange={(newValue) =>
                    //           dispatch(
                    //             setOverviewData({ endDatePopRe: newValue })
                    //           )
                    //         }
                    //       />
                    //     </LocalizationProvider>
                    //   </Box>
                    //   <Box
                    //     style={{
                    //       width: "100%",
                    //       marginTop: "14px",
                    //       marginRight: "9px",
                    //     }}
                    //   >
                    //     <LocalizationProvider dateAdapter={AdapterDayjs}>
                    //       <DateTimePicker
                    //         label="Start Date"
                    //         viewRenderers={{
                    //           hours: renderTimeViewClock,
                    //           minutes: renderTimeViewClock,
                    //           seconds: renderTimeViewClock,
                    //         }}
                    //         className="custom-datepicker second-datepicker-color"
                    //         value={startDateReFormatPOP1}
                    //         onChange={(newValue) =>
                    //           dispatch(
                    //             setOverviewData({ startDatePopRe1: newValue })
                    //           )
                    //         }
                    //       />
                    //     </LocalizationProvider>
                    //   </Box>
                    //   <Box
                    //     style={{
                    //       width: "100%",
                    //       marginTop: "14px",
                    //       marginRight: "9px",
                    //     }}
                    //   >
                    //     <LocalizationProvider dateAdapter={AdapterDayjs}>
                    //       <DateTimePicker
                    //         label="End Date"
                    //         viewRenderers={{
                    //           hours: renderTimeViewClock,
                    //           minutes: renderTimeViewClock,
                    //           seconds: renderTimeViewClock,
                    //         }}
                    //         className="custom-datepicker second-datepicker-color"
                    //         value={endDateReFormatPOP1}
                    //         onChange={(newValue) =>
                    //           dispatch(
                    //             setOverviewData({ endDatePopRe1: newValue })
                    //           )
                    //         }
                    //       />
                    //     </LocalizationProvider>
                    //   </Box>
                    // </>

                    <div className="pop-datepicker-div">
                      <div className="pop-datepicker-wrap1">
                        <div>
                          <Box
                          >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                label="Start Date"
                                className="custom-datepicker datepicker-color"

                                viewRenderers={{
                                  hours: renderTimeViewClock,
                                  minutes: renderTimeViewClock,
                                  seconds: renderTimeViewClock,
                                }}
                                disableFuture
                                value={startDateReFormatPOP}
                                onChange={(newValue) =>
                                  dispatch(
                                    setOverviewData({ startDatePopRe: newValue })
                                  )
                                }
                              />
                            </LocalizationProvider>
                          </Box>
                        </div>
                        <div>
                          <Box
                          // className="header-btn-month"
                          >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                label="End Date"
                                className="custom-datepicker datepicker-color"

                                value={endDateReFormatPOP}
                                onChange={(newValue) =>
                                  dispatch(
                                    setOverviewData({ endDatePopRe: newValue })
                                  )
                                }
                                viewRenderers={{
                                  hours: renderTimeViewClock,
                                  minutes: renderTimeViewClock,
                                  seconds: renderTimeViewClock,
                                }}
                                disableFuture
                              />
                            </LocalizationProvider>
                          </Box>
                        </div>
                      </div>
                      <div className="pop-datepicker-wrap2">
                        <div>
                          <Box
                          // className="box-datepicker"
                          >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                label="Start Date"
                                className="custom-datepicker second-datepicker-color"
                                value={startDateReFormatPOP1}
                                onChange={(newValue) =>
                                  dispatch(
                                    setOverviewData({ startDatePopRe1: newValue })
                                  )
                                }
                                viewRenderers={{
                                  hours: renderTimeViewClock,
                                  minutes: renderTimeViewClock,
                                  seconds: renderTimeViewClock,
                                }}
                                disableFuture
                              />
                            </LocalizationProvider>
                          </Box>
                        </div>
                        <div>
                          <Box
                          >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                label="End Date"
                                className="custom-datepicker second-datepicker-color"
                                value={endDateReFormatPOP1}
                                onChange={(newValue) =>
                                  dispatch(
                                    setOverviewData({ endDatePopRe1: newValue })
                                  )
                                }
                                viewRenderers={{
                                  hours: renderTimeViewClock,
                                  minutes: renderTimeViewClock,
                                  seconds: renderTimeViewClock,
                                }}
                                disableFuture
                              />
                            </LocalizationProvider>
                          </Box>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {/* <Box
                        style={{
                          width: "100%",
                          marginTop: "14px",
                          marginRight: "9px",
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            className="custom-datepicker datepicker-color"
                            label="Start Date"
                            value={startDateReFormatPOP}
                            inputFormat="DD/MM/YYYY"

                            onChange={(newValue) =>
                             handlePOPStartDate(newValue)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{ background: "#ffff" }}
                                InputLabelProps={{
                                  style: {
                                    color: "#C0255F",
                                  },
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                      <Box
                        style={{
                          width: "100%",
                          marginTop: "14px",
                          marginRight: "9px",
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            className="custom-datepicker datepicker-color"
                            label="End Date"
                            value={endDateReFormatPOP}
                            inputFormat="DD/MM/YYYY"

                            onChange={(newValue) =>
                              handlePOP1EndDate(newValue)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{ background: "#ffff" }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                      <Box
                        style={{
                          width: "100%",
                          marginTop: "14px",
                          marginRight: "9px",
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            className="custom-datepicker second-datepicker-color"
                            label="Start Date"
                            value={startDateReFormatPOP1}
                            inputFormat="DD/MM/YYYY"

                            onChange={(newValue) =>
                             handlePOP1StartDate(newValue)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{ background: "#ffff" }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                      <Box
                        style={{
                          width: "100%",
                          marginTop: "14px",
                          marginRight: "9px",
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            className="custom-datepicker second-datepicker-color"
                            label="End Date"
                            value={endDateReFormatPOP1}
                            inputFormat="DD/MM/YYYY"

                            onChange={(newValue) =>
                            handlePOP1EndDate(newValue)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{ background: "#ffff" }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box> */}
                      <div className="pop-datepicker-div">
                        <div className="pop-datepicker-wrap1">
                          <div>
                            <Box
                            // className="box-datepicker"
                            >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  className="custom-datepicker datepicker-color"
                                  label="Start Date"
                                  value={startDateReFormatPOP}
                                  inputFormat="DD/MM/YYYY"

                                  onChange={(newValue) =>
                                    dispatch(
                                      setOverviewData({ startDatePopRe: newValue })
                                    )
                                  }
                                  disableFuture
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      style={{ background: "#ffff" }}
                                      InputLabelProps={{
                                        style: {
                                          color: "#C0255F",
                                        },
                                      }}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Box>
                          </div>
                          <div>
                            <Box
                            // className="header-btn-month"
                            >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  className="custom-datepicker datepicker-color"
                                  label="End Date"
                                  value={endDateReFormatPOP}
                                  inputFormat="DD/MM/YYYY"

                                  onChange={(newValue) =>
                                    dispatch(
                                      setOverviewData({ endDatePopRe: newValue })
                                    )
                                  }
                                  disableFuture
                                  renderInput={(params) => (
                                    <TextField {...params} style={{ background: "#ffff" }} />
                                  )}
                                />
                              </LocalizationProvider>
                            </Box>
                          </div>
                        </div>
                        <div className="pop-datepicker-wrap2">
                          <div>
                            <Box
                            // className="box-datepicker"
                            >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  className="custom-datepicker second-datepicker-color"
                                  label="Start Date"
                                  value={startDateReFormatPOP1}
                                  inputFormat="DD/MM/YYYY"

                                  onChange={(newValue) =>
                                    dispatch(
                                      setOverviewData({ startDatePopRe1: newValue })
                                    )
                                  }
                                  disableFuture
                                  renderInput={(params) => (
                                    <TextField {...params} style={{ background: "#ffff" }} />
                                  )}
                                />
                              </LocalizationProvider>
                            </Box>
                          </div>
                          <div>
                            <Box
                            // className="header-btn-month"
                            >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  className="custom-datepicker second-datepicker-color"
                                  label="End Date"
                                  value={endDateReFormatPOP1}
                                  inputFormat="DD/MM/YYYY"

                                  onChange={(newValue) =>
                                    dispatch(
                                      setOverviewData({ endDatePopRe1: newValue })
                                    )
                                  }
                                  disableFuture
                                  renderInput={(params) => (
                                    <TextField {...params} style={{ background: "#ffff" }} />
                                  )}
                                />
                              </LocalizationProvider>
                            </Box>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div
              className=""
              style={{ maxHeight: "239px", overflowY: "auto" }}
            >
              <Table className="overview-table overview-table1 zoominbarchart-table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "22%" }}></TableCell>
                    <TableCell>Min</TableCell>
                    <TableCell>Max</TableCell>
                    <TableCell>Mean</TableCell>
                    <TableCell>Std Dev</TableCell>
                    <TableCell>All Time Min</TableCell>
                    <TableCell>All Time Max</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell className="zoomchart-td">
                      <Grid item xs={12}>
                        <Grid
                          container
                          alignItems="center"
                          style={{
                            // background: "#efeffd",
                            width: "100%",
                            // marginBottom: px",
                            marginBottom: "0px",
                          }}
                        >
                          <Grid
                            item
                            xs={8}
                            style={{
                              padding: "7px 12px",
                              borderLeft: `4px solid ${findColor ? findColor.color : ""
                                }`,
                            }}
                          >
                            <h6 className="mt-2">
                              {zoomChartData.length
                                ? zoomChartData[0]?.device_name
                                : 0}
                            </h6>
                          </Grid>
                        </Grid>
                      </Grid>
                    </TableCell>

                    <TableCell>
                      {zoomChartData.length
                        ? zoomChartData[0]
                          ? Number(zoomChartData[0].min_value).toFixed(2)
                          : 0
                        : 0}
                    </TableCell>

                    <TableCell>
                      {zoomChartData.length
                        ? zoomChartData[0]
                          ? Number(zoomChartData[0].max_value).toFixed(2)
                          : 0
                        : 0}
                    </TableCell>
                    <TableCell>
                      {zoomChartData.length
                        ? zoomChartData[0]
                          ? typeof zoomChartData[0].mean_value === "number"
                            ? zoomChartData[0].mean_value.toFixed(2)
                            : 0
                          : 0
                        : 0}
                    </TableCell>
                    <TableCell>
                      {zoomChartData.length
                        ? zoomChartData[0]
                          ? typeof zoomChartData[0].std_dev_value === "number"
                            ? zoomChartData[0].std_dev_value.toFixed(2)
                            : 0
                          : 0
                        : 0}
                    </TableCell>
                    <TableCell>
                      {zoomChartData.length
                        ? zoomChartData[0]
                          ? Number(zoomChartData[0].all_time_min_value).toFixed(
                            2
                          )
                          : 0
                        : 0}
                    </TableCell>
                    <TableCell>
                      {zoomChartData.length
                        ? zoomChartData[0]
                          ? Number(zoomChartData[0].all_time_max_value).toFixed(
                            2
                          )
                          : 0
                        : 0}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="zoomchart-td">
                      <Grid item xs={12}>
                        <Grid
                          container
                          alignItems="center"
                          style={{
                            // background: "#efeffd",
                            width: "100%",
                            // marginBottom: px",
                            marginBottom: "0px",
                          }}
                        >
                          <Grid
                            item
                            xs={8}
                            style={{
                              padding: "7px 12px",
                              borderLeft: `4px solid ${findColor ? findColor.color : ""
                                }`,
                            }}
                          >
                            <h6 className="mt-2">
                              {zoomChartData1.length
                                ? zoomChartData1[0]?.device_name
                                : 0}
                            </h6>
                          </Grid>
                        </Grid>
                      </Grid>
                    </TableCell>

                    <TableCell style={{ paddingLeft: "20px !important" }}>
                      {zoomChartData1.length
                        ? zoomChartData1[0]
                          ? Number(zoomChartData1[0].min_value).toFixed(2)
                          : 0
                        : 0}
                    </TableCell>
                    <TableCell>
                      {zoomChartData1.length
                        ? zoomChartData1[0]
                          ? Number(zoomChartData1[0].max_value).toFixed(2)
                          : 0
                        : 0}
                    </TableCell>
                    <TableCell>
                      {zoomChartData1.length
                        ? zoomChartData1[0]
                          ? typeof zoomChartData1[0].mean_value === "number"
                            ? zoomChartData1[0].mean_value.toFixed(2)
                            : 0
                          : 0
                        : 0}
                    </TableCell>
                    <TableCell>
                      {zoomChartData1.length
                        ? zoomChartData1[0]
                          ? typeof zoomChartData1[0].std_dev_value === "number"
                            ? zoomChartData1[0].std_dev_value.toFixed(2)
                            : 0
                          : 0
                        : 0}
                    </TableCell>
                    <TableCell>
                      {zoomChartData1.length
                        ? zoomChartData1[0]
                          ? Number(
                            zoomChartData1[0].all_time_min_value
                          ).toFixed(2)
                          : 0
                        : 0}
                    </TableCell>
                    <TableCell>
                      {zoomChartData1.length
                        ? zoomChartData1[0]
                          ? Number(
                            zoomChartData1[0].all_time_max_value
                          ).toFixed(2)
                          : 0
                        : 0}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Box>
        </div>

        {/* =============== ZoomInEnergyBarChart - responsive design =============== */}
        <Grid container spacing={2} className="mt-2 mb-5"
        >
          <Grid item xs={12} sm={6}>
            <div className="overview-details">
         
              <Grid container alignItems="center" style={{ width: "100%" }}>
                <Grid item xs={8} style={{ padding: "7px 12px", borderLeft: `4px solid ${findColor ? findColor.color : ""}` }}>
                  <h6 className="mt-2">
                    {zoomChartData.length
                      ? zoomChartData[0]?.device_name
                      : 0}
                  </h6>
                </Grid>
              </Grid>
              <div className="overview-details-wrap">
                <div><h4>Min:</h4></div>
                <div><h6>{zoomChartData.length
                  ? zoomChartData[0]
                    ? Number(zoomChartData[0].min_value).toFixed(2)
                    : 0
                  : 0}</h6></div>
              </div>
              <div className="overview-details-wrap">
                <div><h4>Max:</h4></div>
                <div><h6> {zoomChartData.length
                  ? zoomChartData[0]
                    ? Number(zoomChartData[0].max_value).toFixed(2)
                    : 0
                  : 0}</h6></div>
              </div>
              <div className="overview-details-wrap">
                <div><h4>Mean:</h4></div>
                <div><h6>{zoomChartData.length
                  ? zoomChartData[0]
                    ? typeof zoomChartData[0].mean_value === "number"
                      ? zoomChartData[0].mean_value.toFixed(2)
                      : 0
                    : 0
                  : 0}</h6></div>
              </div>
              {isOpen && 
              <>
               <div className="overview-details-wrap">
                <div><h4>Std Dev:</h4></div>
                <div><h6> {zoomChartData.length
                  ? zoomChartData[0]
                    ? typeof zoomChartData[0].std_dev_value === "number"
                      ? zoomChartData[0].std_dev_value.toFixed(2)
                      : 0
                    : 0
                  : 0}</h6></div>
              </div>
              <div className="overview-details-wrap">
                <div><h4>All Time Min:</h4></div>
                <div><h6> {zoomChartData.length
                  ? zoomChartData[0]
                    ? Number(zoomChartData[0].all_time_min_value).toFixed(
                      2
                    )
                    : 0
                  : 0}</h6></div>
              </div>
              <div className="overview-details-wrap">
                <div><h4>All Time Max:</h4></div>
                <div><h6> {zoomChartData.length
                  ? zoomChartData[0]
                    ? Number(zoomChartData[0].all_time_max_value).toFixed(
                      2
                    )
                    : 0
                  : 0}</h6></div>
              </div>
              </>
              }
             
              <img
                src={isOpen ? images.upIcon : images.downIcon}
                alt="toggle visibility"
                className={isOpen ? "up-arrow" : "down-arrow"}
                onClick={() => toggleShowAll(zoomChartData[0].device_id)}
              />
            </div>
          </Grid>


          <Grid item xs={12} sm={6}>
            <div className="overview-details">
              <Grid container alignItems="center" style={{ width: "100%" }}>
                <Grid item xs={8} style={{ padding: "7px 12px", borderLeft: `4px solid ${findColor ? findColor.color : ""}` }}>
                  <h6 className="mt-2">
                    <h6 className="mt-2">
                      {zoomChartData1.length
                        ? zoomChartData1[0]?.device_name
                        : 0}
                    </h6>
                  </h6>
                </Grid>
              </Grid>
              <div className="overview-details-wrap">
                <div><h4>Min:</h4></div>
                <div>
                  <h6> {zoomChartData1.length
                    ? zoomChartData1[0]
                      ? Number(zoomChartData1[0].min_value).toFixed(2)
                      : 0
                    : 0}</h6>

                </div>
              </div>
              <div className="overview-details-wrap">
                <div><h4>Max:</h4></div>
                <div><h6> {zoomChartData1.length
                  ? zoomChartData1[0]
                    ? Number(zoomChartData1[0].max_value).toFixed(2)
                    : 0
                  : 0}</h6></div>
              </div>
              <div className="overview-details-wrap">
                <div><h4>Mean</h4></div>
                <div><h6>{zoomChartData1.length
                  ? zoomChartData1[0]
                    ? typeof zoomChartData1[0].mean_value === "number"
                      ? zoomChartData1[0].mean_value.toFixed(2)
                      : 0
                    : 0
                  : 0}</h6></div>
              </div>
              {isOpen1 && <>
                <div className="overview-details-wrap">
                <div><h4>Std Dev:</h4></div>
                <div><h6>  {zoomChartData1.length
                  ? zoomChartData1[0]
                    ? typeof zoomChartData1[0].std_dev_value === "number"
                      ? zoomChartData1[0].std_dev_value.toFixed(2)
                      : 0
                    : 0
                  : 0}</h6></div>
              </div>
              <div className="overview-details-wrap">
                <div><h4>All Time Min:</h4></div>
                <div><h6> {zoomChartData1.length
                  ? zoomChartData1[0]
                    ? Number(
                      zoomChartData1[0].all_time_min_value
                    ).toFixed(2)
                    : 0
                  : 0}</h6></div>
              </div>
              <div className="overview-details-wrap">
                <div><h4>All Time Max:</h4></div>
                <div><h6>  {zoomChartData1.length
                  ? zoomChartData1[0]
                    ? Number(
                      zoomChartData1[0].all_time_max_value
                    ).toFixed(2)
                    : 0
                  : 0}</h6></div>
              </div>
              </>}
             
              <img
                src={isOpen1 ? images.upIcon : images.downIcon}
                alt="toggle visibility"
                className={isOpen1 ? "up-arrow" : "down-arrow"}
                onClick={() => toggleShowAll(zoomChartData1[0].device_id)}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ZoomInPOPChart;
