import React, { useEffect, useState } from "react";
import Mqqtdata from "../../../ProcessDashboard/Mqqtdata";

const OverViewMachineMqttData = ({
  topics,
  setMqttTagPreviousvalue,
  setSliderRealTimeTagData,
  tagListDataGroupAndList,
}) => {
  const [mqttTagData, setMqttTagData] = useState([]);
  console.log("mqttTagData", mqttTagData, topics);
  useEffect(() => {
    const mergedArray = [].concat(...Object.values(mqttTagData));

    if (tagListDataGroupAndList.length) {
        const filteredArray = mergedArray.filter((obj) => {
            const device = obj.device__uid;
            const deviceTag = obj.device_tag;
        
            return tagListDataGroupAndList.some(
              (item) =>
                item?.machineUID == device &&
                item?.dvName == deviceTag
            );
          });
          setSliderRealTimeTagData(filteredArray);

      setMqttTagPreviousvalue((prev) => {
        const prevValue = [...prev].map((row) => {
          const findTag = filteredArray.find(
            (item) => item.device_tag == row.dvName
          );
          if (findTag) {
            const checkTimeData = findTag.tagDateAndTime === row.dateAndTime;
            if (checkTimeData) {
              return row;
            }
            return {
              dvName: findTag.device_tag,
              prevValue: row.curValue,
              curValue: findTag.tagValue,
              dateAndTime: findTag.tagDateAndTime,
            };
          }
          return row;
        });


        return prevValue;
      });
    }
  }, [mqttTagData]);

  return (
    <>
      <Mqqtdata setMqttTagData={setMqttTagData} topics={topics} />
    </>
  );
};

export default OverViewMachineMqttData;
