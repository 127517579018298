import React, { useEffect, useState } from "react";
import {
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Popper,
} from "@mui/material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { images } from "../../../../config/images";
import { useNavigate, useParams } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import ErrorIcon from "@mui/icons-material/Error";
import TableRow from "@mui/material/TableRow";
import {
  deviceTypeWiseDevice,
  deviceTypeWiseReportGateway,
  deviceTypeWiseReportMachine,
  getDeviceAPI,
  getDeviceProduct,
  getDeviceTypeByProduct,
} from "../service";
import {
  ArrowDropDownIcon,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { ToastContainer, toast } from "react-toastify";

const ReportManagementReportList = ({
  selectDeviceId,
  setSelectDeviceId,
  selectEmailSchedule,
  setSelectEmailSchedule,
  selectAGG,
  setSelectAGG,
  selectDate,
  setSelectTime,
  selectDeviceType,
  setSelectDevicetype,
  setActiveClass,
  reportData,
  configureDeviceData,
  setConfigureDeviceData,
}) => {
  const [deviceList, setDeviceList] = useState([]);
  const [openAssignDeviceModal, setOpenAssignDeviceModal] = useState(false);
  const [reportGateway, setReportGateway] = useState([]);
  const [reportMachineData, setReportMachineData] = useState([]);
  const [reportDeviceData, setReportDeviceData] = useState([]);
  const [machineName, setMachineName] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [assignDeviceIdArray, setAssignDeviceIdArray] = useState([]);
  const [selectedDeviceDetails, setSelectedDeviceDetails] = useState([]);
  const [selectedGateway, setSelectedGateway] = useState(null);
  const [selectedMachineId, setSelectedMachineId] = useState(null);
  const [selectgatewayDeviceId, setSelectGatewayDeviceId] = useState([]);
  const [getProduct, setGetProduct] = useState([]);
  const [getProductId, setProductId] = useState("");
  const [getProductName, setProductName] = useState("");
  const [getDeviceByTypeData, setDeviceByTypeData] = useState([]);

  console.log("getDeviceByTypeData", selectDeviceType);

  // const [selectDeviceType, setSelectDevicetype] = useState("Consumption");

  useEffect(() => {
    setAnchorEl(null);
  }, []);

  // useEffect(() => {
  //   setSelectedDeviceDetails(reportData.device);
  // }, [reportData]);
  useEffect(() => {
    if (openAssignDeviceModal) {
      setSelectGatewayDeviceId(selectDeviceId);
      setSelectedDeviceDetails(configureDeviceData);
    }
  }, [openAssignDeviceModal]);

  const openMachine = Boolean(anchorEl);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const navigate = useNavigate();
  const param = useParams();

  useEffect(() => {
    handleGetDeviceProduct();
  }, []);

  const handleGetDeviceProduct = async () => {
    const params = {
      plant_id: Number(param.plantId),
    };
    try {
      const resp = await getDeviceProduct(params);
      if (resp.status == 200 || resp.status == 201) {
        setGetProduct(resp.data.products);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  useEffect(() => {
    handleProductData();
  }, [getProductId]);

  const handleProductData = async () => {
    if (getProductName == "HikEMM") {
      const params = {
        product: getProductId,
      };
      try {
        const resp = await getDeviceTypeByProduct(params);
        if (resp.status == 200 || resp.status == 201) {
          setDeviceByTypeData(resp.data.payload);
        }
      } catch (error) {
        console.log("handleSubmit", error);
      }
    }
  };

  const handleCheckBoxClick = async (e) => {
    setProductId(e.id);
    setProductName(e.name);
    if (selectDeviceType === e.name) {
      setSelectDevicetype(""); // Deselect if same checkbox is clicked again
    } else {
      setSelectDevicetype(e.name);
    }
  };

  const handleCheckBoxClick1 = (device) => {
    // Deselect previously selected checkbox
    if (selectDeviceType === device.device_type_name) {
      setSelectDevicetype(""); // Deselect if same checkbox is clicked again
    } else {
      setSelectDevicetype(device.device_type_name);
    }
  };
  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleSelectDevice = (id) => {
    if (!Array.isArray(selectDeviceId)) {
      setSelectDeviceId([]);
    }
    if (selectDeviceId.includes(id)) {
      setSelectDeviceId(selectDeviceId.filter((element) => element !== id));
    } else {
      setSelectDeviceId([...selectDeviceId, id]);
    }
  };

  useEffect(() => {
    if (openAssignDeviceModal) {
      handleReportGateway();
    }
  }, [openAssignDeviceModal]);

  const handleReportGateway = async () => {
    if (selectDeviceType !== "HikEMM") {
      const params = {
        device_type: selectDeviceType,
        plant: Number(param.plantId),
      };
      try {
        const resp = await deviceTypeWiseReportGateway(params);
        if (resp.status == 200 || resp.status == 201) {
          setReportGateway(resp.data.payload);
        }
      } catch (error) {
        console.log("handlesubmit", error);
      }
    }
  };

  const handleClick = async (id, event) => {
    const params = {
      device_type: selectDeviceType,
      plant: Number(param.plantId),
      gateway: id,
    };
    try {
      const resp = await deviceTypeWiseReportMachine(params);
      if (resp.status == 200 || resp.status == 201) {
        setReportMachineData(resp.data.payload);
        setSelectedGateway((prevState) => (prevState === id ? null : id));
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  const handleMachineClick = async (id, e) => {
    setMachineName(e.machine_name);
    setSelectedMachineId(id);
    const params = {
      device_type: selectDeviceType,
      plant: Number(param.plantId),
      gateway: e.gateway,
      machine: id,
    };
    try {
      const resp = await deviceTypeWiseDevice(params);
      if (resp.status == 200 || resp.status == 201) {
        setReportDeviceData(resp.data.payload);
        const getDeviceId = resp.data.payload.map((e) => e.id);

        if (getDeviceId.every((value) => selectDeviceId.includes(value))) {
          setSelectAll(true);
        } else {
          setSelectAll(false);
          // console.log("selected",selected);
        }
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  // const handleSelectAll = () => {
  //   setSelectAll(!selectAll);
  //   if (!selectAll) {
  //     // If "Select All" is checked, select all devices
  //     const allDeviceIds = reportDeviceData.map((e) => e.id);
  //     setAssignDeviceIdArray(allDeviceIds);
  //   } else {
  //     // If "Select All" is unchecked, clear the selection
  //     setAssignDeviceIdArray([]);
  //   }
  // };
  const handleCheckBox = (id) => {
    if (selectDeviceId.includes(id)) {
      setSelectDeviceId(selectDeviceId.filter((d) => d !== id));
      setSelectAll(false); // Uncheck "Select All" if any checkbox is deselected
    } else {
      setSelectDeviceId([...selectDeviceId, id]);
      setSelectAll(true);
    }
  };

  // model clicked
  const handleModelDeviceID = (id, e) => {
    if (selectgatewayDeviceId.includes(id)) {
      setSelectGatewayDeviceId(selectgatewayDeviceId.filter((d) => d !== id));
      setSelectedDeviceDetails(selectedDeviceDetails.filter((f) => f.id != id));
    } else {
      setSelectGatewayDeviceId([...selectgatewayDeviceId, id]);
      setSelectedDeviceDetails([...selectedDeviceDetails, e]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectGatewayDeviceId(
        selectgatewayDeviceId.filter(
          (d) => !reportDeviceData.map((e) => e.id).includes(d)
        )
      );
      setSelectedDeviceDetails(
        selectedDeviceDetails.filter(
          (d) => !reportDeviceData.map((e) => e.id).includes(d.id)
        )
      );
    } else {
      setSelectGatewayDeviceId(
        [...selectgatewayDeviceId].concat(reportDeviceData.map((e) => e.id))
      );
      setSelectedDeviceDetails(
        [...selectedDeviceDetails].concat(reportDeviceData.map((e) => e))
      );
    }
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = (deviceId) => {
    const updatedSelectedDevices = [...selectDeviceId];
    const index = updatedSelectedDevices.indexOf(deviceId);

    if (index === -1) {
      updatedSelectedDevices.push(deviceId);
    } else {
      updatedSelectedDevices.splice(index, 1);
    }

    setSelectDeviceId(updatedSelectedDevices);
  };

  // const handleSubmit = () => {
  //   const selectedDetails = reportDeviceData
  //     .filter((e) => selectDeviceId.includes(e.id))
  //     .map(({ id, device_name, uid, device_description }) => ({
  //       id,
  //       device_name,
  //       uid,
  //       device_description,
  //     }));

  //   setSelectedDeviceDetails(selectedDetails);
  //   setOpenAssignDeviceModal(false);
  // };

  const handleSubmit = () => {
    // Filter out the selected devices that were already in the selectedDeviceDetails
    // const newSelectedDevices = selectDeviceId.filter(
    //   (deviceId) =>
    //     !selectedDeviceDetails.some((detail) => detail.id === deviceId)
    // );

    // // Map the newly selected devices to their details
    // const newSelectedDetails = newSelectedDevices.map((deviceId) => {
    //   const device = reportDeviceData.find((device) => device?.id === deviceId);

    //   return {
    //     id: device?.id,
    //     device_name: device?.device_name,
    //     machine_uid: device?.machine_uid,
    //     device_description: device?.device_description,
    //   };
    // });

    // const updatedDetails = [...selectedDeviceDetails, ...newSelectedDetails];

    // setSelectedDeviceDetails(updatedDetails);
    // setOpenAssignDeviceModal(false);
    setConfigureDeviceData(selectedDeviceDetails);
    setSelectDeviceId(selectgatewayDeviceId);
    setOpenAssignDeviceModal(false);
  };

  const handleCloseMode = () => {
    setSelectGatewayDeviceId(selectDeviceId);
    setConfigureDeviceData(configureDeviceData);
    setSelectDeviceId(selectDeviceId);
    setOpenAssignDeviceModal(false);
  };

  const handleAddDevice = () => {
    if (selectDeviceType !== "HikEMM") {
      setOpenAssignDeviceModal(true);
    } else {
      notify("Please select one device type(Source / Consumption)");
    }
  };
  return (
    <>
      <Grid container style={{ paddingTop: "20px" }}>
        <Grid item xs={12}>
          <Card className="manageDepartment-card">
            <Grid
              container
              style={{
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingTop: "30px",
                paddingBottom: "30px",
              }}
            >
              {/* =============== Tag List Modal Start =============== */}

              {/* =============== Tag List Modal End =============== */}
              <Grid item sm={12} md={12} lg={3} xl={4}>
                <Typography variant="subtitle2" gutterBottom>
                  <b>Report Type</b>
                </Typography>
                <div className="reportManagement-schedule-card">
                  {getProduct.map((product) => (
                    <div key={product.id}>
                      <div className="alarmManagementCard-Roleswrap">
                        <div>
                          <Checkbox
                            {...label}
                            style={{
                              color: "#1C407B",
                            }}
                            checked={selectDeviceType === product.name}
                            onClick={() => handleCheckBoxClick(product)}
                          />
                        </div>
                        <div>
                          <Typography
                            variant="body2"
                            gutterBottom
                            className="m-0"
                          >
                            {product.name}
                          </Typography>
                        </div>
                      </div>
                      {product.name === "HikEMM" &&
                        getDeviceByTypeData.map((device) => (
                          <div
                            key={device.id}
                            className="alarmManagementCard-Roleswrap"
                            style={{ marginLeft: "32px" }}
                          >
                            <div>
                              <Checkbox
                                {...label}
                                style={{
                                  color: "#1C407B",
                                }}
                                checked={
                                  selectDeviceType === device.device_type_name
                                }
                                onClick={() => handleCheckBoxClick1(device)}
                              />
                            </div>
                            <div>
                              <Typography
                                variant="body2"
                                gutterBottom
                                className="m-0"
                              >
                                {device.device_type_name}
                              </Typography>
                            </div>
                          </div>
                        ))}
                    </div>
                  ))}

                  {/* <div className="alarmManagementCard-Roleswrap">
                    <div>
                      <Checkbox
                        {...label}
                        style={{
                          color: "#1C407B",
                        }}
                        checked={selectDeviceType == "Source"}
                        onClick={() => setSelectDevicetype("Source")}
                      />
                    </div>
                    <div>
                      <Typography variant="body2" gutterBottom className="m-0">
                        Source
                      </Typography>
                    </div>
                  </div>
                  <div className="alarmManagementCard-Roleswrap">
                    <div>
                      <Checkbox
                        {...label}
                        style={{
                          color: "#1C407B",
                        }}
                        checked={selectDeviceType == "Other"}
                        onClick={() => setSelectDevicetype("Other")}
                      />
                    </div>
                    <div>
                      <Typography variant="body2" gutterBottom className="m-0">
                        Other
                      </Typography>
                    </div>
                  </div> */}
                  {/* <div className='alarmManagementCard-Roleswrap'>
                                        <div>
                                            <Checkbox
                                                {...label}
                                                defaultChecked
                                                style={{
                                                    color: "#1C407B",
                                                }} />
                                        </div>
                                        <div>
                                            <Typography variant="body2" gutterBottom className='m-0'>
                                                Advance Analytics Summary
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className='alarmManagementCard-Roleswrap'>
                                        <div>
                                            <Checkbox
                                                {...label}
                                                defaultChecked={false}
                                                style={{
                                                    color: "#1C407B",
                                                }} />
                                        </div>
                                        <div>
                                            <Typography variant="body2" gutterBottom className='m-0'>
                                                Machine Running Hours
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className='alarmManagementCard-Roleswrap'>
                                        <div>
                                            <Checkbox
                                                {...label}
                                                defaultChecked={false}
                                                style={{
                                                    color: "#1C407B",
                                                }} />
                                        </div>
                                        <div>
                                            <Typography variant="body2" gutterBottom className='m-0'>
                                                Energy Cost
                                            </Typography>
                                        </div>
                                    </div> */}
                </div>
              </Grid>
              <Grid item sm={12} md={12} lg={6} xl={4}>
                <Typography variant="subtitle2" gutterBottom>
                  <b>Devices</b>
                </Typography>
                <div className="reportManagement-schedule-card">
                  <div className="d-flex justify-content-end">
                    <Typography variant="p" display="lock" gutterBottom>
                      <b style={{ marginRight: "10px" }}>Select Device</b>
                    </Typography>
                    <img
                      src={images.addIcon}
                      alt=""
                      className="addDeviceImg"
                      style={{ height: "23px", cursor: "pointer" }}
                      onClick={handleAddDevice}
                    />
                  </div>
                  {configureDeviceData?.length
                    ? configureDeviceData?.map((row) => {
                        return (
                          <Card className="addDeviceDetailsCard gap">
                            <Box className="addDeviceCardDetailsWrap">
                              <Box className="addDeviceDetailsCardetails-wrap">
                                <Checkbox
                                  {...label}
                                  className="signIncheckBox"
                                  style={{
                                    color: "#1C407B",
                                  }}
                                  checked={selectDeviceId.includes(row.id)}
                                  onClick={() => {
                                    if (openAssignDeviceModal) {
                                      return true;
                                    }
                                    handleCheckBox(row.id);
                                  }}
                                />
                                <Box className="addDeviceDetailsCardetails">
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    style={{ marginBottom: "0px" }}
                                  >
                                    <b>{row.device_name}</b>
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className="addDeviceDetailsCardetails-text"
                                    style={{ marginBottom: "0px" }}
                                  >
                                    UID - {row.machine_uid}
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className="addDeviceDetailsCardetails-text"
                                    style={{ marginBottom: "0px" }}
                                  >
                                    {row.device_description}
                                  </Typography>
                                </Box>
                              </Box>
                              {/* <button
                                                        className="reportManagement-deviceLists-btn"
                                                    >
                                                        Tag List
                                                    </button> */}
                            </Box>
                          </Card>
                        );
                      })
                    : []}
                </div>
              </Grid>
              <Grid item sm={12} md={12} lg={3} xl={4}>
                <Typography variant="subtitle2" gutterBottom>
                  <b>Schedule</b>
                </Typography>
                <div className="reportManagement-schedule-card">
                  <Typography
                    variant="body2"
                    gutterBottom
                    className="m-0"
                    style={{ fontWeight: "500" }}
                  >
                    AGG.
                  </Typography>
                  {selectEmailSchedule == "Daily" && (
                    <>
                      <select
                        class="form-select form-select-lg reportManagement-schedule-dropdown"
                        aria-label=".form-select-lg example"
                        value={selectAGG}
                        onChange={(e) => setSelectAGG(e.target.value)}
                      >
                        <option value="none" selected disabled hidden>
                          1 Min
                        </option>
                        <option value="1">1 Min</option>
                        <option value="3">3 Min</option>
                        <option value="5">5 Min</option>
                        <option value="10">10 Min</option>
                        <option value="15">15 Min</option>
                      </select>
                    </>
                  )}
                  {selectEmailSchedule == "Weekly" && (
                    <>
                      <select
                        class="form-select form-select-lg reportManagement-schedule-dropdown"
                        aria-label=".form-select-lg example"
                        value={selectAGG}
                        onChange={(e) => setSelectAGG(e.target.value)}
                      >
                        <option value="none" selected disabled hidden>
                          5 Min
                        </option>
                        <option value="5">5 Min</option>
                        <option value="10">10 Min</option>
                        <option value="15">15 Min</option>
                        <option value="24">Daily</option>
                      </select>
                    </>
                  )}

                  {selectEmailSchedule == "Monthly" && (
                    <>
                      <select
                        class="form-select form-select-lg reportManagement-schedule-dropdown"
                        aria-label=".form-select-lg example"
                        value={selectAGG}
                        onChange={(e) => setSelectAGG(e.target.value)}
                      >
                        <option value="none" selected disabled hidden>
                          15 Min
                        </option>
                        <option value="15">15 Min</option>
                        <option value="24">Daily</option>
                        <option value="7">Weekly</option>
                      </select>
                    </>
                  )}

                  {selectEmailSchedule == "Years" && (
                    <>
                      <select
                        class="form-select form-select-lg reportManagement-schedule-dropdown"
                        aria-label=".form-select-lg example"
                        value={selectAGG}
                        onChange={(e) => setSelectAGG(e.target.value)}
                      >
                        <option value="none" selected disabled hidden>
                          Daily
                        </option>
                        <option value="24">Daily</option>
                        <option value="7">Weekly</option>
                        <option value="30">Monthly</option>
                      </select>
                    </>
                  )}
                  <Typography
                    variant="body2"
                    gutterBottom
                    className="m-0 pt-4"
                    style={{ fontWeight: "500" }}
                  >
                    Email Schedule
                  </Typography>
                  <select
                    class="form-select form-select-lg reportManagement-schedule-dropdown"
                    aria-label=".form-select-lg example"
                    value={selectEmailSchedule}
                    onChange={(e) => setSelectEmailSchedule(e.target.value)}
                  >
                    <option value="none" selected disabled hidden>
                      Weekly
                    </option>
                    <option value="Daily">Daily</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Years">Years</option>
                  </select>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className="m-0 pt-4"
                    style={{ fontWeight: "500" }}
                  >
                    Date Time
                  </Typography>

                  {selectEmailSchedule == "Daily" && (
                    <Box>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["TimePicker"]}>
                          <TimePicker
                            label="With Time Clock"
                            value={selectDate}
                            className="custom-datepicker"
                            onChange={(newValue) => setSelectTime(newValue)}
                            viewRenderers={{
                              hours: renderTimeViewClock,
                              minutes: renderTimeViewClock,
                              seconds: renderTimeViewClock,
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Box>
                  )}

                  {selectEmailSchedule == "Weekly" && (
                    <Box>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label="select Date"
                          value={selectDate}
                          onChange={(newValue) => setSelectTime(newValue)}
                          className="custom-datepicker"
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                        />
                      </LocalizationProvider>
                    </Box>
                  )}

                  {selectEmailSchedule == "Monthly" && (
                    <Box>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label="select Date"
                          value={selectDate}
                          onChange={(newValue) => setSelectTime(newValue)}
                          className="custom-datepicker"
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                        />
                      </LocalizationProvider>
                    </Box>
                  )}

                  {selectEmailSchedule == "Years" && (
                    <Box>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label="select Date"
                          value={selectDate}
                          onChange={(newValue) => setSelectTime(newValue)}
                          className="custom-datepicker"
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                        />
                      </LocalizationProvider>
                    </Box>
                  )}
                  <Typography variant="body2" gutterBottom className="m-0 pt-4">
                    <b>Note:</b> To ensure timely execution, please schedule
                    reports at least 1 hour after the current time to avoid
                    delays in processing
                  </Typography>
                </div>
                <button
                  className={
                    selectDeviceId.length
                      ? "Plantmanagemet submit"
                      : "alarmManagement-submit-disable"
                  }
                  onClick={() => {
                    if (openAssignDeviceModal) {
                      notify("Please close the popup");
                      return true;
                    }

                    setActiveClass("User");
                  }}
                >
                  Next
                </button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid></Grid>
      </Grid>
      {openAssignDeviceModal && (
        <>
          <div className="loader-container">
            <div className="select-gateway-popup">
              {/* <div className="d-flex devices-text"> */}
              <div style={{ display: "flex" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={5}>
                    <h5 style={{ color: "#1C407B" }}>
                      <b>Assign Device</b>
                    </h5>
                  </Grid>
                  <Grid item xs={12} sm={7}></Grid>
                </Grid>
              </div>

              <Grid container spacing={2} className="pt-2">
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box className="devicemodal-box">
                    <h6 style={{ fontWeight: 600 }}>Select Gateway</h6>

                    <div
                      style={{
                        height: "420px",
                        overflow: "auto",
                        position: "relative",
                      }}
                    >
                      {reportGateway?.length ? (
                        reportGateway?.map((gateway, index) => (
                          <div key={gateway.id}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "13px 8px",
                                background: "white",
                                cursor: "pointer",
                                marginTop: index > 0 ? "4px" : "0", // Add margin top for all but the first gateway
                              }}
                              onClick={(event) =>
                                handleClick(gateway.id, gateway)
                              }
                            >
                              {gateway.gateway_name} <ArrowDropDownIcon />
                            </div>
                            {selectedGateway === gateway.id && (
                              <div
                                style={{ maxHeight: "300px", overflow: "auto" }}
                              >
                                {reportMachineData.map((machine) => (
                                  <div
                                    key={machine.id}
                                    style={{
                                      padding: "7px 25px",
                                      cursor: "pointer",
                                      boxShadow: "0px 6px 20px #6D6D6D1A",
                                      background: "#ffff",
                                      borderRight:
                                        selectedMachineId === machine.id
                                          ? "3px solid #1c407b"
                                          : "none",
                                    }}
                                    onClick={() =>
                                      handleMachineClick(machine.id, machine)
                                    }
                                  >
                                    <h6 className="machine-title">
                                      {machine.machine_name}
                                    </h6>
                                    <p className="machine-description">
                                      {machine.uid}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        ))
                      ) : (
                        <div className="gateway-error-div">
                          <ErrorIcon className="gateway-error-icon" />
                          <h5 className="mt-2">No data found.</h5>
                        </div>
                      )}
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box className="devicemodal-box">
                    <div className="d-flex justify-content-between">
                      {machineName ? (
                        <h6 style={{ fontWeight: 600 }}>
                          {machineName} - Device List
                        </h6>
                      ) : (
                        <h6></h6>
                      )}
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              style={{
                                color: "#1C407B",
                                padding: "0px",
                                marginRight: "3px",
                              }}
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                          }
                          label={
                            <Typography style={{ fontSize: "15px" }}>
                              All
                            </Typography>
                          }
                        />
                      </FormGroup>
                    </div>

                    <div
                      className="pt-2 mb-2"
                      style={{
                        height: "420px",
                        overflow: "auto",
                      }}
                    >
                      {reportDeviceData?.length ? (
                        reportDeviceData?.map((e) => (
                          <div
                            key={e.id}
                            className="assignDeviceModalWrap mb-2"
                            style={{ background: "white" }}
                          >
                            <div className="assignDeviceDetails">
                              <div className="assignDeviceDetailsWrap">
                                <div>
                                  <Checkbox
                                    style={{ color: "#1C407B" }}
                                    checked={selectgatewayDeviceId.includes(
                                      e.id
                                    )}
                                    onClick={() => handleModelDeviceID(e.id, e)}
                                  />
                                </div>
                                <div>
                                  <Typography
                                    variant="body2"
                                    gutterBottom
                                    className="assignDeviceDetailsTitle"
                                  >
                                    {e.device_name}
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className="assignDeviceDetailsDisc"
                                  >
                                    {e.device_description}
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="gateway-error-div">
                          <ErrorIcon className="gateway-error-icon" />
                          <h5 className="mt-2">No data found.</h5>
                        </div>
                      )}
                    </div>
                  </Box>
                </Grid>
              </Grid>

              <div className="text-center pt-5">
                <button
                  className="canclebtn"
                  onClick={() => handleCloseMode()}
                  // onClick={(e) => handleCloseConfigrationClick(e)}
                >
                  Cancel
                </button>
                <button className="submitbtn" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default ReportManagementReportList;
