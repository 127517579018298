import React from "react";
import Typography from "@mui/material/Typography";
import "./FooterText.css";
import SubscriptionFooter from "../../container/SuperAdminDashboard/SubscriptionManagement/SubscriptionFooter";
import { useLocation } from "react-router-dom";

function FooterText() {
  const location = useLocation();

  return (
    <>
      {location.pathname.split("/")[1] == "subscription" ? (
        <Typography
          variant="subtitle2"
          gutterBottom
          style={{
            backgroundColor: "#F1F1FF",
            color: "#4D5963",
            textAlign: "end",
            margin: "0",
            position: "fixed",
            bottom: "0px",
            right: "0",
            padding: "1px",
            width: "100%",
            zIndex: "99",
            paddingRight: "23px",
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          <SubscriptionFooter />
          <div>
            Powered by{" "}
            <b style={{ fontWeight: "900" }}>Hikar®Technomation(p) Ltd</b> ©
            2024. All Rights Reserved
          </div>
        </Typography>
      ) : (
        <Typography
          variant="subtitle2"
          gutterBottom
          style={{
            backgroundColor: "#F1F1FF",
            color: "#4D5963",
            textAlign: "end",
            margin: "0",
            position: "fixed",
            bottom: "0px",
            right: "0",
            padding: "1px",
            width: "100%",
            zIndex: "99",
            paddingRight: "23px",
          }}
        >
          <div className="footer-text-title">
            Powered by{" "}
            <b style={{ fontWeight: "900" }}>Hikar®Technomation(p) Ltd</b> ©
            2024. All Rights Reserved
          </div>
        </Typography>
      )}
      {location.pathname.split("/")[1] == "editsubscription" ? (
        <Typography
          variant="subtitle2"
          gutterBottom
          style={{
            backgroundColor: "#F1F1FF",
            color: "#4D5963",
            textAlign: "end",
            margin: "0",
            position: "fixed",
            bottom: "0px",
            right: "0",
            padding: "1px",
            width: "100%",
            zIndex: "99",
            paddingRight: "23px",
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          <div className="sub-footertext">
            <div className="active-plan"></div> Assigned
            <div className="not-activated-plan"></div> Unassigned
          </div>
          <div className="footer-text-title">
            Powered by{" "}
            <b style={{ fontWeight: "900" }}>Hikar®Technomation(p) Ltd</b> ©
            2024. All Rights Reserved
          </div>
        </Typography>
      ) : null}
    </>
  );
}

export default FooterText;
