import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import {
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Popper,
} from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { useNavigate, useParams } from "react-router-dom";
import { images } from "../../../../config/images";
import Checkbox from "@mui/material/Checkbox";
import { useEffect } from "react";
import {
  getDeviceListData,
  getGroupConfiguration,
  getPlantDeviceAlarmManagement,
  getPlantGatewayAlarmmanagement,
  getPlantMachineAlarmManagement,
} from "../services";
import { getDeviceList } from "../../../ProcessDashboard/Configration/services";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { ToastContainer, toast } from "react-toastify";
import ErrorIcon from "@mui/icons-material/Error";

const AlarmManagementDeviceList = ({
  selectedDevices,
  setSelectedDevices,
  onSelectedDevicesChange,
  setActiveClass,
  getConfigurationData,
  configureDeviceData,
  setConfigureDeviceData,
}) => {
  const [getDeviceData, setGetDeviceData] = useState([]);
  const [openAssignDeviceModal, setOpenAssignDeviceModal] = useState(false);
  const [plantGateway, setPlantGateway] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [machineData, setMachineData] = useState([]);
  const [machineName, setMachineName] = useState("");
  const [deviceData, setDeviceData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [assignDeviceIdArray, setAssignDeviceIdArray] = useState([]);
  const [selectDeviceListArray, setSelectDeviceListArray] = useState([]);
  const [selectedDeviceDetails, setSelectedDeviceDetails] = useState([]);
  const [selectedMachineId, setSelectedMachineId] = useState(null);
  const [selectedGateway, setSelectedGateway] = useState(null);
  const [selectgatewayDeviceId, setSelectGatewayDeviceId] = useState([]);
  useEffect(() => {
    setAnchorEl(null);
  }, []);

  useEffect(() => {
    if (openAssignDeviceModal) {
      setSelectGatewayDeviceId(selectedDevices);
      setSelectedDeviceDetails(configureDeviceData);
    }
  }, [openAssignDeviceModal]);

  console.log(
    "selectedDeviceDetailsselectedDeviceDetails",
    selectedDeviceDetails,
    selectgatewayDeviceId,
    selectedDevices
  );

  const openMachine = Boolean(anchorEl);

  const navigate = useNavigate();
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const param = useParams();

  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  useEffect(() => {
    handleGetDeviceData();
  }, []);
  const handleGetDeviceData = async () => {
    const params = {
      plant_id: param.plantId,
    };
    const resp = await getDeviceList(params);
    if (resp.status === 200 || resp.status === 201) {
      setGetDeviceData(resp.data.payload);
    }
  };

  const handleCheckboxChange = (deviceId) => {
    const updatedSelectedDevices = [...selectedDevices];
    const index = updatedSelectedDevices.indexOf(deviceId);

    if (index === -1) {
      updatedSelectedDevices.push(deviceId);
    } else {
      updatedSelectedDevices.splice(index, 1);
    }

    setSelectedDevices(updatedSelectedDevices);
  };

  useEffect(() => {
    handlePlantGateway();
  }, []);

  const handlePlantGateway = async () => {
    const params = {
      plant_id: param.plantId,
    };
    try {
      const resp = await getPlantGatewayAlarmmanagement(params);
      if (resp.status == 200 || resp.status == 201) {
        setPlantGateway(resp.data.payload);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  const handleClick = async (id, event) => {
    const params = {
      plant: Number(param.plantId),
      gateway: id,
    };
    try {
      const resp = await getPlantMachineAlarmManagement(params);
      if (resp.status == 200 || resp.status == 201) {
        setMachineData(resp.data.payload);
        setSelectedGateway((prevState) => (prevState === id ? null : id));
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  const handleMachineClick = async (id, e) => {
    setMachineName(e.machine_name);
    setSelectedMachineId(id);
    const params = {
      plant: Number(param.plantId),
      gateway: e.gateway,
      machine: id,
    };
    try {
      const resp = await getPlantDeviceAlarmManagement(params);
      if (resp.status == 200 || resp.status == 201) {
        setDeviceData(resp.data.payload);
        const getDeviceId = resp.data.payload.map((e) => e.id);

        if (
          getDeviceId.every((value) => selectgatewayDeviceId.includes(value))
        ) {
          setSelectAll(true);
        } else {
          setSelectAll(false);
          // console.log("selected",selected);
        }
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  useEffect(() => {
    const getDeviceId = deviceData.map((e) => e.id);
    if (
      getDeviceId.length &&
      getDeviceId.every((value) => selectgatewayDeviceId.includes(value))
    ) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
      // console.log("selected",selected);
    }
  }, [selectgatewayDeviceId]);

  // const handleSubmit = () => {
  //   const selectedDetails = deviceData
  //     .filter((e) => selectedDevices.includes(e.id))
  //     .map(({ id, device_name, uid, device_description }) => ({
  //       id,
  //       device_name,
  //       uid,
  //       device_description,
  //     }));

  //   setSelectedDeviceDetails(selectedDetails);
  //   setOpenAssignDeviceModal(false);
  // };
  const handleSubmit = () => {
    setConfigureDeviceData(selectedDeviceDetails);
    setSelectedDevices(selectgatewayDeviceId);
    setOpenAssignDeviceModal(false);
  };

  const handleCloseMode = () => {
    setSelectGatewayDeviceId(selectedDevices);
    setConfigureDeviceData(configureDeviceData);
    setSelectedDevices(selectedDevices);
    setOpenAssignDeviceModal(false);
  };

  const handleCheckBox = (id) => {
    if (selectedDevices.includes(id)) {
      setSelectedDevices(selectedDevices.filter((d) => d !== id));
      setSelectAll(false); // Uncheck "Select All" if any checkbox is deselected
    } else {
      setSelectedDevices([...selectedDevices, id]);
      setSelectAll(true);
    }
  };

  // model clicked
  const handleModelDeviceID = (id, e) => {
    if (selectgatewayDeviceId.includes(id)) {
      setSelectGatewayDeviceId(selectgatewayDeviceId.filter((d) => d !== id));
      setSelectedDeviceDetails(selectedDeviceDetails.filter((f) => f.id != id));
    } else {
      setSelectGatewayDeviceId([...selectgatewayDeviceId, id]);
      setSelectedDeviceDetails([...selectedDeviceDetails, e]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectGatewayDeviceId(
        selectgatewayDeviceId.filter(
          (d) => !deviceData.map((e) => e.id).includes(d)
        )
      );
      setSelectedDeviceDetails(
        selectedDeviceDetails.filter(
          (d) => !deviceData.map((e) => e.id).includes(d.id)
        )
      );
    } else {
      setSelectGatewayDeviceId(
        [...selectgatewayDeviceId].concat(deviceData.map((e) => e.id))
      );
      setSelectedDeviceDetails(
        [...selectedDeviceDetails].concat(deviceData.map((e) => e))
      );
    }
    setSelectAll(!selectAll);
  };

  // select DeviceIdArray
  const handleSelectAssingDeviceId = (id) => {
    if (!Array.isArray(assignDeviceIdArray)) {
      setAssignDeviceIdArray([]);
    }
    if (assignDeviceIdArray.includes(id)) {
      setAssignDeviceIdArray(
        assignDeviceIdArray.filter((element) => element !== id)
      );
    } else {
      setAssignDeviceIdArray([...assignDeviceIdArray, id]);
    }
  };
  const popperWidth = plantGateway.length >= 8 ? 320 : 340;
  return (
    <>
      <Card className="alarmMangement-deviceList-card">
        <div className="alarmMangement-deviceList-title d-flex justify-content-between">
          <div>
            <Typography variant="subtitle2" gutterBottom>
              <b>Select Device</b>
            </Typography>
          </div>
          <div>
            <Typography variant="p" display="lock" gutterBottom>
              <b style={{ marginRight: "10px" }}>Select Device</b>
            </Typography>
            <img
              src={images.addIcon}
              alt=""
              className="addDeviceImg"
              style={{ cursor: "pointer" }}
              onClick={() => setOpenAssignDeviceModal(true)}
            />
          </div>
        </div>
        <div className="alarmManagement-device">
          {configureDeviceData?.map((detail) => (
            <div
              key={detail.id}
              className="alarmManagement-deviceLlistWrap mb-2"
            >
              <div className="alarmManagement-deviceListContent">
                <div>
                  <Checkbox
                    checked={selectedDevices.includes(detail.id)}
                    onClick={() => {
                      if (openAssignDeviceModal) {
                        return true;
                      }
                      handleCheckBox(detail.id);
                    }}
                    inputProps={{ "aria-label": "Checkbox demo" }}
                    style={{ color: "#1C407B" }}
                  />
                </div>
                <div className="alarmManagement-device-innerContent">
                  <Typography
                    variant="body2"
                    gutterBottom
                    className="assignDeviceDetailsTitle"
                  >
                    {detail.device_name}
                  </Typography>
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    className="assignDeviceDetailsDisc"
                  >
                    UID - {detail.machine_uid}
                  </Typography>
                  <Typography
                    variant="caption"
                    gutterBottom
                    className="assignDeviceDetailsDisc"
                  >
                    {detail.device_description}
                  </Typography>
                </div>
              </div>
            </div>
          ))}
        </div>
        <button
          className={
            selectedDevices.length
              ? "alarmManagement-devicList-nextButton"
              : "alarmmanagemet-submit-disable"
          }
          onClick={() => {
            if (openAssignDeviceModal) {
              notify("Please Close Modal");
              return true;
            }

            setActiveClass("User");
          }}
        >
          Next
        </button>
      </Card>
      {openAssignDeviceModal && (
        <>
          <div className="select-gateway-popup">
            {/* <div className="d-flex devices-text"> */}
            <div style={{ display: "flex" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={5}>
                  <h5 style={{ color: "#1C407B" }}>
                    <b>Assign Device</b>
                  </h5>
                </Grid>
                <Grid item xs={12} sm={7}></Grid>
              </Grid>
            </div>

            <Grid container spacing={2} className="pt-2">
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box className="devicemodal-box">
                  <h6 style={{ fontWeight: 600 }}>Select Gateway</h6>

                  <div
                    style={{
                      height: "420px",
                      overflow: "auto",
                      position: "relative",
                    }}
                  >
                    {plantGateway?.length ? (
                      plantGateway?.map((gateway, index) => (
                        <div key={gateway.id}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "13px 8px",
                              background: "white",
                              cursor: "pointer",
                              marginTop: index > 0 ? "4px" : "0", // Add margin top for all but the first gateway
                            }}
                            onClick={(event) =>
                              handleClick(gateway.id, gateway)
                            }
                          >
                            {gateway.gateway_name} <ArrowDropDownIcon />
                          </div>
                          {selectedGateway === gateway.id && (
                            <div
                              style={{ maxHeight: "300px", overflow: "auto" }}
                            >
                              {machineData.map((machine) => (
                                <div
                                  key={machine.id}
                                  style={{
                                    padding: "7px 25px",
                                    cursor: "pointer",
                                    boxShadow: "0px 6px 20px #6D6D6D1A",
                                    background: "#ffff",
                                    borderRight:
                                      selectedMachineId === machine.id
                                        ? "3px solid #1c407b"
                                        : "none",
                                  }}
                                  onClick={() =>
                                    handleMachineClick(machine.id, machine)
                                  }
                                >
                                  <h6 className="machine-title">
                                    {machine.machine_name}
                                  </h6>
                                  <p className="machine-description">
                                    {machine.uid}
                                  </p>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      ))
                    ) : (
                      <div className="gateway-error-div">
                        <ErrorIcon className="gateway-error-icon" />
                        <h5 className="mt-2">No data found.</h5>
                      </div>
                    )}
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box className="devicemodal-box">
                  <div className="d-flex justify-content-between">
                    {machineName ? (
                      <h6 style={{ fontWeight: 600 }}>
                        {machineName} - Device List
                      </h6>
                    ) : (
                      <h6></h6>
                    )}
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            style={{
                              color: "#1C407B",
                              padding: "0px",
                              marginRight: "3px",
                            }}
                            checked={selectAll}
                            onChange={handleSelectAll}
                          />
                        }
                        label={
                          <Typography style={{ fontSize: "15px" }}>
                            All
                          </Typography>
                        }
                      />
                    </FormGroup>
                  </div>

                  <div
                    className="pt-2 mb-2"
                    style={{
                      height: "420px",
                      overflow: "auto",
                    }}
                  >
                    {deviceData?.length ? (
                      deviceData?.map((e) => (
                        <div
                          key={e.id}
                          className="assignDeviceModalWrap mb-2"
                          style={{ background: "white" }}
                        >
                          <div className="assignDeviceDetails">
                            <div className="assignDeviceDetailsWrap">
                              <div>
                                <Checkbox
                                  style={{ color: "#1C407B" }}
                                  // checked={selectedDevices.includes(e.id)}
                                  // onClick={() => handleCheckboxChange(e.id)}
                                  checked={selectgatewayDeviceId.includes(e.id)}
                                  onClick={() => handleModelDeviceID(e.id, e)}
                                />
                              </div>
                              <div>
                                <Typography
                                  variant="body2"
                                  gutterBottom
                                  className="assignDeviceDetailsTitle"
                                >
                                  {e.device_name}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  display="block"
                                  gutterBottom
                                  className="assignDeviceDetailsDisc"
                                >
                                  {e.device_description}
                                </Typography>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="gateway-error-div">
                        <ErrorIcon className="gateway-error-icon" />
                        <h5 className="mt-2">No data found.</h5>
                      </div>
                    )}
                  </div>
                </Box>
              </Grid>
            </Grid>

            <div className="text-center pt-5">
              <button
                className="canclebtn"
                onClick={() => handleCloseMode()}
                // onClick={(e) => handleCloseConfigrationClick(e)}
              >
                Cancel
              </button>
              <button className="submitbtn" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AlarmManagementDeviceList;
