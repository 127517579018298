import React, { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  FormControlLabel,
  FormGroup,
  TextField,
  createTheme,
} from "@mui/material";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Switch from "@mui/material/Switch";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@mui/material/Container";
import { useEffect } from "react";
import {
  exportAnalyticsCOCExcel,
  getExportAnalyticsReportExcel,
  getExportPOPExcel,
  getOverViewAnalyticsData,
  getOverViewPieChartData,
  getOverviewDeviceData,
  getOverviewReportData,
  getOverviewReportDeviceData,
} from "./services";
import { OverViewAnalyticsReportApiData } from "./OverViewAnalyticsReportApiData";
import { useDispatch, useSelector } from "react-redux";
import { setOverviewData } from "./Slice/OverViewAnalyticsSlice";
import { ParetoChart } from "./ParetoChart";
import dayjs, { Dayjs } from "dayjs";
import { images } from "../../../config/images";
import { MultiAxisLineChart } from "./MultiAxisReportLineChart";
import { PopDonutChart } from "./PopPieChart";
import { PopgroupBarChart } from "./PopgroupBarChart";
import { ToastContainer, toast } from "react-toastify";
import { DonutChart } from "./PieChart";
import COCDatePicker from "../../../components/COCDatepicker/COCDatePicker";
import POPDatePicker from "../../../components/POPDatePicker/POPDatePicker";
import { ConvertToISOFormat } from "../../../components/ConvertToISOFormat/ConvertToISOFormat";
import { CompactTagUnit } from "../../../components/CompactValue/CompactValue";
import DeviceListShimmer from "../../../components/Shimmer/DeviceListShimmer/DeviceListShimmer";
import { setProcessDashboarDateSet } from "../sliceProcessDashboard/dateSliceprocessDashboard";

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-input": {
      padding: "8px 10px", // set the desired padding here
    },
  },
});

const OverViewAnalytics = () => {
  const [activeClass, setActiveClass] = useState("Analytics");
  const [deviceData, setDeviceData] = useState([]);
  const [deviceId, setDeviceId] = useState("");
  const [reportOverViewData, setReportOverViewData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [deviceUnitData, setDeviceUnitData] = useState([]);
  const [unitSearchQuery, setUnitSearchQuery] = useState("");
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [analyticsData, setAnalyticsData] = useState([]);
  const [analyticsPOPData, setAnalyticsPOPData] = useState([]);
  const [analyticsPOP1Data, setAnalyticsPOP1Data] = useState([]);
  const [analyticsPieChartData, setAnalyticsPieChartData] = useState([]);
  const [analyticsPopPieChart, setAnalyticsPopPieChart] = useState([]);
  const [analyticsPop1PieChart, setAnalyticsPop1PieChart] = useState([]);
  const [donutPOPChartSum, setDonutPOPChartSum] = useState([]);
  const [donutPOP1ChartSum, setDonut1POPChartSum] = useState([]);
  const [donutPOPUnit, setDonutPOPUnit] = useState([]);
  const [donutChartSum, setDonutChartSum] = useState([]);
  const [donutUnit, setDonutUnit] = useState([]);

  // Report Overview Analysis
  const [deviceReport, setDeviceReport] = useState([]);
  const [unitReport, setUnitReport] = useState([]);
  const [showTimeReport, setShowTimeReport] = useState(false);
  const [selectAggReport, setSelectAggReport] = useState("15");
  const [selectIntervalReport, setSelectIntervalReport] = useState("1");
  const [selectFirstUnit, setSelectFirstUnit] = useState("none");
  const [selectSecondUnit, setSelectSecondUnit] = useState("none");
  const [startDateRep, setStartDateRep] = useState(null);
  const [endDateRep, setEndDateRep] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isLoading1, setLoading1] = useState(false);
  const [isLoadingPop, setLoadingPop] = useState(false);
  const [isLoadingPop1, setLoadingPop1] = useState(false);
  const [isLoadingReport, setLoadingReport] = useState(false);
  const [isShimmerCOCParetoChart, setisShimmerCOCParetoChart] = useState(false);
  const [isShimmerCOCDonutChart, setisShimmerCOCDonutChart] = useState(false);
  const [openEnergyModal, setOpenEnergyModal] = useState(false);

  const modalRef = useRef();

  const [isShimmerPopgroupBarChartPOP, setisShimmerPopgroupBarChartPOP] =
    useState(false);
  const [isShimmerPopDonutChartPOP, setisShimmerPopDonutChartPOP] =
    useState(false);

  const [isShimmerdevicelist, setisShimmerdevicelist] = useState(false);
  const [isShimmerUnitlist, setisShimmerUnitlist] = useState(false);

  const [isShimmerReportdevicelist, setisShimmerReportdevicelist] =
    useState(false);
  const [isShimmerReportUnitlist, setisShimmerReportUnitlist] = useState(false);

  // chartData Report
  const [fisrtLineChartReportData, setFisrtLineChartReportData] = useState([]);
  const [secondeLineChartReportData, setSecondLineChartReportData] = useState(
    []
  );

  const [
    isShimmerMultiAxisLineChartReports,
    setisShimmerMultiAxisLineChartReports,
  ] = useState(false);

  const {
    deviceIdRedux,
    deviceUnitRedux,
    deviceColors,
    deviceIdReportRedux,
    deviceUnitReportRedux,
    deviceColorsReportRedux,
    unitColorsReportRedux,
    deviceIdPOPRedux,
    deviceUnitPOPRedux,
    devicePOPColors,
    startDateRe,
    endDateRe,
    startDatePopRe,
    endDatePopRe,
    startDatePopRe1,
    endDatePopRe1,
    activeClassConsumer,
    showTime,
    showTimePop,
    selectTimeRange,
    selectPOPTimeRange,
    reportStartDate,
    reportEndDate,
    reportSelectUnit1,
    reportSelectUnit2,
    reportAgg,
    reportInterval,
    reportShowTime,
  } = useSelector((state) => state.overViewState);

  const { selectUnit } = useSelector(
    (state) => state.dateSliceprocessDashboardState
  );

  const { user_access } = useSelector((state) => state.userState);

  const MAX_DATE_RANGE_DAYS = 365; // Maximum duration of one year in days

  const startDateReFormat = startDateRe ? dayjs(startDateRe) : null;
  const endDateReFormat = endDateRe ? dayjs(endDateRe) : null;

  const startDatePOPReFormat = startDatePopRe ? dayjs(startDatePopRe) : null;
  const endDatePOPReFormat = endDatePopRe ? dayjs(endDatePopRe) : null;

  const startDatePOP1ReFormat = startDatePopRe1 ? dayjs(startDatePopRe1) : null;
  const endDatePOP1ReFormat = endDatePopRe1 ? dayjs(endDatePopRe1) : null;

  const startDateReportFormat = reportStartDate ? dayjs(reportStartDate) : null;
  const endDateReportFormat = reportEndDate ? dayjs(reportEndDate) : null;

  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";
  const startTimeFormat = startDateRe
    ? dayjs(startDateRe).format(timeFormat)
    : "";

  const endTimeFormat = endDateRe ? dayjs(endDateRe).format(timeFormat) : "";
  const startPOPTimeFormat = startDatePopRe
    ? dayjs(startDatePopRe).format(timeFormat)
    : "";
  const endPOPTimeFormat = endDatePopRe
    ? dayjs(endDatePopRe).format(timeFormat)
    : "";
  const startPOP1TimeFormat = startDatePopRe1
    ? dayjs(startDatePopRe1).format(timeFormat)
    : "";
  const endPOP1TimeFormat = endDatePopRe1
    ? dayjs(endDatePopRe1).format(timeFormat)
    : "";
  const formatStartDate = startDateRe
    ? dayjs(startDateRe).format(dateFormat)
    : "";
  const formatEndDate = endDateRe ? dayjs(endDateRe).format(dateFormat) : "";

  const formatPOPStartDate = startDatePopRe
    ? dayjs(startDatePopRe).format(dateFormat)
    : "";
  const formatPOPEndDate = endDatePopRe
    ? dayjs(endDatePopRe).format(dateFormat)
    : "";
  const formatPOP1StartDate = startDatePopRe1
    ? dayjs(startDatePopRe1).format(dateFormat)
    : "";
  const formatPOP1EndDate = endDatePopRe1
    ? dayjs(endDatePopRe1).format(dateFormat)
    : "";

  const startTimeFormatRep = reportStartDate
    ? dayjs(reportStartDate).format(timeFormat)
    : "";
  const endTimeFormatRep = reportEndDate
    ? dayjs(reportEndDate).format(timeFormat)
    : "";

  const formatStartDateRep = reportStartDate
    ? dayjs(reportStartDate).format(dateFormat)
    : "";
  const formatEndDateRep = reportEndDate
    ? dayjs(reportEndDate).format(dateFormat)
    : "";

  const notify = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleTostMsg = () => {
    notifyError("You don't have access");
  };

  const handleToggleTime = () => {
    dispatch(setOverviewData({ showTime: !showTime }));
  };
  const handleToggleTimePop = () => {
    dispatch(setOverviewData({ showTimePop: !showTimePop }));
  };
  const handlePOPExcel = async () => {
    const findPlantDevice = deviceIdPOPRedux.length
      ? deviceIdPOPRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitPOPRedux.length
      ? deviceUnitPOPRedux.filter((f) => f.plantId == param.id)
      : [];

    if (
      findPlantDevice.length &&
      findPlantUnit.length &&
      formatPOPStartDate &&
      formatPOPEndDate &&
      selectPOPTimeRange
    ) {
      const params = {
        company: param.companyId,
        plant: param.id,
        device_id: findPlantDevice.map((id) => id.deviceID),
        tag_unit: findPlantUnit[0]["tagUnit"],
        start_date: `${formatPOPStartDate} 00:00:00`,
        end_date: `${formatPOPEndDate} 23:59:59`,
        start_date_1: `${formatPOP1StartDate} 00:00:00`,
        end_date_1: `${formatPOP1EndDate} 23:59:59`,
        aggregate: Number(selectPOPTimeRange),
      };

      if (showTime) {
        params["start_date"] = `${formatPOPStartDate} ${startPOPTimeFormat}`;
        params["end_date"] = `${formatPOPEndDate} ${endPOPTimeFormat}`;
        params[
          "start_date_1"
        ] = `${formatPOP1StartDate} ${startPOP1TimeFormat}`;
        params["end_date_1"] = `${formatPOP1EndDate} ${endPOP1TimeFormat}`;
      }
      try {
        const resp = await getExportPOPExcel(params);
        if (resp.status == 200 || resp.status == 201) {
          notify("Export Analytics Period Over Period Report Successfully");
        }
      } catch (error) {
        console.log("handlesubmit", error);
      }
    } else {
      console.log("error");
    }
  };

  const handleExportExcelClick = async () => {
    const findPlantDevice = deviceIdReportRedux.length
      ? deviceIdReportRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitReportRedux.length
      ? deviceUnitReportRedux.filter((f) => f.plantId == param.id)
      : [];
    if (
      formatStartDateRep &&
      formatEndDateRep &&
      reportAgg &&
      reportInterval &&
      findPlantDevice.length &&
      findPlantUnit.length
    ) {
      const params = {
        company: param.companyId,
        plant_id: param.id,
        device_id: findPlantDevice[0]["deviceID"],
        tag_unit: findPlantUnit.map((unit) => unit.tagUnit),
        start_date: formatStartDateRep,
        end_date: formatEndDateRep,
        aggregate: Number(reportAgg),
        intervals: Number(reportInterval),
      };
      try {
        const resp = await getExportAnalyticsReportExcel(params);
        if (resp.status == 200 || resp.status == 201) {
          notify("Export Overview Report Data Successfully");
        }
      } catch (error) {
        console.log("handlesubmit", error);
      }
    }
  };
  const switchColors = [
    "#FEBB22",
    "#002B2A",
    "#C99C00",
    "#EB9E79",
    "#69927A",
    "#872B81",
    "#F06292",
    "#0EB520",
    "#CD4D4A",
    "#123782",
    "#544632",
    "#94655B",
    "#0F4C81",
    "#8B0000",
    "#C60BC8",
    "#7D2264",
    "#BC2F00",
    "#556830",
    "#FCA311",
    "#021116",
    "#808080",
    "#300338",
    "#634C23",
    "#841E62",
    "#F34424",
    "#003333",
    "#020000",
    "#305355",
    "#5C3A31",
    "#D9750C",
    "#0C4562",
    "#001A30",
    "#00838F",
    "#785049",
    "#FF8800",
    "#0B7C8E",
    "#08535E",
    "#0E3504",
    "#C48200",
    "#846748",
    "#075571",
    "#9C27B0",
    "#A97E07",
    "#CE286D",
    "#8409DF",
    "#0B648D",
    "#29445A",
    "#1D733F",
    "#6E02B1",
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const param = useParams();

  useEffect(() => {
    handlegetDevice();
  }, []);

  const handlegetDevice = async () => {
    setisShimmerdevicelist(true);
    setisShimmerUnitlist(true);
    const params = {
      plant_id: param.id,
      // company_id: param.companyId,
    };
    try {
      const resp = await getOverviewDeviceData(params);
      if (resp.data.success == true) {
        setTimeout(() => {
          setisShimmerdevicelist(false);
        }, 1000);
        setTimeout(() => {
          setisShimmerUnitlist(false);
        }, 1000);
        setDeviceData(resp.data.payload.device);
        setDeviceUnitData(resp.data.payload.tag);
        // if (resp.data.payload.length) {
        //   setDeviceId(resp.data.payload[0].device);
        // }
      }
    } catch (error) {
      setisShimmerdevicelist(false);
      setisShimmerUnitlist(false);
      console.log("handlesubmit", error);
    }
  };

  // get device In Report
  const handlegetDeviceReport = async () => {
    setisShimmerReportdevicelist(true);
    setisShimmerReportUnitlist(true);
    const params = {
      plant_id: param.id,
      dashboard: 1,
    };
    try {
      const resp = await getOverviewReportDeviceData(params);
      if (resp.data.success == true) {
        setDeviceReport(resp.data.payload.device);
        setUnitReport(resp.data.payload.tag);
        setTimeout(() => {
          setisShimmerReportdevicelist(false);
        }, 1000);
        setTimeout(() => {
          setisShimmerReportUnitlist(false);
        }, 1000);
        // if (resp.data.payload.length) {
        //   setDeviceId(resp.data.payload[0].device);
        // }
      }
    } catch (error) {
      setisShimmerReportdevicelist(false);
      setisShimmerReportUnitlist(false);
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    handlegetDeviceReport();
  }, []);

  const handleSelectDeviceId = (id, index, event, row) => {
    const existingIndex = deviceColors.findIndex(
      (color) => color.deviceId === row.device && color.plantId == param.id
    );

    if (existingIndex !== -1) {
      const updatedColors = [...deviceColors];
      updatedColors.splice(existingIndex, 1);
      dispatch(
        setOverviewData({
          deviceColors: updatedColors,
        })
      );
    } else {
      // Add the object to the state array if it doesn't exist
      dispatch(
        setOverviewData({
          deviceColors: [
            ...deviceColors,
            {
              deviceId: row.device,
              dvName: row.device__device_name,
              color: event.target.name,
              plantId: param.id,
            },
          ],
        })
      );
    }

    if (!Array.isArray(deviceIdRedux)) {
      setDeviceId([]);
      dispatch(setOverviewData({ deviceIdRedux: [] }));
    }
    const matchingIndex = deviceIdRedux.findIndex(
      (f) => f.deviceID === id && f.plantId === param.id
    );

    if (matchingIndex !== -1) {
      // Matching element found, remove it
      console.log("Match found:", deviceIdRedux[matchingIndex]);

      const updatedArray = [...deviceIdRedux];
      updatedArray.splice(matchingIndex, 1); // Remove the matching element
      dispatch(
        setOverviewData({
          deviceIdRedux: updatedArray,
        })
      );
    } else {
      // No match found, add the new object
      console.log("No match found");

      dispatch(
        setOverviewData({
          deviceIdRedux: [
            ...deviceIdRedux,
            { deviceID: id, plantId: param.id },
          ],
        })
      );
    }
  };

  const handlePOPSelectDeviceId = (id, index, event, row) => {
    const existingIndex = devicePOPColors.findIndex(
      (color) => color.deviceId === row.device && color.plantId == param.id
    );

    const existingIndexDevice = deviceIdPOPRedux.findIndex(
      (color) => color.deviceID === row.device && color.plantId == param.id
    );

    if (existingIndex !== -1 && existingIndexDevice !== -1) {
      // If the selected device already exists, remove it
      const updatedColors = [...devicePOPColors];
      updatedColors.splice(existingIndex, 1);
      dispatch(
        setOverviewData({
          devicePOPColors: updatedColors,
        })
      );

      // Unselect the device
      const updateDevice = [...deviceIdPOPRedux];
      updateDevice.splice(existingIndexDevice, 1);
      dispatch(setOverviewData({ deviceIdPOPRedux: updateDevice }));
    } else {
      // Remove any previously selected devices
      // dispatch(setOverviewData({ devicePOPColors: [] }));

      const existingId = deviceIdPOPRedux.length
        ? deviceIdPOPRedux.find((f) => f.plantId == param.id)
          ? true
          : false
        : false;

      if (existingId) {
        return true;
      }

      // Add the selected device to the state array
      dispatch(
        setOverviewData({
          devicePOPColors: [
            {
              deviceId: row.device,
              dvName: row.device__device_name,
              color: event.target.name,
              plantId: param.id,
            },
          ],
        })
      );

      // Set the selected device as the only device in the array
      dispatch(
        setOverviewData({
          deviceIdPOPRedux: [
            ...deviceIdPOPRedux,
            { plantId: param.id, deviceID: id },
          ],
        })
      );
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleUnitSearchChange = (event) => {
    setUnitSearchQuery(event.target.value);
  };

  const handleSelectTagUnit = (id) => {
    const selectedUnit = deviceUnitRedux.find(
      (unit) => unit.plantId === param.id
    );
    if (!Array.isArray(deviceUnitRedux)) {
      dispatch(setOverviewData({ deviceUnitRedux: [] }));
    }

    if (selectedUnit && selectedUnit.tagUnit == id) {
      dispatch(
        setOverviewData({
          deviceUnitRedux: deviceUnitRedux.filter(
            (unit) => unit.plantId !== param.id
          ),
        })
      );
    } else {
      const selectTag = deviceUnitRedux.length
        ? deviceUnitRedux.find((f) => f.plantId == param.id)
          ? true
          : false
        : false;
      if (selectTag) {
        return true;
      } else {
        dispatch(
          setOverviewData({
            deviceUnitRedux: [
              ...deviceUnitRedux,
              { plantId: param.id, tagUnit: id },
            ],
          })
        );
      }
    }
  };

  const handlePOPSelectTagUnit = (id) => {
    const selectedUnit = deviceUnitPOPRedux.find(
      (unit) => unit.plantId === param.id
    );
    if (!Array.isArray(deviceUnitPOPRedux)) {
      dispatch(setOverviewData({ deviceUnitPOPRedux: [] }));
    }

    if (selectedUnit && selectedUnit.tagUnit == id) {
      dispatch(
        setOverviewData({
          deviceUnitPOPRedux: deviceUnitPOPRedux.filter(
            (unit) => unit.plantId !== param.id
          ),
        })
      );
    } else {
      const selectTag = deviceUnitPOPRedux.length
        ? deviceUnitPOPRedux.find((f) => f.plantId == param.id)
          ? true
          : false
        : false;
      if (selectTag) {
        return true;
      } else {
        dispatch(
          setOverviewData({
            deviceUnitPOPRedux: [
              ...deviceUnitPOPRedux,
              { plantId: param.id, tagUnit: id },
            ],
          })
        );
      }
    }
  };

  const handleReportInterval = (e) => {
    dispatch(setOverviewData({ reportInterval: e.target.value }));
  };

  const handleAggReport = (e) => {
    dispatch(setOverviewData({ reportAgg: e.target.value }));
  };

  const handleReportSelectUnit11 = (e) => {
    dispatch(setOverviewData({ reportSelectUnit1: e.target.value }));
  };
  const handleReportSelectUnit12 = (e) => {
    dispatch(setOverviewData({ reportSelectUnit2: e.target.value }));
  };

  const handleShowReportToggle = () => {
    dispatch(setOverviewData({ reportShowTime: !reportShowTime }));
  };
  const handleSelectDeviceIdReport = (id, index, event, row) => {
    const existingIndex = deviceColorsReportRedux.findIndex(
      (color) => color.deviceId === row.device && color.plantId == param.id
    );

    if (existingIndex !== -1) {
      const updatedColors = [...deviceColorsReportRedux];
      updatedColors.splice(existingIndex, 1);
      dispatch(
        setOverviewData({
          deviceColorsReportRedux: updatedColors,
        })
      );
    } else {
      // Add the object to the state array if it doesn't exist
      dispatch(
        setOverviewData({
          deviceColorsReportRedux: [
            ...deviceColorsReportRedux,
            {
              deviceId: row.device,
              dvName: row.device__device_name,
              color: event.target.name,
              plantId: param.id,
            },
          ],
        })
      );
    }

    const selectedUnit = deviceIdReportRedux.find(
      (unit) => unit.plantId === param.id
    );
    if (!Array.isArray(deviceIdReportRedux)) {
      dispatch(setOverviewData({ deviceIdReportRedux: [] }));
    }

    if (selectedUnit && selectedUnit.deviceID == id) {
      dispatch(
        setOverviewData({
          deviceIdReportRedux: deviceIdReportRedux.filter(
            (unit) => unit.plantId !== param.id
          ),
        })
      );
    } else {
      const selectTag = deviceIdReportRedux.length
        ? deviceIdReportRedux.find((f) => f.plantId == param.id)
          ? true
          : false
        : false;
      if (selectTag) {
        return true;
      } else {
        dispatch(
          setOverviewData({
            deviceIdReportRedux: [
              ...deviceIdReportRedux,
              { plantId: param.id, deviceID: id },
            ],
          })
        );
      }
    }
  };

  const handleSelectTagUnitReport = (id, event, row) => {
    const existingIndex = unitColorsReportRedux.findIndex(
      (color) => color.tagUnit === row.tag_unit && color.plantId == param.id
    );

    if (existingIndex !== -1) {
      const updatedColors = [...unitColorsReportRedux];
      updatedColors.splice(existingIndex, 1);
      dispatch(
        setOverviewData({
          unitColorsReportRedux: updatedColors,
        })
      );
    } else {
      // Add the object to the state array if it doesn't exist
      dispatch(
        setOverviewData({
          unitColorsReportRedux: [
            ...unitColorsReportRedux,
            {
              tagUnit: row.tag_unit,
              color: event.target.name,
              plantId: param.id,
            },
          ],
        })
      );
    }

    if (!Array.isArray(deviceUnitReportRedux)) {
      setDeviceId([]);
      dispatch(setOverviewData({ deviceUnitReportRedux: [] }));
    }
    const matchingIndex = deviceUnitReportRedux.findIndex(
      (f) => f.tagUnit === id && f.plantId === param.id
    );

    if (matchingIndex !== -1) {
      // Matching element found, remove it
      console.log("Match found:", deviceUnitReportRedux[matchingIndex]);

      const updatedArray = [...deviceUnitReportRedux];
      updatedArray.splice(matchingIndex, 1); // Remove the matching element
      dispatch(
        setOverviewData({
          deviceUnitReportRedux: updatedArray,
        })
      );
    } else {
      // No match found, add the new object
      console.log("No match found");

      dispatch(
        setOverviewData({
          deviceUnitReportRedux: [
            ...deviceUnitReportRedux,
            { tagUnit: id, plantId: param.id },
          ],
        })
      );
    }

    const findPlantUnit = deviceUnitReportRedux.length
      ? deviceUnitReportRedux.filter((f) => f.plantId == param.id)
      : [];
    if (!findPlantUnit.length) {
      setSelectFirstUnit("none");
      setSelectSecondUnit("none");
    }
  };

  useEffect(() => {
    const findPlantDevice = deviceIdRedux.length
      ? deviceIdRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitRedux.length
      ? deviceUnitRedux.filter((f) => f.plantId == param.id)
      : [];
    if (findPlantDevice.length && findPlantUnit.length) {
      handleAnalyticsData();
    } else {
      setAnalyticsData([]);
    }
  }, [
    deviceIdRedux,
    deviceUnitRedux,
    formatStartDate,
    formatEndDate,
    selectTimeRange,
    startTimeFormat,
    endTimeFormat,
    showTime,
    selectUnit,
  ]);

  useEffect(() => {
    const findPlantDevice = deviceIdRedux.length
      ? deviceIdRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitRedux.length
      ? deviceUnitRedux.filter((f) => f.plantId == param.id)
      : [];
    if (findPlantDevice.length && findPlantUnit.length) {
      handleAnalyticsPieChartData();
    } else {
      setAnalyticsPieChartData([]);
    }
  }, [
    deviceIdRedux,
    deviceUnitRedux,
    formatStartDate,
    formatEndDate,
    showTime,
    startTimeFormat,
    endTimeFormat,
    selectUnit,
  ]);

  const handleAnalyticsData = async () => {
    const findPlantDevice = deviceIdRedux.length
      ? deviceIdRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitRedux.length
      ? deviceUnitRedux.filter((f) => f.plantId == param.id)
      : [];
    if (
      findPlantDevice.length &&
      findPlantUnit[0] &&
      formatStartDate &&
      formatEndDate &&
      selectTimeRange
    ) {
      setisShimmerCOCParetoChart(true);
      const params = {
        device_id: findPlantDevice.map((id) => id.deviceID),
        tag_unit: findPlantUnit[0]["tagUnit"],
        start_date: `${formatStartDate} 00:00:00`,
        end_date: `${formatEndDate} 23:59:59`,
        // start_time: startTimeFormat !== "00:00:00" ? startTimeFormat : "",
        // end_time: endTimeFormat !== "00:00:00" ? endTimeFormat : "",
        aggregate: Number(selectTimeRange),
        unit: selectUnit,
      };

      if (showTime) {
        params["start_date"] = `${formatStartDate} ${startTimeFormat}`;
        params["end_date"] = `${formatEndDate} ${endTimeFormat}`;
      }
      try {
        setLoading(true);

        const resp = await getOverViewAnalyticsData(params);
        if (resp.data.success === true) {
          setLoading(false);
          setTimeout(() => {
            setisShimmerCOCParetoChart(false);
          }, 2000);

          setAnalyticsData(resp.data.payload);
        }
      } catch (error) {
        setisShimmerCOCParetoChart(false);
        setLoading(false);
        console.log("handleSubmit", error);
      }
    }
  };

  useEffect(() => {
    const findPlantByDevice = deviceIdPOPRedux.length
      ? deviceIdPOPRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantByUnit = deviceUnitPOPRedux.length
      ? deviceUnitPOPRedux.filter((f) => f.plantId == param.id)
      : [];
    if (findPlantByDevice.length && findPlantByUnit.length) {
      handleAnalyticsPOPBarChart();
    } else {
      setAnalyticsPOPData([]);
    }
  }, [
    deviceIdPOPRedux,
    deviceUnitPOPRedux,
    formatPOPStartDate,
    formatPOPEndDate,
    startPOPTimeFormat,
    endPOPTimeFormat,
    selectPOPTimeRange,
    showTimePop,
    selectUnit,
  ]);

  const handleAnalyticsPOPBarChart = async () => {
    const findPlantByDevice = deviceIdPOPRedux.length
      ? deviceIdPOPRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantByUnit = deviceUnitPOPRedux.length
      ? deviceUnitPOPRedux.filter((f) => f.plantId == param.id)
      : [];

    if (
      findPlantByDevice.length &&
      findPlantByUnit &&
      formatPOPStartDate &&
      formatPOPEndDate &&
      selectPOPTimeRange
    ) {
      setisShimmerPopgroupBarChartPOP(true);
      const params = {
        device_id: findPlantByDevice.map((row) => row.deviceID),
        tag_unit: findPlantByUnit[0]["tagUnit"],
        start_date: `${formatPOPStartDate} 00:00:00`,
        end_date: `${formatPOPEndDate} 23:59:59`,
        aggregate: Number(selectPOPTimeRange),
        unit: selectUnit,
      };

      if (showTimePop) {
        params["start_date"] = `${formatPOPStartDate} ${startPOPTimeFormat}`;
        params["end_date"] = `${formatPOPEndDate} ${endPOPTimeFormat}`;
      }
      try {
        setLoadingPop(true);
        const resp = await getOverViewAnalyticsData(params);
        if (resp.data.success === true) {
          setTimeout(() => setisShimmerPopgroupBarChartPOP(false), 2000);

          setLoadingPop(false);
          setAnalyticsPOPData(resp.data.payload);
        }
      } catch (error) {
        setisShimmerPopgroupBarChartPOP(false);
        setLoadingPop(false);
        console.log("handleAnalyticsData", error);
      }
    }
  };
  useEffect(() => {
    const findPlantByDevice = deviceIdPOPRedux.length
      ? deviceIdPOPRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantByUnit = deviceUnitPOPRedux.length
      ? deviceUnitPOPRedux.filter((f) => f.plantId == param.id)
      : [];

    if (findPlantByDevice.length && findPlantByUnit.length) {
      handleAnalyticsPOPBarChart2();
    } else {
      setAnalyticsPOP1Data([]);
    }
  }, [
    deviceIdPOPRedux,
    deviceUnitPOPRedux,
    formatPOP1StartDate,
    formatPOP1EndDate,
    selectPOPTimeRange,
    showTimePop,
    selectUnit,
  ]);

  const handleAnalyticsPOPBarChart2 = async () => {
    const findPlantByDevice = deviceIdPOPRedux.length
      ? deviceIdPOPRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantByUnit = deviceUnitPOPRedux.length
      ? deviceUnitPOPRedux.filter((f) => f.plantId == param.id)
      : [];
    if (
      findPlantByDevice &&
      deviceUnitPOPRedux &&
      formatPOP1StartDate &&
      formatPOP1EndDate &&
      selectPOPTimeRange
    ) {
      setisShimmerPopgroupBarChartPOP(true);
      const params = {
        device_id: findPlantByDevice.map((row) => row.deviceID),
        tag_unit: findPlantByUnit[0]["tagUnit"],
        start_date: `${formatPOP1StartDate} 00:00:00`,
        end_date: `${formatPOP1EndDate} 23:59:59`,
        aggregate: Number(selectPOPTimeRange),
        unit: selectUnit,
      };

      if (showTimePop) {
        params["start_date"] = `${formatPOP1StartDate} ${startPOP1TimeFormat}`;
        params["end_date"] = `${formatPOP1EndDate} ${endPOP1TimeFormat}`;
      }
      try {
        const resp = await getOverViewAnalyticsData(params);
        if (resp.data.success === true) {
          setTimeout(() => {
            setisShimmerPopgroupBarChartPOP(false);
          }, 2000);
          setAnalyticsPOP1Data(resp.data.payload);
        }
      } catch (error) {
        setisShimmerPopgroupBarChartPOP(false);
        console.log("handleAnalyticsData", error);
      }
    }
  };
  const handleAnalyticsPieChartData = async () => {
    const findPlantDevice = deviceIdRedux.length
      ? deviceIdRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitRedux.length
      ? deviceUnitRedux.filter((f) => f.plantId == param.id)
      : [];

    if (
      findPlantDevice.length &&
      findPlantUnit[0] &&
      formatStartDate &&
      formatEndDate
    ) {
      setisShimmerCOCDonutChart(true);
      const params = {
        device_id: findPlantDevice.map((id) => id.deviceID),
        tag_unit: findPlantUnit[0]["tagUnit"],
        start_date: formatStartDate,
        end_date: formatEndDate,
        unit: selectUnit,
      };
      try {
        setLoading1(true);
        const resp = await getOverViewPieChartData(params);
        if (resp.data.success == true) {
          setTimeout(() => {
            setisShimmerCOCDonutChart(false);
          }, 2000);

          setLoading1(false);
          setAnalyticsPieChartData(resp.data.payload);
          setDonutChartSum(resp.data.tag_value_sum);
          setDonutUnit(resp.data.payload[0].tag_unit);
        }
      } catch (error) {
        setTimeout(() => {
          setisShimmerCOCDonutChart(false);
        }, 2000);
        setLoading1(false);
        console.log("handleSubmit", error);
      }
    }
  };

  useEffect(() => {
    const findPlantByDevice = deviceIdPOPRedux.length
      ? deviceIdPOPRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitPOPRedux.length
      ? deviceUnitPOPRedux.filter((f) => f.plantId == param.id)
      : [];

    if (findPlantByDevice.length && findPlantUnit.length) {
      handleAnalyticsPOPpieChartData();
    } else {
      setAnalyticsPopPieChart([]);
    }
  }, [
    deviceIdPOPRedux,
    deviceUnitPOPRedux,
    formatPOPStartDate,
    formatPOPEndDate,
    showTimePop,
    selectUnit,
  ]);

  const handleAnalyticsPOPpieChartData = async () => {
    const findPlantByDevice = deviceIdPOPRedux.length
      ? deviceIdPOPRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitPOPRedux.length
      ? deviceUnitPOPRedux.filter((f) => f.plantId == param.id)
      : [];

    if (
      findPlantByDevice.length &&
      deviceUnitPOPRedux[0] &&
      formatPOPStartDate &&
      formatPOPEndDate
    ) {
      setisShimmerPopDonutChartPOP(true);
      const params = {
        device_id: findPlantByDevice.map((row) => row.deviceID),
        tag_unit: findPlantUnit[0]["tagUnit"],
        start_date: formatPOPStartDate,
        end_date: formatPOPEndDate,
        unit: selectUnit,
      };
      try {
        setLoadingPop1(true);
        const resp = await getOverViewPieChartData(params);
        if (resp.data.success == true) {
          setTimeout(setisShimmerPopDonutChartPOP(false), 3000);

          setLoadingPop1(false);
          setAnalyticsPopPieChart(resp.data.payload);
          setDonutPOPChartSum(resp.data.tag_value_sum);
          setDonutPOPUnit(resp.data.payload[0].tag_unit);
        }
      } catch (error) {
        setisShimmerPopDonutChartPOP(false);
        setLoadingPop1(false);
        console.log("handleSubmit", error);
      }
    }
  };

  useEffect(() => {
    handleAnalyticsPOP1pieChartData();
  }, [
    deviceIdPOPRedux,
    deviceUnitPOPRedux,
    formatPOP1StartDate,
    formatPOP1EndDate,
    showTimePop,
    selectUnit,
  ]);

  const handleAnalyticsPOP1pieChartData = async () => {
    const findPlantByDevice = deviceIdPOPRedux.length
      ? deviceIdPOPRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitPOPRedux.length
      ? deviceUnitPOPRedux.filter((f) => f.plantId == param.id)
      : [];

    if (
      findPlantByDevice.length &&
      findPlantUnit[0] &&
      formatPOP1StartDate &&
      formatPOP1EndDate
    ) {
      setisShimmerPopDonutChartPOP(true);
      const params = {
        device_id: findPlantByDevice.map((row) => row.deviceID),
        tag_unit: findPlantUnit[0]["tagUnit"],
        start_date: formatPOP1StartDate,
        end_date: formatPOP1EndDate,
        unit: selectUnit,
      };
      try {
        const resp = await getOverViewPieChartData(params);
        if (resp.data.success == true) {
          setTimeout(setisShimmerPopDonutChartPOP(false), 3000);

          setAnalyticsPop1PieChart(resp.data.payload);
          setDonut1POPChartSum(resp.data.tag_value_sum);
          setDonutUnit(resp.data.payload[0].tag_unit);
        }
      } catch (error) {
        setisShimmerPopDonutChartPOP(false);
        console.log("handleSubmit", error);
      }
    }
  };
  useEffect(() => {
    getOverviewReportApi();
  }, [
    formatStartDateRep,
    formatEndDateRep,
    deviceIdReportRedux,
    deviceUnitReportRedux,
    selectUnit,
    startTimeFormatRep,
    endTimeFormatRep
  ]);

  // report Table data
  const getOverviewReportApi = async () => {
    const findPlantUnit = deviceUnitReportRedux.length
      ? deviceUnitReportRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantDevice = deviceIdReportRedux.length
      ? deviceIdReportRedux.filter((f) => f.plantId == param.id)
      : [];

    if (
      findPlantDevice.length &&
      findPlantUnit.length &&
      formatStartDateRep &&
      formatEndDateRep
    ) {
      const paramRequset = {
        device_id: findPlantDevice[0]["deviceID"],
        tag_units: findPlantUnit.map((unit) => unit.tagUnit),
        start_date: `${formatStartDateRep} 00:00:00`,
        end_date: `${formatEndDateRep} 23:59:59`,
        unit: selectUnit,
      };
      if (reportShowTime) {
        paramRequset["start_date"] = `${formatStartDateRep} ${startTimeFormatRep}`;
        paramRequset["end_date"] = `${formatEndDateRep} ${endTimeFormatRep}`;
      }
  
      try {
        const resp = await getOverviewReportData(paramRequset);

        if (resp.data.success == true) {
          setReportOverViewData(resp.data.payload);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const handleExportEnergyData = async () => {
    const findPlantDevice = deviceIdRedux.length
      ? deviceIdRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitRedux.length
      ? deviceUnitRedux.filter((f) => f.plantId == param.id)
      : [];
    if (
      findPlantDevice.length &&
      findPlantUnit.length &&
      formatStartDate &&
      formatEndDate &&
      selectTimeRange
    ) {
      const params = {
        company: param.companyId,
        plant: param.id,
        device_id: findPlantDevice.map((id) => id.deviceID),
        tag_unit: findPlantUnit[0]["tagUnit"],
        start_date: `${formatStartDate} 00:00:00`,
        end_date: `${formatEndDate} 23:59:59`,
        aggregate: Number(selectTimeRange),
      };

      if (showTime) {
        params["start_date"] = `${formatStartDate} ${startTimeFormat}`;
        params["end_date"] = `${formatEndDate} ${endTimeFormat}`;
      }
      try {
        const resp = await exportAnalyticsCOCExcel(params);
        if (resp.status == 200 || resp.status == 201) {
          notify("Export Analytics Consumer Over Consumer Report Successfully");
        }
      } catch (error) {
        console.log("handlesubmit", error);
      }
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    const findPlantDevice = deviceIdReportRedux.length
      ? deviceIdReportRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitReportRedux.length
      ? deviceUnitReportRedux.filter((f) => f.plantId == param.id)
      : [];
    if (!findPlantDevice.length || !findPlantUnit.length) {
      setReportOverViewData([]);
      setFisrtLineChartReportData([]);
      setSecondLineChartReportData([]);
    }
  }, [deviceIdReportRedux, deviceUnitReportRedux]);

  const handleStartDate = (newValue) => {
    const durationInDays = endDateReFormat
      ? endDateReFormat.diff(newValue, "days")
        ? endDateReFormat.diff(newValue, "days") > 0
          ? endDateReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(setOverviewData({ startDateRe: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDateReFormat && newValue.isBefore(endDateReFormat)) {
      dispatch(setOverviewData({ startDateRe: newValue }));
    } else if (!endDateReFormat) {
      dispatch(setOverviewData({ startDateRe: newValue }));
    } else if (endDateReFormat && newValue.isSame(endDateReFormat, "day")) {
      // Check for time validation if showTime is true
      if (
        showTime &&
        (newValue.hour() > endDateReFormat.hour() ||
          (newValue.hour() === endDateReFormat.hour() &&
            newValue.minute() >= endDateReFormat.minute()))
      ) {
        dispatch(setOverviewData({ startDateRe: null }));
        notifyError("Start time must be before end time");
      } else {
        dispatch(setOverviewData({ startDateRe: newValue }));
      }
    } else {
      dispatch(setOverviewData({ startDateRe: null }));
      notifyError("End date must be greater than start date");
    }
  };

  const handleEndDate = (newValue) => {
    const durationInDays = startDateReFormat
      ? startDateReFormat.diff(newValue, "days")
        ? startDateReFormat.diff(newValue, "days") > 0
          ? startDateReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(setOverviewData({ endDateRe: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDateReFormat && newValue.isAfter(startDateReFormat)) {
      dispatch(setOverviewData({ endDateRe: newValue }));
    } else if (!startDateReFormat) {
      dispatch(setOverviewData({ endDateRe: newValue }));
    } else if (startDateReFormat && newValue.isSame(startDateReFormat, "day")) {
      // Check for time validation if showTime is true
      if (
        showTime &&
        (newValue.hour() < startDateReFormat.hour() ||
          (newValue.hour() === startDateReFormat.hour() &&
            newValue.minute() <= startDateReFormat.minute()))
      ) {
        dispatch(setOverviewData({ endDateRe: null }));
        notifyError("End time must be after start time");
      } else {
        dispatch(setOverviewData({ endDateRe: newValue }));
      }
    } else {
      dispatch(setOverviewData({ endDateRe: null }));
      notifyError("Start date must be less than end date");
    }
  };

  const handlePOPStartDate = (newValue) => {
    const durationInDays = endDatePOPReFormat
      ? endDatePOPReFormat.diff(newValue, "days")
        ? endDatePOPReFormat.diff(newValue, "days") > 0
          ? endDatePOPReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(setOverviewData({ startDatePopRe: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDatePOPReFormat && newValue.isBefore(endDatePOPReFormat)) {
      dispatch(setOverviewData({ startDatePopRe: newValue }));
    } else if (!endDatePOPReFormat) {
      dispatch(setOverviewData({ startDatePopRe: newValue }));
    } else if (
      endDatePOPReFormat &&
      newValue.isSame(endDatePOPReFormat, "day")
    ) {
      // Check for time validation if showTime is true
      if (
        showTimePop &&
        (newValue.hour() > endDatePOPReFormat.hour() ||
          (newValue.hour() === endDatePOPReFormat.hour() &&
            newValue.minute() >= endDatePOPReFormat.minute()))
      ) {
        dispatch(setOverviewData({ startDatePopRe: null }));
        notifyError("Start time must be before end time");
      } else {
        dispatch(setOverviewData({ startDatePopRe: newValue }));
      }
    } else {
      dispatch(setOverviewData({ startDatePopRe: null }));
      notifyError("End date must be greater than start date");
    }
    // dispatch(setOverviewData({ startDatePopRe: newValue }));
  };

  const handlePOPEndDate = (newValue) => {
    const durationInDays = startDatePOPReFormat
      ? startDatePOPReFormat.diff(newValue, "days")
        ? startDatePOPReFormat.diff(newValue, "days") > 0
          ? startDatePOPReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(setOverviewData({ endDatePopRe: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDatePOPReFormat && newValue.isAfter(startDatePOPReFormat)) {
      dispatch(setOverviewData({ endDatePopRe: newValue }));
    } else if (!startDatePOPReFormat) {
      dispatch(setOverviewData({ endDatePopRe: newValue }));
    } else if (
      startDatePOPReFormat &&
      newValue.isSame(startDatePOPReFormat, "day")
    ) {
      // Check for time validation if showTime is true
      if (
        showTimePop &&
        (newValue.hour() < startDatePOPReFormat.hour() ||
          (newValue.hour() === startDatePOPReFormat.hour() &&
            newValue.minute() <= startDatePOPReFormat.minute()))
      ) {
        dispatch(setOverviewData({ endDatePopRe: null }));
        notifyError("End time must be after start time");
      } else {
        dispatch(setOverviewData({ endDatePopRe: newValue }));
      }
    } else {
      dispatch(setOverviewData({ endDatePopRe: null }));
      notifyError("Start date must be less than end date");
    }
  };
  const handlePOP1StartDate = (newValue) => {
    const durationInDays = endDatePOP1ReFormat
      ? endDatePOP1ReFormat.diff(newValue, "days")
        ? endDatePOP1ReFormat.diff(newValue, "days") > 0
          ? endDatePOP1ReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(setOverviewData({ startDatePopRe1: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDatePOP1ReFormat && newValue.isBefore(endDatePOP1ReFormat)) {
      dispatch(setOverviewData({ startDatePopRe1: newValue }));
    } else if (!endDatePOP1ReFormat) {
      dispatch(setOverviewData({ startDatePopRe1: newValue }));
    } else if (
      endDatePOP1ReFormat &&
      newValue.isSame(endDatePOP1ReFormat, "day")
    ) {
      // Check for time validation if showTime is true
      if (
        showTimePop &&
        (newValue.hour() > endDatePOP1ReFormat.hour() ||
          (newValue.hour() === endDatePOP1ReFormat.hour() &&
            newValue.minute() >= endDatePOP1ReFormat.minute()))
      ) {
        dispatch(setOverviewData({ startDatePopRe1: null }));
        notifyError("Start time must be before end time");
      } else {
        dispatch(setOverviewData({ startDatePopRe1: newValue }));
      }
    } else {
      dispatch(setOverviewData({ startDatePopRe1: null }));
      notifyError("End date must be greater than start date");
    }
  };

  const handlePOP1EndDate = (newValue) => {
    const durationInDays = startDatePOP1ReFormat
      ? startDatePOP1ReFormat.diff(newValue, "days")
        ? startDatePOP1ReFormat.diff(newValue, "days") > 0
          ? startDatePOP1ReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(setOverviewData({ endDatePopRe1: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDatePOP1ReFormat && newValue.isAfter(startDatePOP1ReFormat)) {
      dispatch(setOverviewData({ endDatePopRe1: newValue }));
    } else if (!startDatePOP1ReFormat) {
      dispatch(setOverviewData({ endDatePopRe1: newValue }));
    } else if (
      startDatePOP1ReFormat &&
      newValue.isSame(startDatePOP1ReFormat, "day")
    ) {
      // Check for time validation if showTime is true
      if (
        showTimePop &&
        (newValue.hour() < startDatePOP1ReFormat.hour() ||
          (newValue.hour() === startDatePOP1ReFormat.hour() &&
            newValue.minute() <= startDatePOP1ReFormat.minute()))
      ) {
        dispatch(setOverviewData({ endDatePopRe1: null }));
        notifyError("End time must be after start time");
      } else {
        dispatch(setOverviewData({ endDatePopRe1: newValue }));
      }
    } else {
      dispatch(setOverviewData({ endDatePopRe1: null }));
      notifyError("Start date must be less than end date");
    }
  };

  const handleReportsReportStartDate = (newValue) => {
    const durationInDays = endDateReportFormat
      ? endDateReportFormat.diff(newValue, "days")
        ? endDateReportFormat.diff(newValue, "days") > 0
          ? endDateReportFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(setOverviewData({ reportStartDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDateReportFormat && newValue.isBefore(endDateReportFormat)) {
      dispatch(setOverviewData({ reportStartDate: newValue }));
    } else if (!endDateReportFormat) {
      dispatch(setOverviewData({ reportStartDate: newValue }));
    } else if (
      endDateReportFormat &&
      newValue.isSame(endDateReportFormat, "day")
    ) {
      // Check for time validation if showTime is true
      if (
        reportShowTime &&
        (newValue.hour() > endDateReportFormat.hour() ||
          (newValue.hour() === endDateReportFormat.hour() &&
            newValue.minute() >= endDateReportFormat.minute()))
      ) {
        dispatch(setOverviewData({ reportStartDate: null }));
        notifyError("Start time must be before end time");
      } else {
        dispatch(setOverviewData({ reportStartDate: newValue }));
      }
    } else {
      dispatch(setOverviewData({ reportStartDate: null }));
      notifyError("End date must be greater than start date");
    }
  };

  const handleReportsReportEndDate = (newValue) => {
    const durationInDays = startDateReportFormat
      ? startDateReportFormat.diff(newValue, "days")
        ? startDateReportFormat.diff(newValue, "days") > 0
          ? startDateReportFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(setOverviewData({ endDateReportFormat: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDateReportFormat && newValue.isAfter(startDateReportFormat)) {
      dispatch(setOverviewData({ reportEndDate: newValue }));
    } else if (!startDateReportFormat) {
      dispatch(setOverviewData({ reportEndDate: newValue }));
    } else if (
      startDateReportFormat &&
      newValue.isSame(startDateReportFormat, "day")
    ) {
      // Check for time validation if showTime is true
      if (
        reportShowTime &&
        (newValue.hour() < startDateReportFormat.hour() ||
          (newValue.hour() === startDateReportFormat.hour() &&
            newValue.minute() <= startDateReportFormat.minute()))
      ) {
        dispatch(setOverviewData({ reportEndDate: null }));
        notifyError("End time must be after start time");
      } else {
        dispatch(setOverviewData({ reportEndDate: newValue }));
      }
    } else {
      dispatch(setOverviewData({ reportEndDate: null }));
      notifyError("Start date must be less than end date");
    }
  };
  const plantDashboardAccess = user_access.length
    ? user_access.find(
        (user) => user.access_module?.access_module_name === "Plant Dashboard"
      )
    : null;

  const analyticsAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Analytics"
        )
      : null
    : null;

  const ReportAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Reports"
        )
      : null
    : null;

    const handleOpenEnergyModal = () => {
      setOpenEnergyModal((prev) => {
        const newState = !prev;
        console.log("openEnergy", newState);
        return newState;
      });
    };
    
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target) && !event.target.closest('.filter-img-class')) {
        setOpenEnergyModal(false);
      }
    };
    
    useEffect(() => {
      if (openEnergyModal) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [openEnergyModal]);
    

  return (
    <>
      <OverViewAnalyticsReportApiData
        showTimeReport={reportShowTime}
        setFisrtLineChartReportData={setFisrtLineChartReportData}
        setSecondLineChartReportData={setSecondLineChartReportData}
        selectFirstUnit={reportSelectUnit1}
        selectAggReport={reportAgg}
        selectIntervalReport={reportInterval}
        deviceIdReportRedux={deviceIdReportRedux}
        selectSecondUnit={reportSelectUnit2}
        startTimeFormatRep={startTimeFormatRep}
        endTimeFormatRep={endTimeFormatRep}
        formatStartDateRep={formatStartDateRep}
        formatEndDateRep={formatEndDateRep}
        setLoadingReport={setLoadingReport}
        setisShimmerMultiAxisLineChartReports={
          setisShimmerMultiAxisLineChartReports
        }
      />
      <Container maxWidth="xxl" className="pt-4">
        {/* <div className="page-wraper"> */}
        <div className="page-header page-header-analytics">
          {activeClass == "Analytics" ? (
            <div
              onClick={() =>
                navigate(
                  `/overView/company/${param.companyId}/plant/${param.id}`
                )
              }
              className="page-back-btn"
            >
              <ArrowBackIcon />
              <span>Overview - Analytics</span>
            </div>
          ) : (
            <div
              onClick={() =>
                navigate(
                  `/overView/company/${param.companyId}/plant/${param.id}`
                )
              }
              className="page-back-btn"
            >
              <ArrowBackIcon />
              <span>Overview - Report</span>
            </div>
          )}
          <div className="d-flex">
            <button
              className={`${
                activeClass == "Analytics" ? "Analytics-tabs" : "Reports-tabs"
              }`}
              onClick={() => setActiveClass("Analytics")}
            >
              Analytics
            </button>
            <button
              className={`${
                activeClass == "Reports" ? "Analytics-tabs" : "Reports-tabs"
              }`}
              onClick={() => setActiveClass("Reports")}
            >
              Reports
            </button>
          </div>
        </div>
        {activeClass == "Analytics" ? (
          <>
            <div className="analysis-tabs-btn pt-3">
              <div className="analytics-tabs-btn">
                <div className="d-flex Overview-btns">
                  <button
                    className={`${
                      activeClassConsumer == "Consumer"
                        ? "Analytics-tabs-overview"
                        : "Reports-tabs-overview"
                    }`}
                    onClick={() =>
                      dispatch(
                        setOverviewData({ activeClassConsumer: "Consumer" })
                      )
                    }
                    style={{ width: "210px", height: "50px" }}
                  >
                    Consumer over Consumer
                  </button>
                  <button
                    className={`${
                      activeClassConsumer == "Period"
                        ? "Analytics-tabs-overview"
                        : "Reports-tabs-overview"
                    }`}
                    onClick={() =>
                      dispatch(
                        setOverviewData({ activeClassConsumer: "Period" })
                      )
                    }
                    style={{ width: "210px", height: "50px" }}
                  >
                    Period over Period
                  </button>
                </div>
                {activeClassConsumer == "Consumer" ? (
                 <div
                 onClick={(e) => {
                   e.stopPropagation(); // Prevent the event from propagating to the document
                   handleOpenEnergyModal();
                 }}
                 className="filter-img-class"
               >
                 <img
                   src={images.filterImg}
                   alt=""
                   style={{ height: "40px" }}
                 />
                 </div>
                ) : (
                  <div
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent the event from propagating to the document
                    handleOpenEnergyModal();
                  }}
                    className="filter-img-class"
                  >
                    <img
                      src={images.filterImg}
                      alt=""
                      style={{ height: "40px" }}
                    />
                  </div>
                )}
              </div>
              {activeClassConsumer == "Consumer" ? (
                <div
                  className="analytics-aggregation"
                  style={{ justifyContent: "flex-end" }}
                >
                  <div className="d-flex agg-div">
                    <div className="d-flex gap-2">
                      <h6 className="AGG-dropdown">AGG.</h6>
                      {(analyticsAccess?.is_viewer &&
                        analyticsAccess?.is_editor) ||
                      (!analyticsAccess?.is_viewer &&
                        analyticsAccess?.is_editor) ||
                      (analyticsAccess?.is_viewer &&
                        !analyticsAccess?.is_editor) ? (
                        <Box
                          sx={{ minWidth: 200 }}
                          style={{ float: "right", marginRight: "4px" }}
                        >
                          <select
                            className="form-input-class agg-input"
                            style={{ padding: "16px 8px", borderRadius: "4px" }}
                            id="selectedClass"
                            value={selectTimeRange}
                            onChange={(e) =>
                              dispatch(
                                setOverviewData({
                                  selectTimeRange: e.target.value,
                                })
                              )
                            }
                            required
                          >
                            <option value="15" selected disabled hidden>
                              15 Min
                            </option>
                            <option value="15">15 Min</option>
                            <option value="30">30 Min</option>
                            <option value="60">Hourly</option>
                            <option value="1">Daily</option>
                            <option value="7">Weekly</option>
                          </select>
                        </Box>
                      ) : (
                        <Box
                          sx={{ minWidth: 200 }}
                          style={{ float: "right", marginRight: "4px" }}
                        >
                          <select
                            className="form-input-class agg-input"
                            style={{ padding: "16px 8px", borderRadius: "4px" }}
                            id="selectedClass"
                            onChange={handleTostMsg}
                            required
                          >
                            <option value="15" selected disabled hidden>
                              15 Min
                            </option>
                            <option value="15">15 Min</option>
                            <option value="30">30 Min</option>
                            <option value="60">Hourly</option>
                            <option value="1">Daily</option>
                            <option value="7">Weekly</option>
                          </select>
                        </Box>
                      )}
                      <div>
                        <div>
                          <select
                            className="compact-btn"
                            value={selectUnit}
                            onChange={(e) =>
                              dispatch(
                                setProcessDashboarDateSet({
                                  selectUnit: e.target.value,
                                })
                              )
                            }
                          >
                            <option value="k">Kilo</option>
                            <option value="m">Mega</option>
                            <option value="g">Giga</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex consumer-div">
                    {(analyticsAccess?.is_viewer &&
                      analyticsAccess?.is_editor) ||
                    (!analyticsAccess?.is_viewer &&
                      analyticsAccess?.is_editor) ||
                    (analyticsAccess?.is_viewer &&
                      !analyticsAccess?.is_editor) ? (
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="top"
                          control={
                            <Switch color="primary" checked={showTime} />
                          }
                          label="Time"
                          labelPlacement="top"
                          className="time-ref overview"
                          onChange={handleToggleTime}
                        />
                      </FormGroup>
                    ) : (
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="top"
                          className="time-ref"
                          control={<Switch color="primary" />}
                          label="Time"
                          labelPlacement="top"
                          onChange={handleTostMsg}
                        />
                      </FormGroup>
                    )}

                    <COCDatePicker
                      time={showTime}
                      startDateReFormat={startDateReFormat}
                      endDateReFormat={endDateReFormat}
                      handleStartDate={handleStartDate}
                      handleEndDate={handleEndDate}
                      access={analyticsAccess}
                      handleTostMsg={handleTostMsg}
                    />
                  </div>
                </div>
              ) : (
                <div className="analytics-aggregation analyticsAgg-datePicker analyticsAgg-datePicker-overview">
                  <div className="d-flex agg-div gap-2">
                    <h6 className="AGG-dropdown">AGG.</h6>
                    {(analyticsAccess?.is_viewer &&
                      analyticsAccess?.is_editor) ||
                    (!analyticsAccess?.is_viewer &&
                      analyticsAccess?.is_editor) ||
                    (analyticsAccess?.is_viewer &&
                      !analyticsAccess?.is_editor) ? (
                      <Box sx={{ minWidth: 200 }} style={{ float: "right" }}>
                        <select
                          className="form-input-class agg-input"
                          style={{ padding: "16px 8px", borderRadius: "4px" }}
                          id="selectedClass"
                          value={selectPOPTimeRange}
                          onChange={(event) =>
                            dispatch(
                              setOverviewData({
                                selectPOPTimeRange: event.target.value,
                              })
                            )
                          }
                          required
                        >
                          <option value="15" selected disabled hidden>
                            15 Min
                          </option>
                          <option value="15">15 Min</option>
                          <option value="30">30 Min</option>
                          <option value="60">Hourly</option>
                          <option value="1">Daily</option>
                          <option value="7">Weekly</option>
                        </select>
                      </Box>
                    ) : (
                      <Box sx={{ minWidth: 200 }} style={{ float: "right" }}>
                        <select
                          className="form-input-class agg-input"
                          style={{ padding: "16px 8px", borderRadius: "4px" }}
                          id="selectedClass"
                          value={selectPOPTimeRange}
                          onChange={handleTostMsg}
                          required
                        >
                          <option value="15" selected disabled hidden>
                            15 Min
                          </option>
                          <option value="15">15 Min</option>
                          <option value="30">30 Min</option>
                          <option value="60">Hourly</option>
                          <option value="1">Daily</option>
                          <option value="7">Weekly</option>
                        </select>
                      </Box>
                    )}
                    <div>
                      <div>
                        <select
                          className="compact-btn"
                          value={selectUnit}
                          onChange={(e) =>
                            dispatch(
                              setProcessDashboarDateSet({
                                selectUnit: e.target.value,
                              })
                            )
                          }
                        >
                          <option value="k">Kilo</option>
                          <option value="m">Mega</option>
                          <option value="g">Giga</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="pop-datepicker">
                    {(analyticsAccess?.is_viewer &&
                      analyticsAccess?.is_editor) ||
                    (!analyticsAccess?.is_viewer &&
                      analyticsAccess?.is_editor) ||
                    (analyticsAccess?.is_viewer &&
                      !analyticsAccess?.is_editor) ? (
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="top"
                          control={
                            <Switch color="primary" checked={showTimePop} />
                          }
                          label="Time"
                          labelPlacement="top"
                          onChange={handleToggleTimePop}
                          className="popdate-picker-label"
                        />
                      </FormGroup>
                    ) : (
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="top"
                          control={<Switch color="primary" />}
                          label="Time"
                          labelPlacement="top"
                          onChange={handleTostMsg}
                          className="popdate-picker-label"
                        />
                      </FormGroup>
                    )}

                    <POPDatePicker
                      time={showTimePop}
                      startDateReFormat={startDatePOPReFormat}
                      endDateReFormat={endDatePOPReFormat}
                      startDate1ReFormat={startDatePOP1ReFormat}
                      endDate1ReFormat={endDatePOP1ReFormat}
                      handleStartDate={handlePOPStartDate}
                      handleEndDate={handlePOPEndDate}
                      handlePOP1StartDate={handlePOP1StartDate}
                      handlePOP1EndDate={handlePOP1EndDate}
                      access={analyticsAccess}
                      handleTostMsg={handleTostMsg}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="pt-2 consumption-grid-card">
              <Grid container spacing={2}>
                {activeClassConsumer == "Consumer" ? (
                  <>
                    <Grid item xs={12} sm={12} md={3}>
                      <Box className="consumerData-box consumer-box">
                        <Box className="consumer-text-disc">
                          <h6 className="Title">Title</h6>
                          <h6>Energy Meter Charts</h6>
                        </Box>

                        <TextField
                          variant="outlined"
                          className="search-textfield"
                          placeholder="Search Device here"
                          value={searchQuery}
                          onChange={handleSearchChange}
                          InputProps={{
                            startAdornment: <SearchIcon />,
                            inputProps: {
                              style: { padding: "9px" },
                            },
                          }}
                        />
                        <div className="furnace-grid">
                          <Box className="Grid-text mt-2">
                            {!isShimmerdevicelist ? (
                              deviceData.length ? (
                                deviceData
                                  .filter((e) =>
                                    e.device__device_name
                                      ?.toLowerCase()
                                      ?.includes(searchQuery.toLowerCase())
                                  )
                                  .map((e, index) => (
                                    <Grid
                                      item
                                      xs={12}
                                      key={e.device__device_name}
                                    >
                                      <Grid
                                        container
                                        alignItems="center"
                                        style={{
                                          background: "#efeffd",
                                          width: "100%",
                                          marginBottom: "7px",
                                        }}
                                      >
                                        <Grid
                                          item
                                          xs={8}
                                          style={{
                                            padding: "7px 12px",
                                            borderLeft: `4px solid ${
                                              switchColors[
                                                index % switchColors.length
                                              ]
                                            }`,
                                          }}
                                        >
                                          <h6
                                            className="mt-2"
                                            style={{ fontSize: "16px" }}
                                          >
                                            {e.device__device_name}
                                          </h6>
                                        </Grid>
                                        {(analyticsAccess?.is_viewer &&
                                          analyticsAccess?.is_editor) ||
                                        (!analyticsAccess?.is_viewer &&
                                          analyticsAccess?.is_editor) ||
                                        (analyticsAccess?.is_viewer &&
                                          !analyticsAccess?.is_editor) ? (
                                          <Grid
                                            item
                                            xs={4}
                                            textAlign="right"
                                            className="grid-switch-unit"
                                          >
                                            <Switch
                                              style={{ float: "right" }}
                                              onClick={(event) =>
                                                handleSelectDeviceId(
                                                  e.device,
                                                  index,
                                                  event,
                                                  e
                                                )
                                              }
                                              name={switchColors[index]}
                                              checked={
                                                deviceIdRedux.find(
                                                  (f) =>
                                                    f.deviceID == e.device &&
                                                    f.plantId == param.id
                                                )
                                                  ? true
                                                  : false
                                              }
                                              sx={{
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor: "#828282",
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#D9D9E5 !important",
                                                },
                                                "& .Mui-checked": {
                                                  "& .MuiSwitch-thumb": {
                                                    bgcolor:
                                                      switchColors[
                                                        index %
                                                          switchColors.length
                                                      ],
                                                  },
                                                  "& .MuiSwitch-track": {
                                                    bgcolor:
                                                      "#E2D9F2 !important",
                                                  },
                                                },
                                              }}
                                            />
                                          </Grid>
                                        ) : (
                                          <Grid
                                            item
                                            xs={4}
                                            textAlign="right"
                                            className="grid-switch-unit"
                                          >
                                            <Switch
                                              style={{ float: "right" }}
                                              onClick={handleTostMsg}
                                              name={switchColors[index]}
                                              checked={
                                                deviceIdRedux.find(
                                                  (f) =>
                                                    f.deviceID == e.device &&
                                                    f.plantId == param.id
                                                )
                                                  ? true
                                                  : false
                                              }
                                              sx={{
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor: "#828282",
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#D9D9E5 !important",
                                                },
                                                "& .Mui-checked": {
                                                  "& .MuiSwitch-thumb": {
                                                    bgcolor:
                                                      switchColors[
                                                        index %
                                                          switchColors.length
                                                      ],
                                                  },
                                                  "& .MuiSwitch-track": {
                                                    bgcolor:
                                                      "#E2D9F2 !important",
                                                  },
                                                },
                                              }}
                                            />
                                          </Grid>
                                        )}
                                      </Grid>
                                    </Grid>
                                  ))
                              ) : (
                                []
                              )
                            ) : (
                              <DeviceListShimmer itemCount={3} />
                            )}
                          </Box>
                        </div>
                        <Box className="consumer-text-disc mt-2"></Box>

                        <TextField
                          variant="outlined"
                          className="search-textfield"
                          placeholder="Search Unit here"
                          value={unitSearchQuery}
                          onChange={handleUnitSearchChange}
                          InputProps={{
                            startAdornment: <SearchIcon />,
                            inputProps: {
                              style: { padding: "9px" },
                            },
                          }}
                        />

                        <div className="device-unit" style={{height:"218px"}}>
                          <Box className="Grid-text1 mt-2">
                            {!isShimmerUnitlist ? (
                              deviceUnitData
                                .filter(
                                  (e) =>
                                    e.tag_unit &&
                                    e.tag_unit
                                      .toLowerCase()
                                      .includes(unitSearchQuery.toLowerCase())
                                )
                                .map((e) => (
                                  <Grid item xs={12} key={e.tag_unit}>
                                    {e.tag_unit && (
                                      <Grid
                                        container
                                        alignItems="center"
                                        style={{
                                          background: "#efeffd",
                                          width: "100%",
                                          marginBottom: "7px",
                                        }}
                                      >
                                        <Grid
                                          item
                                          xs={8}
                                          className="mt-2"
                                          style={{ padding: "7px 12px" }}
                                        >
                                          <h6>{e.tag_unit}</h6>
                                        </Grid>
                                        {(analyticsAccess?.is_viewer &&
                                          analyticsAccess?.is_editor) ||
                                        (!analyticsAccess?.is_viewer &&
                                          analyticsAccess?.is_editor) ||
                                        (analyticsAccess?.is_viewer &&
                                          !analyticsAccess?.is_editor) ? (
                                          <Grid
                                            item
                                            xs={4}
                                            textAlign="right"
                                            className="grid-switch-unit"
                                          >
                                            <Switch
                                              checked={
                                                deviceUnitRedux.length
                                                  ? deviceUnitRedux.find(
                                                      (f) =>
                                                        f.tagUnit ==
                                                          e.tag_unit &&
                                                        f.plantId == param.id
                                                    )
                                                    ? true
                                                    : false
                                                  : false
                                              }
                                              style={{ float: "right" }}
                                              onClick={() =>
                                                handleSelectTagUnit(e.tag_unit)
                                              }
                                              sx={{
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor: "#828282",
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#D9D9E5 !important",
                                                },
                                                "& .Mui-checked": {
                                                  "& .MuiSwitch-thumb": {
                                                    bgcolor: "#50C878",
                                                  },
                                                  "& .MuiSwitch-track": {
                                                    bgcolor:
                                                      "#CEE6E1 !important",
                                                  },
                                                },
                                              }}
                                            />
                                          </Grid>
                                        ) : (
                                          <Grid
                                            item
                                            xs={4}
                                            textAlign="right"
                                            className="grid-switch-unit"
                                          >
                                            <Switch
                                              checked={
                                                deviceUnitRedux.length
                                                  ? deviceUnitRedux.find(
                                                      (f) =>
                                                        f.tagUnit ==
                                                          e.tag_unit &&
                                                        f.plantId == param.id
                                                    )
                                                    ? true
                                                    : false
                                                  : false
                                              }
                                              style={{ float: "right" }}
                                              onClick={() => handleTostMsg}
                                              sx={{
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor: "#828282",
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#D9D9E5 !important",
                                                },
                                                "& .Mui-checked": {
                                                  "& .MuiSwitch-thumb": {
                                                    bgcolor: "#50C878",
                                                  },
                                                  "& .MuiSwitch-track": {
                                                    bgcolor:
                                                      "#CEE6E1 !important",
                                                  },
                                                },
                                              }}
                                            />
                                          </Grid>
                                        )}
                                      </Grid>
                                    )}
                                  </Grid>
                                ))
                            ) : (
                              <DeviceListShimmer itemCount={3} />
                            )}
                          </Box>
                        </div>

                        <div className="mt-2">
                          <button
                            className="export-btn"
                            style={{ marginTop: "27px" }}
                            onClick={handleExportEnergyData}
                          >
                            EXPORT ENERGY DATA
                          </button>
                        </div>
                      </Box>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} sm={12} md={3}>
                      <Box className="consumerData-box consumer-box">
                        <Box className="consumer-text-disc">
                          <h6 className="Title">Title</h6>
                          <h6>Energy Meter Charts</h6>
                        </Box>

                        <TextField
                          variant="outlined"
                          className="search-textfield"
                          placeholder="Search Device here"
                          value={searchQuery}
                          onChange={handleSearchChange}
                          InputProps={{
                            startAdornment: <SearchIcon />,
                            inputProps: {
                              style: { padding: "9px" },
                            },
                          }}
                        />
                        <div className="furnace-grid">
                          <Box className="Grid-text mt-2">
                            {!isShimmerdevicelist ? (
                              deviceData.length ? (
                                deviceData
                                  .filter((e) =>
                                    e.device__device_name
                                      ?.toLowerCase()
                                      ?.includes(searchQuery.toLowerCase())
                                  )
                                  .map((e, index) => (
                                    <Grid
                                      item
                                      xs={12}
                                      key={e.device__device_name}
                                    >
                                      <Grid
                                        container
                                        alignItems="center"
                                        style={{
                                          background: "#efeffd",
                                          width: "100%",
                                          marginBottom: "7px",
                                        }}
                                      >
                                        <Grid
                                          item
                                          xs={8}
                                          style={{
                                            padding: "7px 12px",
                                            borderLeft: `4px solid ${
                                              switchColors[
                                                index % switchColors.length
                                              ]
                                            }`,
                                          }}
                                        >
                                          <h6
                                            className="mt-2"
                                            style={{ fontSize: "16px" }}
                                          >
                                            {e.device__device_name}
                                          </h6>
                                        </Grid>
                                        {(analyticsAccess?.is_viewer &&
                                          analyticsAccess?.is_editor) ||
                                        (!analyticsAccess?.is_viewer &&
                                          analyticsAccess?.is_editor) ||
                                        (analyticsAccess?.is_viewer &&
                                          !analyticsAccess?.is_editor) ? (
                                          <Grid
                                            item
                                            xs={4}
                                            textAlign="right"
                                            className="grid-switch-unit"
                                          >
                                            <Switch
                                              style={{ float: "right" }}
                                              onClick={(event) =>
                                                handlePOPSelectDeviceId(
                                                  e.device,
                                                  index,
                                                  event,
                                                  e
                                                )
                                              }
                                              name={switchColors[index]}
                                              checked={
                                                deviceIdPOPRedux.length
                                                  ? deviceIdPOPRedux.find(
                                                      (f) =>
                                                        f.deviceID ==
                                                          e.device &&
                                                        f.plantId == param.id
                                                    )
                                                    ? true
                                                    : false
                                                  : false
                                              }
                                              sx={{
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor: "#828282",
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#D9D9E5 !important",
                                                },
                                                "& .Mui-checked": {
                                                  "& .MuiSwitch-thumb": {
                                                    bgcolor:
                                                      switchColors[
                                                        index %
                                                          switchColors.length
                                                      ],
                                                  },
                                                  "& .MuiSwitch-track": {
                                                    bgcolor:
                                                      "#E2D9F2 !important",
                                                  },
                                                },
                                              }}
                                            />
                                          </Grid>
                                        ) : (
                                          <Grid
                                            item
                                            xs={4}
                                            textAlign="right"
                                            className="grid-switch-unit"
                                          >
                                            <Switch
                                              style={{ float: "right" }}
                                              onClick={handleTostMsg}
                                              name={switchColors[index]}
                                              checked={
                                                deviceIdPOPRedux.length
                                                  ? deviceIdPOPRedux.find(
                                                      (f) =>
                                                        f.deviceID ==
                                                          e.device &&
                                                        f.plantId == param.id
                                                    )
                                                    ? true
                                                    : false
                                                  : false
                                              }
                                              sx={{
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor: "#828282",
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#D9D9E5 !important",
                                                },
                                                "& .Mui-checked": {
                                                  "& .MuiSwitch-thumb": {
                                                    bgcolor:
                                                      switchColors[
                                                        index %
                                                          switchColors.length
                                                      ],
                                                  },
                                                  "& .MuiSwitch-track": {
                                                    bgcolor:
                                                      "#E2D9F2 !important",
                                                  },
                                                },
                                              }}
                                            />
                                          </Grid>
                                        )}
                                      </Grid>
                                    </Grid>
                                  ))
                              ) : (
                                []
                              )
                            ) : (
                              <DeviceListShimmer itemCount={3} />
                            )}
                          </Box>
                        </div>
                        <Box className="consumer-text-disc mt-2"></Box>

                        <TextField
                          variant="outlined"
                          className="search-textfield"
                          placeholder="Search Unit here"
                          value={unitSearchQuery}
                          onChange={handleUnitSearchChange}
                          InputProps={{
                            startAdornment: <SearchIcon />,
                            inputProps: {
                              style: { padding: "9px" },
                            },
                          }}
                        />

                        <div className="device-unit" style={{height:"209px"}}>
                          {(analyticsAccess?.is_viewer &&
                            analyticsAccess?.is_editor) ||
                          (!analyticsAccess?.is_viewer &&
                            analyticsAccess?.is_editor) ||
                          (analyticsAccess?.is_viewer &&
                            !analyticsAccess?.is_editor) ? (
                            <Box className="Grid-text1 mt-2">
                              {!isShimmerUnitlist ? (
                                deviceUnitData
                                  .filter(
                                    (e) =>
                                      e.tag_unit &&
                                      e.tag_unit
                                        .toLowerCase()
                                        .includes(unitSearchQuery.toLowerCase())
                                  )
                                  .map((e) => (
                                    <Grid item xs={12} key={e.tag_unit}>
                                      {e.tag_unit && (
                                        <Grid
                                          container
                                          alignItems="center"
                                          style={{
                                            background: "#efeffd",
                                            width: "100%",
                                            marginBottom: "7px",
                                          }}
                                        >
                                          <Grid
                                            item
                                            xs={8}
                                            className="mt-2"
                                            style={{ padding: "7px 12px" }}
                                          >
                                            <h6>{e.tag_unit}</h6>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={4}
                                            textAlign="right"
                                            className="grid-switch-unit"
                                          >
                                            <Switch
                                              checked={
                                                deviceUnitPOPRedux.length
                                                  ? deviceUnitPOPRedux.find(
                                                      (f) =>
                                                        f.tagUnit ==
                                                          e.tag_unit &&
                                                        f.plantId == param.id
                                                    )
                                                    ? true
                                                    : false
                                                  : false
                                              }
                                              style={{ float: "right" }}
                                              onClick={() =>
                                                handlePOPSelectTagUnit(
                                                  e.tag_unit
                                                )
                                              }
                                              sx={{
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor: "#828282",
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#D9D9E5 !important",
                                                },
                                                "& .Mui-checked": {
                                                  "& .MuiSwitch-thumb": {
                                                    bgcolor: "#50C878",
                                                  },
                                                  "& .MuiSwitch-track": {
                                                    bgcolor:
                                                      "#CEE6E1 !important",
                                                  },
                                                },
                                              }}
                                            />
                                          </Grid>
                                        </Grid>
                                      )}
                                    </Grid>
                                  ))
                              ) : (
                                <DeviceListShimmer itemCount={3} />
                              )}
                            </Box>
                          ) : (
                            <Box className="Grid-text1 mt-2">
                              {!isShimmerUnitlist ? (
                                deviceUnitData
                                  .filter(
                                    (e) =>
                                      e.tag_unit &&
                                      e.tag_unit
                                        .toLowerCase()
                                        .includes(unitSearchQuery.toLowerCase())
                                  )
                                  .map((e) => (
                                    <Grid item xs={12} key={e.tag_unit}>
                                      {e.tag_unit && (
                                        <Grid
                                          container
                                          alignItems="center"
                                          style={{
                                            background: "#efeffd",
                                            width: "100%",
                                            marginBottom: "7px",
                                          }}
                                        >
                                          <Grid
                                            item
                                            xs={8}
                                            className="mt-2"
                                            style={{ padding: "7px 12px" }}
                                          >
                                            <h6>{e.tag_unit}</h6>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={4}
                                            textAlign="right"
                                            className="grid-switch-unit"
                                          >
                                            <Switch
                                              checked={
                                                deviceUnitPOPRedux.length
                                                  ? deviceUnitPOPRedux.find(
                                                      (f) =>
                                                        f.tagUnit ==
                                                          e.tag_unit &&
                                                        f.plantId == param.id
                                                    )
                                                    ? true
                                                    : false
                                                  : false
                                              }
                                              style={{ float: "right" }}
                                              onClick={handleTostMsg}
                                              sx={{
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor: "#828282",
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#D9D9E5 !important",
                                                },
                                                "& .Mui-checked": {
                                                  "& .MuiSwitch-thumb": {
                                                    bgcolor: "#50C878",
                                                  },
                                                  "& .MuiSwitch-track": {
                                                    bgcolor:
                                                      "#CEE6E1 !important",
                                                  },
                                                },
                                              }}
                                            />
                                          </Grid>
                                        </Grid>
                                      )}
                                    </Grid>
                                  ))
                              ) : (
                                <DeviceListShimmer itemCount={3} />
                              )}
                            </Box>
                          )}
                        </div>

                        <div className="mt-3">
                          <button
                            className="export-btn"
                            style={{ marginTop: "27px" }}
                            onClick={handlePOPExcel}
                          >
                            EXPORT ENERGY DATA
                          </button>
                        </div>
                      </Box>
                    </Grid>
                  </>
                )}

                {activeClassConsumer == "Consumer" ? (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={9}
                    className="consumption-table-box"
                  >
                    <Box className="consumerData-box">
                      <div style={{ minHeight: "322px" }}>
                        <div className="d-flex justify-content-between chart-div">
                          <h6>
                            <b>Pareto Chart - Consumer over Consumer</b>
                          </h6>
                          <div
                            onClick={() =>
                              navigate(
                                `/overviewanalytics/energybarchart/company/${param.companyId}/plant/${param.id}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                            className="zoom-chart-div"
                          >
                            <img src={images.zoomIn} />
                          </div>
                        </div>
                        <div className="spinner-container">
                          <ParetoChart
                            analyticsData={analyticsData}
                            Shimmer={isShimmerCOCParetoChart}
                          />
                        </div>
                      </div>
                      <div className="mt-2 chart-div">
                        <h6>
                          <b>Pie Chart - Consumer over Consumer</b>
                        </h6>
                        <div className="spinner-container">
                          <DonutChart
                            analyticsPieChartData={analyticsPieChartData}
                            selectedColors={deviceColors}
                            donutChartSum={donutChartSum}
                            donutUnit={donutUnit}
                            Shimmer={isShimmerCOCDonutChart}
                          />
                        </div>
                      </div>
                    </Box>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={9}
                    className="consumption-table-box"
                  >
                    <Box className="consumerData-box">
                      <div style={{ minHeight: "356px" }}>
                        <div className="d-flex justify-content-between chart-div">
                          <h6>
                            <b>Bar Chart - Period over Period</b>
                          </h6>
                          <div
                            onClick={() =>
                              navigate(
                                `/overviewanalytics/energybarchartpop/company/${param.companyId}/plant/${param.id}`
                              )
                            }
                            className="zoom-chart-div"
                            style={{ cursor: "pointer" }}
                          >
                            <img src={images.zoomIn} />
                          </div>
                        </div>
                        <div className="spinner-container">
                          <PopgroupBarChart
                            analyticsPOPData={analyticsPOPData}
                            analyticsPOP1Data={analyticsPOP1Data}
                            selectedColors={devicePOPColors}
                            deviceUnitPOPRedux={
                              deviceUnitPOPRedux.length
                                ? deviceUnitPOPRedux.filter(
                                    (f) => f.plantId == param.id
                                  ).length
                                  ? deviceUnitPOPRedux.filter(
                                      (f) => f.plantId == param.id
                                    )
                                  : []
                                : []
                            }
                            Shimmer={isShimmerPopgroupBarChartPOP}
                          />
                        </div>
                      </div>
                      <div className="chart-div">
                        <h6>
                          <b>Pie Chart - Period over Period</b>
                        </h6>
                        <div className="spinner-container">
                          <PopDonutChart
                            analyticsPopPieChart={analyticsPopPieChart}
                            analyticsPop1PieChart={analyticsPop1PieChart}
                            selectedColors={devicePOPColors}
                            donutPOPChartSum={donutPOPChartSum}
                            donutPOPUnit={donutPOPUnit}
                            donutPOP1ChartSum={donutPOP1ChartSum}
                            Shimmer={isShimmerPopDonutChartPOP}
                          />
                        </div>
                      </div>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </div>
          </>
        ) : (
          <>
            <div className="d-flex justify-content-end pt-3">
              <div className="analyticsAgg-datePicker-overview">
                <div class="overview-report-wrap" style={{flexWrap:"wrap"}}>
                  <div>
                    <select
                      className="compact-btn mb-0"
                      value={selectUnit}
                      onChange={(e) =>
                        dispatch(
                          setProcessDashboarDateSet({
                            selectUnit: e.target.value,
                          })
                        )
                      }
                    >
                      <option value="k">Kilo</option>
                      <option value="m">Mega</option>
                      <option value="g">Giga</option>
                    </select>
                  </div>
                  <div className="overview-report-time">
                    <h6 className="AGG-dropdown">Interval</h6>
                    {(ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                    (!ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                    (ReportAccess?.is_viewer && !ReportAccess?.is_editor) ? (
                      <>
                        <Box style={{ float: "right" }}>
                          <select
                            className="form-input-class"
                            style={{
                              padding: "8px",
                              fontSize: "14px",
                              borderRadius: "4px",
                            }}
                            id="selectedClass"
                            value={reportInterval}
                            onChange={handleReportInterval}
                            required
                          >
                            <option value="1" selected disabled hidden>
                              1 min
                            </option>
                            <option value="1">1 Min</option>
                            <option value="15">15 Min</option>
                            <option value="30">30 Min</option>
                            <option value="60">Hourly</option>
                            <option value="24">Day</option>
                            <option value="7">Weekly</option>
                          </select>
                        </Box>
                      </>
                    ) : (
                      <Box style={{ float: "right" }}>
                        <select
                          className="form-input-class"
                          style={{
                            padding: "8px",
                            fontSize: "14px",
                            borderRadius: "4px",
                          }}
                          id="selectedClass"
                          // value={selectIntervalReport}
                          onChange={handleTostMsg}
                          required
                        >
                          <option value="1" selected disabled hidden>
                            1 min
                          </option>
                          <option value="1">1 Min</option>
                          <option value="15">15 Min</option>
                          <option value="30">30 Min</option>
                          <option value="60">Hourly</option>
                          <option value="24">Day</option>
                          <option value="7">Weekly</option>
                        </select>
                      </Box>
                    )}
                  </div>
                  <div className="overview-report-agg">
                    <h6 className="AGG-dropdown">AGG.</h6>
                    {(ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                    (!ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                    (ReportAccess?.is_viewer && !ReportAccess?.is_editor) ? (
                      <Box sx={{ minWidth: 200 }} style={{ float: "right" }}>
                        <select
                          className="form-input-class"
                          style={{
                            padding: "8px",
                            fontSize: "14px",
                            borderRadius: "4px",
                          }}
                          id="selectedClass"
                          value={reportAgg}
                          onChange={handleAggReport}
                          // onChange={(e) => ["kwh", "kvah", "kvarh"].includes(selectFirstUnit.toLocaleLowerCase()) || ["kwh", "kvah", "kvarh"].includes(selectSecondUnit.toLocaleLowerCase()) ? null : setSelectAggReport(e.target.value) }
                          required
                        >
                          <option value="15" selected disabled hidden>
                            15 Min
                          </option>
                          <option value="15">15 Min</option>
                          <option value="30">30 Min</option>
                          <option value="60">Hourly</option>
                          <option value="1">Day</option>
                          <option value="7">Weekly</option>
                        </select>
                      </Box>
                    ) : (
                      <Box sx={{ minWidth: 200 }} style={{ float: "right" }}>
                        <select
                          className="form-input-class"
                          style={{
                            padding: "8px",
                            fontSize: "14px",
                            borderRadius: "4px",
                          }}
                          id="selectedClass"
                          // value={selectAggReport}
                          onChange={handleTostMsg}
                          // onChange={(e) => ["kwh", "kvah", "kvarh"].includes(selectFirstUnit.toLocaleLowerCase()) || ["kwh", "kvah", "kvarh"].includes(selectSecondUnit.toLocaleLowerCase()) ? null : setSelectAggReport(e.target.value) }
                          required
                        >
                          <option value="15" selected disabled hidden>
                            15 Min
                          </option>
                          <option value="15">15 Min</option>
                          <option value="30">30 Min</option>
                          <option value="60">Hourly</option>
                          <option value="1">Day</option>
                          <option value="7">Weekly</option>
                        </select>
                      </Box>
                    )}
                  </div>
                </div>

                <div className="overview-time-wrap">
                  <div>
                    {(ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                    (!ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                    (ReportAccess?.is_viewer && !ReportAccess?.is_editor) ? (
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="top"
                          control={
                            <Switch color="primary" checked={reportShowTime} />
                          }
                          label="Time"
                          labelPlacement="top"
                          onChange={handleShowReportToggle}
                          className="overview-switch"
                        />
                      </FormGroup>
                    ) : (
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="top"
                          control={<Switch color="primary" />}
                          label="Time"
                          labelPlacement="top"
                          onChange={handleTostMsg}
                          className="overview-switch"
                        />
                      </FormGroup>
                    )}
                  </div>
                  <div className="overview-date-picker-wrap">
                    {/* <div> */}
                    {reportShowTime ? (
                      <>
                        {(ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                        (!ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                        (ReportAccess?.is_viewer &&
                          !ReportAccess?.is_editor) ? (
                          <>
                            <Box className="">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                  label="Start Date"
                                  value={startDateReportFormat}
                                  onChange={(newValue) =>
                                    handleReportsReportStartDate(newValue)
                                  }
                                  disableFuture
                                  viewRenderers={{
                                    hours: renderTimeViewClock,
                                    minutes: renderTimeViewClock,
                                    seconds: renderTimeViewClock,
                                  }}
                                    className="custom-datepicker"
                                />
                              </LocalizationProvider>
                            </Box>
                            <Box className="">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                  label="End Date"
                                  value={endDateReportFormat}
                                  disableFuture
                                  viewRenderers={{
                                    hours: renderTimeViewClock,
                                    minutes: renderTimeViewClock,
                                    seconds: renderTimeViewClock,
                                  }}
                                  onChange={(newValue) =>
                                    handleReportsReportEndDate(newValue)
                                  }
                                  className="custom-datepicker"
                                />
                              </LocalizationProvider>
                            </Box>
                          </>
                        ) : (
                          <>
                            <Box className="">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                  label="Start Date"
                                  // value={startDateRep}
                                  onChange={handleTostMsg}
                                  disableFuture
                                  viewRenderers={{
                                    hours: renderTimeViewClock,
                                    minutes: renderTimeViewClock,
                                    seconds: renderTimeViewClock,
                                  }}
                                  className="custom-datepicker"
                                />
                              </LocalizationProvider>
                            </Box>
                            <Box className="">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                  label="End Date"
                                  // value={endDateRep}
                                  disableFuture
                                  viewRenderers={{
                                    hours: renderTimeViewClock,
                                    minutes: renderTimeViewClock,
                                    seconds: renderTimeViewClock,
                                  }}
                                  onChange={handleTostMsg}
                                  className="custom-datepicker"
                                />
                              </LocalizationProvider>
                            </Box>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {(ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                        (!ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                        (ReportAccess?.is_viewer &&
                          !ReportAccess?.is_editor) ? (
                          <>
                            <Box className="">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  className="custom-datepicker"
                                  label="Start Date"
                                  value={startDateReportFormat}
                                  inputFormat="DD/MM/YYYY"
                                  onChange={(newValue) => {
                                    handleReportsReportStartDate(newValue);
                                  }}
                                  disableFuture
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      style={{ background: "#ffff" }}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Box>
                            <Box
                              className=""
                              // style={{ width: "30%" }}
                            >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  className="custom-datepicker"
                                  label="End Date"
                                  value={endDateReportFormat}
                                  inputFormat="DD/MM/YYYY"
                                  onChange={(newValue) => {
                                    handleReportsReportEndDate(newValue);
                                  }}
                                  disableFuture
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      style={{ background: "#ffff" }}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Box>
                          </>
                        ) : (
                          <>
                            <Box className="">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  className="custom-datepicker"
                                  label="Start Date"
                                  // value={startDateRep}
                                  inputFormat="DD/MM/YYYY"
                                  onChange={handleTostMsg}
                                  disableFuture
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      style={{ background: "#ffff" }}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Box>
                            <Box
                              className=""
                              // style={{ width: "30%" }}
                            >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  className="custom-datepicker"
                                  label="End Date"
                                  // value={endDateRep}
                                  inputFormat="DD/MM/YYYY"
                                  onChange={handleTostMsg}
                                  disableFuture
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      style={{ background: "#ffff" }}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Box>
                          </>
                        )}
                      </>
                    )}
                    {/* </div> */}
                  </div>
                  <div
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent the event from propagating to the document
                    handleOpenEnergyModal();
                  }}
                    className="filter-img-class"
                  >
                    <img
                      src={images.filterImg}
                      alt=""
                      style={{ height: "40px" }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-2 consumption-grid-card">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={3}>
                  <Box
                    className="consumerData-box consumer-box"
                    style={{ minHeight: "761px" }}
                  >
                    <Box className="consumer-text-disc">
                      <h6 className="Title">Title</h6>
                      <h6>Energy Meter Charts</h6>
                    </Box>

                    <TextField
                      variant="outlined"
                      className="search-textfield"
                      placeholder="Search Device here"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      InputProps={{
                        startAdornment: <SearchIcon />,
                        inputProps: {
                          style: { padding: "9px" },
                        },
                      }}
                    />
                    <div className="furnace-grid">
                      <Box className="Grid-text mt-2">
                        {!isShimmerReportdevicelist ? (
                          deviceReport.length ? (
                            deviceReport
                              .filter((e) =>
                                e.device__device_name
                                  ?.toLowerCase()
                                  ?.includes(searchQuery.toLowerCase())
                              )
                              .map((e, index) => (
                                <Grid item xs={12} key={e.device__device_name}>
                                  <Grid
                                    container
                                    alignItems="center"
                                    style={{
                                      background: "#efeffd",
                                      width: "100%",
                                      marginBottom: "7px",
                                    }}
                                  >
                                    <Grid
                                      item
                                      xs={8}
                                      style={{
                                        padding: "7px 12px",
                                        borderLeft: `4px solid ${
                                          switchColors[
                                            index % switchColors.length
                                          ]
                                        }`,
                                      }}
                                    >
                                      <h6
                                        className="mt-2"
                                        style={{ fontSize: "16px" }}
                                      >
                                        {e.device__device_name}
                                      </h6>
                                    </Grid>

                                    {(ReportAccess?.is_viewer &&
                                      ReportAccess?.is_editor) ||
                                    (!ReportAccess?.is_viewer &&
                                      ReportAccess?.is_editor) ||
                                    (ReportAccess?.is_viewer &&
                                      !ReportAccess?.is_editor) ? (
                                      <Grid
                                        item
                                        xs={4}
                                        textAlign="right"
                                        className="grid-switch-unit"
                                      >
                                        <Switch
                                          style={{ float: "right" }}
                                          onClick={(event) =>
                                            handleSelectDeviceIdReport(
                                              e.device,
                                              index,
                                              event,
                                              e
                                            )
                                          }
                                          name={switchColors[index]}
                                          // checked={deviceIdReportRedux.includes(
                                          //   e.device
                                          // )}
                                          checked={
                                            deviceIdReportRedux.length
                                              ? deviceIdReportRedux.find(
                                                  (f) =>
                                                    f.deviceID == e.device &&
                                                    f.plantId == param.id
                                                )
                                                ? true
                                                : false
                                              : false
                                          }
                                          sx={{
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#828282",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#D9D9E5 !important",
                                            },
                                            "& .Mui-checked": {
                                              "& .MuiSwitch-thumb": {
                                                bgcolor:
                                                  switchColors[
                                                    index % switchColors.length
                                                  ],
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#E2D9F2 !important",
                                              },
                                            },
                                          }}
                                        />
                                      </Grid>
                                    ) : (
                                      <Grid
                                        item
                                        xs={4}
                                        textAlign="right"
                                        className="grid-switch-unit"
                                      >
                                        <Switch
                                          style={{ float: "right" }}
                                          onClick={handleTostMsg}
                                          name={switchColors[index]}
                                          // checked={deviceIdReportRedux.includes(
                                          //   e.device
                                          // )}
                                          checked={
                                            deviceIdReportRedux.length
                                              ? deviceIdReportRedux.find(
                                                  (f) =>
                                                    f.deviceID == e.device &&
                                                    f.plantId == param.id
                                                )
                                                ? true
                                                : false
                                              : false
                                          }
                                          sx={{
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#828282",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#D9D9E5 !important",
                                            },
                                            "& .Mui-checked": {
                                              "& .MuiSwitch-thumb": {
                                                bgcolor:
                                                  switchColors[
                                                    index % switchColors.length
                                                  ],
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#E2D9F2 !important",
                                              },
                                            },
                                          }}
                                        />
                                      </Grid>
                                    )}
                                  </Grid>
                                </Grid>
                              ))
                          ) : (
                            []
                          )
                        ) : (
                          <DeviceListShimmer itemCount={3} />
                        )}
                      </Box>
                    </div>
                    <Box className="consumer-text-disc mt-2">
                      {/* <h6 className="Title">Description</h6>
                    <h6>Units</h6> */}
                    </Box>

                    <TextField
                      variant="outlined"
                      className="search-textfield"
                      placeholder="Search Unit here"
                      value={unitSearchQuery}
                      onChange={handleUnitSearchChange}
                      InputProps={{
                        startAdornment: <SearchIcon />,
                        inputProps: {
                          style: { padding: "9px" },
                        },
                      }}
                    />

                    <div style={{height:"272px",overflow:"auto"}}>
                      {(ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                      (!ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                      (ReportAccess?.is_viewer && !ReportAccess?.is_editor) ? (
                        <Box className="Grid-text1 mt-2">
                          {!isShimmerReportUnitlist ? (
                            unitReport
                              .filter(
                                (e) =>
                                  e.tag_unit &&
                                  e.tag_unit
                                    .toLowerCase()
                                    .includes(unitSearchQuery.toLowerCase())
                              )
                              .map((e, index) => (
                                <Grid item xs={12} key={e.tag_unit}>
                                  {e.tag_unit && (
                                    <Grid
                                      container
                                      alignItems="center"
                                      style={{
                                        background: "#efeffd",
                                        width: "100%",
                                        marginBottom: "7px",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={8}
                                        className="mt-2"
                                        style={{ padding: "7px 12px" }}
                                      >
                                        <h6>{e.tag_unit}</h6>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={4}
                                        textAlign="right"
                                        className="grid-switch-unit"
                                      >
                                        <Switch
                                          checked={
                                            deviceUnitReportRedux.length
                                              ? deviceUnitReportRedux.find(
                                                  (f) =>
                                                    f.tagUnit == e.tag_unit &&
                                                    f.plantId == param.id
                                                )
                                                ? true
                                                : false
                                              : false
                                          }
                                          name={switchColors[index]}
                                          style={{ float: "right" }}
                                          onClick={(event) =>
                                            handleSelectTagUnitReport(
                                              e.tag_unit,
                                              event,
                                              e
                                            )
                                          }
                                          sx={{
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#828282",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#D9D9E5 !important",
                                            },
                                            "& .Mui-checked": {
                                              "& .MuiSwitch-thumb": {
                                                bgcolor:
                                                  switchColors[
                                                    index % switchColors.length
                                                  ],
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#E2D9F2 !important",
                                              },
                                            },
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  )}
                                </Grid>
                              ))
                          ) : (
                            <DeviceListShimmer itemCount={3} />
                          )}
                        </Box>
                      ) : (
                        <Box className="Grid-text1 mt-2">
                          {!isShimmerReportUnitlist ? (
                            unitReport
                              .filter(
                                (e) =>
                                  e.tag_unit &&
                                  e.tag_unit
                                    .toLowerCase()
                                    .includes(unitSearchQuery.toLowerCase())
                              )
                              .map((e, index) => (
                                <Grid item xs={12} key={e.tag_unit}>
                                  {e.tag_unit && (
                                    <Grid
                                      container
                                      alignItems="center"
                                      style={{
                                        background: "#efeffd",
                                        width: "100%",
                                        marginBottom: "7px",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={8}
                                        className="mt-2"
                                        style={{ padding: "7px 12px" }}
                                      >
                                        <h6>{e.tag_unit}</h6>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={4}
                                        textAlign="right"
                                        className="grid-switch-unit"
                                      >
                                        <Switch
                                          checked={
                                            deviceUnitReportRedux.length
                                              ? deviceUnitReportRedux.find(
                                                  (f) =>
                                                    f.tagUnit == e.tag_unit &&
                                                    f.plantId == param.id
                                                )
                                                ? true
                                                : false
                                              : false
                                          }
                                          name={switchColors[index]}
                                          style={{ float: "right" }}
                                          onClick={handleTostMsg}
                                          sx={{
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#828282",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#D9D9E5 !important",
                                            },
                                            "& .Mui-checked": {
                                              "& .MuiSwitch-thumb": {
                                                bgcolor:
                                                  switchColors[
                                                    index % switchColors.length
                                                  ],
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#E2D9F2 !important",
                                              },
                                            },
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  )}
                                </Grid>
                              ))
                          ) : (
                            <DeviceListShimmer itemCount={3} />
                          )}
                        </Box>
                      )}
                    </div>

                    <div className="mt-3">
                      <button
                        className="export-btn"
                        onClick={() => handleExportExcelClick()}
                      >
                        EXPORT ENERGY DATA
                      </button>
                    </div>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={9}
                  className="consumption-table-box"
                >
                  <Box className="consumerData-box" style={{ padding: "0px" }}>
                    <div style={{ minHeight: "400px" }}>
                      <MultiAxisLineChart
                        fisrtLineChartReportData={fisrtLineChartReportData}
                        secondeLineChartReportData={secondeLineChartReportData}
                        selectFirstUnit={CompactTagUnit(
                          selectUnit,
                          reportSelectUnit1
                        )}
                        selectSecondUnit={CompactTagUnit(
                          selectUnit,
                          reportSelectUnit2
                        )}
                        Shimmer={isShimmerMultiAxisLineChartReports}
                      />
                    </div>
                    <div className="d-flex justify-content-between m-3">
                      <select
                        className="form-input-class"
                        style={{
                          padding: "0px 14px",
                          borderRadius: "4px",
                          height: "40px",
                        }}
                        id="selectedClass"
                        value={reportSelectUnit1}
                        onChange={handleReportSelectUnit11}
                        required
                      >
                        <option value="none" selected hidden>
                          Select Unit
                        </option>
                        {deviceUnitReportRedux
                          ? deviceUnitReportRedux
                              .filter((f) => f.plantId == param.id)
                              .map((e) => (
                                <option value={e.tagUnit}>{e.tagUnit}</option>
                              ))
                          : []}
                      </select>

                      <select
                        className="form-input-class"
                        style={{
                          padding: "0px 14px",
                          borderRadius: "4px",
                          height: "40px",
                        }}
                        id="selectedClass"
                        value={reportSelectUnit2}
                        onChange={handleReportSelectUnit12}
                        required
                      >
                        <option value="none" selected hidden>
                          Select Unit
                        </option>
                        {deviceUnitReportRedux
                          ? deviceUnitReportRedux
                              .filter((f) => f.plantId == param.id)
                              .map((e) => (
                                <option value={e.tagUnit}>{e.tagUnit}</option>
                              ))
                          : []}
                      </select>
                    </div>
                    <div
                      
                      className="report-overview-table"
                    >
                      <Table className="source-table">
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>

                            {reportOverViewData.length
                              ? reportOverViewData.map((row) => {
                                  const formattedValue = row.tag_unit;
                                  return (
                                    <TableCell>{formattedValue}</TableCell>
                                  );
                                })
                              : []}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>Min</TableCell>
                            {reportOverViewData.length
                              ? reportOverViewData.map((row) => {
                                  const formattedValue1 = row.min_value
                                    ? parseFloat(row.min_value).toFixed(2)
                                    : 0.0;
                                  return (
                                    <TableCell>{formattedValue1}</TableCell>
                                  );
                                })
                              : []}
                          </TableRow>
                          <TableRow>
                            <TableCell>Max</TableCell>
                            {reportOverViewData.length
                              ? reportOverViewData.map((row) => {
                                  const formattedValue2 = row.max_value
                                    ? parseFloat(row.max_value).toFixed(2)
                                    : 0.0;
                                  return (
                                    <TableCell>{formattedValue2}</TableCell>
                                  );
                                })
                              : []}
                          </TableRow>
                          <TableRow>
                            <TableCell>Mean</TableCell>
                            {reportOverViewData.length
                              ? reportOverViewData.map((row) => {
                                  const formattedValue3 = row.mean_value
                                    ? parseFloat(row.mean_value).toFixed(2)
                                    : 0.0;
                                  return (
                                    <TableCell>{formattedValue3}</TableCell>
                                  );
                                })
                              : []}
                          </TableRow>
                          <TableRow>
                            <TableCell>Std. Dev</TableCell>
                            {reportOverViewData.length
                              ? reportOverViewData.map((row) => {
                                  const formattedValue4 = row.std_dev_value
                                    ? parseFloat(row.std_dev_value).toFixed(2)
                                    : 0.0;
                                  return (
                                    <TableCell>{formattedValue4}</TableCell>
                                  );
                                })
                              : []}
                          </TableRow>
                          <TableRow>
                            <TableCell>All Time Min</TableCell>
                            {reportOverViewData.length
                              ? reportOverViewData.map((row) => {
                                  const formattedValue5 = row.all_time_min_value
                                    ? parseFloat(
                                        row.all_time_min_value
                                      ).toFixed(2)
                                    : 0.0;
                                  return (
                                    <TableCell>{formattedValue5}</TableCell>
                                  );
                                })
                              : []}
                          </TableRow>
                          <TableRow>
                            <TableCell>All Time Max</TableCell>
                            {reportOverViewData.length
                              ? reportOverViewData.map((row) => {
                                  const formattedValue6 = row.all_time_max_value
                                    ? parseFloat(
                                        row.all_time_max_value
                                      ).toFixed(2)
                                    : 0.0;
                                  return (
                                    <TableCell>{formattedValue6}</TableCell>
                                  );
                                })
                              : []}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>{" "}
                  </Box>
                </Grid>
              </Grid>
            </div>
          </>
        )}
        {openEnergyModal &&
          (activeClassConsumer == "Consumer" ? (
            <>
              <div className="consumerCard" ref={modalRef}>
                <Box className="consumerData-box consumer-card-box">
                  <Box className="consumer-text-disc">
                    <h6 className="Title">Title</h6>
                    <h6>Energy Meter Charts</h6>
                  </Box>

                  <TextField
                    variant="outlined"
                    className="search-textfield"
                    placeholder="Search Device here"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    InputProps={{
                      startAdornment: <SearchIcon />,
                      inputProps: {
                        style: { padding: "9px" },
                      },
                    }}
                  />
                  <div className="furnace-grid">
                    <Box className="Grid-text mt-2">
                      {!isShimmerdevicelist ? (
                        deviceData.length ? (
                          deviceData
                            .filter((e) =>
                              e.device__device_name
                                ?.toLowerCase()
                                ?.includes(searchQuery.toLowerCase())
                            )
                            .map((e, index) => (
                              <Grid item xs={12} key={e.device__device_name}>
                                <Grid
                                  container
                                  alignItems="center"
                                  style={{
                                    background: "#efeffd",
                                    width: "100%",
                                    marginBottom: "7px",
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={8}
                                    style={{
                                      padding: "7px 12px",
                                      borderLeft: `4px solid ${
                                        switchColors[
                                          index % switchColors.length
                                        ]
                                      }`,
                                    }}
                                  >
                                    <h6
                                      className="mt-2"
                                      style={{ fontSize: "16px" }}
                                    >
                                      {e.device__device_name}
                                    </h6>
                                  </Grid>
                                  {(analyticsAccess?.is_viewer &&
                                    analyticsAccess?.is_editor) ||
                                  (!analyticsAccess?.is_viewer &&
                                    analyticsAccess?.is_editor) ||
                                  (analyticsAccess?.is_viewer &&
                                    !analyticsAccess?.is_editor) ? (
                                    <Grid
                                      item
                                      xs={4}
                                      textAlign="right"
                                      className="grid-switch-unit"
                                    >
                                      <Switch
                                        style={{ float: "right" }}
                                        onClick={(event) =>
                                          handleSelectDeviceId(
                                            e.device,
                                            index,
                                            event,
                                            e
                                          )
                                        }
                                        name={switchColors[index]}
                                        checked={
                                          deviceIdRedux.find(
                                            (f) =>
                                              f.deviceID == e.device &&
                                              f.plantId == param.id
                                          )
                                            ? true
                                            : false
                                        }
                                        sx={{
                                          "& .MuiSwitch-thumb": {
                                            bgcolor: "#828282",
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#D9D9E5 !important",
                                          },
                                          "& .Mui-checked": {
                                            "& .MuiSwitch-thumb": {
                                              bgcolor:
                                                switchColors[
                                                  index % switchColors.length
                                                ],
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#E2D9F2 !important",
                                            },
                                          },
                                        }}
                                      />
                                    </Grid>
                                  ) : (
                                    <Grid
                                      item
                                      xs={4}
                                      textAlign="right"
                                      className="grid-switch-unit"
                                    >
                                      <Switch
                                        style={{ float: "right" }}
                                        onClick={handleTostMsg}
                                        name={switchColors[index]}
                                        checked={
                                          deviceIdRedux.find(
                                            (f) =>
                                              f.deviceID == e.device &&
                                              f.plantId == param.id
                                          )
                                            ? true
                                            : false
                                        }
                                        sx={{
                                          "& .MuiSwitch-thumb": {
                                            bgcolor: "#828282",
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#D9D9E5 !important",
                                          },
                                          "& .Mui-checked": {
                                            "& .MuiSwitch-thumb": {
                                              bgcolor:
                                                switchColors[
                                                  index % switchColors.length
                                                ],
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#E2D9F2 !important",
                                            },
                                          },
                                        }}
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                            ))
                        ) : (
                          []
                        )
                      ) : (
                        <DeviceListShimmer itemCount={3} />
                      )}
                    </Box>
                  </div>
                  <Box className="consumer-text-disc mt-2"></Box>

                  <TextField
                    variant="outlined"
                    className="search-textfield"
                    placeholder="Search Unit here"
                    value={unitSearchQuery}
                    onChange={handleUnitSearchChange}
                    InputProps={{
                      startAdornment: <SearchIcon />,
                      inputProps: {
                        style: { padding: "9px" },
                      },
                    }}
                  />

                  <div className="device-unit">
                    <Box className="Grid-text1 mt-2">
                      {!isShimmerUnitlist ? (
                        deviceUnitData
                          .filter(
                            (e) =>
                              e.tag_unit &&
                              e.tag_unit
                                .toLowerCase()
                                .includes(unitSearchQuery.toLowerCase())
                          )
                          .map((e) => (
                            <Grid item xs={12} key={e.tag_unit}>
                              {e.tag_unit && (
                                <Grid
                                  container
                                  alignItems="center"
                                  style={{
                                    background: "#efeffd",
                                    width: "100%",
                                    marginBottom: "7px",
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={8}
                                    className="mt-2"
                                    style={{ padding: "7px 12px" }}
                                  >
                                    <h6>{e.tag_unit}</h6>
                                  </Grid>
                                  {(analyticsAccess?.is_viewer &&
                                    analyticsAccess?.is_editor) ||
                                  (!analyticsAccess?.is_viewer &&
                                    analyticsAccess?.is_editor) ||
                                  (analyticsAccess?.is_viewer &&
                                    !analyticsAccess?.is_editor) ? (
                                    <Grid
                                      item
                                      xs={4}
                                      textAlign="right"
                                      className="grid-switch-unit"
                                    >
                                      <Switch
                                        checked={
                                          deviceUnitRedux.length
                                            ? deviceUnitRedux.find(
                                                (f) =>
                                                  f.tagUnit == e.tag_unit &&
                                                  f.plantId == param.id
                                              )
                                              ? true
                                              : false
                                            : false
                                        }
                                        style={{ float: "right" }}
                                        onClick={() =>
                                          handleSelectTagUnit(e.tag_unit)
                                        }
                                        sx={{
                                          "& .MuiSwitch-thumb": {
                                            bgcolor: "#828282",
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#D9D9E5 !important",
                                          },
                                          "& .Mui-checked": {
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#50C878",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#CEE6E1 !important",
                                            },
                                          },
                                        }}
                                      />
                                    </Grid>
                                  ) : (
                                    <Grid
                                      item
                                      xs={4}
                                      textAlign="right"
                                      className="grid-switch-unit"
                                    >
                                      <Switch
                                        checked={
                                          deviceUnitRedux.length
                                            ? deviceUnitRedux.find(
                                                (f) =>
                                                  f.tagUnit == e.tag_unit &&
                                                  f.plantId == param.id
                                              )
                                              ? true
                                              : false
                                            : false
                                        }
                                        style={{ float: "right" }}
                                        onClick={() => handleTostMsg}
                                        sx={{
                                          "& .MuiSwitch-thumb": {
                                            bgcolor: "#828282",
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#D9D9E5 !important",
                                          },
                                          "& .Mui-checked": {
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#50C878",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#CEE6E1 !important",
                                            },
                                          },
                                        }}
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                              )}
                            </Grid>
                          ))
                      ) : (
                        <DeviceListShimmer itemCount={3} />
                      )}
                    </Box>
                  </div>

                  <div className="mt-3">
                    <button
                      className="export-btn"
                      style={{ marginTop: "46px" }}
                      onClick={handleExportEnergyData}
                    >
                      EXPORT ENERGY DATA
                    </button>
                  </div>
                </Box>
              </div>
            </>
          ) : (
            <div className="consumerCard" ref={modalRef}>
              <Box className="consumerData-box consumer-card-box">
                <Box className="consumer-text-disc">
                  <h6 className="Title">Title</h6>
                  <h6>Energy Meter Charts</h6>
                </Box>

                <TextField
                  variant="outlined"
                  className="search-textfield"
                  placeholder="Search Device here"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: <SearchIcon />,
                    inputProps: {
                      style: { padding: "9px" },
                    },
                  }}
                />
                <div className="furnace-grid">
                  <Box className="Grid-text mt-2">
                    {!isShimmerdevicelist ? (
                      deviceData.length ? (
                        deviceData
                          .filter((e) =>
                            e.device__device_name
                              ?.toLowerCase()
                              ?.includes(searchQuery.toLowerCase())
                          )
                          .map((e, index) => (
                            <Grid item xs={12} key={e.device__device_name}>
                              <Grid
                                container
                                alignItems="center"
                                style={{
                                  background: "#efeffd",
                                  width: "100%",
                                  marginBottom: "7px",
                                }}
                              >
                                <Grid
                                  item
                                  xs={8}
                                  style={{
                                    padding: "7px 12px",
                                    borderLeft: `4px solid ${
                                      switchColors[index % switchColors.length]
                                    }`,
                                  }}
                                >
                                  <h6
                                    className="mt-2"
                                    style={{ fontSize: "16px" }}
                                  >
                                    {e.device__device_name}
                                  </h6>
                                </Grid>
                                {(analyticsAccess?.is_viewer &&
                                  analyticsAccess?.is_editor) ||
                                (!analyticsAccess?.is_viewer &&
                                  analyticsAccess?.is_editor) ||
                                (analyticsAccess?.is_viewer &&
                                  !analyticsAccess?.is_editor) ? (
                                  <Grid
                                    item
                                    xs={4}
                                    textAlign="right"
                                    className="grid-switch-unit"
                                  >
                                    <Switch
                                      style={{ float: "right" }}
                                      onClick={(event) =>
                                        handlePOPSelectDeviceId(
                                          e.device,
                                          index,
                                          event,
                                          e
                                        )
                                      }
                                      name={switchColors[index]}
                                      checked={
                                        deviceIdPOPRedux.length
                                          ? deviceIdPOPRedux.find(
                                              (f) =>
                                                f.deviceID == e.device &&
                                                f.plantId == param.id
                                            )
                                            ? true
                                            : false
                                          : false
                                      }
                                      sx={{
                                        "& .MuiSwitch-thumb": {
                                          bgcolor: "#828282",
                                        },
                                        "& .MuiSwitch-track": {
                                          bgcolor: "#D9D9E5 !important",
                                        },
                                        "& .Mui-checked": {
                                          "& .MuiSwitch-thumb": {
                                            bgcolor:
                                              switchColors[
                                                index % switchColors.length
                                              ],
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#E2D9F2 !important",
                                          },
                                        },
                                      }}
                                    />
                                  </Grid>
                                ) : (
                                  <Grid
                                    item
                                    xs={4}
                                    textAlign="right"
                                    className="grid-switch-unit"
                                  >
                                    <Switch
                                      style={{ float: "right" }}
                                      onClick={handleTostMsg}
                                      name={switchColors[index]}
                                      checked={
                                        deviceIdPOPRedux.length
                                          ? deviceIdPOPRedux.find(
                                              (f) =>
                                                f.deviceID == e.device &&
                                                f.plantId == param.id
                                            )
                                            ? true
                                            : false
                                          : false
                                      }
                                      sx={{
                                        "& .MuiSwitch-thumb": {
                                          bgcolor: "#828282",
                                        },
                                        "& .MuiSwitch-track": {
                                          bgcolor: "#D9D9E5 !important",
                                        },
                                        "& .Mui-checked": {
                                          "& .MuiSwitch-thumb": {
                                            bgcolor:
                                              switchColors[
                                                index % switchColors.length
                                              ],
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#E2D9F2 !important",
                                          },
                                        },
                                      }}
                                    />
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          ))
                      ) : (
                        []
                      )
                    ) : (
                      <DeviceListShimmer itemCount={3} />
                    )}
                  </Box>
                </div>
                <Box className="consumer-text-disc mt-2"></Box>

                <TextField
                  variant="outlined"
                  className="search-textfield"
                  placeholder="Search Unit here"
                  value={unitSearchQuery}
                  onChange={handleUnitSearchChange}
                  InputProps={{
                    startAdornment: <SearchIcon />,
                    inputProps: {
                      style: { padding: "9px" },
                    },
                  }}
                />

                <div className="device-unit">
                  {(analyticsAccess?.is_viewer && analyticsAccess?.is_editor) ||
                  (!analyticsAccess?.is_viewer && analyticsAccess?.is_editor) ||
                  (analyticsAccess?.is_viewer &&
                    !analyticsAccess?.is_editor) ? (
                    <Box className="Grid-text1 mt-2">
                      {!isShimmerUnitlist ? (
                        deviceUnitData
                          .filter(
                            (e) =>
                              e.tag_unit &&
                              e.tag_unit
                                .toLowerCase()
                                .includes(unitSearchQuery.toLowerCase())
                          )
                          .map((e) => (
                            <Grid item xs={12} key={e.tag_unit}>
                              {e.tag_unit && (
                                <Grid
                                  container
                                  alignItems="center"
                                  style={{
                                    background: "#efeffd",
                                    width: "100%",
                                    marginBottom: "7px",
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={8}
                                    className="mt-2"
                                    style={{ padding: "7px 12px" }}
                                  >
                                    <h6>{e.tag_unit}</h6>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    textAlign="right"
                                    className="grid-switch-unit"
                                  >
                                    <Switch
                                      checked={
                                        deviceUnitPOPRedux.length
                                          ? deviceUnitPOPRedux.find(
                                              (f) =>
                                                f.tagUnit == e.tag_unit &&
                                                f.plantId == param.id
                                            )
                                            ? true
                                            : false
                                          : false
                                      }
                                      style={{ float: "right" }}
                                      onClick={() =>
                                        handlePOPSelectTagUnit(e.tag_unit)
                                      }
                                      sx={{
                                        "& .MuiSwitch-thumb": {
                                          bgcolor: "#828282",
                                        },
                                        "& .MuiSwitch-track": {
                                          bgcolor: "#D9D9E5 !important",
                                        },
                                        "& .Mui-checked": {
                                          "& .MuiSwitch-thumb": {
                                            bgcolor: "#50C878",
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#CEE6E1 !important",
                                          },
                                        },
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>
                          ))
                      ) : (
                        <DeviceListShimmer itemCount={3} />
                      )}
                    </Box>
                  ) : (
                    <Box className="Grid-text1 mt-2">
                      {!isShimmerUnitlist ? (
                        deviceUnitData
                          .filter(
                            (e) =>
                              e.tag_unit &&
                              e.tag_unit
                                .toLowerCase()
                                .includes(unitSearchQuery.toLowerCase())
                          )
                          .map((e) => (
                            <Grid item xs={12} key={e.tag_unit}>
                              {e.tag_unit && (
                                <Grid
                                  container
                                  alignItems="center"
                                  style={{
                                    background: "#efeffd",
                                    width: "100%",
                                    marginBottom: "7px",
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={8}
                                    className="mt-2"
                                    style={{ padding: "7px 12px" }}
                                  >
                                    <h6>{e.tag_unit}</h6>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    textAlign="right"
                                    className="grid-switch-unit"
                                  >
                                    <Switch
                                      checked={
                                        deviceUnitPOPRedux.length
                                          ? deviceUnitPOPRedux.find(
                                              (f) =>
                                                f.tagUnit == e.tag_unit &&
                                                f.plantId == param.id
                                            )
                                            ? true
                                            : false
                                          : false
                                      }
                                      style={{ float: "right" }}
                                      onClick={handleTostMsg}
                                      sx={{
                                        "& .MuiSwitch-thumb": {
                                          bgcolor: "#828282",
                                        },
                                        "& .MuiSwitch-track": {
                                          bgcolor: "#D9D9E5 !important",
                                        },
                                        "& .Mui-checked": {
                                          "& .MuiSwitch-thumb": {
                                            bgcolor: "#50C878",
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#CEE6E1 !important",
                                          },
                                        },
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>
                          ))
                      ) : (
                        <DeviceListShimmer itemCount={3} />
                      )}
                    </Box>
                  )}
                </div>

                <div className="mt-3">
                  <button
                    className="export-btn"
                    style={{ marginTop: "46px" }}
                    onClick={handlePOPExcel}
                  >
                    EXPORT ENERGY DATA
                  </button>
                </div>
              </Box>
            </div>
          ))}



          {openEnergyModal && 
            (activeClass== "Reports" &&(
              <div className="consumerCard" ref={modalRef}>
                  <Box
                    className="consumerData-box consumer-card-box"
                    style={{ minHeight: "761px" }}
                  >
                    <Box className="consumer-text-disc">
                      <h6 className="Title">Title</h6>
                      <h6>Energy Meter Charts</h6>
                    </Box>

                    <TextField
                      variant="outlined"
                      className="search-textfield"
                      placeholder="Search Device here"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      InputProps={{
                        startAdornment: <SearchIcon />,
                        inputProps: {
                          style: { padding: "9px" },
                        },
                      }}
                    />
                    <div className="furnace-grid">
                      <Box className="Grid-text mt-2">
                        {!isShimmerReportdevicelist ? (
                          deviceReport.length ? (
                            deviceReport
                              .filter((e) =>
                                e.device__device_name
                                  ?.toLowerCase()
                                  ?.includes(searchQuery.toLowerCase())
                              )
                              .map((e, index) => (
                                <Grid item xs={12} key={e.device__device_name}>
                                  <Grid
                                    container
                                    alignItems="center"
                                    style={{
                                      background: "#efeffd",
                                      width: "100%",
                                      marginBottom: "7px",
                                    }}
                                  >
                                    <Grid
                                      item
                                      xs={8}
                                      style={{
                                        padding: "7px 12px",
                                        borderLeft: `4px solid ${
                                          switchColors[
                                            index % switchColors.length
                                          ]
                                        }`,
                                      }}
                                    >
                                      <h6
                                        className="mt-2"
                                        style={{ fontSize: "16px" }}
                                      >
                                        {e.device__device_name}
                                      </h6>
                                    </Grid>

                                    {(ReportAccess?.is_viewer &&
                                      ReportAccess?.is_editor) ||
                                    (!ReportAccess?.is_viewer &&
                                      ReportAccess?.is_editor) ||
                                    (ReportAccess?.is_viewer &&
                                      !ReportAccess?.is_editor) ? (
                                      <Grid
                                        item
                                        xs={4}
                                        textAlign="right"
                                        className="grid-switch-unit"
                                      >
                                        <Switch
                                          style={{ float: "right" }}
                                          onClick={(event) =>
                                            handleSelectDeviceIdReport(
                                              e.device,
                                              index,
                                              event,
                                              e
                                            )
                                          }
                                          name={switchColors[index]}
                                          // checked={deviceIdReportRedux.includes(
                                          //   e.device
                                          // )}
                                          checked={
                                            deviceIdReportRedux.length
                                              ? deviceIdReportRedux.find(
                                                  (f) =>
                                                    f.deviceID == e.device &&
                                                    f.plantId == param.id
                                                )
                                                ? true
                                                : false
                                              : false
                                          }
                                          sx={{
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#828282",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#D9D9E5 !important",
                                            },
                                            "& .Mui-checked": {
                                              "& .MuiSwitch-thumb": {
                                                bgcolor:
                                                  switchColors[
                                                    index % switchColors.length
                                                  ],
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#E2D9F2 !important",
                                              },
                                            },
                                          }}
                                        />
                                      </Grid>
                                    ) : (
                                      <Grid
                                        item
                                        xs={4}
                                        textAlign="right"
                                        className="grid-switch-unit"
                                      >
                                        <Switch
                                          style={{ float: "right" }}
                                          onClick={handleTostMsg}
                                          name={switchColors[index]}
                                          // checked={deviceIdReportRedux.includes(
                                          //   e.device
                                          // )}
                                          checked={
                                            deviceIdReportRedux.length
                                              ? deviceIdReportRedux.find(
                                                  (f) =>
                                                    f.deviceID == e.device &&
                                                    f.plantId == param.id
                                                )
                                                ? true
                                                : false
                                              : false
                                          }
                                          sx={{
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#828282",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#D9D9E5 !important",
                                            },
                                            "& .Mui-checked": {
                                              "& .MuiSwitch-thumb": {
                                                bgcolor:
                                                  switchColors[
                                                    index % switchColors.length
                                                  ],
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#E2D9F2 !important",
                                              },
                                            },
                                          }}
                                        />
                                      </Grid>
                                    )}
                                  </Grid>
                                </Grid>
                              ))
                          ) : (
                            []
                          )
                        ) : (
                          <DeviceListShimmer itemCount={3} />
                        )}
                      </Box>
                    </div>
                    <Box className="consumer-text-disc mt-2">
                      {/* <h6 className="Title">Description</h6>
                    <h6>Units</h6> */}
                    </Box>

                    <TextField
                      variant="outlined"
                      className="search-textfield"
                      placeholder="Search Unit here"
                      value={unitSearchQuery}
                      onChange={handleUnitSearchChange}
                      InputProps={{
                        startAdornment: <SearchIcon />,
                        inputProps: {
                          style: { padding: "9px" },
                        },
                      }}
                    />

                    <div className="device-unit">
                      {(ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                      (!ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                      (ReportAccess?.is_viewer && !ReportAccess?.is_editor) ? (
                        <Box className="Grid-text1 mt-2">
                          {!isShimmerReportUnitlist ? (
                            unitReport
                              .filter(
                                (e) =>
                                  e.tag_unit &&
                                  e.tag_unit
                                    .toLowerCase()
                                    .includes(unitSearchQuery.toLowerCase())
                              )
                              .map((e, index) => (
                                <Grid item xs={12} key={e.tag_unit}>
                                  {e.tag_unit && (
                                    <Grid
                                      container
                                      alignItems="center"
                                      style={{
                                        background: "#efeffd",
                                        width: "100%",
                                        marginBottom: "7px",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={8}
                                        className="mt-2"
                                        style={{ padding: "7px 12px" }}
                                      >
                                        <h6>{e.tag_unit}</h6>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={4}
                                        textAlign="right"
                                        className="grid-switch-unit"
                                      >
                                        <Switch
                                          checked={
                                            deviceUnitReportRedux.length
                                              ? deviceUnitReportRedux.find(
                                                  (f) =>
                                                    f.tagUnit == e.tag_unit &&
                                                    f.plantId == param.id
                                                )
                                                ? true
                                                : false
                                              : false
                                          }
                                          name={switchColors[index]}
                                          style={{ float: "right" }}
                                          onClick={(event) =>
                                            handleSelectTagUnitReport(
                                              e.tag_unit,
                                              event,
                                              e
                                            )
                                          }
                                          sx={{
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#828282",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#D9D9E5 !important",
                                            },
                                            "& .Mui-checked": {
                                              "& .MuiSwitch-thumb": {
                                                bgcolor:
                                                  switchColors[
                                                    index % switchColors.length
                                                  ],
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#E2D9F2 !important",
                                              },
                                            },
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  )}
                                </Grid>
                              ))
                          ) : (
                            <DeviceListShimmer itemCount={3} />
                          )}
                        </Box>
                      ) : (
                        <Box className="Grid-text1 mt-2">
                          {!isShimmerReportUnitlist ? (
                            unitReport
                              .filter(
                                (e) =>
                                  e.tag_unit &&
                                  e.tag_unit
                                    .toLowerCase()
                                    .includes(unitSearchQuery.toLowerCase())
                              )
                              .map((e, index) => (
                                <Grid item xs={12} key={e.tag_unit}>
                                  {e.tag_unit && (
                                    <Grid
                                      container
                                      alignItems="center"
                                      style={{
                                        background: "#efeffd",
                                        width: "100%",
                                        marginBottom: "7px",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={8}
                                        className="mt-2"
                                        style={{ padding: "7px 12px" }}
                                      >
                                        <h6>{e.tag_unit}</h6>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={4}
                                        textAlign="right"
                                        className="grid-switch-unit"
                                      >
                                        <Switch
                                          checked={
                                            deviceUnitReportRedux.length
                                              ? deviceUnitReportRedux.find(
                                                  (f) =>
                                                    f.tagUnit == e.tag_unit &&
                                                    f.plantId == param.id
                                                )
                                                ? true
                                                : false
                                              : false
                                          }
                                          name={switchColors[index]}
                                          style={{ float: "right" }}
                                          onClick={handleTostMsg}
                                          sx={{
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#828282",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#D9D9E5 !important",
                                            },
                                            "& .Mui-checked": {
                                              "& .MuiSwitch-thumb": {
                                                bgcolor:
                                                  switchColors[
                                                    index % switchColors.length
                                                  ],
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#E2D9F2 !important",
                                              },
                                            },
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  )}
                                </Grid>
                              ))
                          ) : (
                            <DeviceListShimmer itemCount={3} />
                          )}
                        </Box>
                      )}
                    </div>

                    <div className="mt-3">
                      <button
                        className="export-btn"
                        style={{ marginTop: "117px" }}
                        onClick={() => handleExportExcelClick()}
                      >
                        EXPORT ENERGY DATA
                      </button>
                    </div>
                  </Box>
                </div>
            ))
          }
      </Container>
      {/* </div> */}
    </>
  );
};
export default OverViewAnalytics;
