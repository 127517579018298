import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    VmsReportCOCSelectmachine: [],
    VmsReportCOCSelectUnit: [],
    VmsReportCOCSelectTag: [],
    VmsReportCOCStartDate: null,
    VmsReportCOCEndDate: null,
    selectTimeRangeCOC: "none",
    VmsReportPOPSelectmachine: [],
    VmsReportPOPSelectUnit: [],
    VmsReportPOPSelectTag: [],
    VmsReportPOPStartDate1: null,
    VmsReportPOPEndDate1: null,
    VmsReportPOPStartDate2: null,
    VmsReportPOPEndDate2: null,
    selectTimeRangePOP: "none",
    VmsReportTime : false,
    VmsReportPOPTime: false,
    VmsReportReportsSelectmachine: [],
    VmsReportReportsSelectUnit: [],
    VmsReportReportsSelectTag: [],
    VmsReportReportsStartDate: null,
    VmsReportReportsEndDate: null,
    VmsReportReportsTime: false,
    reportsIntervalTime : "1",
    reportIntervalTimePop : "1",
    vmsReportCOCRange : "",
    vmsReportPOPRange : "",
}

const VmsReportSlice = createSlice({
    name: "VmsReport",
    initialState,
    reducers: {
        setVmsReportData(state, { payload }) {
            return { ...state, ...payload };
        },
        clearVmsReportData(state) {
            return initialState;
        }
    },
});

export const {
    setVmsReportData,
    clearVmsReportData
} = VmsReportSlice.actions;

export default VmsReportSlice.reducer;


