import React from "react";
import Highcharts from "highcharts/highstock";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";
import { ShimmerText } from "react-shimmer-effects";
import {ParetoChartShimmer, SmallParetoChartShimmer} from "../../../../components/Shimmer/ParetoChartShimmer/ParetoChartShimmer";

HC_more(Highcharts);
exporting(Highcharts);

const AnalyticsGraph = ({ getsummaryGraphData, configureTagUnit, shimmer }) => {
  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: getsummaryGraphData.map((e) => e.date),
      labels: {
        style: {
          fontSize: "14px",
          color: "#001323",
        },
      },
      scrollbar: {
        enabled: true, // Enable the scrollbar for the X-axis
      },
    },
    yAxis: {
      title: {
        text: configureTagUnit.accepted
          ? configureTagUnit.accepted + " / " + configureTagUnit.rejected
          : "",
        style: {
          fontSize: "17px",
          fontWeight: "bold",
          color: "#001323",
        },
      },
      labels: {
        style: {
          fontSize: "12px",
          color: "#001323",
        },
      },
    },
    legend: {
      enabled: false,
    },
    exporting: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        stacking: "normal",
        borderRadius: "4px",
      },
    },
    series: [
      {
        name: configureTagUnit.rejected,
        color: "#ccd7eb",
        data: getsummaryGraphData.map((e) => e.rejected),
      },
      {
        name: configureTagUnit.accepted,
        color: "#1C407B",
        data: getsummaryGraphData.map((e) => e.accepted),
      },
    ],
  };
  return (
    <div className="analyticsGraph p-3">
      {!shimmer ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          className="analyticsGraph"
        />
      ) : (<SmallParetoChartShimmer barCount={12} className="ParetoChartShimmer-OverView"/> 
        
      )}
    </div>
  );
};

export default AnalyticsGraph;



{/* <ShimmerText line={12} gap={15} /> */}