import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import annotationPlugin from "chartjs-plugin-annotation";
import { images } from '../../../config';
import { ZoomchartShimmer } from '../../../components/Shimmer/ZoomChartShimmer/ZoomChartShimmer';

ChartJS.register(CategoryScale, LinearScale, BarElement, annotationPlugin, Title, Tooltip, Legend);

export function BarChart({average, zoomChartData ,startTimeFormat, formatEndDate,isShimmer }) {

  
  const { deviceColors } = useSelector((state) => state.overViewState);
  console.log("zoomChartData", zoomChartData);

  // Calculate the average tag_value
  const averageTagValue = zoomChartData.length
    ? zoomChartData.reduce((sum, row) => sum + (row.tag_value || 0), 0) / zoomChartData.length
    : 0;
  
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      annotation: {
        annotations:average ? [
          {
            type: "line",
            mode: "horizontal",
            scaleID: "y",
            value: averageTagValue,
            borderColor: "green", // Change the borderColor to the desired color for the average line
            borderWidth: 2,
            borderDash: [5, 5],
            label: {
              content: "Average",
              enabled: true,
              position: "right",
            },
          },
        ] : []
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
    },
    elements: {
      bar: {
        barPercentage: 1,
        categoryPercentage: 1,
      },
    },
  };
  

  const labels = [`${startTimeFormat}  -  ${formatEndDate}`];


  const kp = ["#FDAA66", "#9B64B7", "#E579A0"]
  const kp2 = [10,20,30,40]

  const data = {
    labels,
    datasets: zoomChartData.length ? zoomChartData.map((row, i) => {
      const findColor = deviceColors.find((f) => f.deviceId == row.device_id)
      return (
        {
          label: row.device_name,
          data: [row.tag_value ? row.tag_value.toFixed(2) : 0],
          // data: [kp2[i]],
          backgroundColor: findColor ? findColor.color : '',
          barPercentage: 1, // Set barPercentage to 1 for the specific dataset
          // categoryPercentage: 1, // Set categoryPercentage to 1 for the specific dataset
        }
      )
    }) : []
  };

  return (
    <div style={{ width: '100%', height: '300px' }}>
        <div>
            {!isShimmer ? zoomChartData.length ? <Bar options={options} data={data} />: <img src={images.noDataFoundImg} className="notFoundDataImg" alt=''/> : <ZoomchartShimmer className="ParetoChartShimmer-OverView" style={{height:"293px"}}/> }
            
        </div>
      {/* {zoomChartData.length ? <Bar options={options} data={data} /> : <img src={images.noDataFoundImg} className="notFoundDataImg" /> } */}
      
    </div>
  );
}
