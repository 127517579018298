import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
// import Box from '@mui/material/Box';
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import { images } from "../../config/images";
import "./SuperAdminDashboard.css";

import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getClientDashboardCount,
  superAdminDeviceCount,
  getWarningMsg,
} from "./service";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ShimmerText } from "react-shimmer-effects";
import { setHeaderDetails } from "../Header/headerSlice";
import { useDispatch } from "react-redux";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const SuperAdminDashboard = () => {
  const navigate = useNavigate();

  const {
    is_super_admin,
    is_admin,
    user_access,
    is_created_by_super_admin_user_company,
  } = useSelector((state) => state.userState);

  const [deviceDataCount, setDeviceDataCount] = useState(0);

  const [showAlert, setShowAlert] = useState({});
  const [showSubConfiguration, setSubConfiguration] = useState(false);
  const [showDeviceConfiguration, setDeviceConfiguration] = useState(false);
  const [showAccessConfiguration, setAccessConfiguration] = useState(false);
  const [showUserConfiguration, setUserConfiguration] = useState(false);
  const [showPlantConfiguration, setPlantConfiguration] = useState(false);
  const [showAlarmConfiguration, setAlarmConfiguration] = useState(false);
  const [showReportConfiguration, setReportConfiguration] = useState(false);
  const [showClientConfiguration, setClientConfiguration] = useState(false);

  const [superAdminClientDashboardCount, setSuperAdminClientDashboardCount] =
    useState({
      companyCount: 0,
      plantCount: 0,
      userCount: 0,
    });

  const [
    isShimmersuperAdminClientDashboardCount,
    setisShimmersuperAdminClientDashboardCount,
  ] = useState(false);
  const [isShimmerTopBarCounts, setisShimmerTopBarCounts] = useState(false);

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });
  const dispatch = useDispatch();
  // // // user access

  const handleHover = () => {
    setSubConfiguration(!showSubConfiguration);
  };
  const handleDeviceHover = () => {
    setDeviceConfiguration(!showDeviceConfiguration);
  };

  const handleAccessHover = () => {
    setAccessConfiguration(!showAccessConfiguration);
  };

  const handleUserHover = () => {
    setUserConfiguration(!showUserConfiguration);
  };

  const handlePlantHover = () => {
    setPlantConfiguration(!showPlantConfiguration);
  };

  const handleAlarmHover = () => {
    setAlarmConfiguration(!showAlarmConfiguration);
  };

  const handleReportHover = () => {
    setReportConfiguration(!showReportConfiguration);
  };

  const handleClientHover = () => {
    setClientConfiguration(!showClientConfiguration);
  };

  const superAdminAccess = user_access.length
    ? user_access.find(
      (user) => user.access_module?.access_module_name === "Admin Dashboard"
    )
    : null;
  const accessManagementAccess = superAdminAccess
    ? superAdminAccess.sub_module.length
      ? superAdminAccess.sub_module.find(
        (f) => f.sub_module_name == "Access Management"
      )
      : null
    : null;
  const accessManagementDevice = superAdminAccess
    ? superAdminAccess.sub_module.length
      ? superAdminAccess.sub_module.find(
        (f) => f.sub_module_name == "Device Management"
      )
      : null
    : null;
  const accessManagementSubscription = superAdminAccess
    ? superAdminAccess.sub_module.length
      ? superAdminAccess.sub_module.find(
        (f) => f.sub_module_name == "Subscription Management"
      )
      : null
    : null;
  const accessManagementUser = superAdminAccess
    ? superAdminAccess.sub_module.length
      ? superAdminAccess.sub_module.find(
        (f) => f.sub_module_name == "User Management"
      )
      : null
    : null;
  const accessManagementPlant = superAdminAccess
    ? superAdminAccess.sub_module.length
      ? superAdminAccess.sub_module.find(
        (f) => f.sub_module_name == "Plant Management"
      )
      : null
    : null;
  const accessManagementUserAlarm = superAdminAccess
    ? superAdminAccess.sub_module.length
      ? superAdminAccess.sub_module.find(
        (f) => f.sub_module_name == "Alarm Management"
      )
      : null
    : null;
  const accessManagementUserReport = superAdminAccess
    ? superAdminAccess.sub_module.length
      ? superAdminAccess.sub_module.find(
        (f) => f.sub_module_name == "Report Management"
      )
      : null
    : null;
  const accessClientManagement = superAdminAccess
    ? superAdminAccess.sub_module.length
      ? superAdminAccess.sub_module.find(
        (f) => f.sub_module_name == "Client Management"
      )
      : null
    : null;

  // // user access

  useEffect(() => {
    handleGetWarningMsg();
  }, []);

  const handleGetWarningMsg = async () => {
    try {
      const resp = await getWarningMsg();
      if (resp.data.success == true) {
        setShowAlert(resp.data.payload);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  const getClientDashboardCountAPI = async () => {
    try {
      setisShimmersuperAdminClientDashboardCount(true);
      const response = await getClientDashboardCount();

      if (response.status == 200) {
        setTimeout(() => {
          setisShimmersuperAdminClientDashboardCount(false);
        }, 1000);
        setSuperAdminClientDashboardCount({
          companyCount: response.data.payload.company,
          plantCount: response.data.payload.plant,
          userCount: response.data.payload.user,
        });
      }
    } catch (e) {
      setisShimmersuperAdminClientDashboardCount(false);
      console.log("Error from getClientDashboardCountAPI....", e);
    }
  };

  useEffect(() => {
    // getdeviceData();
    getClientDashboardCountAPI();
  }, []);

  useEffect(() => {
    handleDeviceCount();
  }, []);

  const handleDeviceCount = async () => {
    setisShimmerTopBarCounts(true);
    try {
      const resp = await superAdminDeviceCount();
      if (resp.status == 200 || resp.status == 201) {
        setTimeout(() => {
          setisShimmerTopBarCounts(false);
        }, 1000);
        setDeviceDataCount(resp.data.payload);
      }
    } catch (e) {
      setisShimmerTopBarCounts(false);
      console.log("Error from handleDeviceCount...", e);
    }
  };

  const TextShimmer = () => {
    return (
      <>
        <ShimmerText line={1} gap={10} className="Header-TextShimmer" />
      </>
    );
  };

  return (
    <>
      <div className="containerWrap">
        <Typography
          variant="h5"
          gutterBottom
          style={{
            paddingTop: "30px",
            paddingLeft: "34px",
            color: "#001323",
            fontWeight: "600",
          }}
        >
          {is_super_admin || is_created_by_super_admin_user_company
            ? "Super"
            : ""}{" "}
          Admin Dashboard
        </Typography>
        <Container maxWidth="xxl">
          <Card className="dashboardCard">
            <Grid container>
              <Grid xs={6} sm={6} md={3} lg={3} className="grid-item">
                <div className="dashboardCard-item">
                  <div className="dashboard-card-img">
                    <img
                      src={images.IIOTdeviceImage}
                      alt=""
                      className="dashboardCard-img"
                    />
                  </div>
                  <div className="dashboardCard-text">
                    <div>
                      <p className="dashboardCard-title">
                        IIOT <br/>DEVICES
                      </p>
                    </div>
                    <div>
                      <h6 className="dashboardCard-number">
                        {!isShimmerTopBarCounts
                          ? deviceDataCount.iiot_devices
                          : TextShimmer(3)}
                      </h6>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid xs={6} sm={6} md={3} lg={3} className="grid-item">
                <div className="dashboardCard-item">
                  <div className="dashboard-card-img">
                    <img
                      src={images.activeImage}
                      alt=""
                      className="dashboardCard-img"
                    />
                  </div>
                  <div className="dashboardCard-text">
                    <div>
                      <p className="dashboardCard-title">
                        SUBSCRIPTION<br class="d-none d-md-block"></br> ACTIVE
                      </p>
                    </div>
                    <div>
                      <h6 className="dashboardCard-number">
                        {!isShimmerTopBarCounts
                          ? deviceDataCount.subscription_active
                          : TextShimmer(3)}
                      </h6>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid xs={6} sm={6} md={3} lg={3} className="grid-item">
                <div className="dashboardCard-item">
                  <div className="dashboard-card-img">
                    <img
                      src={images.expiringSoonImage}
                      alt=""
                      className="dashboardCard-img"
                    />
                  </div>
                  <div className="dashboardCard-text">
                    <div>
                      <p className="dashboardCard-title">
                        SUBSCRIPTION <br class="d-none d-md-block"></br> EXPIRING
                        SOON
                      </p>
                    </div>
                    <div>
                      <h6 className="dashboardCard-number">
                        {!isShimmerTopBarCounts
                          ? deviceDataCount.subscription_expiring_soon
                          : TextShimmer(3)}
                      </h6>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid xs={6} sm={6} md={3} lg={3} className="grid-item">
                <div className="dashboardCard-item">
                  <div className="dashboard-card-img">
                    <img
                      src={images.expiredImage}
                      alt=""
                       className="dashboardCard-img"
                    />
                  </div>
                  <div className="dashboardCard-text">
                    <div>
                      <p className="dashboardCard-title">
                        SUBSCRIPTION <br class="d-none d-md-block"></br> EXPIRED
                      </p>
                    </div>
                    <div>
                      <h6 className="dashboardCard-number">
                        {!isShimmerTopBarCounts
                          ? deviceDataCount.subscription_expired
                          : TextShimmer(3)}
                      </h6>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Card>
          {/* ====================================================================== */}
          <Grid container spacing={4} mt={"0px"}>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <Card
                className="superAdminDashboard-card"
                onClick={() => {
                  if (accessManagementSubscription?.is_viewer) {
                    navigate("/subscriptionManagement");
                    dispatch(
                      setHeaderDetails({ headerName: "Super Admin Dashboard" })
                    );
                    return true;
                  } else {
                    notifyError("You don't have access");
                    return true;
                  }
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={{ sm: 6, md: 4, lg: 2, xl: 4 }}
                >
                  <img
                    src={images.subscriptionManagement}
                    alt=""
                    className="superAdmin-dashboardCard-image"
                  />
                  <Typography
                    variant="h5"
                    gutterBottom
                    className="superAdminDashboard-title"
                  >
                    Subscription Management
                  </Typography>
                </Stack>
                {showAlert["Subscription Management"] ? (
                  <div
                    className="alertImageContainer"
                    onMouseEnter={handleHover}
                    onMouseLeave={handleHover}
                  >
                    <img
                      src={images.alertImage}
                      alt=""
                      className="alertImage"
                    />
                    {showSubConfiguration && (
                      <div className="alert-image-data top-alert alert-top-data">
                        <Box className="clientMangement-updatedataWrap-data">
                          <div>
                            <h6 className="mb-0" style={{ fontWeight: "bold" }}>
                              Configuration Pending..
                            </h6>
                          </div>
                        </Box>
                      </div>
                    )}
                  </div>
                ) : null}
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <Card
                className="superAdminDashboard-card"
                onClick={() => {
                  if (accessManagementDevice?.is_viewer) {
                    navigate("/deviceManagement");
                    dispatch(
                      setHeaderDetails({ headerName: "Super Admin Dashboard" })
                    );
                  } else {
                    notifyError("You don't have access");
                    return true;
                  }
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={{ sm: 6, md: 4, lg: 2, xl: 4 }}
                >
                  <img
                    src={images.deviceManagement}
                    alt=""
                    className="superAdmin-dashboardCard-image"
                  />
                  <Typography
                    variant="h5"
                    gutterBottom
                    className="superAdminDashboard-title"
                  >
                    Device Management
                  </Typography>
                </Stack>

                {showAlert["Device Management"] ? (
                  <div
                    className="alertImageContainer"
                    onMouseEnter={handleDeviceHover}
                    onMouseLeave={handleDeviceHover}
                  >
                    <img
                      src={images.alertImage}
                      alt=""
                      className="alertImage"
                    />
                    {showDeviceConfiguration && (
                      <div className="alert-image-data top-alert alert-top-data">
                        <Box className="clientMangement-updatedataWrap-data">
                          <div>
                            <h6 className="mb-0" style={{ fontWeight: "bold" }}>
                              Configuration Pending..
                            </h6>
                          </div>
                        </Box>
                      </div>
                    )}
                  </div>
                ) : null}
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={3}
              xl={3}
              onClick={() => {
                if (accessManagementAccess?.is_viewer) {
                  navigate("/accessManagement");
                  dispatch(
                    setHeaderDetails({ headerName: "Super Admin Dashboard" })
                  );
                  return true;
                } else {
                  notifyError("You don't have access");
                  return true;
                }
              }}
            >
              <Card className="superAdminDashboard-card">
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={{ sm: 6, md: 4, lg: 2, xl: 4 }}
                >
                  <img
                    src={images.accessManagement}
                    alt=""
                    className="superAdmin-dashboardCard-image"
                  />
                  <Typography
                    variant="h5"
                    gutterBottom
                    className="superAdminDashboard-title"
                  >
                    Access Management
                  </Typography>
                </Stack>

                {showAlert["Access Management"] ? (
                  <div
                    className="alertImageContainer"
                    onMouseEnter={handleAccessHover}
                    onMouseLeave={handleAccessHover}
                  >
                    <img
                      src={images.alertImage}
                      alt=""
                      className="alertImage"
                    />
                    {showAccessConfiguration && (
                      <div className="alert-image-data top-alert alert-top-data">
                        <Box className="clientMangement-updatedataWrap-data">
                          <div>
                            <h6 className="mb-0" style={{ fontWeight: "bold" }}>
                              Configuration Pending..
                            </h6>
                          </div>
                        </Box>
                      </div>
                    )}
                  </div>
                ) : null}
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <Card
                className="superAdminDashboard-card"
                onClick={() => {
                  if (accessManagementUser?.is_viewer) {
                    navigate("/usermanagement");
                    dispatch(
                      setHeaderDetails({ headerName: "Super Admin Dashboard" })
                    );
                    return true;
                  } else {
                    notifyError("You don't have access");
                    return true;
                  }
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={{ sm: 6, md: 4, lg: 2, xl: 4 }}
                >
                  <img
                    src={images.userManagement}
                    alt=""
                    className="superAdmin-dashboardCard-image"
                  />
                  <Typography
                    variant="h5"
                    gutterBottom
                    className="superAdminDashboard-title"
                  >
                    User Management
                  </Typography>
                </Stack>

                {showAlert["User Management"] ? (
                  <div
                    className="alertImageContainer"
                    onMouseEnter={handleUserHover}
                    onMouseLeave={handleUserHover}
                  >
                    <img
                      src={images.alertImage}
                      alt=""
                      className="alertImage"
                    />
                    {showUserConfiguration && (
                      <div className="alert-image-data alert-user-image-data top-alert alert-top-data">
                        <Box className="clientMangement-updatedataWrap-data">
                          <div>
                            <h6 className="mb-0" style={{ fontWeight: "bold" }}>
                              Configuration Pending..
                            </h6>
                          </div>
                        </Box>
                      </div>
                    )}
                  </div>
                ) : null}
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <Card
                className="superAdminDashboard-card"
                onClick={() => {
                  if (accessManagementPlant?.is_viewer) {
                    navigate("/plantmanagement");
                    dispatch(
                      setHeaderDetails({ headerName: "Super Admin Dashboard" })
                    );
                    return true;
                  } else {
                    notifyError("You don't have access");
                    return true;
                  }
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={{ sm: 6, md: 4, lg: 2, xl: 4 }}
                >
                  <img
                    src={images.plantsManagement}
                    alt=""
                    className="superAdmin-dashboardCard-image"
                  />
                  <Typography
                    variant="h5"
                    gutterBottom
                    className="superAdminDashboard-title"
                  >
                    Plant Management
                  </Typography>
                </Stack>

                {showAlert["Plant Management"] ? (
                  <div
                    className="alertImageContainer"
                    onMouseEnter={handlePlantHover}
                    onMouseLeave={handlePlantHover}
                  >
                    <img
                      src={images.alertImage}
                      alt=""
                      className="alertImage"
                    />
                    {showPlantConfiguration && (
                      <div className="alert-image-data top-alert alert-top-data">
                        <Box className="clientMangement-updatedataWrap-data">
                          <div>
                            <h6 className="mb-0" style={{ fontWeight: "bold" }}>
                              Configuration Pending..
                            </h6>
                          </div>
                        </Box>
                      </div>
                    )}
                  </div>
                ) : null}
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <Card
                className="superAdminDashboard-card"
                onClick={() => {
                  if (accessManagementUserAlarm?.is_viewer) {
                    navigate("/alarmmanagement");
                    dispatch(
                      setHeaderDetails({ headerName: "Super Admin Dashboard" })
                    );
                    return true;
                  } else {
                    notifyError("You don't have access");
                    return true;
                  }
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={{ sm: 6, md: 4, lg: 2, xl: 4 }}
                >
                  <img
                    src={images.NotificationImg}
                    alt=""
                    className="superAdmin-dashboardCard-image"
                  />
                  <Typography
                    variant="h5"
                    gutterBottom
                    className="superAdminDashboard-title"
                  >
                    Alarm Management
                  </Typography>
                </Stack>

                {showAlert["Alarm Management"] ? (
                  <div
                    className="alertImageContainer"
                    onMouseEnter={handleAlarmHover}
                    onMouseLeave={handleAlarmHover}
                  >
                    <img
                      src={images.alertImage}
                      alt=""
                      className="alertImage"
                    />
                    {showAlarmConfiguration && (
                      <div className="alert-image-data top-alert alert-top-data">
                        <Box className="clientMangement-updatedataWrap-data">
                          <div>
                            <h6 className="mb-0" style={{ fontWeight: "bold" }}>
                              Configuration Pending..
                            </h6>
                          </div>
                        </Box>
                      </div>
                    )}
                  </div>
                ) : null}
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <Card
                className="superAdminDashboard-card"
                onClick={() => {
                  if (accessManagementUserReport?.is_viewer) {
                    navigate("/reportmanagement");
                    dispatch(
                      setHeaderDetails({ headerName: "Super Admin Dashboard" })
                    );
                    return true;
                  } else {
                    notifyError("You don't have access");
                    return true;
                  }
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={{ sm: 6, md: 4, lg: 2, xl: 4 }}
                >
                  <img
                    src={images.reportManagement}
                    alt=""
                    className="superAdmin-dashboardCard-image"
                  />
                  <Typography
                    variant="h5"
                    gutterBottom
                    className="superAdminDashboard-title"
                  >
                    Report Management
                  </Typography>
                </Stack>

                {showAlert["Report Management"] ? (
                  <div
                    className="alertImageContainer"
                    onMouseEnter={handleReportHover}
                    onMouseLeave={handleReportHover}
                  >
                    <img
                      src={images.alertImage}
                      alt=""
                      className="alertImage"
                    />
                    {showReportConfiguration && (
                      <div className="alert-image-data top-alert alert-top-data">
                        <Box className="clientMangement-updatedataWrap-data">
                          <div>
                            <h6 className="mb-0" style={{ fontWeight: "bold" }}>
                              Configuration Pending..
                            </h6>
                          </div>
                        </Box>
                      </div>
                    )}
                  </div>
                ) : null}
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <Card
                className="superAdminDashboard-card"
                onClick={() => navigate("/select")}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={{ sm: 6, md: 4, lg: 2, xl: 4 }}
                >
                  <img
                    src={images.processDashboard}
                    alt=""
                    className="superAdmin-dashboardCard-image"
                    style={{ height: "67px" }}
                  />
                  <Typography
                    variant="h5"
                    gutterBottom
                    className="superAdminDashboard-title"
                  >
                    Process Dashboard
                  </Typography>
                </Stack>

                {/* {showAlert["User Management"] ? (
                  <div
                    className="alertImageContainer"
                    onMouseEnter={handleUserHover}
                    onMouseLeave={handleUserHover}
                  >
                    <img
                      src={images.alertImage}
                      alt=""
                      className="alertImage"
                    />
                    {showUserConfiguration && (
                      <div className="alert-image-data alert-user-image-data top-alert alert-top-data">
                        <Box className="clientMangement-updatedataWrap-data">
                          <div>
                            <h6 className="mb-0" style={{ fontWeight: "bold" }}>
                              Configuration Pending..
                            </h6>
                          </div>
                        </Box>
                      </div>
                    )}
                  </div>
                ) : null} */}
              </Card>
            </Grid>
          </Grid>
          {/* ========================================================================================= */}
          {(is_super_admin === true ||
            is_created_by_super_admin_user_company) && (
              <Card
                style={{ marginTop: "100px", overflow: "visible" }}
                className="dasboardCard superAdmin"
              >
                <Stack
                  direction="row"
                  divider={
                    <Divider
                      orientation="vertical"
                      flexItem
                      style={{
                        backgroundColor: "#DCE1EA",
                        width: "2px",
                        height: "49px",
                        marginTop: "20px",
                      }}
                    />
                  }
                  // spacing={{ sm: 14, md: 10, lg: 28 }}
                  className="dashboardItem"
                  onClick={() => {
                    if (accessClientManagement?.is_viewer) {
                      navigate("/clientmanagement");
                      dispatch(
                        setHeaderDetails({ headerName: "Super Admin Dashboard" })
                      );
                      return true;
                    }
                  }}
                >
                  <Item className="superAdmin-dashboardCard-item">
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={{ sm: 0, md: 2, lg: 2, xl: 2 }}
                      className="item"
                    >
                      <img
                        src={images.clientManagement}
                        alt=""
                        className="clientManagement-image"
                      />
                      <Typography
                        variant="h5"
                        gutterBottom
                        className="superAdminDashboardcard-title"
                      >
                        Client Management
                      </Typography>
                    </Stack>
                  </Item>
                  <Item className="superAdmin-dashboardCard-item">
                    <Stack direction="column" spacing={{ lg: 0 }}>
                      <Typography
                        gutterBottom
                        className="superAdminDashboard-text"
                      >
                        Company
                      </Typography>
                      <Typography gutterBottom className="dashboardCard-number">
                        {!isShimmersuperAdminClientDashboardCount
                          ? superAdminClientDashboardCount.companyCount
                          : TextShimmer(2)}
                      </Typography>
                    </Stack>
                  </Item>
                  <Item className="superAdmin-dashboardCard-item">
                    <Stack direction="column" spacing={{ lg: 0 }}>
                      <Typography
                        gutterBottom
                        className="superAdminDashboard-text"
                      >
                        Plants
                      </Typography>
                      <Typography gutterBottom className="dashboardCard-number">
                        {!isShimmersuperAdminClientDashboardCount
                          ? superAdminClientDashboardCount.plantCount
                          : TextShimmer(2)}
                      </Typography>
                    </Stack>
                  </Item>
                  <Item className="superAdmin-dashboardCard-item">
                    <Stack direction="column" spacing={{ lg: 0 }}>
                      <Typography
                        gutterBottom
                        className="superAdminDashboard-text"
                      >
                        User
                      </Typography>
                      <Typography gutterBottom className="dashboardCard-number">
                        {!isShimmersuperAdminClientDashboardCount
                          ? superAdminClientDashboardCount.userCount
                          : TextShimmer(2)}
                      </Typography>
                    </Stack>
                  </Item>
                </Stack>

                {showAlert["Client Management"] ? (
                  <div
                    className="alertImageContainer"
                    onMouseEnter={handleClientHover}
                    onMouseLeave={handleClientHover}
                  >
                    <img src={images.alertImage} alt="" className="alertImage" />
                    {showClientConfiguration && (
                      <div className="alert-image-data alert-user-image-data top-alert alert-top-data">
                        <Box className="clientMangement-updatedataWrap-data">
                          <div>
                            <h6 className="mb-0" style={{ fontWeight: "bold" }}>
                              Configuration Pending..
                            </h6>
                          </div>
                        </Box>
                      </div>
                    )}
                  </div>
                ) : null}
                {/* <MoreVertIcon className="detailsImage" /> */}
              </Card>
            )}
          {/* <FooterText /> */}
        </Container>
      </div>
    </>
  );
};

export default SuperAdminDashboard;
