import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../OverView/OverView.css";
import { makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import { fontSize } from "@mui/system";
import Checkbox from "@mui/material/Checkbox";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { getOverViewConfuguration } from "./services";
import Loader from "../../../components/Loader/Loader";
import { cubejsApi } from "../../Cubejs/CubejsApiWrapper";
import { useCubeQuery } from "@cubejs-client/react";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Mqqtdata from "../Mqqtdata";
import { images } from "../../../config";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import {
  CompactTagUnit,
  CompactTagValue,
} from "../../../components/CompactValue/CompactValue";
import { setProcessDashboarDateSet } from "../sliceProcessDashboard/dateSliceprocessDashboard";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    borderCollapse: "separate",
    borderSpacing: 0,
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    zIndex: 99,
    backgroundColor: "#fff",
    fontSize: 16,
    fontWeight: 500,
  },
  tableWrapper: {
    maxHeight: 600,
    overflowY: "auto",
  },
});

const OverView = () => {
  const [overViewTagData, setOverViewTagData] = useState([]);
  const [overViewDeviceData, setOverViewDeviceData] = useState([]);
  const [overViewDeviceTagData, setOverViewDeviceTagData] = useState([]);
  const [tableRowData, setTableRowData] = useState([]);
  const [deviceId, setDeviceId] = useState([]);
  const [unitId, setUnitId] = useState([]);
  const [deviceIndex, setDeviceIndex] = useState([]);
  const [unitIndex, setUnitIndex] = useState([]);
  const [topics, setTopics] = useState([]);
  const [mqttTagData, setMqttTagData] = useState([]);
  const [tagData, setTagData] = useState([]);
  const [openDeviceId, setOpenDeviceId] = useState(null);

  // Toggle function to open/close specific device
  const toggleDevice = (deviceId) => {
    setOpenDeviceId(openDeviceId === deviceId ? null : deviceId);
  };

  const { user_access } = useSelector((state) => state.userState);

  const { selectUnit } = useSelector(
    (state) => state.dateSliceprocessDashboardState
  );

  const navigate = useNavigate();
  const classes = useStyles();
  const tableRef = useRef(null);

  const param = useParams();

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleScroll = (event) => {
    const target = event.target;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    // Check if the user scrolled to the top of the table
    if (scrollTop === 0) {
      // Page up logic goes here
      console.log("Page up");
    }

    // Check if the user scrolled to the bottom of the table
    if (scrollHeight - scrollTop === clientHeight) {
      // Page down logic goes here
      console.log("Page down");
    }
  };

  useEffect(() => {
    GetOverviewConfigurationApi();
  }, []);

  const GetOverviewConfigurationApi = async () => {
    const paramRequset = {
      plant_id: param.id,
      company_id: param.companyId,
    };
    const resp = await getOverViewConfuguration(paramRequset);
    if (resp.status == 200) {
      if (resp.data.payload.length) {
        console.log(
          "resp.data.payload[0].tag_assigned",
          resp.data.payload[0].tag_assigned
        );
        const uniqueTagUnits = {};
        const filteredTags = resp.data.payload[0].tag_assigned.filter((tag) => {
          if (!uniqueTagUnits[tag.tag_unit]) {
            uniqueTagUnits[tag.tag_unit] = true;
            return true;
          }
          return false;
        });
        setOverViewTagData(filteredTags);
        setOverViewDeviceData(resp.data.payload[0].device);
        setOverViewDeviceTagData(resp.data.payload[0].tag_assigned);
      }
    }
    console.log("resp", resp);
  };

  // topic array

  useEffect(() => {
    if (overViewDeviceData.length) {
      const topicArray = overViewDeviceData.map(
        (e) => "Hikar/DeviceUID/" + e.machine_uid
      );
      // console.log("topicArray", topicArray);
      setTopics(topicArray);
    }
  }, [overViewDeviceData]);

  // const overViewtable = useCubeQuery({
  //   "dimensions": [
  //     "DeviceConfigurationTagconfiguration.latestValue",
  //     "SuperadminDevice.deviceName",
  //     "DeviceConfigurationTagconfiguration.tagUnit"
  //   ],
  //   "order": {
  //     "DeviceConfigurationTagconfiguration.latestValue": "asc"
  //   },
  //   "filters": [
  //     {
  //       "member": "DeviceConfigurationTagconfiguration.tagName",
  //       "operator": "contains",
  //       "values": overViewTagData.length ? overViewTagData.map((row) => row.tag_name) : ["kp"]
  //     }
  //   ]
  // }, {subscribe: true})

  // useEffect(() => {
  //   if (overViewtable.resultSet) {
  //     const tableData = overViewtable.resultSet.tablePivot().map((raw) => {
  //       return ({
  //         tagUnit: raw["DeviceConfigurationTagconfiguration.tagUnit"],
  //         deviceName: raw["SuperadminDevice.deviceName"],
  //         tagValue: raw["DeviceConfigurationTagconfiguration.latestValue"] ? raw["DeviceConfigurationTagconfiguration.latestValue"]  : 0
  //       })
  //     })

  //     setTableRowData(tableData)
  //     console.log("tableData", tableData);
  //   }
  // }, [overViewtable])

  // useEffect(() => {

  //   if (overViewTagData.length) {

  //     // Function to execute the cube query
  //     const executeCubeQuery = async () => {
  //       try {
  //         const realTimeArray = [];

  //         const cubeData = await Promise.all(
  //           overViewTagData.map(async (array, i) => {
  //             const unitData = await cubejsApi.load({
  //               "filters": [
  //                 {
  //                   "member": "DeviceConfigurationTagconfiguration.tagid",
  //                   "operator": "equals",
  //                   "values": [
  //                     array.id.toString()
  //                   ]
  //                 }
  //               ],
  //               "dimensions": [
  //                 "DeviceConfigurationTagconfiguration.latestValue",
  //                 "DeviceConfigurationTagconfiguration.tagUnit",
  //                 "SuperadminDevice.deviceName"
  //               ],
  //             });

  //             const tableRowData = {
  //               tagUnit: unitData.rawData().length ? unitData.rawData()[0]["DeviceConfigurationTagconfiguration.tagUnit"] : null,
  //               deviceName: unitData.rawData().length ? unitData.rawData()[0]["SuperadminDevice.deviceName"] : null,
  //               tagValue: unitData.rawData().length ? parseFloat(unitData.rawData()[0]["DeviceConfigurationTagconfiguration.latestValue"]) : 0
  //             }
  //             // console.log("unitData", unitData.rawData());
  //             return tableRowData;
  //           })
  //         );

  //         setTableRowData(cubeData);
  //         console.log("cubeData", cubeData);

  //       } catch (error) {
  //         // Handle any errors that occur during the cube query
  //         console.error('Cube query error:', error);
  //       }
  //     };

  //     // Execute the cube query immediately
  //     executeCubeQuery();

  //     // Set interval to execute the cube query every minute
  //     setInterval(() => {
  //       executeCubeQuery();
  //     }, 5000);
  //   }

  // }, [overViewTagData]);

  const handleSelectDevice = (id, index) => {
    if (!Array.isArray(deviceId)) {
      setDeviceId([]);
    }
    if (deviceId.includes(id) || deviceIndex.includes(index)) {
      setDeviceId(deviceId.filter((element) => element !== id));
    } else {
      setDeviceId([id]);
    }

    if (!Array.isArray(deviceIndex)) {
      setDeviceIndex([]);
    }
    if (deviceIndex.includes(index)) {
      setDeviceIndex(deviceIndex.filter((element) => element !== index));
    } else {
      setDeviceIndex([index]);
    }
  };

  const handleSelectUnit = (id, index) => {
    if (!Array.isArray(unitId)) {
      setUnitId([]);
    }
    if (unitId.includes(id) || deviceIndex.includes(index)) {
      setUnitId(unitId.filter((element) => element !== id));
    } else {
      setUnitId([id]);
    }

    if (!Array.isArray(unitIndex)) {
      setUnitIndex([]);
    }
    if (unitIndex.includes(index)) {
      setUnitIndex(unitIndex.filter((element) => element !== index));
    } else {
      setUnitIndex([index]);
    }
  };

  function handleDeviceUpMoveRaw(id) {
    const itemIndex = overViewDeviceData.findIndex((item) => item.id === id);

    if (!deviceIndex.length) {
      console.log("overViewDeviceData");
      return true;
    }

    if (deviceIndex[0] == -1) {
      console.log("overViewDeviceData");
      return true;
    }

    if (itemIndex !== -1) {
      const item = overViewDeviceData[itemIndex];
      overViewDeviceData.splice(itemIndex, 1); // Remove the item from its current position
      overViewDeviceData.splice(deviceIndex[0], 0, item); // Insert the item at the new position
      setOverViewDeviceData(overViewDeviceData);
      setDeviceIndex([deviceIndex[0] - 1]);
    }
  }

  function handleDeviceDownMoveRaw(id) {
    const itemIndex = overViewDeviceData.findIndex((item) => item.id === id);

    if (!deviceIndex.length) {
      console.log("overViewDeviceData");
      return true;
    }

    if (deviceIndex[0] == overViewDeviceData.length - 1) {
      console.log("overViewDeviceData");
      return true;
    }

    if (itemIndex !== -1) {
      const item = overViewDeviceData[itemIndex];
      overViewDeviceData.splice(itemIndex, 1); // Remove the item from its current position

      // Adjust the target index based on the current deviceIndex
      const targetIndex = deviceIndex[0] === 0 ? 1 : deviceIndex[0] + 1;

      overViewDeviceData.splice(targetIndex, 0, item); // Insert the item at the new position
      setOverViewDeviceData([...overViewDeviceData]); // Make a shallow copy before updating the state
      setDeviceIndex([deviceIndex[0] + 1]); // Update the deviceIndex
    }
  }

  function handleUnitLeftMoveRaw(id) {
    const itemIndex = overViewTagData.findIndex((item) => item.id === id);

    if (!unitIndex.length) {
      console.log("overViewDeviceData");
      return true;
    }

    if (unitIndex[0] == -1) {
      console.log("overViewDeviceData");
      return true;
    }

    if (itemIndex !== -1) {
      const item = overViewTagData[itemIndex];
      overViewTagData.splice(itemIndex, 1); // Remove the item from its current position
      overViewTagData.splice(unitIndex[0], 0, item); // Insert the item at the new position
      setOverViewTagData(overViewTagData);
      setUnitIndex([unitIndex[0] - 1]);
    }

    // if (!unitIndex.length) {
    //   console.log("overViewDeviceData");
    //   return true;
    // }

    // if (unitIndex[0] == 0) {
    //   console.log("overViewDeviceData");
    //   return true;
    // } else {
    //   const updateArray = [...overViewTagData]
    //   const element = updateArray.splice(indexToMove, 1)[0];
    //   updateArray.splice(indexToMove - 1, 0, element);
    //   setOverViewTagData(updateArray);
    //   setDeviceIndex([deviceIndex[0] - 1])
    // }
  }

  function handleUnitRightMoveRaw(id) {
    const itemIndex = overViewTagData.findIndex((item) => item.id === id);

    if (!unitIndex.length) {
      console.log("overViewTagData");
      return true;
    }

    if (unitIndex[0] == overViewTagData.length - 1) {
      console.log("overViewTagData");
      return true;
    }

    if (itemIndex !== -1) {
      const item = overViewTagData[itemIndex];
      overViewTagData.splice(itemIndex, 1); // Remove the item from its current position

      // Adjust the target index based on the current unitIndex
      const targetIndex = unitIndex[0] === 0 ? 1 : unitIndex[0] + 1;

      overViewTagData.splice(targetIndex, 0, item); // Insert the item at the new position
      setOverViewTagData([...overViewTagData]); // Make a shallow copy before updating the state
      setUnitIndex([unitIndex[0] + 1]); // Update the deviceIndex
    }
  }

  useEffect(() => {
    if (overViewDeviceTagData.length) {
      const mergedArray = [].concat(...Object.values(mqttTagData));

      const filteredArray = mergedArray.filter((obj) => {
        const device = obj.device__uid;
        const deviceTag = obj.device_tag;
        return overViewDeviceTagData.some(
          (item) => item.device__uid === device && item.device_tag === deviceTag
        );
      });
      console.log("filteredArray", filteredArray);
      setTagData(filteredArray);
    }
  }, [mqttTagData]);

  const uidTag = [
    [{ tag_name: "F1MTR_KVARH" }, { tag_name: "F1MTR_KVAR" }],
    [{ tag_name: "F2MTR_KVAR" }],
  ];
  const plantDashboardAccess = user_access.length
    ? user_access.find(
        (user) => user.access_module?.access_module_name === "Plant Dashboard"
      )
    : null;

  const overViewAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Overview"
        )
      : null
    : null;

  const handleTostMsg = () => {
    notifyError("You don't have access");
  };

  const dispatch = useDispatch();

  const handleConfOverViewNavigate =()=>{
    navigate(`/overview-configration/company/${param.companyId}/plant/${param.id}`)
  }

  return (
    <>
      {overViewDeviceTagData.length ? (
        <Mqqtdata
          setMqttTagData={setMqttTagData}
          topics={topics}
          overViewTagData={overViewDeviceTagData}
        />
      ) : (
        ""
      )}
      <div className="page-wraper pb-5 page-wraper-overview">
        <div className="page-header d-flex justify-content-between overview-table-header">
          <div
            onClick={() =>
              navigate(
                `/company/${param.companyId}/plant/plantprocessdashboard/${param.id}`
              )
            }
            className="page-back-btn"
          >
            <ArrowBackIcon />
            <span>Overview</span>
          </div>

          <div className="header-btn-group d-flex">
            {(overViewAccess?.is_viewer && overViewAccess?.is_editor) ||
            (!overViewAccess?.is_viewer && overViewAccess?.is_editor) ||
            (overViewAccess?.is_viewer && !overViewAccess?.is_editor) ? (
              <button
                className="overviewReport"
                onClick={() =>
                  navigate(
                    `/overView-analytics/company/${param.companyId}/plant/${param.id}`
                  )
                }
              >
                Report & Analysis
              </button>
            ) : (
              <button
                className="overviewReport"
                onClick={() => handleTostMsg()}
              >
                Report & Analysis
              </button>
            )}
          </div>
        </div>
        <div className="overview-card-header">
        <div className="page-header d-flex justify-content-between">
          <div
            className="d-flex justify-content-between"
            style={{ width: "100%",flexWrap:"wrap" }}
          >
            <div
              onClick={() =>
                navigate(
                  `/company/${param.companyId}/plant/plantprocessdashboard/${param.id}`
                )
              }
              className="over-view-header"
              style={{ marginTop: "-19px" }}
            >
              <ArrowBackIcon />
              <span>Overview</span>
            </div>
            <div className="d-flex gap-2">
              <div>
                <select
                  className="compact-btn"
                  value={selectUnit}
                  onChange={(e) =>
                    dispatch(
                      setProcessDashboarDateSet({ selectUnit: e.target.value })
                    )
                  }
                >
                  <option value="k">Kilo</option>
                  <option value="m">Mega</option>
                  <option value="g">Giga</option>
                </select>
              </div>
              <div>
                {(overViewAccess?.is_viewer && overViewAccess?.is_editor) ||
                (!overViewAccess?.is_viewer && overViewAccess?.is_editor) ||
                (overViewAccess?.is_viewer && !overViewAccess?.is_editor) ? (
                  <button
                    className="configration-btn confi-btn"
                    onClick={() => handleConfOverViewNavigate()}
                  >
                    <img
                      src={images.configration}
                      style={{
                        height: "18px",
                        width: "18px",
                        marginRight: "8px",
                      }}
                      alt=""
                    />
                    Configuration
                  </button>
                ) : (
                  <button className="configration-btn" onClick={handleTostMsg}>
                    <img
                      src={images.configration}
                      style={{
                        height: "18px",
                        width: "18px",
                        marginRight: "8px",
                      }}
                      alt=""
                    />
                    Configuration
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mb-3">
          {(overViewAccess?.is_viewer && overViewAccess?.is_editor) ||
          (!overViewAccess?.is_viewer && overViewAccess?.is_editor) ||
          (overViewAccess?.is_viewer && !overViewAccess?.is_editor) ? (
            <button
              className="overviewReport"
              style={{ float: "right", marginTop: "-9px" }}
              onClick={() =>
                navigate(
                  `/overView-analytics/company/${param.companyId}/plant/${param.id}`
                )
              }
            >
              Report & Analysis
            </button>
          ) : (
            <button
              className="overviewReport "
              style={{ float: "right" }}
              onClick={() => handleTostMsg()}
            >
              Report & Analysis
            </button>
          )}
        </div>
        </div>
      
        <div className="pt-3 overviewtable-wrap">
          <div className={`${classes.tableWrapper} overViewTableData`}>
            <TableContainer component={Paper} className="table-overview-data">
              <Table className="overView-data-table" aria-label="my table">
                <TableHead>
                  <TableRow className={classes.stickyHeader}>
                    <TableCell
                      colSpan={2}
                      style={{
                        background: "#ffff",
                        position: "sticky",
                        left: 0,
                        zIndex: 99,
                      }}
                    >
                      {/* <TableCell colSpan={2} style={{ background: "#ffff"}}> */}
                      <div className="d-flex">
                        <div>
                          <div
                            onClick={() => handleDeviceUpMoveRaw(deviceId[0])}
                            style={{ cursor: "pointer" }}
                          >
                            <img src={images.up} alt="" />
                          </div>
                          <div
                            onClick={() => handleDeviceDownMoveRaw(deviceId[0])}
                            style={{ cursor: "pointer" }}
                          >
                            <img src={images.down} alt="" />
                          </div>
                        </div>
                        <div
                          style={{
                            marginTop: "10px",
                            marginLeft: "16px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleUnitLeftMoveRaw(unitId[0])}
                        >
                          <img src={images.left} alt="" />
                        </div>
                        <div
                          style={{ marginTop: "10px", cursor: "pointer" }}
                          onClick={() => handleUnitRightMoveRaw(unitId[0])}
                        >
                          <img src={images.right1} alt="" />
                        </div>
                      </div>
                    </TableCell>
                    {overViewTagData.length
                      ? overViewTagData.map((row, index) => {
                          // Check if the current name has already been included
                          const isDuplicate = overViewTagData
                            .slice(0, index)
                            .some(
                              (prevRow) => prevRow.tag_unit === row.tag_unit
                            );

                          if (!isDuplicate) {
                            return (
                              <TableCell
                                style={{
                                  fontWeight: "700",
                                  fontSize: "17px",
                                  background: "#FFFF",
                                }}
                                key={row.tag_unit}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Checkbox
                                    checked={unitId.includes(row.id)}
                                    onClick={() =>
                                      handleSelectUnit(row.id, index)
                                    }
                                  />
                                  <span style={{ marginLeft: "5px" }}>
                                    {CompactTagUnit(selectUnit, row.tag_unit)}
                                  </span>
                                </div>
                              </TableCell>
                            );
                          }

                          return null;
                        })
                      : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {overViewDeviceData.length
                    ? overViewDeviceData.map((row, ind) => {
                        return (
                          <TableRow>
                            <TableCell style={{ width: "0%" }}>
                              <Checkbox
                                checked={deviceId.includes(row.id)}
                                onClick={() => handleSelectDevice(row.id, ind)}
                              />
                            </TableCell>
                            <TableCell>{row.device_name}</TableCell>
                            {overViewTagData.length
                              ? overViewTagData.map((row2, index) => {
                                  const findTagUnitValue1 = tagData.find(
                                    (f) =>
                                      f.tagUnit == row2.tag_unit &&
                                      f.device__uid == row.machine_uid
                                  );

                                  const isDuplicate = overViewTagData
                                    .slice(0, index)
                                    .some(
                                      (prevRow) =>
                                        prevRow.tag_unit === row2.tag_unit
                                    );

                                  if (!isDuplicate) {
                                    return (
                                      <TableCell>
                                        {findTagUnitValue1
                                          ? CompactTagValue(
                                              selectUnit,
                                              findTagUnitValue1.tagValue
                                            )
                                          : ""}
                                      </TableCell>
                                    );
                                  }

                                  return null;
                                })
                              : null}
                          </TableRow>
                        );
                      })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        {/* <div>
        <FooterText/>
        </div> */}
      </div>

      {/* =============== Overview - responsive design =============== */}

      <Container maxWidth="xl" className="mb-5">
        <Grid container spacing={2} className="alarm-container">
          {overViewDeviceData.length
            ? overViewDeviceData.map((row) => {
                const isOpen = openDeviceId === row.id; // Check if the current device is open
                return (
                  <Grid item xs={12} sm={6} key={row.id}>
                    <div className="overview-details">
                      <div>{row.device_name}</div> {/* Dynamic device name */}
                      {overViewTagData.slice(0, 3).map((row2, index) => {
                        const itemLabel = CompactTagUnit(
                          selectUnit,
                          row2.tag_unit
                        );
                        const itemValue = tagData.find(
                          (f) =>
                            f.tagUnit === row2.tag_unit &&
                            f.device__uid === row.machine_uid
                        );

                        return (
                          <div key={index} className="overview-details-wrap">
                            <div>
                              <h4>{itemLabel} :</h4>
                            </div>
                            <div>
                              <h6>
                                {itemValue
                                  ? CompactTagValue(
                                      selectUnit,
                                      itemValue.tagValue
                                    )
                                  : ""}
                              </h6>
                            </div>
                          </div>
                        );
                      })}
                      {isOpen &&
                        overViewTagData.slice(3).map((row2, index) => {
                          const itemLabel = CompactTagUnit(
                            selectUnit,
                            row2.tag_unit
                          );
                          const itemValue = tagData.find(
                            (f) =>
                              f.tagUnit === row2.tag_unit &&
                              f.device__uid === row.machine_uid
                          );

                          return (
                            <div
                              key={index + 3}
                              className="overview-details-wrap"
                            >
                              <div>
                                <h4>{itemLabel} :</h4>
                              </div>
                              <div>
                                <h6>
                                  {itemValue
                                    ? CompactTagValue(
                                        selectUnit,
                                        itemValue.tagValue
                                      )
                                    : ""}
                                </h6>
                              </div>
                            </div>
                          );
                        })}
                      <img
                        src={isOpen ? images.upIcon : images.downIcon}
                        alt="toggle visibility"
                        className={isOpen ? "up-arrow" : "down-arrow"}
                        onClick={() => toggleDevice(row.id)} // Pass the device id to toggle function
                      />
                    </div>
                  </Grid>
                );
              })
            : null}
        </Grid>
      </Container>
    </>
  );
};
export default OverView;
