import React, { useEffect } from 'react';
import { connect } from 'mqtt';

function Mqqtdata({ setMqttTagData, topics, overViewTagData }) {

   

    useEffect(() => {
        // dev server
        const mqttBrokerPort = 8883;
        const mqttBrokerAddress = process.env.REACT_APP_MQTT_URL;
        const mqttUsername = process.env.REACT_APP_MQTT_USERNAME; // Replace 'your-username' with the actual username
        const mqttPassword = process.env.REACT_APP_MQTT_PASSWORD; // Replace 'your-password' with the actual password

        const getTagTime = () => {
            return new Date().toLocaleTimeString('en-US', {
                timeZone: 'Asia/Kolkata',
                hour12: false,
            });
        };


        const getTagDateAndTime = () => {
            return new Date().toLocaleString('en-US', {
                timeZone: 'Asia/Kolkata',
                hour12: false,
            });
        };

        const getTagDateAndTimeFormat = () => {
            const options = {
                timeZone: 'Asia/Kolkata',
                hour12: false,
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            };

            const dateTimeString = new Date().toLocaleString('en-US', options);

            // Reformatting dateTimeString to YYYY-MM-DD HH:MM:SS
            const [month, day, year, hour, minute, second] = dateTimeString.match(/\d+/g);
            return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
        };
        



        const mqttClient = connect(mqttBrokerAddress, {
            username: mqttUsername,
            password: mqttPassword,
            rejectUnauthorized: false
        });

      mqttClient.on('connect', () => {
            console.log('Connected to MQTT broker');
        });

        mqttClient.on('message', (topics, message) => {
            try {
                const messageString = message.toString();
                // console.log("messageString", messageString);
                const tags = topics.split("/").slice(-2).join("/");
                const data = JSON.parse(`[${messageString.replace(/}{/g, '},{')}]`);

                const formattedMessage = data.map(obj => {
                    const device_tag = Object.keys(obj)[0];
                    const tagName = overViewTagData ? overViewTagData.find((f) => device_tag == f.device_tag) : "";
                    const tagUnit = overViewTagData ? overViewTagData.find((f) => f.device__uid == tags && device_tag == f.device_tag) : "";
                    const tagValue = obj[device_tag];
                
                    return {
                        tagValue: tagValue,
                        device__uid: tags,
                        device_tag,
                        [device_tag]: tagValue,
                        tagName: tagName ? tagName.tag_name : "",
                        tagUnit: tagUnit ? tagUnit.tag_unit : "",
                        tagTime: getTagTime(),
                        tagDateAndTime: getTagDateAndTime(),
                        tagDateAndTimeFormat: getTagDateAndTimeFormat()
                    };
                });

                setMqttTagData(prevData => {
                    const updatedData = { ...prevData };
                    const dataArray = updatedData[tags] || [];
                    
                    // Create a map of device_tag to newObj for faster lookup
                    const newObjMap = new Map(formattedMessage.map(obj => [obj.device_tag, obj]));
                
                    // Use reduce to update the dataArray
                    const updatedArray = dataArray.reduce((acc, item) => {
                        const newObj = newObjMap.get(item.device_tag);
                        if (newObj) {
                            // If the device_tag already exists, replace the existing object
                            acc.push(newObj);
                            newObjMap.delete(item.device_tag); // Remove already processed item
                        } else {
                            // If the device_tag doesn't exist, keep the existing object
                            acc.push(item);
                        }
                        return acc;
                    }, []);
                
                    // Add any remaining new objects to the array
                    updatedArray.push(...newObjMap.values());
                
                    updatedData[tags] = updatedArray;
                    return updatedData;
                });
                
            } catch (error) {
                console.log("Error handling message: ", error);
            }
        });

        mqttClient.on('error', (error) => {
            console.log("MQTT error: ", error);
        });

        const intervalId = setInterval(() => {
            // Code to execute every 3 seconds
            mqttClient.subscribe(topics);
        }, 3000);

        return () => {
            clearInterval(intervalId); // Clear the interval when the component is unmounted
            mqttClient.end(); // Disconnect the MQTT client
        };
    }, [topics]);

    return (
        <div>
            {/* Your component JSX */}
        </div>
    );
}

export default Mqqtdata;
