import { request , multipartRequest } from "../../../api/api"


export const advanceCreateConfiguration =(params)=>{
    return request.post(`/advance/createconfigurations/`,params)
}

export const advanceGetConfiguration =(params)=>{
    return request.post(`/advance/getconfigurations/`,params)
}

export const advanceUpdateConfiguration = (id,params) =>{
    return request.put(`/advance/createconfigurations/${id}/`,params)
}

export const advancegetAdvanceAnalyticsRunningHour =(params)=>{
    return request.post(`/advance/getAdvanceAnalyticsRunningHour/`,params)
}

export const getAdvanceAnalyticsProductionAvailabilityCOCDataApi = (params) => {
    return request.post(`/advance/getAdvanceAnalyticsProductionAvailabilityCOCData/`, params)
}

export const getAdvanceAnalyticsProductionAvailabilityCOCPieChartDataApi = (params) => {
    return request.post(`/advance/getAdvanceAnalyticsProductionAvailabilityCOCPieChartData/`, params)
}


export const createCostPrice = (params) =>{
    return request.post(`/advance/createCostPrice/`,params)
}

export const getCostPrice =(params)=>{
  return request.post(`/advance/getCostPrice/`,params)
}

export const getEnergyCostSummary =(params) =>{
    return request.post(`/advance/getEnergyCostSummary/`,params)
}

export const getEnergyCostDevice =(params) =>{
    return request.post(`/advance/getEnergyCostDevices/`,params)
}

export const getEnergyCostSummaryCOCBarChart =(params) =>{
    return request.post(`/advance/getEnergyCostSummaryCOCBarChartData/`,params)
}

export const getCo2FootPrintSummary = (params) =>{
return request.post(`/advance/getCo2FootprintSummary/`,params)
}

export const getSummaryGraphData =(params) =>{
    return request.post(`/advance/getSummaryGraphData/`, params)
}
export const analyticsProductionavailibityCocExcel = (params)=>{
    return request.post(`/advance/exportAdvanceAnalyticsProductionAvailabityCOCExcelReport/`,params)
}
export const analyticsProductionavailibityPOPExcel = (params)=>{
    return request.post(`/advance/exportAdvanceAnalyticsProductionAvailabityPOPExcelReport/`,params)
}

export const analyticsEnergyCostCOCExcel = (params)=>{
    return request.post(`/advance/exportAdvanceAnalyticsEnergyCostCOCExcelReport/`,params)
}
export const analyticsEnergyCostPOPExcel = (params)=>{
    return request.post(`/advance/exportAdvanceAnalyticsEnergyCostPOPExcelReport/`,params)
}