import React, { useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Table, TableCell, TableRow, Typography } from "@material-ui/core";
import { Box, Card, Checkbox, Grid, TableBody, TableHead } from "@mui/material";
import { images } from "../../../../config/images";
import {
  getDeviceConfigDashboard,
  getDeviceConfigData,
  getDeviceTagByType,
  getUnityModel,
} from "../VmsMachine/service";
import { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import SearchIcon from "@mui/icons-material/Search";
import Mqqtdata from "../../../ProcessDashboard/Mqqtdata";
import {
  createDeviceConfig,
  getDeviceType,
} from "../../../ProcessDashboard/Source/services";
import { useDispatch, useSelector } from "react-redux";
import { setVmsData } from "../vmsSlice/vmsSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VmsARMachine from "./VmsARMachine/VmsARMachine";
import { getConsumptionDeviceData } from "../../../ProcessDashboard/Consumption/service";
import { ShimmerTable, ShimmerTitle } from "react-shimmer-effects";

const SubMachine = () => {
  const [informationModal, setInformationModal] = useState(false);
  const [machineTag, setMachineTag] = useState(true);
  const [deviceData, setDeviceData] = useState([]);
  const [tagData, setTagData] = useState([]);
  const [tagListData, setTagListData] = useState([]);
  const [mqttTagData, setMqttTagData] = useState([]);
  const [tagMqttData, setTagMqttData] = useState([]);
  const [topics, setTopics] = useState([]);
  const [searchTagName, setSearchTagName] = useState("");
  const [devicepopup, setDevicePopup] = useState(true);
  const [searchTagUnit, setSearchTagUnit] = useState("");
  const [searchTagComment, setSearchTagComment] = useState("");
  const [selectTagId, setSelectTagId] = useState([]);
  const [selectedTagName, setSelectedTagName] = useState([]);
  const [mqttTagPreviousvalue, setMqttTagPreviousvalue] = useState([]);
  const [tagStatus, setTagStatus] = useState([]);
  const [deviceUID, setDeviceUID] = useState("");
  const [unloadUnityModel, setUnloadUnityModel] = useState(null);
  const [modelFound, setModelFound] = useState(false);
  const [modelUrlData, setModelUrlData] = useState(null);
  const [allSelectTag, setAllSelectTag] = useState(false);
  const [navigateRoute, setNavigateRoute] = useState("");
  const [sendRealTimeDataModel, setSendRealTimeDataModel] = useState([]);
  const [isShimmerDeviceList, setisShimmerDeviceList] = useState(false);
  const [isShimmerDeviceTagList, setisShimmerDeviceTagList] = useState(false);

  //fullscreenn
  const [fullScreenModel, setFullScreenModel] = useState(false);

  const { configureModel } = useSelector((state) => state.vmsState);
  const dispatch = useDispatch();
  const { user_access } = useSelector((state) => state.userState);
  const navigate = useNavigate();

  console.log("mqttTagPreviousvalue", deviceData);
  const notify = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });
  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleDeviceDashboardNavigate = async () => {
    if (modelFound) {
      navigate(`/vmsmachine/company/${param.companyId}/plant/${param.plantId}`);
      return true;
    }
    setNavigateRoute(
      `/vmsmachine/company/${param.companyId}/plant/${param.plantId}`
    );
  };
  const handleOverViewNavigate = async () => {
    if (modelFound) {
      navigate(
        `/vms-overview/company/${param.companyId}/plant/${param.plantId}/device/${param.deviceId}/machine/${param.machineId}`
      );
      return true;
    }
    setNavigateRoute(
      `/vms-overview/company/${param.companyId}/plant/${param.plantId}/device/${param.deviceId}/machine/${param.machineId}`
    );
  };

  const handleReportNavigate = async () => {
    if (modelFound) {
      navigate(
        `/vms-reports/company/${param.companyId}/plant/${param.plantId}/device/${param.deviceId}/machine/${param.machineId}`
      );
      return true;
    }
    setNavigateRoute(
      `/vms-reports/company/${param.companyId}/plant/${param.plantId}/device/${param.deviceId}/machine/${param.machineId}`
    );
  };

  // handle fullscrenn
  const handleFullScrennModel = () => {
    console.log("Current State:", fullScreenModel);
    setFullScreenModel((prev) => !prev);
    console.log("New State:", !fullScreenModel);
  };
  
  const handleTostMsg = () => {
    notifyError("You don't have access");
  };
  const handleAdvanceAnalyticsNavigate = async () => {
    if (modelFound) {
      navigate(
        `/vms-advance-analytics/company/${param.companyId}/plant/${param.plantId}/device/${param.deviceId}/machine/${param.machineId}`
      );
      return true;
    }
    setNavigateRoute(
      `/vms-advance-analytics/company/${param.companyId}/plant/${param.plantId}/device/${param.deviceId}/machine/${param.machineId}`
    );
  };

  // all device_tag select
  const handleAllTagSelect = (checkvalue) => {
    console.log("", checkvalue);
    const selectAllTagId = tagListData.length
      ? tagListData.map((row) => row.id)
      : [];
    const selectAllTagName = tagListData.length
      ? tagListData.map((row) => row.tag_name)
      : [];
    if (checkvalue) {
      setSelectTagId(selectAllTagId);
      setSelectedTagName(selectAllTagName);
      setAllSelectTag((prev) => !prev);
    } else {
      setSelectTagId([]);
      setSelectedTagName([]);
      setAllSelectTag(false);
    }
  };

  // each device_tag select
  const handleSelectTag = (id, tagName) => {
    if (!Array.isArray(selectTagId)) {
      setSelectTagId([]);
    }
    if (selectTagId.includes(id)) {
      setSelectTagId(selectTagId.filter((element) => element !== id));
    } else {
      setSelectTagId([...selectTagId, id]);
    }

    if (!Array.isArray(selectedTagName)) {
      setSelectedTagName([]);
    }
    if (selectedTagName.includes(tagName)) {
      setSelectedTagName(
        selectedTagName.filter((element) => element !== tagName)
      );
    } else {
      setSelectedTagName([...selectedTagName, tagName]);
    }
  };

  //check all device_tag select or not
  useEffect(() => {
    if (selectTagId.length && tagListData.length) {
      if (selectTagId.length == tagListData.length) {
        setAllSelectTag(true);
      } else {
        setAllSelectTag(false);
      }
    }
  }, [tagListData, selectTagId, configureModel]);

  useEffect(() => {
    if (tagData.length) {
      const mergedArray = [].concat(...Object.values(mqttTagData));

      const filteredArray = mergedArray.filter((obj) => {
        const device = obj.device__uid;
        const deviceTag = obj.device_tag;
        return tagData.some(
          (item) => item.device__uid == device && item.device_tag == deviceTag
        );
      });

      console.log("filteredArray", filteredArray);
      setTagMqttData(filteredArray);

      setMqttTagPreviousvalue((prev) => {
        const prevValue = [...prev].map((row) => {
          const findTag = filteredArray.find(
            (f) => f.device_tag == row.tagName
          );
          const obj = {
            tagName: findTag?.device_tag || "-",
            prevValue: row.curValue,
            curValue: findTag?.tagValue || 0,
          };
          return obj;
        });
        return prevValue;
      });
    }

    if (tagListData.length) {
      const mergedArray = [].concat(...Object.values(mqttTagData));
      console.log("tagListData", tagListData, mergedArray);

      const filteredArray = mergedArray.map((obj) => {
        const device = obj.device__uid;
        const deviceTag = obj.device_tag;

        const tagFind = tagListData.find((item) => item.device__uid == device && item.device_tag == deviceTag)
        return {
          tag_name: tagFind ? tagFind.tag_name : "",
          // tagListData.find(
          //   (item) =>
          //     item.device__uid == device && item.device_tag == deviceTag
          // )?.tag_name || "",
          mqtt_tag_name: deviceTag,
          tag_value: tagFind?.signal_type ? ["AI", "AO"].includes(tagFind.signal_type) ? obj.tagValue : obj.tagValue == "1" ? tagFind.high_range : tagFind.low_range : obj.tagValue,
          alarm: tagFind?.setpoint_hi && tagFind?.setpoint_lo ? tagFind?.setpoint_hi < obj.tagValue || tagFind?.setpoint_lo > obj.tagValue ? "true" : "false" : "false"
        };
      });
      setSendRealTimeDataModel(filteredArray.filter((f) => f.tag_name != ""));
    }
  }, [mqttTagData]);

  useEffect(() => {
    if (mqttTagPreviousvalue.length) {
      const averagCalTagvalue = mqttTagPreviousvalue.map((row) => {
        const avgCal = ((row.curValue - row.prevValue) / row.prevValue) * 100;
        console.log("avgCal", avgCal);
        return {
          tagName: row.tagName,
          statusValue: isFinite(avgCal) ? avgCal : 0.0,
        };
      });
      setTagStatus(averagCalTagvalue);
    }
  }, [mqttTagPreviousvalue]);

  const param = useParams();
  const location = useLocation();

  console.log("liehfdufhjfhfiug", param);
  const handleInformationModal = () => {
    setInformationModal(!informationModal);
  };

  useEffect(() => {
    getDeviceData();
  }, []);

  const getDeviceId = async () => {
    const params = {
      device: param.deviceId,
    };
    try {
      setisShimmerDeviceList(true);
      const resp = await getConsumptionDeviceData(params);
      if (resp.status === 200) {
        setTopics(["Hikar/DeviceUID/" + resp.data.payload.machine_uid]);
        setTimeout(()=>{setisShimmerDeviceList(false)},1000)
        setDeviceData(resp.data.payload);
        setDeviceUID(resp.data.payload.machine_uid.replace(/\//g, "_"));
        // seykeyvalue((prev) => prev + 1);
      }
    } catch (error) {
      setisShimmerDeviceList(false);
      console.error("An error occurred while fetching device data:", error);
      // Handle the error appropriately, e.g., show an error message to the user.
    }
  };


  // found Device is server side
  const getUnityModelAPI = async () => {
    const paramReq = {
      device_id: param.deviceId,
    };
    try {
      const resp = await getUnityModel(paramReq);
      if (resp.data.success == true) {
        setModelFound(false);
        setModelUrlData({
          dataUrl: resp.data.payload.data.split("?")[0],
          frameworkUrl: resp.data.payload.framework.split("?")[0],
          loaderUrl: resp.data.payload.loader.split("?")[0],
          wasmUrl: resp.data.payload.wasm.split("?")[0],
        });
      }
    } catch (error) {
      console.error("An error occurred while fetching device data:", error);
      setModelFound(true);
      // Handle the error appropriately, e.g., show an error message to the user.
    }
  };

  const getDeviceConfigDataApi = async () => {
    try {
      const paramReq = {
        plant_id: param.plantId,
        company_id: param.companyId,
        dashboard: 2,
        device_id: param.deviceId,
      };

      const resp = await getDeviceConfigDashboard(paramReq);

      if (resp.status === 200) {

        console.log("respresprespresprespresp", resp.data.payload);
        setTagData(
          resp.data.payload.tag_assigned.sort((a, b) => a.id - b.id)
        );
        setSelectTagId(resp.data.payload.tag_assigned.map((row) => row.id));
        setSelectedTagName(
          resp.data.payload.tag_assigned.map((row) => row.tag_name)
        );
        const tagObj = resp.data.payload.tag_assigned.map((row) => {
          const obj = {
            tagName: row.device_tag ? row.device_tag : "-",
            prevValue: 0,
            curValue: 0,
          };
          return obj;
        });
        setMqttTagPreviousvalue(tagObj);
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching device config data:",
        error
      );
      // Handle the error appropriately, e.g., show an error message to the user.
    }
  };

  useEffect(() => {
    getDeviceConfigDataApi();
  }, [configureModel]);

  useEffect(() => {
    getDeviceId();
  }, []);

  const getDeviceData = async () => {
    const params = {
      device_id: param.deviceId,
      plant_id: param.plantId,
      company_id: param.companyId,
    };
    try {
      setisShimmerDeviceTagList(true);
      const resp = await getDeviceTagByType(params);
      if (resp.status === 200 && resp.data.payload.length) {
        setTimeout(()=>{setisShimmerDeviceTagList(false)},1000)
        setTagListData(resp.data.payload.sort((a, b) => a.id - b.id));
      }
    } catch (error) {
      setisShimmerDeviceTagList(false);
      console.log("error", error);
      // Handle the error appropriately, e.g., show an error message to the user.
    }
  };

  const createDeviceConfigApi = async () => {
    const paramsReq = {
      company_id: param.companyId,
      plant_id: param.plantId,
      device: param.deviceId,
      tag_assigned: selectTagId,
    };
    try {
      const resp = await createDeviceConfig(paramsReq);
      if (resp.data.success == true) {
        notify("Tag Configure successfully");
        dispatch(setVmsData({ configureModel: !configureModel }));
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  const plantDashboardAccess = user_access.length
    ? user_access.find(
        (user) => user.access_module?.access_module_name === "Plant Dashboard"
      )
    : null;
  const plantAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Plant Process Dashboard"
        )
      : null
    : null;
  const overViewAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Overview"
        )
      : null
    : null;
  const analyticsAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Advance Analytics"
        )
      : null
    : null;

  const analyticsReportAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Analytics"
        )
      : null
    : null;

  const ReportAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Reports"
        )
      : null
    : null;

  const DeviceShimmer = () => {
    return (
      <>
        <Box className={`Device-caption   mb-2`}>
          <ShimmerTitle
            line={2}
            gap={10}
            variant="primary"
            className="shimmer-style"
          />
        </Box>
        <Box className={`Device-caption   mb-2`}>
          <ShimmerTitle
            line={2}
            gap={10}
            variant="primary"
            className="shimmer-style"
          />
        </Box>
        <Box className={`Device-caption   mb-2`}>
          <ShimmerTitle
            line={2}
            gap={10}
            variant="primary"
            className="shimmer-style"
          />
        </Box>
        <Box className={`Device-caption   mb-2`}>
          <ShimmerTitle
            line={2}
            gap={10}
            variant="primary"
            className="shimmer-style"
          />
        </Box>
      </>
    );
  };

  const DeviceNotFound = () => {
    return (
      <>
        <Box className={`Device-caption   mb-2`}>Device Not configured</Box>
      </>
    );
  };

  const TagListShimmer = () => {
    return (
      <>
      {[1,2,3,4,5,6,7,8].map((e) =>{
        return(
          <TableRow>
          <TableCell>
          <Checkbox
                                            style={{ color: "#1C407B" }}
                                            onClick={() =>
                                              handleSelectTag(e.id, e.tag_name)
                                            }
                                            checked={selectTagId.includes(e.id)}
                                          ></Checkbox>
          </TableCell>
          <TableCell className="ShimmerTable-configration-table-TagList">
            {" "}
            <ShimmerTable row={1} col={1} />
          </TableCell>
          <TableCell className="ShimmerTable-configration-table-TagList">
            {" "}
            <ShimmerTable row={1} col={1} />
          </TableCell>
          <TableCell className="ShimmerTable-configration-table-TagList">
            {" "}
            <ShimmerTable row={1} col={1} />
          </TableCell>
        </TableRow>
        )
      })}
      
     
      </>
    );
  };

  const TagListNotFound = () => {
    return (
      <>
        <TableRow>
          <TableCell colSpan={4} className="Tags-DataNotFound">
            Tags are not configured
          </TableCell>
        </TableRow>
      </>
    );
  };
  return (
    <>
      {topics.length ? (
        <Mqqtdata topics={topics} setMqttTagData={setMqttTagData} />
      ) : null}
      <div>{/*  */}</div>
      <div className="page-wraper">
        <div className="page-back-btn">
          <ArrowBackIcon onClick={() => handleDeviceDashboardNavigate()} />
          <span onClick={() => handleDeviceDashboardNavigate()}>
            {deviceData ? deviceData.device_name : ""}
          </span>
        </div>
        {/* <div
        >
          <ArrowBackIcon />
          <span style={{cursor:"pointer"}} onClick={() => navigate(`/VmsMachine/company/${param.companyId}/plant/${param.plantId}`)}>{deviceData ? deviceData.device_name : ""}</span>
        </div> */}
        <Typography
          gutterBottom
          style={{
            fontSize: "15px",
            marginBottom: "0 !important",
            marginTop: "5px",
          }}
        >
          {deviceData ? deviceData.device_description : ""}
        </Typography>

        <div className={`${machineTag ? "d-block" : "d-none"}`}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
              <Card className="submachine">
                {/* <div className="d-flex justify-content-end">
                  <img
                    src={images.information}
                    onClick={handleInformationModal}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                {informationModal && (
                  <div>
                    <Box className="information-card">
                      <div>
                        <h6>
                          <b>Title 1 </b> Lorem Ipsum is simply dummy text
                        </h6>
                        <h6>
                          <b>Title 1 </b> Lorem Ipsum is simply dummy text
                        </h6>
                        <h6>
                          <b>Title 1 </b> Lorem Ipsum is simply dummy text
                        </h6>
                        <h6>
                          <b>Title 1 </b> Lorem Ipsum is simply dummy text
                        </h6>
                      </div>
                    </Box>
                  </div>
                )} */}

                <div className="submachine-img">
                  {deviceUID ? (
                    <VmsARMachine
                      deviceUID={deviceUID}
                      navigateRoute={navigateRoute}
                      fullScreenModel={fullScreenModel}
                      setFullScreenModel={setFullScreenModel}
                      sendRealTimeDataModel={sendRealTimeDataModel}
                    />
                  ) : null}
                  {/* {deviceUID && !modelFound && modelUrlData ? <VmsARMachine deviceUID={deviceUID} navigateRoute={navigateRoute} modelUrlData={modelUrlData} /> : null}
                  <div className="device-image-modal"> 
                    {modelFound && <img src={images.noDataFoundImg} className="notFoundDataImg" />} 
                    </div> */}
                  {/* <img src={images.machine} className="machineimg" /> */}
                </div>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div className="view-machine">
                        <img
                          src={images.cubeIcon}
                          style={{ margin: "0 10px" }}
                          alt=""
                        />{" "}
                        View In Your Space
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div className="d-flex justify-content-end gap-2">
                        <img
                          src={images.crossIcon}
                          style={{ cursor: "pointer" }}
                          onClick={handleFullScrennModel}
                        />
                        <img src={images.plusIcon} />
                        <img src={images.MinusIcon} />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <div className="submachine-tag">
                <Table className="submachineTable">
                  <TableHead className="submachine-title">
                    <TableCell style={{ fontSize: "15px" }}>
                      <b>S.No</b>
                    </TableCell>
                    <TableCell style={{ fontSize: "15px" }}>
                      <b>Tag Name</b>
                    </TableCell>
                    <TableCell style={{ fontSize: "15px" }}>
                      <b>Value</b>
                    </TableCell>
                    <TableCell style={{ fontSize: "15px" }}>
                      <b>Unit</b>
                    </TableCell>
                    <TableCell style={{ fontSize: "15px" }}>
                      <b>Status</b>
                    </TableCell>
                  </TableHead>

                  <TableBody className="table-body-spacing">
                    {tagData.length
                      ? tagData.map((e, index) => {
                          const findTagData = tagMqttData.find(
                            (f) => f.device_tag == e.device_tag
                          );

                          // find status
                          const findStatus = tagStatus.length
                            ? tagStatus.find((f) => f.tagName == e.device_tag)
                            : 0;

                          return (
                            <>
                              <TableRow style={{ height: "12px" }}></TableRow>

                              <TableRow>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>
                                  {e.tag_name ? e.tag_name : "-"}
                                </TableCell>
                                <TableCell>
                                  {findTagData ? e?.signal_type ? ["AI", "AO"].includes(e.signal_type) ? findTagData.tagValue : findTagData.tagValue == "1" ? e.high_range : e.low_range : findTagData.tagValue : 0}
                                  {/* {findTagData ? findTagData.tagValue : 0} */}
                                </TableCell>
                                <TableCell>{e.tag_unit}</TableCell>
                                <TableCell style={{ color: "#F7C51E" }}>
                                  {findStatus ? (
                                    findStatus.statusValue.toFixed(2) < 0 ? (
                                      <SouthIcon />
                                    ) : findStatus.statusValue.toFixed(2) !=
                                      0 ? (
                                      <NorthIcon />
                                    ) : null
                                  ) : null}
                                  {findStatus
                                    ? findStatus.statusValue.toFixed(2)
                                    : 0.0}
                                  %
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })
                      : []}
                  </TableBody>
                </Table>
              </div>
            </Grid>
          </Grid>
        </div>
        {configureModel && (
          <>
           <div className="overlay123">
            <div className="deviceModal-popup">
              <div className="d-flex devices-text">
                <h6 className="mt-2">Devices</h6>
               
              </div>
              <Grid container spacing={2} className="pt-2">
                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <Box className="devicemodal-box">
                    <div className="configration-details">
                      {!isShimmerDeviceList ? (
                        <Box
                          className="Device-caption mb-2 Device-caption1"
                          // onClick={(row) => handelSelectDevice(row, e.id)}
                        >
                          <h6>{deviceData ? deviceData.device_name : ""}</h6>
                          <p>
                            UID - {deviceData ? deviceData.machine_uid : ""}
                          </p>
                          <p></p>
                        </Box>
                      ) : (
                        DeviceShimmer()
                      )}
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={7} lg={7}>
                <h6 className="device-tag-list">
                      Tag List
                    </h6>
                  <Box className="devicemodal-box1">
                    <div
                      className="device-table-tag"
                      style={{ height: "454px", overflow: "auto" }}
                    >
                      <Table className="subMachine-device-table">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{ background: "white" }}
                            ></TableCell>
                            <TableCell style={{ background: "white" }}>
                              Tag Name
                            </TableCell>
                            <TableCell style={{ background: "white" }}>
                              Tag Comment
                            </TableCell>
                            <TableCell
                              style={{ background: "white", width: "0%" }}
                            >
                              Unit
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="search-table-row1 table-back-color">
                              <Checkbox
                                style={{ color: "#1C407B" }}
                                checked={allSelectTag}
                                onClick={(event) =>
                                  handleAllTagSelect(event.target.checked)
                                }
                              />
                            </TableCell>
                            <TableCell className="search-table-row table-back-color">
                              <SearchIcon className="search-icon" />
                              <input
                                type="search"
                                placeholder="Tag Name"
                                name="Date"
                                value={searchTagName}
                                onChange={(e) =>
                                  setSearchTagName(e.target.value)
                                }
                              />
                            </TableCell>

                            <TableCell className="search-table-row table-back-color">
                              <SearchIcon className="search-icon" />
                              <input
                                type="search"
                                placeholder="Tag Comment"
                                name="Date"
                                value={searchTagComment}
                                onChange={(e) =>
                                  setSearchTagComment(e.target.value)
                                }
                              />
                            </TableCell>

                            <TableCell className="search-table-row table-back-color">
                              <SearchIcon className="search-icon" />
                              <input
                                type="search"
                                placeholder="Unit"
                                name="Date"
                                value={searchTagUnit}
                                onChange={(e) =>
                                  setSearchTagUnit(e.target.value)
                                }
                              />
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {!isShimmerDeviceTagList
                            ? tagListData
                              ? tagListData
                                  .filter(
                                    (e) =>
                                      e.tag_name &&
                                      e.tag_name
                                        .toLowerCase()
                                        .includes(searchTagName.toLowerCase())
                                  )
                                  .filter(
                                    (e) =>
                                      e.tag_comment &&
                                      e.tag_comment
                                        .toLowerCase()
                                        .includes(
                                          searchTagComment.toLowerCase()
                                        )
                                  )
                                  .filter(
                                    (e) =>
                                      e.tag_unit &&
                                      e.tag_unit
                                        .toLowerCase()
                                        .includes(searchTagUnit.toLowerCase())
                                  )
                                  .map((e) => {
                                    return (
                                      <TableRow>
                                        <TableCell style={{ padding: "0px" }}>
                                          <Checkbox
                                            style={{ color: "#1C407B" }}
                                            onClick={() =>
                                              handleSelectTag(e.id, e.tag_name)
                                            }
                                            checked={selectTagId.includes(e.id)}
                                          ></Checkbox>
                                        </TableCell>
                                        <TableCell>{e.tag_name}</TableCell>
                                        <TableCell>{e.tag_comment}</TableCell>
                                        <TableCell>{e.tag_unit}</TableCell>
                                      </TableRow>
                                    );
                                  })
                              : TagListNotFound()
                            : TagListShimmer()}
                        </TableBody>
                      </Table>
                    </div>
                  </Box>
                </Grid>
              </Grid>

              <div className="pt-3 d-flex selectTagDesc">
                <h6>Selected Tag: </h6>
                {selectedTagName.length
                  ? [...new Set(selectedTagName)].map((tag, index, array) => (
                      <React.Fragment key={tag}>
                        <p>{tag}</p>
                        {index < array.length - 1 && <p>,</p>}
                      </React.Fragment>
                    ))
                  : ""}
              </div>
              <div className="configration-details-btn">
                <button
                  className="canclebtn"
                  onClick={() =>
                    dispatch(setVmsData({ configureModel: !configureModel }))
                  }
                >
                  Cancel
                </button>
                <button
                  className="submitbtn"
                  onClick={() => createDeviceConfigApi()}
                >
                  Submit
                </button>
              </div>
            </div>
            </div>
          </>
        )}
        <div className="pt-4 d-flex gap-3">
          {(overViewAccess?.is_viewer && overViewAccess?.is_editor) ||
          (!overViewAccess?.is_viewer && overViewAccess?.is_editor) ||
          (overViewAccess?.is_viewer && !overViewAccess?.is_editor) ? (
            <div
              onClick={() => handleOverViewNavigate()}
              className="machine-btn"
              style={{ cursor: "pointer" }}
            >
              OVERVIEW
            </div>
          ) : (
            <div
              onClick={() => handleTostMsg()}
              className="machine-btn"
              style={{ cursor: "pointer" }}
            >
              OVERVIEW
            </div>
          )}
          {(analyticsReportAccess?.is_viewer &&
            analyticsReportAccess?.is_editor) ||
          (!analyticsReportAccess?.is_viewer &&
            analyticsReportAccess?.is_editor) ||
          (ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
          (!ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
          (ReportAccess?.is_viewer && !ReportAccess?.is_editor) ? (
            <div
              onClick={() => handleReportNavigate()}
              className="machine-btn"
              style={{ cursor: "pointer" }}
            >
              REPORTS
            </div>
          ) : (
            <div
              onClick={() => handleTostMsg()}
              className="machine-btn"
              style={{ cursor: "pointer" }}
            >
              REPORTS
            </div>
          )}

          {/* <div
            onClick={() => handleAdvanceAnalyticsNavigate()}
            className="machine-btn"
            style={{ cursor: "pointer" }}
          >
            ADVANCED ANALYTICS
          </div> */}
          {(analyticsAccess?.is_viewer && analyticsAccess?.is_editor) ||
          (!analyticsAccess?.is_viewer && analyticsAccess?.is_editor) ||
          (analyticsAccess?.is_viewer && !analyticsAccess?.is_editor) ? (
            <div
              onClick={() => handleAdvanceAnalyticsNavigate()}
              className="machine-btn"
              style={{ cursor: "pointer" }}
            >
              ADVANCED ANALYTICS
            </div>
          ) : (
            <div
              onClick={() => handleTostMsg()}
              className="machine-btn"
              style={{ cursor: "pointer" }}
            >
              ADVANCED ANALYTICS
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default SubMachine;
