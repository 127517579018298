import React, { useEffect, useState } from "react";
import { Box, TextField } from "@mui/material";
import { Grid } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Switch from "@mui/material/Switch";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import { useParams } from "react-router-dom";
import "../../Reports/Report.css";
import {
  analyticsReportExcel,
  anlyticsPopReportExcel,
  reportExcel,
} from "../service";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import DeviceListShimmer from "../../../../../../components/Shimmer/DeviceListShimmer/DeviceListShimmer";

const TagListComponent = ({
  deviceData,
  deviceUnitData,
  tagListData,
  activeTabClass,
  activeClass,
  handleSelectMachine,
  handleSelectTagUnit,
  handleSelectTag,
  VmsReportSelectmachine,
  VmsReportSelectTagUnit,
  VmsReportSelectTag,
  startDateFormatCOC,
  endDateFormatCOC,
  startTimeFormatCOC,
  endTimeFormatCOC,
  startDateFormatPOP2,
  endDateFormatPOP2,
  startTimeFormatPOP2,
  endTimeFormatPOP2,
  reportsIntervalTime1,
  time,
  access,
  handleTostMsg,
  isShimmerdevicelist
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [searchTag, setSearchTag] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const {
    reportsIntervalTime,
    selectTimeRangeCOC,
    selectTimeRangePOP,
    reportIntervalTimePop,
    vmsReportCOCRange,
    vmsReportPOPRange
  } = useSelector((state) => state.VmsReportSlice);

  console.log("selectTimeRangeCOCselectTimeRangeCOC", activeClass);

  const param = useParams();

  const notify = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  console.log(startDateFormatCOC);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setAnchorEl2(null);
  };

  const handleClickUnit = (event) => {
    event.stopPropagation();
    setAnchorEl2(anchorEl2 ? null : event.currentTarget);
    setAnchorEl(null);
  };

  const handleClosePaper = () => {
    setAnchorEl(null);
    setAnchorEl2(null);
  };

  const handlePropagation = (event) => {
    event.stopPropagation();
  };

  const openMachine = Boolean(anchorEl);
  const openUnit = Boolean(anchorEl2);

  useEffect(() => {
    setAnchorEl(null);
    setAnchorEl2(null);
  }, [activeTabClass, activeClass]);

  const switchColors = [
    "#FEBB22",
    "#002B2A",
    "#C99C00",
    "#EB9E79",
    "#69927A",
    "#872B81",
    "#F06292",
    "#CD4D4A",
    "#123782",
    "#544632",
    "#94655B",
    "#0F4C81",
    "#8B0000",
    "#C60BC8",
    "#7D2264",
    "#BC2F00",
    "#556830",
    "#FCA311",
    "#021116",
    "#808080",
    "#300338",
    "#634C23",
    "#841E62",
    "#F34424",
    "#003333",
    "#020000",
    "#305355",
    "#5C3A31",
    "#D9750C",
    "#0C4562",
    "#001A30",
    "#00838F",
    "#785049",
    "#FF8800",
    "#0B7C8E",
    "#08535E",
    "#0E3504",
    "#C48200",
    "#846748",
    "#075571",
    "#9C27B0",
    "#A97E07",
    "#CE286D",
    "#8409DF",
    "#0B648D",
    "#29445A",
    "#1D733F",
    "#6E02B1",
  ];

  const isCheckboxMachineDisabled = (id) => {
    return VmsReportSelectmachine
      ? VmsReportSelectmachine.length >= 4 &&
          !VmsReportSelectmachine.some((device) => device.machineId === id)
      : false;
  };

  const isCheckboxTagUnitDisabled = (tagUnit) => {
    return VmsReportSelectTagUnit
      ? VmsReportSelectTagUnit.length >= 1 &&
          !VmsReportSelectTagUnit.some((device) => device.tagUnit === tagUnit)
      : false;
  };

  const isCheckBoxTagDisabled = (activeMainTab, activesubTab, id) => {
    if (activeMainTab == "Analytics" && activesubTab == "Consumer") {
      return VmsReportSelectTag
        ? VmsReportSelectTag.length >= 4 &&
            !VmsReportSelectTag.some((device) => device.tagId == id)
        : false;
    }

    if (activeMainTab == "Analytics" && activesubTab == "Period") {
      return VmsReportSelectTag
        ? VmsReportSelectTag.length >= 1 &&
            !VmsReportSelectTag.some((device) => device.tagId == id)
        : false;
    }
    return false;
  };

  const handleExportEnergyData = async () => {
    if (activeClass == "Analytics") {
      if (activeTabClass == "Consumer") {
        const params = {
          company: param.companyId,
          plant: param.plantId,
          tag: VmsReportSelectTag?.map((e) => e.tagId),
          start_date: `${startDateFormatCOC} 00:00:00`,
          end_date: `${endDateFormatCOC} 23:59:59`,
          spike_range: vmsReportCOCRange,
          interval: Number(reportsIntervalTime),
        };
        if (time) {
          params["start_date"] = `${startDateFormatCOC} ${startTimeFormatCOC}`;
          params["end_date"] = `${endDateFormatCOC} ${endTimeFormatCOC}`;
        }

        try {
          const resp = await analyticsReportExcel(params);
          if (resp.status == 200 || resp.status == 201) {
            notify("Export Excel Successfully");
          }
        } catch (error) {
          console.log("handlesubmit", error);
        }
      } else if (activeTabClass == "Period") {
        const params = {
          company: param.companyId,
          plant: param.plantId,
          tag: VmsReportSelectTag?.map((e) => e.tagId),
          start_date: `${startDateFormatCOC} 00:00:00`,
          end_date: `${endDateFormatCOC} 23:59:59`,
          start_date_1: `${startDateFormatPOP2} 00:00:00`,
          end_date_1: `${endDateFormatPOP2} 23:59:59`,
          spike_range: vmsReportPOPRange,
          interval: Number(reportIntervalTimePop),
        };
        if (time) {
          params["start_date"] = `${startDateFormatCOC} ${startTimeFormatCOC}`;
          params["end_date"] = `${endDateFormatCOC} ${endTimeFormatCOC}`;
          params[
            "start_date_1"
          ] = `${startDateFormatPOP2} ${startTimeFormatPOP2}`;
          params["end_date_1"] = `${endDateFormatPOP2} ${endTimeFormatPOP2}`;
        }
        try {
          const resp = await anlyticsPopReportExcel(params);
          if (resp.status == 200 || resp.status == 201) {
            notify("Export Excel Successfully");
          }
        } catch (error) {
          console.log("handlesubmit", error);
        }
      }
    } else {
      const params = {
        company: param.companyId,
        plant: param.plantId,
        tag: VmsReportSelectTag?.map((e) => e.tagId),
        start_date: `${startDateFormatCOC} 00:00:00`,
        end_date: `${endDateFormatCOC} 23:59:59`,
        interval: Number(reportsIntervalTime1),
      };
      if (time) {
        params["start_date"] = `${startDateFormatCOC} ${startTimeFormatCOC}`;
        params["end_date"] = `${endDateFormatCOC} ${endTimeFormatCOC}`;
      }
      try {
        const resp = await reportExcel(params);
        if (resp.status == 200 || resp.status == 201) {
          notify("Export Excel Successfully");
        }
      } catch (error) {
        console.log("handlesubmit", error);
      }
    }
  };
  return (
    <>
      <Grid item xs={12} sm={12} md={3} onClick={handleClosePaper}>
        <Box
          className={
            activeClass === "Reports" ? "consumerData-boxes" : "vmsTagListBox"
          }
        >
          <Box className="consumer-text-disc">
            <h6 className="Title">Title</h6>
            <h6>Data Charts</h6>
          </Box>

          <Box>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "13px 8px",
                borderRadius: "4px",
                width: "100%",
                background: "#f5f5f5",
                cursor: "pointer",
                marginTop: "4px",
              }}
              onClick={handleClick}
            >
              Select Machine <ArrowDropDownIcon />
            </div>
          </Box>

          <Popper
            open={openMachine}
            anchorEl={anchorEl}
            placement="bottom-start"
            style={{ width: "23%", maxHeight: "300px", overflow: "auto" }}
            className="machine-dropdown"
          >
            {/* <Paper>
              {deviceData.length ? deviceData.map((row) => <MenuItem className="vms-coc-menuItem" onClick={handlePropagation}> {row.device__device_name} <Switch style={{ float: "right" }} checked={VmsReportSelectmachine ? VmsReportSelectmachine.find((f) => f.machineId == row.device) ? true : false : false} disabled={isCheckboxMachineDisabled(row.device)} onClick={() => handleSelectMachine(row)} /></MenuItem>) : <MenuItem className="vms-coc-menuItem" onClick={handlePropagation}>No Machine Found</MenuItem>}
            </Paper> */}
            <Paper>
              {deviceData.length ? (
                deviceData.map((row) => (
                  <MenuItem
                    className="vms-coc-menuItem"
                    onClick={handlePropagation}
                  >
                    {" "}
                    {row.device__device_name}{" "}
                    {(access?.is_viewer && access?.is_editor) ||
                    (!access?.is_viewer && access?.is_editor) || (access?.is_viewer && !access?.is_editor) ? (
                      <Switch
                        style={{ float: "right" }}
                        checked={
                          VmsReportSelectmachine
                            ? VmsReportSelectmachine.find(
                                (f) => f.machineId == row.device
                              )
                              ? true
                              : false
                            : false
                        }
                        onClick={() => handleSelectMachine(row)}
                      />
                    ) : (
                      <Switch
                        style={{ float: "right" }}
                        checked={
                          VmsReportSelectmachine
                            ? VmsReportSelectmachine.find(
                                (f) => f.machineId == row.device
                              )
                              ? true
                              : false
                            : false
                        }
                        onClick={handleTostMsg}
                      />
                    )}
                  </MenuItem>
                ))
              ) : (
                <MenuItem
                  className="vms-coc-menuItem"
                  onClick={handlePropagation}
                >
                  No Machine Found
                </MenuItem>
              )}
            </Paper>
          </Popper>

          <Box>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "13px 8px",
                borderRadius: "4px",
                width: "100%",
                background: "#f5f5f5",
                cursor: "pointer",
                marginTop: "4px",
              }}
              onClick={handleClickUnit}
            >
              Select Unit <ArrowDropDownIcon />
            </div>

            <Popper
              open={openUnit}
              anchorEl={anchorEl2}
              placement="bottom-start"
              style={{ width: "23%", maxHeight: "300px", overflow: "auto" }}
            >
              {/* <Paper>
                {deviceUnitData.length ? deviceUnitData.map((row) => <MenuItem className="vms-coc-menuItem" onClick={handlePropagation}>{row.tag_unit} <Switch style={{ float: "right" }} checked={VmsReportSelectTagUnit ? VmsReportSelectTagUnit.find((f) => f.tagUnit == row.tag_unit) ? true : false : false} disabled={isCheckboxTagUnitDisabled(row.tag_unit)} onClick={() => handleSelectTagUnit(row)}/></MenuItem>) : <MenuItem className="vms-coc-menuItem" onClick={handlePropagation}>No Unit Found</MenuItem>}
              </Paper> */}
              <Paper>
                { deviceUnitData.length ? (
                  deviceUnitData.map((row) => (
                    <MenuItem
                      className="vms-coc-menuItem"
                      onClick={handlePropagation}
                    >
                      {row.tag_unit}{" "}
                      {(access?.is_viewer && access?.is_editor) ||
                      (!access?.is_viewer && access?.is_editor)  || (access?.is_viewer && !access?.is_editor)? (
                        <Switch
                          style={{ float: "right" }}
                          checked={
                            VmsReportSelectTagUnit
                              ? VmsReportSelectTagUnit.find(
                                  (f) => f.tagUnit == row.tag_unit
                                )
                                ? true
                                : false
                              : false
                          }
                          onClick={() => handleSelectTagUnit(row)}
                        />
                      ) : (
                        <Switch
                          style={{ float: "right" }}
                          checked={
                            VmsReportSelectTagUnit
                              ? VmsReportSelectTagUnit.find(
                                  (f) => f.tagUnit == row.tag_unit
                                )
                                ? true
                                : false
                              : false
                          }
                          onClick={handleTostMsg}
                        />
                      )}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem
                    className="vms-coc-menuItem"
                    onClick={handlePropagation}
                  >
                    No Unit Found
                  </MenuItem>
                )}
              </Paper>
            </Popper>
          </Box>

          <div style={{ marginTop: "14px", fontWeight: "bold" }}>Tag List</div>

          <TextField
            variant="outlined"
            className="search-textfield"
            placeholder="Search Unit here"
            value={searchTag}
            onChange={(e) => setSearchTag(e.target.value)}
            InputProps={{
              startAdornment: <SearchIcon />,
              inputProps: {
                style: { padding: "9px" },
              },
            }}
          />

          <div
            className={
              activeClass == "Reports"
                ? "vms-report-taglist-unit"
                : "vms-report-taglistUnit"
            }
          >
            <Box className="Grid-text1 mt-2">
              {!isShimmerdevicelist ? tagListData
                .filter(
                  (e) =>
                    e.tag_name &&
                    e.tag_name.toLowerCase().includes(searchTag.toLowerCase())
                )
                .map((row, index) => (
                  <Grid item xs={12}>
                    {row.tag_unit && (
                      <Grid
                        container
                        alignItems="center"
                        style={{
                          background: "#efeffd",
                          width: "100%",
                          marginBottom: "7px",
                        }}
                      >
                        <Grid
                          item
                          xs={8}
                          className="mt-2"
                          style={{
                            padding: "7px 12px",
                            borderLeft: `4px solid ${
                              switchColors[index % switchColors.length]
                            }`,
                          }}
                        >
                          <h6>{row.tag_name}</h6>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          textAlign="right"
                          className="grid-switch-unit"
                        >
                          <Switch
                            checked={
                              VmsReportSelectTag
                                ? VmsReportSelectTag.find(
                                    (f) => f.tagId == row.id
                                  )
                                  ? true
                                  : false
                                : false
                            }
                            style={{ float: "right" }}
                            name={switchColors[index]}
                            // disabled={isCheckBoxTagDisabled(activeClass, activeTabClass, row.id)}
                            onClick={(e) => handleSelectTag(row, e.target.name)}
                            sx={{
                              "& .MuiSwitch-thumb": {
                                bgcolor: "#828282",
                              },
                              "& .MuiSwitch-track": {
                                bgcolor: "#D9D9E5 !important",
                              },
                              "& .Mui-checked": {
                                "& .MuiSwitch-thumb": {
                                  bgcolor:
                                    switchColors[index % switchColors.length],
                                },
                                "& .MuiSwitch-track": {
                                  bgcolor: "#CEE6E1 !important",
                                },
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                )):<DeviceListShimmer itemCount={7}/>}
            </Box>
          </div>

          <div className="mt-3">
            {(access?.is_viewer && access?.is_editor) ||
            (!access?.is_viewer && access?.is_editor) || (access?.is_viewer && !access?.is_editor) ? (
              <button
                className="export-btn"
                style={{ marginTop: "46px" }}
                onClick={handleExportEnergyData}
              >
                Export PredHik Data
              </button>
            ) : (
              <button
                className="export-btn"
                style={{ marginTop: "46px" }}
                onClick={handleTostMsg}
              >
                Export PredHik Data
              </button>
            )}
          </div>
        </Box>
      </Grid>
    </>
  );
};

export default TagListComponent;