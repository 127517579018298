import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";
import { images } from "../../../../config";
import { useSelector } from "react-redux";
import { ShimmerText } from "react-shimmer-effects";
import {ParetoChartShimmer} from "../../../../components/Shimmer/ParetoChartShimmer/ParetoChartShimmer";

HC_more(Highcharts);
exporting(Highcharts);

export function EnergyPOPChart({ energyCostPOPData, energyCostPOP1Data,emptyDevice,shimmer }) {
  const {user_country_currency_name} = useSelector((state)=>state.userState)
  const labels = [];

  if (
    energyCostPOPData[0]?.data?.length ||
    energyCostPOP1Data[0]?.data?.length
  ) {
    const popDataLength = energyCostPOPData[0]?.data?.length || 0;
    const pop1DataLength = energyCostPOP1Data[0]?.data?.length || 0;
    const maxLength = Math.max(popDataLength, pop1DataLength);

    for (let i = 0; i < maxLength; i++) {
      const popData = energyCostPOPData[0]?.data[i] || {};
      const pop1Data = energyCostPOP1Data[0]?.data[i] || {};
      const minKey = popData.MIN_KEY || "";
      const pop1MinKey = pop1Data.MIN_KEY || "";
      const labeledMinKey = `<span style="color: #C0255F;">${minKey}</span>`;
      const labeledPop1MinKey = `<span style="color: #E66595;">${pop1MinKey}</span>`;
      labels.push(`${labeledMinKey} ${labeledPop1MinKey}`);
    }
  }

  const data = [
    {
      name: energyCostPOPData.length ? energyCostPOPData[0].device_id.device_name : "",
      data: energyCostPOPData.length
        ? energyCostPOPData[0] && energyCostPOPData[0].data.length
          ? energyCostPOPData[0].data.map((row) => parseFloat(row.tag_value))
          : []
        : [],
      color: "#C0255F",
      stack: "Stack 0",
      barPercentage: 1,
      barThickness: 30,
    },

    {
      name: energyCostPOP1Data.length ? energyCostPOP1Data[0].device_id.device_name : "",
      data: energyCostPOP1Data.length
        ? energyCostPOP1Data[0] && energyCostPOP1Data[0].data.length
          ? energyCostPOP1Data[0].data.map((row) => parseFloat(row.tag_value))
          : []
        : [],
      color: "#E66595",
      stack: "Stack 1",
      barPercentage: 1,
      barThickness: 30,
    },
  ];
  const options = {
    chart: {
      type: "column", // Use 'column' type for vertical bar chart
      events: {
        load: function () {
          const xAxis = this.xAxis[0];
          const visibleCategories = labels.slice(-5); // Show the last 5 categories initially
          xAxis.setExtremes(
            labels.length - visibleCategories.length,
            labels.length - 1,
            false
          );
          this.redraw();
        },
      },
    },

    title: {
      text: null,
    },
    legend: {
      enabled: false, // Disable legend
    },
    xAxis: {
      categories: labels,
    },
    yAxis: {
      title: {
        text: user_country_currency_name, // Add your custom title here
      },
      stackLabels: {
        enabled: false,
        style: {
          fontWeight: "bold",
          color: "gray",
        },
      },
    },
    tooltip: {
      valueDecimals: 2, // Set the number of decimal places
    },

    series: data,
    scrollbar: {
      enabled: true, // Enable scrollbar
    },
    exporting: {
      enabled: true, // Disable exporting options
    },
    credits: {
      enabled: false, // Disable credits
    },
  };

  return (
    <div>
      <>
        {!shimmer ? (energyCostPOPData.length || energyCostPOP1Data.length) && emptyDevice ? (
          <HighchartsReact highcharts={Highcharts} options={options} />
        ) : (
          <img src={images.noDataFoundImg} className="notFoundDataImg" alt=""/>
        ) :<ParetoChartShimmer className="ParetoChartShimmer-OverView"/>  }
      </>
    </div>
  );
}


{/* <ShimmerText line={12} gap={15} /> */}