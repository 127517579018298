import React, { useEffect, useMemo, useState } from 'react';
import { cubejsApi } from "../../Cubejs/CubejsApiWrapper";
import Mqqtdata from '../Mqqtdata';
// import { GraphRemoveData } from './GraphRemoveData';
import { async } from 'q';
import { geTagAllTimeMinMax } from '../../VMS/VmsCompanyDashboard/VmsMachine/service';
import { useSelector } from 'react-redux';

const CubeQueryComponent = ({
    getDevice,
    handelCubeChartLineData,
    handelCubeChartLineData1,
    handelCubeChartLineData2,
    handelCubeChartLineData3,
    selectTimeRange,
    setChartLineData,
    chartLineData,
    setCombineChartData,
    setCombineminMax
}) => {

    const [selectTime, setSelectTime] = useState();
    const [topics, setTopics] = useState("");
    const [mqttTagData, setMqttTagData] = useState([]);
    const [minMaxvalue, setMinMaxvalue] = useState("")
    const [minMaxvalue1, setMinMaxvalue1] = useState("")
    const [minMaxvalue2, setMinMaxvalue2] = useState("")
    const [minMaxvalue3, setMinMaxvalue3] = useState("")

    const {
        selectUnit,
    } = useSelector((state) => state.dateSliceprocessDashboardState);

    useEffect(() => {
        if (getDevice.length) {
            const topicArray = getDevice.map((row) => 'Hikar/DeviceUID/' + row.device__machine__uid)
            setTopics(topicArray)
        }
    }, [getDevice])


    useEffect(() => {
        const fetchData = async () => {

            const param = {
                tag_id: getDevice.map((row) => row.id),
                unit: selectUnit
            }
            try {
                const resp = await geTagAllTimeMinMax(param);
                if (resp.status == 200 || resp.status == 201 && resp.data.payload.length) {
                    setCombineminMax(resp.data.payload)
                    console.log("resp.data.payload...CombineminMax", resp.data.payload)
                }
            } catch (error) {
                console.log(error)
            }
        };

        // imidiatlty function call
        fetchData();

        const intervalId = setInterval(fetchData, 15000); // Call fetchData every 15 seconds

        return () => {
            clearInterval(intervalId); // Clear interval on component unmount
        };
    }, [getDevice, selectUnit]);


    useEffect(() => {

        if (getDevice.length) {
            const mergedArray = [].concat(...Object.values(mqttTagData))

            const getTagTime = () => {
                return new Date().toLocaleTimeString('en-US', {
                    timeZone: 'Asia/Kolkata',
                    hour12: false,
                });
            };
            setCombineChartData((prev) => {

                const filteredArray = mergedArray.filter(obj => {
                    const deviceTag = obj.device_tag;
                    return getDevice[0].device_tag == deviceTag;
                }).length ? mergedArray.filter(obj => {
                    const device = obj.device__uid;
                    const deviceTag = obj.device_tag;
                    return getDevice[0].device_tag == deviceTag;
                }) : [prev[0][prev[0].length - 1] ? prev[0][prev[0].length - 1] : { tagValue: 0 }]


                const filteredArray2 = mergedArray.filter(obj => {
                    const device = obj.device__uid;
                    const deviceTag = obj.device_tag;
                    return getDevice[1]?.device_tag == deviceTag
                }).length ? mergedArray.filter(obj => {
                    const device = obj.device__uid;
                    const deviceTag = obj.device_tag;
                    return getDevice[1]?.device_tag == deviceTag
                }) : [prev[1][prev[1].length - 1] ? prev[1][prev[1].length - 1] : { tagValue: 0 }]

                const filteredArray3 = mergedArray.filter(obj => {
                    const device = obj.device__uid;
                    const deviceTag = obj.device_tag;
                    return getDevice[2]?.device_tag == deviceTag
                }).length ? mergedArray.filter(obj => {
                    const device = obj.device__uid;
                    const deviceTag = obj.device_tag;
                    return getDevice[2]?.device_tag == deviceTag
                }) : [prev[2][prev[2].length - 1] ? prev[2][prev[2].length - 1] : { tagValue: 0 }]

                const filteredArray4 = mergedArray.filter(obj => {
                    const device = obj.device__uid;
                    const deviceTag = obj.device_tag;
                    return getDevice[3]?.device_tag == deviceTag
                }).length ? mergedArray.filter(obj => {
                    const device = obj.device__uid;
                    const deviceTag = obj.device_tag;
                    return getDevice[3]?.device_tag == deviceTag
                }) : [prev[3][prev[3].length - 1] ? prev[3][prev[3].length - 1] : { tagValue: 0 }]

                // Create a shallow copy of the previous state
                const zeroIndx = [...prev];

                // Create a shallow copy of the inner array you want to modify (assuming it's an array)
                let innerArrayCopy = [...zeroIndx[0]];
                let innerArrayCopy1 = [...zeroIndx[1]];
                let innerArrayCopy2 = [...zeroIndx[2]];
                let innerArrayCopy3 = [...zeroIndx[3]];

                // Push the new object into the inner array copy

                // Push the new object into the inner array copy
                if (getDevice[0]) {
                    if (innerArrayCopy[innerArrayCopy.length - 1]?.tagTime != getTagTime()) {
                        innerArrayCopy.push({ ...filteredArray[0], tagTime: getTagTime() });
                        handelCubeChartLineData({ ...filteredArray[0], tagTime: getTagTime() }, minMaxvalue)
                    }

                }

                if (getDevice[1]) {
                    if (innerArrayCopy1[innerArrayCopy1.length - 1]?.tagTime != getTagTime()) {
                        innerArrayCopy1.push({ ...filteredArray2[0], tagTime: getTagTime() });
                        handelCubeChartLineData1({ ...filteredArray2[0], tagTime: getTagTime() }, minMaxvalue1)
                    }

                }

                if (getDevice[2]) {
                    if (innerArrayCopy2[innerArrayCopy2.length - 1]?.tagTime != getTagTime()) {
                        innerArrayCopy2.push({ ...filteredArray3[0], tagTime: getTagTime() });
                        handelCubeChartLineData2({ ...filteredArray3[0], tagTime: getTagTime() }, minMaxvalue2)
                    }

                }

                if (getDevice[3]) {
                    if (innerArrayCopy3[innerArrayCopy3.length - 1]?.tagTime != getTagTime()) {
                        innerArrayCopy3.push({ ...filteredArray4[0], tagTime: getTagTime() });
                        handelCubeChartLineData3({ ...filteredArray4[0], tagTime: getTagTime() }, minMaxvalue3)
                    }
                }

                //==========================new add code==============================
                if (innerArrayCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
                    innerArrayCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
                }

                if (innerArrayCopy1.length >= process.env.REACT_APP_GRAPH_LENGTH) {
                    innerArrayCopy1.shift(); // Remove oldest entry if exceeds MAX_LENGTH
                }

                if (innerArrayCopy2.length >= process.env.REACT_APP_GRAPH_LENGTH) {
                    innerArrayCopy2.shift(); // Remove oldest entry if exceeds MAX_LENGTH
                }

                if (innerArrayCopy3.length >= process.env.REACT_APP_GRAPH_LENGTH) {
                    innerArrayCopy3.shift(); // Remove oldest entry if exceeds MAX_LENGTH
                }


                zeroIndx[0] = innerArrayCopy;
                zeroIndx[1] = innerArrayCopy1;
                zeroIndx[2] = innerArrayCopy2;
                zeroIndx[3] = innerArrayCopy3;


                return zeroIndx;

            });

            // setMqttTagData([])   
        }
    }, [mqttTagData])




    return (
        <>
            {selectTimeRange == "live" && topics && <Mqqtdata overViewTagData={getDevice} topics={topics} setMqttTagData={setMqttTagData} />}
        </>
    )
};

export default CubeQueryComponent;