import React from "react";
import { Box, TextField } from "@mui/material";
import { Grid } from "@material-ui/core";
import { images } from "../../../../../../config";
import Switch from "@mui/material/Switch";
import ParetoGraph from "../../../../../../components/Graph/ParetoGraph/ParetoGraph";
import LineChart from "../../../../../../components/Graph/LineChart/LineChart";
import BarGraph from "../../../../../../components/Graph/BarGraph/BarGraph";
import { useState } from "react";
import LineChart2 from "../../../../../../components/Graph/LineChart/LineChart2";
import { useDispatch, useSelector } from "react-redux";
import { setVmsReportData } from "../Slice/VmsReportSlice";


const GraphComponent = ({
  activeTabClass,
  LineGraphData,
  Graphdata,
  tagID,
  tagSelectPOP,
  VmsReportSelectmachine,
  VmsReportSelectmachine1,
  averageArray,
  averagePOP,
  BarGraphData1,
  BarGraphData2,
  LineChartdata,
  LineChartdata2,
  isShimmerCOC,
  isShimmerPOP,
  isShimmerLineChartCOC,
  isShimmerLineChartPOP
}) => {
  const [averageCOCActive, setAverageCOCActive] = useState(false);
  const [averagePOPActive, setAveragePOPActive] = useState(false);

  const { reportsIntervalTime, reportIntervalTimePop } = useSelector(
    (state) => state.VmsReportSlice
  );

  const dispatch = useDispatch();
  return (
    <>
      <Grid item xs={12} sm={12} md={9} className="consumption-table-box">
        <Box className="consumerData-box">
          <div style={{ minHeight: "322px" }}>
            <div className="d-flex justify-content-between">
              <h6>
                {activeTabClass == "Consumer" ? (
                  <b>Pareto Chart - Consumer over Consumer No. of Spikes</b>
                ) : (
                  <b>Bar Chart - Period over Period No. of Spikes</b>
                )}
              </h6>
            </div>
           

              {activeTabClass == "Consumer" ? (
                <ParetoGraph Graphdata={Graphdata} tagID={tagID} shimmer ={isShimmerCOC}/>
              ) : (
                <BarGraph
                  BarGraphData1={BarGraphData1}
                  BarGraphData2={BarGraphData2}
                  VmsReportSelectmachine={tagSelectPOP}
                  shimmer={isShimmerPOP}
                />
              )}
           
          </div>
          <div className="mt-2">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h6>
                {activeTabClass == "Consumer" ? (
                  <b>Line Chart - Consumer over Consumer Trend</b>
                ) : (
                  <b>Line Chart - Period over Period Trend</b>
                )}
              </h6>
              <div style={{ display: "flex", alignItems: "center" }}>
                {activeTabClass == "Consumer" ? (
                  <>
                    <h6 className="AGG-dropdown">Interval</h6>
                    <Box sx={{ minWidth: 200 }} style={{ float: "right" }}>
                      <select
                        className="form-input-class"
                        style={{
                          padding: "14px 8px",
                          borderRadius: "4px",
                          marginRight: "20px",
                        }}
                        id="selectedClass"
                        value={reportsIntervalTime}
                        onChange={(e) =>
                          dispatch(
                            setVmsReportData({
                              reportsIntervalTime: e.target.value,
                            })
                          )
                        }
                        required
                      >
                        <option value="1" selected disabled hidden>
                          1 min
                        </option>
                        <option value="1">1 Min</option>
                        <option value="3">3 Min</option>
                        <option value="5">5 Min</option>
                        <option value="15">15 Min</option>
                      </select>
                    </Box>
                    <span
                      style={{
                        color: "#001323",
                        fontWeight: "500",
                        marginRight: "10px",
                      }}
                    >
                      Average
                    </span>
                    <Switch
                      checked={averageCOCActive}
                      onClick={() => setAverageCOCActive((prev) => !prev)}
                      // Add your switch event handling logic here
                      sx={{
                        "& .MuiSwitch-thumb": {
                          bgcolor: "#828282",
                        },
                        "& .MuiSwitch-track": {
                          bgcolor: "#D9D9E5 !important",
                        },
                        "& .Mui-checked": {
                          "& .MuiSwitch-thumb": {
                            bgcolor: "#50C878",
                          },
                          "& .MuiSwitch-track": {
                            bgcolor: "#CEE6E1 !important",
                          },
                        },
                      }}
                    />
                  </>
                ) : (
                  <>
                    <h6 className="AGG-dropdown">Interval</h6>
                    <Box sx={{ minWidth: 200 }} style={{ float: "right" }}>
                      <select
                        className="form-input-class"
                        style={{
                          padding: "14px 8px",
                          borderRadius: "4px",
                          marginRight: "20px",
                        }}
                        id="selectedClass"
                        value={reportIntervalTimePop}
                        onChange={(e) =>
                          dispatch(
                            setVmsReportData({
                              reportIntervalTimePop: e.target.value,
                            })
                          )
                        }
                        required
                      >
                        <option value="1" selected disabled hidden>
                          1 min
                        </option>
                        <option value="1">1 Min</option>
                        <option value="3">3 Min</option>
                        <option value="5">5 Min</option>
                        <option value="15">15 Min</option>
                      </select>
                    </Box>
                    <span
                      style={{
                        color: "#001323",
                        fontWeight: "500",
                        marginRight: "10px",
                      }}
                    >
                      Average
                    </span>
                    <Switch
                      checked={averagePOPActive}
                      onClick={() => setAveragePOPActive((prev) => !prev)}
                      // Add your switch event handling logic here
                      sx={{
                        "& .MuiSwitch-thumb": {
                          bgcolor: "#828282",
                        },
                        "& .MuiSwitch-track": {
                          bgcolor: "#D9D9E5 !important",
                        },
                        "& .Mui-checked": {
                          "& .MuiSwitch-thumb": {
                            bgcolor: "#50C878",
                          },
                          "& .MuiSwitch-track": {
                            bgcolor: "#CEE6E1 !important",
                          },
                        },
                      }}
                    />
                  </>
                )}
              </div>
            </div>

           
            
              {activeTabClass == "Consumer" ? (
                <LineChart
                  LineGraphData={LineGraphData}
                  tagID={tagID}
                  averageArray={averageArray}
                  averageActive={averageCOCActive}
                  shimmer={isShimmerLineChartCOC}
                />
              ) : (
                <LineChart2
                  LineChartdata={LineChartdata}
                  LineChartdata2={LineChartdata2}
                  averagePOP={averagePOP}
                  averageActive={averagePOPActive}
                  VmsReportSelectmachine={tagSelectPOP}
                  shimmer={isShimmerLineChartPOP}
                />
              )}
              {activeTabClass == "Period" ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                    gap: "25px",
                    marginLeft: "44px",
                    height: "38px",
                  }}
                >
                  {VmsReportSelectmachine && VmsReportSelectmachine.length
                    ? VmsReportSelectmachine.map((row, index) => {
                        return (
                          <>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <span
                                style={{ color: "#001323", fontWeight: "500" }}
                              >
                                {row.dvName}
                              </span>
                              <Switch
                                checked={true}
                                style={{ pointerEvents: "none" }}
                                // Add your switch event handling logic here
                                sx={{
                                  "& .MuiSwitch-thumb": {
                                    bgcolor: "#828282",
                                  },
                                  "& .MuiSwitch-track": {
                                    bgcolor: "#D9D9E5 !important",
                                  },
                                  "& .Mui-checked": {
                                    "& .MuiSwitch-thumb": {
                                      bgcolor: "#50C878",
                                    },
                                    "& .MuiSwitch-track": {
                                      bgcolor: "#CEE6E1 !important",
                                    },
                                  },
                                }}
                              />
                            </div>
                          </>
                        );
                      })
                    : null}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                    gap: "25px",
                    marginLeft: "44px",
                    height: "38px",
                  }}
                >
                  {VmsReportSelectmachine1 && VmsReportSelectmachine1.length
                    ? VmsReportSelectmachine1.map((row, index) => {
                        return (
                          <>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <span
                                style={{ color: "#001323", fontWeight: "500" }}
                              >
                                {row.dvName}
                              </span>
                              <Switch
                                checked={true}
                                style={{ pointerEvents: "none" }}
                                // Add your switch event handling logic here
                                sx={{
                                  "& .MuiSwitch-thumb": {
                                    bgcolor: "#828282",
                                  },
                                  "& .MuiSwitch-track": {
                                    bgcolor: "#D9D9E5 !important",
                                  },
                                  "& .Mui-checked": {
                                    "& .MuiSwitch-thumb": {
                                      bgcolor: "#50C878",
                                    },
                                    "& .MuiSwitch-track": {
                                      bgcolor: "#CEE6E1 !important",
                                    },
                                  },
                                }}
                              />
                            </div>
                          </>
                        );
                      })
                    : null}
                </div>
              )}
          
          </div>
        </Box>
      </Grid>
    </>
  );
};

export default GraphComponent;
