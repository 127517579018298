import React from "react";
import Highcharts from "highcharts/highstock";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";
import { images } from "../../../../config";
import { useSelector } from "react-redux";
import { ShimmerText } from "react-shimmer-effects";
import {ParetoChartShimmer} from "../../../../components/Shimmer/ParetoChartShimmer/ParetoChartShimmer";

HC_more(Highcharts);
exporting(Highcharts);
HighchartsExporting(Highcharts);

export function EnergyCostCOC({
  energyCostDeviceColors,
  analyticsOverviewData,
  emptyDevice,
  shimmer,
}) {
  const { user_country_currency_name } = useSelector(
    (state) => state.userState
  );

  const labels = analyticsOverviewData.length
    ? analyticsOverviewData[0] &&
      analyticsOverviewData[0].data.map((e) => e.MIN_KEY)
    : [];
  const data = analyticsOverviewData.length
    ? analyticsOverviewData.map((row, index) => {
        const findColor = energyCostDeviceColors.length
          ? energyCostDeviceColors.find((f) => f.deviceId == row.device_id.id)
          : "";

        return {
          name: row.device_id.device_name,
          data: row.data.length
            ? row.data.map((row2) => parseFloat(row2.tag_value))
            : [],
          color: findColor ? findColor.color : "",
        };
      })
    : [];
  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: null,
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      categories: labels,
    },
    yAxis: {
      title: {
        text: user_country_currency_name, // Add your custom title here
      },
      stackLabels: {
        enabled: false,
        style: {
          fontWeight: "bold",
          color: "gray",
        },
      },
    },
    plotOptions: {
      series: {
        stacking: "normal",
        dataLabels: {
          enabled: false,
        },
      },
    },
    tooltip: {
      valueDecimals: 2, // Set the number of decimal places
    },
    series: data,
    scrollbar: {
      enabled: true,
    },
    exporting: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <>
      {!shimmer ? (
        analyticsOverviewData.length && emptyDevice ? (
          <HighchartsReact highcharts={Highcharts} options={options} />
        ) : (
          <img src={images.noDataFoundImg} className="notFoundDataImg" alt="" />
        )
      ) : (
        <ParetoChartShimmer  className="ParetoChartShimmer-OverView"/> 
      )}
    </>
  );
}


{/* <ShimmerText line={12} gap={15} /> */}