import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { images } from "../../config/images";
// import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
// import Box from '@mui/material/Box';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "./Hikemmpopup.css";
import { useDispatch, useSelector } from "react-redux";
import { setUserDetails } from "../Login/slice/userSlice";
import { getProductDevice } from "../CompanyDashboard/services";

const HikemPopUP = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [productAvailability, setProductAvailability] = useState({
    hikemm: false,
    predhik: false,
    smarttag: false,
  });

  console.log("productAvailability", productAvailability);
  const { redirectHikPred ,mobileNoRe } = useSelector((state) => state.userState);


  useEffect(() =>{ 
    if (mobileNoRe == null) {
      navigate("/userprofile");
    }
  }, [mobileNoRe]);

  useEffect(() => {
    handleProductDevice();
  }, []);

  const handleProductDevice = async () => {
    const resp = await getProductDevice();
    if (resp.status === 200 || resp.status === 201) {
      setProductAvailability(resp.data.payload);
    }
  };

  const subscriptionPlan = [
    {
      id: 1,
      dashboard_id: 1,
      name: "HikEMM",
      license_dashboard: "Device_licenses",
      license_module: "HikEMM",
      module_id: 1,
      is_subscriptable: true,
      background_image: images.subscriptionbg,
      image: images.speedometerHikemm,
      price_tag: "Hikar EMS Services",
      plan_detail: [
        {
          id: 1,
          description:
            "Smart Energy Monitoring: Real time insights into your energy usage.",
        },
        {
          id: 2,
          description:
            "Maximize Savings: Empowers your business to identify energy inefficiencies.",
        },
        {
          id: 3,
          description:
            "Boost Sustainability: Actively contribute to a greener future.",
        },
      ],
    },
    {
      id: 2,
      dashboard_id: 2,
      name: "PredHik",
      license_dashboard: "Device_licenses",
      license_module: "PredHik",
      module_id: 2,
      is_subscriptable: true,
      background_image: images.subscriptionbg,
      image: images.vmsSpeedometer,
      price_tag: "Hikar VMS Services",
      plan_detail: [
        {
          id: 1,
          description:
            "Predictive Maintenance Made Easy: Insights on equipment health & prevents costly downtime.",
        },
        {
          id: 2,
          description:
            "Optimize Performance: Maximize the efficiency of assets with the real-time insights",
        },
        {
          id: 3,
          description:
            "Extend Equipment Lifespan: Optimize maintenance schedules with data driven approach.",
        },
      ],
    },
    {
      id: 3,
      dashboard_id: 3,
      name: "Smart Tag",
      license_dashboard: "Tag_licenses",
      license_module: "SmartTag",
      module_id: 3,
      is_subscriptable: true,
      background_image: images.subscriptionbg,
      image: images.SmartTaglogo,
      price_tag: "Hikar SmarTag Services",
      plan_detail: [
        {
          id: 1,
          description:
            "Comprehensive Reporting: Delivering in-depth analytics and visualizations.",
        },
        {
          id: 2,
          description:
            "AI-Powered Dashboards: AI/ML analytics, optimized operations, efficiency gains, & proactive decision-making.",
        },
        {
          id: 3,
          description:
            "Effortless Integration: intuitive dashboard is customizable, user friendly experience.",
        },
      ],
    },
  ];

  const handleCardClick = (e) => {
    if (e.is_subscriptable) {
      if (e.license_module == "HikEMM") {
        dispatch(setUserDetails({ redirectHikPred: "hikemm", dashboard: 1 }));

        navigate("/dashboard");
      }
      if (e.license_module == "PredHik") {
        dispatch(setUserDetails({ redirectHikPred: "Vms", dashboard: 2 }));
        navigate("/vms");
      }
      if (e.license_module == "SmartTag") {
        dispatch(setUserDetails({ redirectHikPred: "Smarttag", dashboard: 3 }));
        navigate("/Smarttag-Dashboard");
      }
    }
  };

  return (
    <>
      <div className="containerWrap pt-5">
        <Container maxWidth="xxl">
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }}
            columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 1, xl: 2, xxl: 2 }}
            style={{ marginBottom: "25px", justifyContent: "center" }}
          >
            {subscriptionPlan.map((e) => {
              if (
                (e.name === "HikEMM" && productAvailability.hikemm) ||
                (e.name === "PredHik" && productAvailability.predhik) ||
                (e.name === "Smart Tag" && productAvailability.smarttag)
              ) {
                return (
                  <div className="popup" key={e.name}>
                    <div
                      className="d-flex popup-flex"
                      style={{
                        justifyContent: "center",
                        gap: "20px",
                        flexWrap: "wrap",
                      }}
                    >
                      <Card
                        className="subscriptionCard"
                        onClick={() => handleCardClick(e)}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={e.background_image}
                          alt="avv"
                          className="subscriptionCard-Image"
                        />
                        <div className="imageWrap"></div>
                        <img
                          src={e.image}
                          style={{ width: "14%" }}
                          alt="avv"
                          className="subscriptionCard-Icon"
                        />
                        <Stack className="subscriptionContent">
                          <Typography
                            gutterBottom
                            variant="h5"
                            className="subscription-title-name"
                          >
                            {e.name}
                          </Typography>
                          <Typography
                            gutterBottom
                            variant="h5"
                            className="subscription-text"
                          >
                            {e.price_tag}
                          </Typography>
                          <List
                            sx={{
                              width: "100%",
                              maxWidth: 360,
                              bgcolor: "background.paper",
                            }}
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              marginBottom: "26px",
                            }}
                          >
                            {e.plan_detail.map((e1) => (
                              <ListItem key={e1.id}>
                                <ListItemAvatar>
                                  <Avatar className="checkIconbg">
                                    <CheckRoundedIcon className="checkIcon" />
                                  </Avatar>
                                </ListItemAvatar>
                                <Typography
                                  gutterBottom
                                  variant="body2"
                                  className="subscription-details"
                                >
                                  {e1.description}
                                </Typography>
                              </ListItem>
                            ))}
                          </List>
                        </Stack>
                      </Card>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </Grid>
        </Container>
      </div>
    </>
  );
};
export default HikemPopUP;
