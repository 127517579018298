import { createSlice } from "@reduxjs/toolkit";

const initialState = {

    selectCompany : "",
    selectPlant: "",
    selectCompanyReport: "",
    selectPlantReport: ""
}

const companySlice = createSlice({
    name: "superadmin",
    initialState,
    reducers: {
        setCompanyDetails(state, { payload }) {
            return { ...state, ...payload };
        },
        setPlantDetails(state, { payload }) {
            return { ...state, ...payload };
        },
      
        clearCompanyDetails(state) {
            return initialState;
        },
        clearPlantDetails(state){
            return initialState;
        }
     
    },
});

export const {
    setCompanyDetails,
    setPlantDetails,
    clearCompanyDetails,
} = companySlice.actions;

export default companySlice.reducer;

