import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import "../Alarm/Alarm.css";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SearchIcon from "@mui/icons-material/Search";
import { images } from "../../../config/images";
import {
  Box,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  TablePagination,
  TextField,
} from "@mui/material";
import Switch from "react-switch";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Card,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import {
  addAlarmNote,
  alarmAllAction,
  alarmwindowExcel,
  getAlarm,
} from "./services";
import { alarmAction } from "./services";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
    maxHeight: 400,
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: "#fff",
    fontSize: 16,
  },
  tableContainer: {
    maxHeight: 700,
  },
}));

const Alarm = () => {
  const [alarm, setAlarm] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState("none");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [alarmData, setAlarmData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedAlarms, setSelectedAlarms] = useState([]);
  const [alarmModal, setAlarmModal] = useState({});
  const [countAlarmData, setCountAlarmData] = useState(0);
  const [showTime, setShowTime] = useState(false);
  const [alarmState, setAlarmState] = useState("");
  const [priority, setPriority] = useState("");
  // const[isAction, setAction] = useState("");
  // const[isNoted,setNoted] = useState("")

  const handleOpenModal = (i) => {
    // Set the `alarmModal` state with the index `i`
    setAlarmModal({ [i]: true });
  };

  const { dashboard } = useSelector((state) => state.userState);

  const [showTextarea, setShowTextarea] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const data = [
    { label: "Priority", value: "12345.67" },
    { label: "Date", value: "12345.67" },
    { label: "Time", value: "12345.67" },
    { label: "Tag", value: "12345.67" },
    { label: "Alarm Message", value: "12345.67" },
    { label: "State", value: "12345.67" },
    { label: "Action", value: "12345.67" },
    { label: "Note", value: "12345.67" },
    { label: "kVAH", value: "12345.67" },
  ];

  const [openAlarmId, setOpenAlarmId] = useState(null);

  const toggleShowAll = (id) => {
    setOpenAlarmId(openAlarmId === id ? null : id);
  };

  const initiallValue = {
    date: "",
    time: "",
    tag: "",
    alarm_msg: "",
    state: "",
    priority: "",
    is_action: false,
    is_noted: false,
  };
  const MAX_DATE_RANGE_DAYS = 365;

  const { redirectHikPred } = useSelector((state) => state.userState);

  const [searchalarm, setSearchAlarm] = useState(initiallValue);
  const [note, setNote] = useState("");

  const dateFormat = "YYYY-MM-DD";

  const timeFormat = "HH:mm:ss";

  const startDateFormat = startDate ? dayjs(startDate).format(dateFormat) : "";

  const endDateFormat = endDate ? dayjs(endDate).format(dateFormat) : "";

  const timeStartDateFormat = startDate
    ? dayjs(startDate).format(timeFormat)
    : "";

  const timeEndDateFormat = endDate ? dayjs(endDate).format(timeFormat) : "";

  console.log("startDatestartDate", startDateFormat);

  const { headerName, headerImageSrc } = useSelector(
    (state) => state.headerState
  );

  const handleToggleTime = () => {
    setShowTime(!showTime);
  };
  const classes = useStyles();
  const navigate = useNavigate();

  const notify = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleChangeSearch = (e) => {
    if (e.target.name == "is_action" || e.target.name == "is_noted") {
      setSearchAlarm({ ...searchalarm, [e.target.name]: e.target.checked });
      return true;
    }

    setSearchAlarm({ ...searchalarm, [e.target.name]: e.target.value });
  };

  const handleChangeSearch1 = (name, value) => {
    if (name === "is_action" || name === "is_noted") {
      setSearchAlarm({ ...searchalarm, [name]: value });
    } else {
      setSearchAlarm({ ...searchalarm, [name]: value });
    }
  };

  const handleAlarmClick = () => {
    setAlarm(true);
  };

  const handleClick = () => {
    setAlarm(false);
  };
  const param = useParams();

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const handleAllCheckboxClick = async () => {
    // const allRowIds = alarmData?.results.length
    //   ? alarmData?.results.map((e) => e.id)
    //   : [];
    // setSelectedAlarms(allRowIds);

    const params = {
      plant_id: param.id,
      company_id: param.companyId,
      dashboard: dashboard,
    };
    if (startDate && endDate) {
      params["start_date"] = `${startDateFormat} 00:00:00`;
      params["end_date"] = `${endDateFormat} 23:59:59`;
    }
    if (showTime) {
      params["start_date"] = `${startDateFormat} ${timeStartDateFormat}`;
      params["end_date"] = `${endDateFormat} ${timeEndDateFormat}`;
    }
    if (searchalarm.is_action) {
      params["is_action"] = true;
    }
    if (searchalarm.is_noted) {
      params["is_noted"] = true;
    }
    if (priority) {
      params["priority"] = priority;
    }
    if (alarmState) {
      params["state"] = alarmState;
    }
    if (searchalarm.date) {
      params["created_at"] = searchalarm.date;
    }
    if (searchalarm.time) {
      params["created_time"] = searchalarm.time;
    }
    if (searchalarm.tag) {
      params["tag"] = searchalarm.tag;
    }
    if (searchalarm.alarm_msg) {
      params["alarm_message"] = searchalarm.alarm_msg;
    }
    try {
      const resp = await alarmAllAction(params);
      if (resp.status == 200 || resp.status == 201) {
        setAlarm(false);
        handlegetAlarm();
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };
  const handleCheckboxClick = (event, alarmId) => {
    if (event.target.checked) {
      // Add the alarm ID to the selectedAlarms array
      setSelectedAlarms([...selectedAlarms, alarmId]);
    } else {
      // Remove the alarm ID from the selectedAlarms array
      setSelectedAlarms(selectedAlarms.filter((id) => id !== alarmId));
    }
  };

  const handleAlarmNote = async (id) => {
    const params = {
      alarm_id: id,
      note: note,
    };
    try {
      const resp = await addAlarmNote(params);
      if (resp.status == 200 || resp.status == 201) {
        setAlarmModal(false);
        handlegetAlarm();
        console.log("alarm note successfully");
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };
  useEffect(() => {
    handlegetAlarm();
  }, [
    searchalarm,
    startDate,
    endDate,
    page,
    rowsPerPage,
    alarmState,
    priority,
  ]);

  const handlegetAlarm = async () => {
    const params = {
      plant_id: param.id,
      company_id: param.companyId,
      dashboard: dashboard,
    };

    if (startDate && endDate) {
      params["start_date"] = `${startDateFormat} 00:00:00`;
      params["end_date"] = `${endDateFormat} 23:59:59`;
    }
    if (showTime) {
      params["start_date"] = `${startDateFormat} ${timeStartDateFormat}`;
      params["end_date"] = `${endDateFormat} ${timeEndDateFormat}`;
    }
    if (searchalarm.is_action) {
      params["is_action"] = true;
    }
    if (searchalarm.is_noted) {
      params["is_noted"] = true;
    }
    if (priority) {
      params["priority"] = priority;
    }

    try {
      const resp = await getAlarm(
        params,
        searchalarm,
        page,
        rowsPerPage,
        alarmState
      );
      if (resp.status == 200 || resp.status == 201) {
        setAlarmData(resp.data.payload);
        const alarm_actionId = resp.data.payload
          ? resp.data.payload.results.length
            ? resp.data.payload.results
              .map((row) => {
                if (row.is_action) {
                  return row.id;
                }
              })
              .filter((f) => f != undefined)
            : []
          : [];
        console.log("alarm_actionId", alarm_actionId);
        setSelectedAlarms(alarm_actionId);
        setCountAlarmData(resp.data.payload.count);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    if (selectedAlarms.length) {
      handleAlarmAction();
    }
  }, [selectedAlarms]);

  const handleAlarmAction = async () => {
    const paramRequest = {
      alarm_id: selectedAlarms,
    };
    const resp = await alarmAction(paramRequest);

    if (resp.status == 200 || resp.status == 201) {
      // notify("Sucessfully action Create")
      console.log("Sucessfully action Create");
      setAlarm(false);
    }
  };

  const handleImageClick = (row) => {
    setSelectedRow(row);
    setShowTextarea(true);
  };

  const handlebackAlarmClick = () => {
    if (redirectHikPred) {
      navigate(-1);
      // navigate(`/vmsmachine/company/${param.companyId}/plant/${param.id}`);
      return true;
    }
    // navigate(
    //   `/company/${param.companyId}/plant/plantprocessdashboard/${param.id}`
    // );
    navigate(-1);
  };

  const handleAlarmClickSubmit = async () => {
    if (startDateFormat === "" && endDateFormat === "") {
      notifyError("Please fill up start date and end date");
      return;
    }

    const params = {
      dashboard: dashboard,
      plant_id: param.id,
      company_id: param.companyId,
      start_date: `${startDateFormat} 00:00:00`,
      end_date: `${endDateFormat} 23:59:59`,
    };

    if (showTime) {
      params["start_date"] = `${startDateFormat} ${timeStartDateFormat}`;
      params["end_date"] = `${endDateFormat} ${timeEndDateFormat}`;
    }
    try {
      const resp = await alarmwindowExcel(params);
      if (resp.status == 200 || resp.status == 201) {
        notify("Export Excel Successfully");
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  const handleStartDate = (newValue) => {
    const durationInDays = endDate
      ? dayjs(endDate).diff(newValue, "days") > 0
        ? dayjs(endDate).diff(newValue, "days")
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      setStartDate(null);
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDate && dayjs(newValue).isBefore(endDate)) {
      setStartDate(newValue);
    } else if (!endDate) {
      setStartDate(newValue);
    } else if (endDate && dayjs(newValue).isSame(endDate, "day")) {
      if (
        showTime &&
        (dayjs(newValue).hour() > dayjs(endDate).hour() ||
          (dayjs(newValue).hour() === dayjs(endDate).hour() &&
            dayjs(newValue).minute() >= dayjs(endDate).minute()))
      ) {
        setStartDate(null);
        notifyError("Start time must be before end time");
      } else {
        setStartDate(newValue);
      }
    } else {
      setStartDate(null);
      notifyError("End date must be greater than start date");
    }
  };

  const handleEndDate = (newValue) => {
    const durationInDays = startDate
      ? dayjs(startDate).diff(newValue, "days") > 0
        ? dayjs(startDate).diff(newValue, "days")
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      setEndDate(null);
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDate && dayjs(newValue).isAfter(startDate)) {
      setEndDate(newValue);
    } else if (!startDate) {
      setEndDate(newValue);
    } else if (startDate && dayjs(newValue).isSame(startDate, "day")) {
      if (
        showTime &&
        (dayjs(newValue).hour() < dayjs(startDate).hour() ||
          (dayjs(newValue).hour() === dayjs(startDate).hour() &&
            dayjs(newValue).minute() <= dayjs(startDate).minute()))
      ) {
        setEndDate(null);
        notifyError("End time must be after start time");
      } else {
        setEndDate(newValue);
      }
    } else {
      setEndDate(null);
      notifyError("Start date must be less than end date");
    }
  };
  return (
    <>
      <div>{/*  */}</div>
      <div className="page-wraper alarm-wrapper">
        <div className="page-header d-flex justify-content-between alarm-header">
          <div onClick={handlebackAlarmClick} className="page-back-btn">
            <ArrowBackIcon />
            <span>{headerName} - Alarm</span>
          </div>
          <div
            className="header-btn-group d-flex analytics-aggregation analytics-datepicker alarm-datepicker"
            style={{ justifyContent: "flex-end" }}
          >
            <button className="ackbtn" onClick={() => handleAlarmClick()}>
              <img src={images.right} /> Ack. All
            </button>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="top"
                style={{ margin: 0 }}
                control={
                  <Switch
                    checked={showTime}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={20}
                    width={40}
                    onColor="#50C878"
                  />
                }
                label="Time"
                labelPlacement="top"
                onChange={handleToggleTime}
              />
            </FormGroup>
            {showTime ? (
              <>
                <Box
                  className=""
                // style={{ width: "31%", marginRight: "0px" }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label="Start Date"
                      value={startDate}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      disableFuture
                      onChange={handleStartDate}
                      className="custom-datepicker"
                    />
                  </LocalizationProvider>
                </Box>
                <Box className="">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label="End Date"
                      value={endDate}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      disableFuture
                      onChange={handleEndDate}
                      className="custom-datepicker"
                    />
                  </LocalizationProvider>
                </Box>
              </>
            ) : (
              <>
                <Box
                  className=""
                // style={{ width: "26%", marginRight: "0px" }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="custom-datepicker"
                      label="Start Date"
                      value={startDate}
                      inputFormat="DD/MM/YYYY"
                      disableFuture
                      onChange={handleStartDate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ background: "#ffff" }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
                <Box className="">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="custom-datepicker"
                      label="End Date"
                      value={endDate}
                      inputFormat="DD/MM/YYYY"
                      disableFuture
                      onChange={handleEndDate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ background: "#ffff" }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </>
            )}

            <img
              src={images.excelLogo}
              alt=""
              className="excel-alarm"
              style={{ height: "55px", cursor: "pointer" }}
              onClick={handleAlarmClickSubmit}
            />
          </div>
        </div>
        <div className="responsive-header-design">
          <div
            onClick={handlebackAlarmClick}
            className="page-back-btn d-flex justify-content-between"
          >
            <div>
              <ArrowBackIcon />
              <span>{headerName} - Alarm</span>
            </div>
            <div>
              <button className="ackbtn" onClick={() => handleAlarmClick()}>
                <img src={images.right} alt="" /> Ack. All
              </button>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-end pt-3 flex-wrap">
            <div className="mt-2">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  className="alarm-input-label"
                  value="start"
                  style={{ margin: 0 }}
                  control={
                    <Switch
                      checked={showTime}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={20}
                      width={40}
                      onColor="#50C878"
                    />
                  }
                  label="Time"
                  labelPlacement="start"
                  onChange={handleToggleTime}
                />
              </FormGroup>
            </div>
            {showTime ? (
              <>
                <Box
                  className="alarm-start-date"
                // style={{ width: "31%", marginRight: "0px" }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      className="custom-datepicker"
                      label="Start Date"
                      value={startDate}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      disableFuture
                      onChange={handleStartDate}
                    />
                  </LocalizationProvider>
                </Box>
                <Box
                  className=""
                  // style={{ width: "31%" }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label="End Date"
                      value={endDate}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      disableFuture
                      onChange={handleEndDate}
                      className="custom-datepicker"
                    />
                  </LocalizationProvider>
                </Box>
              </>
            ) : (
              <>
                <Box
                  className=""
                  // style={{ width: "26%", marginRight: "0px" }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="custom-datepicker"
                      label="Start Date"
                      value={startDate}
                      inputFormat="DD/MM/YYYY"
                      disableFuture
                      onChange={handleStartDate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ background: "#ffff" }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
                <Box
                  className=""
                  // style={{ width: "26%" }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="custom-datepicker"
                      label="End Date"
                      value={endDate}
                      inputFormat="DD/MM/YYYY"
                      disableFuture
                      onChange={handleEndDate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ background: "#ffff" }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </>
            )}

            <img
              src={images.excelLogo}
              alt=""
              style={{ height: "55px", cursor: "pointer" }}
              onClick={handleAlarmClickSubmit}
              className="alarm-excel-img"
            />
          </div>
          <div className="d-flex gap-2 justify-content-end pt-3 flex-wrap">
            <div>
              <Select
                className="selectDropdown alarm-input-dropdown"
                style={{ border: "1px solid #D3DAE5", background: "#FFFFFF" }}
                value={priority}
                variant="standard"
                name="priority"
                onChange={(event) => setPriority(event.target.value)}
              >
                <MenuItem value="High">
                  <div className="priority-status"></div>
                </MenuItem>
                <MenuItem value="Medium">
                  <div className="priority-statusInprogress"></div>
                </MenuItem>
                <MenuItem value="Low">
                  <div className="priority-statusClose"></div>
                </MenuItem>
              </Select>
            </div>
            <div>
              <select
                className="form-input-class alarm-state-dropdown"
                id="selectedClass"
                style={{ height: "50px" }}
                value={alarmState}
                onChange={(event) => setAlarmState(event.target.value)}
                required
              >
                <option value="" selected={alarmState === "none"}>
                  State
                </option>
                <option value="LO ALM">LO ALM</option>
                <option value="LO NR">LO NR</option>
                <option value="LL ALM">LL ALM</option>
                <option value="LL NR">LL NR</option>
                <option value="HI ALM">HI ALM</option>
                <option value="HI NR">HI NR</option>
                <option value="HH ALM">HH ALM</option>
                <option value="HH NR">HH NR</option>
              </select>
            </div>
            <div>
              <input class="input-elevated" type="text" placeholder="Search" />
            </div>
          </div>
          <div className="d-flex gap-3 justify-content-end pt-3 flex-wrap">
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="start"
                className="alarm-input-label"
                style={{ margin: 0 }}
                control={
                  <Switch
                    name="is_action"
                    checked={searchalarm.is_action}
                    onChange={(checked) => handleChangeSearch1("is_action", checked)}
                    className="custom-switch"
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={20}
                    width={40}
                    onColor="#50C878"
                  />
                }
                label="Action"
                labelPlacement="start"
                onChange={handleToggleTime}
              />
            </FormGroup>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="start"
                className="alarm-input-label"
                style={{ margin: 0 }}
                control={
                  <Switch
                    name="is_noted"
                    checked={searchalarm.is_noted}
                    onChange={(checked) => handleChangeSearch1("is_noted", checked)}
                    className="custom-switch"
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={20}
                    width={40}
                    onColor="#50C878"
                  />
                }
                label="Note"
                labelPlacement="start"
                onChange={handleToggleTime}
              />
            </FormGroup>

          </div>
        </div>

        <div className="pt-4 pb-4 alrm-date-table">
          <TableContainer className={classes.tableContainer}>
            <Table className="alarm-table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.stickyHeader}>
                    Priority
                  </TableCell>
                  <TableCell className={classes.stickyHeader}>Date</TableCell>
                  <TableCell className={classes.stickyHeader}>Time</TableCell>
                  <TableCell className={classes.stickyHeader}>Tag</TableCell>
                  <TableCell className={classes.stickyHeader}>
                    Alarm Message
                  </TableCell>
                  <TableCell className={classes.stickyHeader}>State</TableCell>
                  <TableCell className={classes.stickyHeader}>Action</TableCell>
                  <TableCell className={classes.stickyHeader}>Note</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    className="search-table-row search-table-row-select"
                    style={{ background: "#F1F1FF" }}
                  >
                    <Select
                      className="selectDropdown"
                      style={{ width: "90%", border: "none", outline: "none" }}
                      value={priority}
                      variant="standard"
                      name="priority"
                      onChange={(event) => setPriority(event.target.value)}
                    >
                      <MenuItem value="High">
                        <div className="priority-status"></div>
                      </MenuItem>
                      <MenuItem value="Medium">
                        <div className="priority-statusInprogress"></div>
                      </MenuItem>
                      <MenuItem value="Low">
                        <div className="priority-statusClose"></div>
                      </MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell
                    className="search-table-row"
                    style={{ background: "#F1F1FF" }}
                  >
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Date"
                      name="date"
                      value={searchalarm.date}
                      onChange={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell
                    className="search-table-row"
                    style={{ background: "#F1F1FF" }}
                  >
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Time"
                      name="time"
                      value={searchalarm.time}
                      onChange={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell
                    className="search-table-row"
                    style={{ background: "#F1F1FF" }}
                  >
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Tag"
                      name="tag"
                      value={searchalarm.tag}
                      onChange={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell
                    className="search-table-row"
                    style={{ background: "#F1F1FF" }}
                  >
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Alarm Message"
                      name="alarm_msg"
                      value={searchalarm.alarm_msg}
                      onChange={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell
                    className="search-table-row"
                    style={{ background: "#F1F1FF" }}
                  >
                    <select
                      className="form-input-class alarm-state-dropdown"
                      id="selectedClass"
                      style={{ height: "50px" }}
                      value={alarmState}
                      onChange={(event) => setAlarmState(event.target.value)}
                      required
                    >
                      <option value="" selected={alarmState === "none"}>
                        State
                      </option>
                      <option value="LO ALM">LO ALM</option>
                      <option value="LO NR">LO NR</option>
                      <option value="LL ALM">LL ALM</option>
                      <option value="LL NR">LL NR</option>
                      <option value="HI ALM">HI ALM</option>
                      <option value="HI NR">HI NR</option>
                      <option value="HH ALM">HH ALM</option>
                      <option value="HH NR">HH NR</option>
                    </select>
                  </TableCell>
                  <TableCell style={{ background: "#F1F1FF", padding: 0 }}>
                    <Switch
                      name="is_action"
                      checked={searchalarm.is_action}
                      onChange={(checked) =>
                        handleChangeSearch1("is_action", checked)
                      }
                      className="custom-switch"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={20}
                      width={40}
                      onColor="#50C878"
                    />
                  </TableCell>

                  <TableCell style={{ background: "#F1F1FF", padding: 0 }}>
                    <Switch
                      name="is_noted"
                      checked={searchalarm.is_noted}
                      onChange={(checked) =>
                        handleChangeSearch1("is_noted", checked)
                      }
                      className="custom-switch"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={20}
                      width={40}
                      onColor="#50C878"
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {alarmData?.results?.map((row, i) => {
                  return (
                    <TableRow>
                      <TableCell>
                        <div
                          className={`${row.priority?.toLowerCase() === "high"
                            ? "priority-status"
                            : row.priority?.toLowerCase() === "medium"
                              ? "priority-statusInprogress"
                              : "priority-statusClose"
                            }`}
                        />
                      </TableCell>
                      <TableCell>
                        <div className={row.is_action ? "" : "inactive-row"}>
                          {dayjs(row.created_at).format("YYYY-MM-DD")}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className={row.is_action ? "" : "inactive-row"}>
                          {dayjs(row.created_at).format("HH:mm:ss")}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className={row.is_action ? "" : "inactive-row"}>
                          {row.tag}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className={row.is_action ? "" : "inactive-row"}>
                          {row.alarm_message}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className={row.is_action ? "" : "inactive-row"}>
                          {row.state}
                        </div>
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          style={{ color: "#1C407B" }}
                          checked={selectedAlarms.includes(row.id)}
                          onChange={(event) =>
                            handleCheckboxClick(event, row.id)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <div onClick={() => handleOpenModal(i)}>
                          <img src={images.document} />
                        </div>
                        {alarmModal[i] && (
                          <div className="remarksModal">
                            <CloseIcon
                              style={{
                                position: "absolute",
                                zIndex: 99,
                                right: 0,
                                top: 0,
                              }}
                              onClick={() =>
                                setAlarmModal({ ...alarmModal, [i]: false })
                              }
                            />
                            <textarea
                              name="remarks"
                              className="alarmTextArea"
                              style={{ padding: "22px", border: "none" }}
                              rows="6"
                              cols="30"
                              value={row.note}
                              onChange={(e) => setNote(e.target.value)}
                            ></textarea>
                            <button
                              className="submitBtn"
                              style={{
                                position: "absolute",
                                bottom: 10,
                                right: 10,
                                background: "#1c407b",
                                border: "#1c407b",
                                padding: "8px 15px",
                                borderRadius: "4px",
                                color: "white",
                              }}
                              onClick={() => handleAlarmNote(row.id)}
                            >
                              Submit
                            </button>
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}

                {alarm && (
                  <>
                    <div className="alarmData">
                      <Card className="alarmCard">
                        <CardContent>
                          <h4 className="card-content">
                            <h5
                              style={{
                                textAlign: "center",
                                fontWeight: 500,
                                fontSize: "22px",
                              }}
                            >
                              <b>
                                Are You Sure To <br />
                                Acknowledge All The Alarm ?
                              </b>
                            </h5>
                          </h4>
                        </CardContent>
                        <div style={{ textAlign: "center" }}>
                          <button
                            className="alarmYes"
                            onClick={handleAllCheckboxClick}
                          >
                            Yes
                          </button>
                          <button className="alarmNo" onClick={handleClick}>
                            No
                          </button>
                        </div>
                      </Card>
                    </div>
                    <div className="sending-uid-overlay" on></div>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="d-flex justify-content-between alarm-legend">
            <div className="d-flex pt-3">
              <div className="priority-status-legend"> </div>{" "}
              <b className="priority-legend">High</b>
              <div className="priority-statusInprogress-legend"> </div>{" "}
              <b className="priority-legend">Medium</b>
              <div className="priority-statusClose-legend"> </div>{" "}
              <b className="priority-legend">Low</b>
            </div>

            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={countAlarmData}
              page={page}
              onPageChange={(e, newPage) => setPage(newPage)}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={(e) => {
                setRowsPerPage(parseInt(e.target.value, 10));
                setPage(0);
              }}
            />
          </div>
        </div>
      </div>

      <Container maxWidth="xl" style={{ height: "503px", overflow: "auto" }}>
        <Grid container spacing={2} className="alarm-container">
          {alarmData?.results?.map((row) => {
            const isOpen = openAlarmId === row.id;

            return (
              <Grid item xs={12} sm={12} key={row.id}>
                <div className="overview-details pt-2">
                  <div className="overview-details-wrap">
                    <div>
                      <h4>Priority :</h4>
                      <h4>Date :</h4>
                      <h4>Time :</h4>
                      {isOpen && (
                        <>
                          <h4>Tag :</h4>
                          <h4>Alarm Message :</h4>
                          <h4>State :</h4>
                          <h4>Action :</h4>
                          <h4>Note :</h4>
                        </>
                      )}
                    </div>

                    <div>
                      <>
                        <h6>
                          <div
                            className={`${row.priority?.toLowerCase() === "high"
                              ? "priority-status"
                              : row.priority?.toLowerCase() === "medium"
                                ? "priority-statusInprogress"
                                : "priority-statusClose"
                              }`}
                          />
                        </h6>

                        <h6>
                          {" "}
                          <div className={row.is_action ? "" : "inactive-row"}>
                            {dayjs(row.created_at).format("YYYY-MM-DD")}
                          </div>
                        </h6>
                        <h6>{dayjs(row.created_at).format("HH:mm:ss")}</h6>
                        {isOpen && (
                          <>
                            <h6>{row.tag}</h6>
                            <h6>{row.alarm_message}</h6>
                            <h6>{row.state}</h6>
                            <h6>
                              <Checkbox
                                style={{ color: "#1C407B", padding: "0px" }}
                                checked={selectedAlarms.includes(row.id)}
                                onChange={(event) =>
                                  handleCheckboxClick(event, row.id)
                                }
                              />
                            </h6>
                            <h6>
                              <div onClick={() => handleOpenModal(row.id)}>
                                <img
                                  src={images.document}
                                  alt="document icon"
                                />
                              </div>
                            </h6>
                          </>
                        )}
                      </>
                    </div>
                  </div>

                  <img
                    src={isOpen ? images.upIcon : images.downIcon}
                    alt="toggle visibility"
                    className={isOpen ? "up-arrow" : "down-arrow"}
                    onClick={() => toggleShowAll(row.id)}
                  />
                </div>
              </Grid>
            );
          })}
        </Grid>
      </Container>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={countAlarmData}
        page={page}
        onPageChange={(e, newPage) => setPage(newPage)}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(e) => {
          setRowsPerPage(parseInt(e.target.value, 10));
          setPage(0);
        }}
        className="alarm-table-pagination"
        style={{ position: "relative", zIndex: 9999 }}
      />
    </>
  );
};
export default Alarm;
