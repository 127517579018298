import React from "react";
import { useEffect } from "react";
import Mqqtdata from "../../../../../ProcessDashboard/Mqqtdata";
import { useState } from "react";

const OverViewSelectTagData = ({
    deviceData,
    unitySelectTag,
    setSelectTagRealTimeData,
    setSelectMqttTagPreviousvalue,
    tagListDataForDevice
}) => {

    const [topics, setTopics] = useState([]);
    const [mqttTagData, setMqttTagData] = useState([]);

    //topic 
    useEffect(() => {
        const vmsTopic = ['Hikar/DeviceUID/' + deviceData.machine_uid];
        setTopics(vmsTopic);
        setSelectTagRealTimeData([]);
    }, [unitySelectTag]);


    useEffect(() => {
        // Use the filter method to get the desired data
        const filteredData = Object.values(mqttTagData).flatMap(deviceData =>
            deviceData.filter(item => item.tagName == unitySelectTag)
        );
       
        if (filteredData.length) {
            // setSelectTagRealTimeData((prev) => [...prev, filteredData[0]]);
            setSelectTagRealTimeData((prev) => {
                // Create a shallow copy of the previous state
                const indexCopy = [...prev];
                //==========================new add code==============================
                if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
                  indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
                }
          
                return [...indexCopy, filteredData[0]]
              });
            setSelectMqttTagPreviousvalue((prev) => {
                return {
                    tagName: unitySelectTag,
                    prevValue: prev.curValue,
                    curValue: filteredData[0].tagValue
                }
            })
        } 
       
    }, [mqttTagData])

    console.log("tagListDataForDevice", tagListDataForDevice);


    return (
        <>
            {topics.length ? <Mqqtdata topics={topics} setMqttTagData={setMqttTagData} overViewTagData={tagListDataForDevice}/> : null}
        </>
    )
}

export default OverViewSelectTagData;