import * as React from "react";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";

// import FooterText from '../../components/FooterText/FooterText';
import "../../PlantDashboard/Dashboard.css";
import Typography from "@mui/material/Typography";

import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { images } from "../../../config/images";
import { setHeaderDetails } from "../../Header/headerSlice";
import { getCompany } from "../../CompanyDashboard/services";
import { companyDashboardCountData } from "../../SuperAdminDashboard/service";
import ShimmerEffectPlaceholder from "../../../components/Shimmer/DashboardShimmer/CompanyListShimmer";
import { ShimmerText } from "react-shimmer-effects";
import "./Vms_Dashboard.css";


const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const VmsCompanyDashboard = () => {
  const [companyData, setCompanyData] = useState([]);
  const [companyDataCount, setCompanyDataCount] = useState([]);
  const [isShimmerCompanyList, setisShimmerCompanyList] = useState(false);
  const [isShimmerHeader, setisShimmerHeader] = useState(false);




  const { is_admin, is_super_admin, is_created_by_super_admin_user_company } = useSelector(state => state.userState);


  useEffect(() => {
    if (is_super_admin == false && is_created_by_super_admin_user_company == false) {
      if (companyData.length) {
        navigate(`/vms-plants/company/${companyData[0].id}/plant`);
        dispatch(setHeaderDetails({ headerName: companyData[0].company_name }))
      }
    }
  }, [companyData])

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    companyDetail();
    if (is_super_admin == true) {
      dispatch(setHeaderDetails({ headerName: "Company Dashboard" }))
    }

  }, []);

  const companyDetail = async () => {
    setisShimmerCompanyList(true)
    const params = {
      dashboard: 2
    }
    try {
      const resp = await getCompany(params);
      if (resp.status == 200) {
        setCompanyData(resp.data.payload ? resp.data.payload : []);
        setTimeout(() => { setisShimmerCompanyList(false) }, 1000)

      }

    } catch (error) {
      // Handle the error here, you can log it or perform any other necessary action
      console.error("Error fetching company data:", error);
    }
  };


  const handlePlantDashboard = async (row) => {
    navigate(`/vms-plants/company/${row.id}/plant`);
    dispatch(setHeaderDetails({ headerName: row.company_name }))
  };

  useEffect(() => {
    handleCompanyDataCount()
  }, [])

  const handleCompanyDataCount = async () => {
    setisShimmerHeader(true)
    try {
      const params = {
        dashboard: 2
      };
      const resp = await companyDashboardCountData(params);
      if (resp.status == 200) {
        setCompanyDataCount(resp.data.payload);
        setTimeout(() => { setisShimmerHeader(false) }, 1000)
      }

    } catch (error) {
      setisShimmerHeader(false)
      // Handle the error here, you can log it or show a user-friendly message
      console.error('Error fetching company dashboard count data:', error);
      // You might also want to set a default value for `CompanyDataCount` or handle the error in some other way
    }
  };

  const TextShimmer = () => {
    return (<>
      <ShimmerText line={1} gap={10} className="Header-TextShimmer" />
    </>)
  }

  return (
    <>
      <div className="containerWrap">
        <Container maxWidth="xxl">
          <Card className="dashboardCard">
            <Grid container>
              <Grid xs={6} sm={6} md={3} lg={3} className="grid-item">
                <div className="dashboardCard-item">
                  <div className="dashboard-card-img">
                    <img
                      src={images.criticalEvent}
                      alt=""
                      className="dashboardCard-img"
                    />
                  </div>

                  <div className="dashboardCard-text">
                    <p className="dashboardCard-title">
                      MACHINES
                    </p>
                    <h6 className="dashboardCard-number">
                      {!isShimmerHeader ? companyDataCount.device_count : TextShimmer()}
                    </h6>
                  </div>
                </div>
              </Grid>

              <Grid xs={6} sm={6} md={3} lg={3} className="grid-item">
                <div className="dashboardCard-item">
                  <div className="dashboard-card-img">
                    <img
                      src={images.clientManagementPlantS}
                      alt=""
                      className="dashboardCard-img"
                    />
                  </div>
                  <div className="dashboardCard-text">
                    <p className="dashboardCard-title">
                      COMPANY
                    </p>
                    <h6 className="dashboardCard-number">
                      {!isShimmerHeader ? companyDataCount.company_count : TextShimmer()}
                    </h6>
                  </div>
                </div>
              </Grid>
              <Grid xs={6} sm={6} md={3} lg={3} className="grid-item">

                <div className="dashboardCard-item">
                  <div className="dashboard-card-img">
                    <img
                      src={images.activeImg}
                      alt=""
                      className="dashboardCard-img"
                    />
                  </div>
                  <div className="dashboardCard-text">
                    <p className="dashboardCard-title">
                      ACTIVE
                    </p>
                    <h6 className="dashboardCard-number">
                      {!isShimmerHeader ? companyDataCount.active_company : TextShimmer()}
                    </h6>
                  </div>
                </div>
              </Grid>

              <Grid xs={6} sm={6} md={3} lg={3} className="grid-item">
                <div className="dashboardCard-item">
                  <div className="dashboard-card-img">
                    <img
                      src={images.serviceCall}
                      alt=""
                      className="dashboardCard-img"
                    />
                  </div>
                  <div className="dashboardCard-text">
                    <p className="dashboardCard-title">
                      SERVICE CALL
                    </p>
                    <h6 className="dashboardCard-number">
                      00
                    </h6>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Card>

          <Grid container spacing={4} mt={"0px"}>
            {!isShimmerCompanyList ? companyData.map((row, i) => {
              return (
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <Card
                    className="dashboardCard-plant"
                    onClick={() => handlePlantDashboard(row)}
                  >
                    <Stack direction="column">
                      <Typography
                        gutterBottom
                        className="dashboardCard-plantTitle"
                      >
                        {row.company_name ? row.company_name : "-"}
                      </Typography>
                      <Typography
                        gutterBottom
                        className="dashboardCard-plantsubTitle"
                      >
                        {row.company_description ? row.company_description : "-"}
                      </Typography>

                      <Box className="dashboardCard-companyContent">
                        <Typography
                          gutterBottom
                          className="dashboardCard-plantText"
                        >
                          Plant:
                        </Typography>
                        <Stack direction="column">
                          <Typography
                            variant="body1"
                            className="dashboardCard-plantNumber1"
                          >
                            {row.active_plant_count}
                          </Typography>
                          <Typography
                            variant="caption"
                            className="dashboardCard-plantSmalltext"
                          >
                            Active
                          </Typography>
                        </Stack>
                        <Box>

                          <Stack direction="column">
                            <Typography
                              variant="body1"
                              className="dashboardCard-plantNumber2"
                            >
                              {row.inactive_plant_count}
                            </Typography>
                            <Typography
                              variant="caption"
                              className="dashboardCard-plantSmalltext"
                            >
                              Inactive
                            </Typography>
                          </Stack>
                        </Box>
                        <div
                          style={{
                            borderRight: "2px solid #C9CFE7",
                            height: "30px",
                          }}
                        ></div>
                        <Box>
                          <Stack
                            direction="column"
                            spacing={1}
                            style={{ marginTop: "5px" }}
                          >
                            <img
                              src={images.serviceCallImg}
                              alt=""
                              className="dashboardCard-companyImg"
                            />
                            <Typography
                              variant="caption"
                              className="dashboardCard-plantSmalltext"
                            >
                              Service call
                            </Typography>
                          </Stack>
                        </Box>
                        <div
                          style={{
                            borderRight: "2px solid #C9CFE7",
                            height: "30px",
                          }}
                        ></div>

                        <Box paddingRight={4}>
                          <Stack
                            direction="column"
                            spacing={1}
                            style={{ marginTop: "5px" }}
                          >
                            {row.subscribe_device_license_count == 0 ? (
                              <img
                                src={images.noSub}
                                alt=""
                                className="dashboardCard-companyImg"
                              />
                            ) : (
                              <img
                                src={images.alertImage}
                                alt=""
                                className="dashboardCard-companyImg"
                              />
                            )}

                            <Typography
                              variant="caption"
                              className="dashboardCard-plantSmalltext"
                            >
                              Subscription
                            </Typography>
                          </Stack>
                        </Box>
                      </Box>
                    </Stack>
                  </Card>
                </Grid>
              );
            }) : <ShimmerEffectPlaceholder />}
          </Grid>

        </Container>
      </div>
    </>
  );
};
export default VmsCompanyDashboard;
