import React, { useEffect, useRef } from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { images } from "../../../config";
import { PieChartShimmer } from "../../../components/Shimmer/PieChartShimmer/PieChartShimmer";
import '../ProcessDashboard.css';

export function DonutChart({
  analyticsPieChartData,
  donutChartSum,
  donutUnit,
  Shimmer,
}) {
  const chartRef = useRef(null);
  const param = useParams();
  const { deviceColors } = useSelector((state) => state.overViewState);

  // Filter device colors for the specific plant
  const findPlantDeviceColor = deviceColors.filter((f) => f.plantId == param.id);

  // Create a map of device names to colors
  const deviceColorMap = new Map(findPlantDeviceColor.map((row) => [row.dvName, row.color]));

  // Prepare chart data
const data = {
  labels: analyticsPieChartData.map((row) => row.device_name), // Use device_name for labels
  datasets: [
    {
      label: "# of Votes",
      data: analyticsPieChartData.map((row) => Math.abs(row.tag_value)), // Use absolute values for display
      backgroundColor: analyticsPieChartData.map((row) => deviceColorMap.get(row.device_name)),
      borderColor: analyticsPieChartData.map((row) => deviceColorMap.get(row.device_name)),
      borderWidth: 1,
    },
  ],
};


  // Define chart options
  const options = {
    chart: {
      type: 'pie',
      backgroundColor: 'transparent',
      height: 250,
      width: 250,
    },
    title: {
      text: ''
    },

    tooltip: {
      enabled: true,
      useHTML: true,
      formatter: function () {
        const total = this.series.data.reduce((acc, point) => acc + point.y, 0);
        const percentage = ((this.y / total) * 100).toFixed(2) + '%';
        const index = this.point.index; // Get the index of the point in the data array
        const deviceName = data.labels[index];
        const deviceColor = data.datasets[0].backgroundColor[index];
        const originalValue = analyticsPieChartData[index].tag_value; // Get the original value
        return `<div style="font-size: 12px">
                  <b>${deviceName}</b><br/>
                  <span style="color:${deviceColor}">\u25A0</span> ${originalValue} (${percentage})
                </div>`;
      }
    },
    
    plotOptions: {
      pie: {
        innerSize: '70%',
        dataLabels: {
          enabled: false,
        }
      }
    },
    credits: {
      enabled: false
    },
    series: [{
      name: 'Values',
      data: analyticsPieChartData.length ? analyticsPieChartData.map((row) => ({
        name: row.device_name,
        y: Math.abs(row.tag_value),
        color: deviceColorMap.get(row.device_name),
      })) : [],
    }]
  
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.update(options);
    }
  }, [analyticsPieChartData]);

  return (
    <div style={{ width: "100%", height: "195px", display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="doughnut-chart1">
      {!Shimmer ? analyticsPieChartData.length ? (
        <>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartRef}
          />
          <p className="doughnut-chartText">
            {typeof donutChartSum === "number"
              ? donutChartSum.toFixed(2)
              : donutChartSum}
          </p>
          <p className="doughnut-chartText-unit">{donutUnit}</p>
        </>
      ) : (
        <img src={images.noDataFoundImg} className="notFoundDataImg" alt="No DataFound Img" />
      ) : <PieChartShimmer size="150px" position="relative" />}
    </div>
  );
}
