import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
// import { Textarea } from '@material-ui/core';
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Grid } from "@mui/material";
// import Typography from '@mui/material/Typography';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControlLabel from "@mui/material/FormControlLabel";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@mui/material/Switch";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { images } from "../../../config/images";
import {
  getcompanyList,
  addCompany,
  editCompany,
  deleteCompany,
  inviteUserByCompany,
  getSortbycompanyList,
  getDeviceCloudStatusData,
} from "../service";
import ExpandCompany from "./expandCompany";
import SearchIcon from "@material-ui/icons/Search";
import { InputAdornment } from "@material-ui/core";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ClientManagement.css";
import Loader from "../../../components/Loader/Loader";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useSelector } from "react-redux";
import ClientManageShimmer from "../../../components/Shimmer/ClientManagementShimmer/ClientManagementShimmer";

const ClientManagement = () => {
  const [openAddcompany, setOpenAddcompany] = useState(false);
  const [openEditcompany, setOpenEditcompany] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [isShimmercompanyData, setisShimmercompanyData] = useState(false);

  console.log("companyDatacompanyData", companyData);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [addCompanyData, setAddCompanyData] = useState({
    id: "",
    company_name: "",
    company_description: "",
  });
  const [updateCompanyData, setUpdateCompanyData] = useState({
    company_name: "",
    company_description: "",
  });

  const [inviteUser, setinviteUser] = useState({
    company_name: "",
    user_role: "",
    first_name: "",
    last_name: "",
    email: "",
  });
  const [updateData, setUpdateData] = useState({});
  const [openInviteUserModal, setOpenInviteUserModal] = useState(false);
  const [Arrow, setArrow] = useState(false);
  const [companyId, setCompanyId] = useState();
  const [expanded, setExpanded] = useState({});
  const [comapnySearch, setCompanySearch] = useState("");
  const [sortByName, setSortByName] = useState(false);

  const [isLoading, setLoading] = useState();
  const navigate = useNavigate();

  const { companyName, user_access } = useSelector((state) => state.userState);

  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  //Add plant modal
  const handleOpenaddCompanyModal = (id) => {
    setCompanyId(id);
    setOpenAddcompany(!openAddcompany);
    setOpenEditcompany(false);
    setOpenDeleteModal(false);
    setOpenInviteUserModal(false);
  };

  //Edit Company model
  const handleOpenEditCompanyModal = (e) => {
    setCompanyId(e.id);
    console.log("kp", e);
    setUpdateCompanyData({
      id: e.id,
      company_name: e.company_name,
      company_description: e.company_description,
    });
    setOpenEditcompany(!openEditcompany);
    setOpenAddcompany(false);
    setOpenDeleteModal(false);
    setOpenInviteUserModal(false);
  };

  const handleClose = () => {
    setArrow(!Arrow);
  };
  const handleTostMsg = () => {
    notify("You don't have access");
  };

  //Edit and delete modal
  const handleOpenUpdateModal = (event, id) => {
    setUpdateData((prevUpdate) => ({
      ...prevUpdate,
      [id]: !prevUpdate[id],
    }));
    event.stopPropagation();
  };
  // expand
  const toggleExpanded = (e) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [e.id]: !prevExpanded[e.id],
    }));
  };

  //Invite user modal
  const handleOpenInviteModal = (e) => {
    setinviteUser({
      company_name: e.id,
      name: e.company_name,
      user_role: "Admin",
      first_name: "",
      last_name: "",
      email: "",
    });
    setOpenInviteUserModal(true);
  };
  const handleOpenCloseInviteModal = () => {
    setOpenInviteUserModal(false);
  };

  const handelChangeInviteUser = (e) => {
    setinviteUser({ ...inviteUser, [e.target.name]: e.target.value });
  };

  // Delete Confirmation Modal
  const handleConfirmationModal = (id) => {
    setOpenDeleteModal(!openDeleteModal);
    setOpenAddcompany(false);
    setOpenEditcompany(false);
    setOpenInviteUserModal(false);
    setCompanyId(id);
  };

  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //add comapany
  const handleChangeAddCompany = (e) => {
    setAddCompanyData({ ...addCompanyData, [e.target.name]: e.target.value });
  };

  //add comapany
  const handleChangeUpdateCompany = (e) => {
    setUpdateCompanyData({
      ...updateCompanyData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCompanySearch = (e) => {
    setCompanySearch(e.target.value);
  };

  //get company list api
  const getcompanyData = async () => {
    setisShimmercompanyData(true);
    try {
      const response = await getcompanyList(comapnySearch);
      console.log("response", response);
      if (response.status == 200) {
        setTimeout(() => {
          setisShimmercompanyData(false);
        }, 1000);
        setCompanyData(response.data.payload);
      }
    } catch (error) {
      setisShimmercompanyData(false);
      notify(error.response.data.message);
    }
  };

  useEffect(() => {
    if (!sortByName) {
      getcompanyData();
    } else {
      getSortbycompanyListApi();
    }
  }, [comapnySearch, sortByName]);

  const getSortbycompanyListApi = async () => {
    try {
      setisShimmercompanyData(true);
      const response = await getSortbycompanyList(comapnySearch);
      if (response.status == 200) {
        setTimeout(() => {
          setisShimmercompanyData(false);
        }, 1000);
        setCompanyData(response.data.payload);
      }
    } catch (error) {
      setisShimmercompanyData(false);
      console.log("Error from getSortbycompanyListApi", error);
    }
  };

  //add company api
  const submitComapny = async () => {
    if (addCompanyData.company_name === "") {
      notify("Please Enter Company Name");
      return;
    }
    if (addCompanyData.company_description === "") {
      notify("Please Enter Company Description.");
      return;
    }
    try {
      setLoading(true);
      const response = await addCompany(addCompanyData);
      console.log("responseresponseresponse", response);
      if (response.data.success == true) {
        setAddCompanyData({
          company_name: "",
          company_description: "",
        });
        setLoading(false);
        setOpenAddcompany(false);
        getcompanyData();
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
      toast.error("Please Enter Company Name.", {
        theme: "colored",
        position: toast.POSITION.TOP_RIGHT,
        toastClassName: "custom-toast",
      });
    }
  };

  //update company api
  const submitUpdateComapny = async () => {
    if (!updateCompanyData.company_name) {
      notify("Please Enter Company Name.");
      return;
    }
    if (!updateCompanyData.company_description) {
      notify("Please Enter Company Description.");
      return;
    }
    try {
      setLoading(true);
      const response = await editCompany(
        updateCompanyData.id,
        updateCompanyData
      );
      if (response.status == 200 || response.status == 200) {
        setOpenEditcompany(false);
        getcompanyData();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("handleSubmit", error);
    }
  };

  //delete comapny api
  const handleDeleteCompany = async () => {
    const response = await deleteCompany(companyId);
    if (response.status == 200) {
      getcompanyData();
      setOpenDeleteModal(false);
    }
  };

  // invite User api

  const submitInviteUser = async () => {
    if (!inviteUser.first_name) {
      notify("Please Enter FirstName.");
      return true;
    }

    if (!inviteUser.last_name) {
      notify("Please Enter LastName.");
      return true;
    }

    if (!inviteUser.email) {
      notify("Please Enter Email.");
      return true;
    }

    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(inviteUser.email)) {
      notify("Please Enter valid Email.");
      return true;
    }

    try {
      setLoading(true);
      const response = await inviteUserByCompany(inviteUser);
      console.log("response", response);
      if (response.status == 200 || response.status == 201) {
        setLoading(false);
        handleOpenCloseInviteModal();
        notifySuccess("User Added Successfully.");
        getcompanyData();
      }
    } catch (error) {
      setLoading(false);
      notify(error.response.data.email[0]);
    }
  };

  // handle sort by name
  const handleSortByName = () => {
    if (
      openAddcompany ||
      openEditcompany ||
      openDeleteModal ||
      openInviteUserModal
    ) {
      return true;
    }
    setSortByName(!sortByName);
  };

  const superAdminAccess = user_access.length
    ? user_access.find(
        (user) => user.access_module?.access_module_name === "Admin Dashboard"
      )
    : null;
  const accessClientManagement = superAdminAccess
    ? superAdminAccess.sub_module.length
      ? superAdminAccess.sub_module.find(
          (f) => f.sub_module_name == "Client Management"
        )
      : null
    : null;

  return (
    <>
      <div>
        
      </div>
      <div className="containerWrap">
        <Container maxWidth="xxl">
          <div className="deviceManagementHeader-wrap">
            <div
              className="deviceManagementTitle-wrap"
              onClick={() => {
                if (
                  openAddcompany ||
                  openEditcompany ||
                  openDeleteModal ||
                  openInviteUserModal
                ) {
                  return true;
                }
                navigate("/superadmindashboard");
              }}
            >
              <KeyboardBackspaceIcon
                onClick={() => {
                  if (
                    openAddcompany ||
                    openEditcompany ||
                    openDeleteModal ||
                    openInviteUserModal
                  ) {
                    return true;
                  }
                  navigate("/superadmindashboard");
                }}
                className="backArrow"
              />{" "}
              <Typography
                variant=""
                gutterBottom
                style={{
                  margin: "0",
                  paddingLeft: "10px",
                  color: "#222D39",
                  fontSize: "16px",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
              >
                {companyName} - Client Management
              </Typography>
            </div>
            <div className="addDevice-buttonWrap">
              {(accessClientManagement?.is_viewer &&
                accessClientManagement?.is_editor) ||
              (!accessClientManagement?.is_viewer &&
                accessClientManagement?.is_editor) ? (
                <button
                  className="addCompany-button"
                  onClick={handleOpenaddCompanyModal}
                >
                  Add Company
                </button>
              ) : (
                <button className="addCompany-button" onClick={handleTostMsg}>
                  Add Company
                </button>
              )}
            </div>
          </div>
          <div className="clientManagement searchbarWrap">
            <TextField
              type="search"
              fullWidth
              placeholder="Search"
              value={comapnySearch}
              onChange={handleCompanySearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={() => handleSortByName()}
                    style={{ cursor: "pointer" }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      height="55px"
                      borderLeft={1}
                      borderColor="#DCE1EA"
                    >
                      {sortByName ? (
                        <ArrowUpwardIcon
                          style={{
                            fontSize: "20px",
                            marginRight: "8px",
                            marginLeft: "10px",
                            color: "#1C407B",
                          }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          style={{
                            fontSize: "20px",
                            marginRight: "8px",
                            marginLeft: "10px",
                            color: "#1C407B",
                          }}
                        />
                      )}
                    </Box>
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#1C407B",
                      }}
                    >
                      NAME
                    </Typography>
                    <ExpandMoreIcon
                      style={{
                        fontSize: "14px",
                        marginLeft: "8px",
                        color: "#1C407B",
                      }}
                    />
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "#90A3B9" }} />
                  </InputAdornment>
                ),
              }}
              className="clientManagement searchbar"
              // style={{ backgroundColor: '#fff' }}
            />
          </div>
          {/* =============== Add Company Modal Start =============== */}
          <ValidatorForm onSubmit={submitComapny}>
            {openAddcompany && (
              <Card className="addPlant-cardModal">
                <Box className="addPlant-titleWrap">
                  <Typography
                    variant="h5"
                    gutterBottom
                    className="addPlant-title"
                  >
                    Add Company
                  </Typography>
                  <img
                    src={images.closeIcon}
                    alt=""
                    style={{
                      width: "30px",
                      cursor: "pointer",
                      backgroundColor: "white",
                    }}
                    className="closeImg"
                    onClick={handleOpenaddCompanyModal}
                  />
                </Box>
                <Box className="addplantMdal-innerWrap">
                  <Stack spacing={2} pt="10px">
                    <TextField
                      id="filled-basic"
                      name="company_name"
                      label={
                        <>
                          Company Name{" "}
                          <span style={{ color: "#E31E24" }}>*</span>
                        </>
                      }
                      variant="filled"
                      className="addplantInputfield"
                      InputLabelProps={{
                        style: { color: "#011627" },
                      }}
                      onChange={handleChangeAddCompany}
                    />
                    <TextField
                      id="filled-basic"
                      name="company_description"
                      label="Description"
                      variant="filled"
                      className="addplantInputfield"
                      multiline
                      rows={4}
                      InputLabelProps={{
                        style: { color: "#011627" },
                      }}
                      onChange={handleChangeAddCompany}
                    />
                    {/* <div className='texteArea-text'>Write Here..</div> */}
                    {/* <Textarea
                                    aria-label="textarea"
                                    placeholder="Enter your text here"
                                    rows={4}
                                    fullWidth
                                    variant="outlined"
                                /> */}
                  </Stack>
                  <Box className="addPlant-cardButtons">
                    <button className="addPlantBtn">Add Company</button>
                  </Box>
                </Box>
              </Card>
            )}
          </ValidatorForm>
          {/* =============== Add Company Modal End =============== */}
          {/* =============== Edit Company Modal Start =============== */}
          <ValidatorForm onSubmit={submitUpdateComapny}>
            {openEditcompany && (
              <Card className="addPlant-cardModal">
                <Box className="addPlant-titleWrap">
                  <Typography
                    variant="h5"
                    gutterBottom
                    className="addPlant-title"
                  >
                    Update Company
                  </Typography>
                  <img
                    src={images.closeIcon}
                    alt=""
                    style={{
                      width: "30px",
                      cursor: "pointer",
                      backgroundColor: "white",
                    }}
                    className="closeImg"
                    onClick={handleOpenEditCompanyModal}
                  />
                </Box>
                <Box className="addplantMdal-innerWrap">
                  <Stack spacing={2} pt="10px">
                    <TextField
                      id="filled-basic"
                      name="company_name"
                      label={
                        <>
                          Company Name{" "}
                          <span style={{ color: "#E31E24" }}>*</span>
                        </>
                      }
                      variant="filled"
                      className="addplantInputfield"
                      onChange={handleChangeUpdateCompany}
                      value={updateCompanyData.company_name}
                      InputLabelProps={{
                        style: { color: "#011627" },
                      }}
                    />
                    <TextField
                      id="filled-basic"
                      name="company_description"
                      label="Description"
                      variant="filled"
                      className="addplantInputfield"
                      multiline
                      rows={4}
                      onChange={handleChangeUpdateCompany}
                      value={updateCompanyData.company_description}
                      InputLabelProps={{
                        style: { color: "#011627" },
                      }}
                    />
                    {/* <div className='texteArea-text'>Write Here..</div> */}
                    {/* <Textarea
                                    aria-label="textarea"
                                    placeholder="Enter your text here"
                                    rows={4}
                                    fullWidth
                                    variant="outlined"
                                /> */}
                  </Stack>
                  <Box className="addPlant-cardButtons">
                    <button className="addPlantBtn">Update Company</button>
                  </Box>
                </Box>
              </Card>
            )}
          </ValidatorForm>
          {/* =============== Edit Company Modal End =============== */}
          {/* =============== Delete Confirmation Modal Start =============== */}
          {openDeleteModal && (
            <Card className="deleteConfirmation-Modal">
              <Typography
                variant="h6"
                gutterBottom
                className="deleteConfirmation-title"
              >
                Are You Sure<br></br> You Want To Delete?
              </Typography>
              <Box className="deleteConfirmation-btnWrap">
                <button
                  className="deleteConfirmation-Yesbtn"
                  onClick={handleDeleteCompany}
                >
                  Yes
                </button>
                <button
                  className="deleteConfirmation-Nobtn"
                  onClick={handleConfirmationModal}
                >
                  No
                </button>
              </Box>
            </Card>
          )}
          {/* =============== Delete Confirmation Modal End =============== */}
          {/* =============== Invite User Modal Start =============== */}
          <ValidatorForm onSubmit={submitInviteUser}>
            {openInviteUserModal && (
              <Card className="inviteUserModal">
                <Box>
                  <Stack direction="row" spacing={{ sm: 42, md: 42, lg: 34 }}>
                    <Typography gutterBottom style={{ display: "none" }}>
                      ..
                    </Typography>
                    <img
                      src={images.closeIcon}
                      alt=""
                      style={{ width: "30px", cursor: "pointer" }}
                      onClick={handleOpenCloseInviteModal}
                    />
                  </Stack>
                  <Typography
                    gutterBottom
                    style={{
                      color: "#1C407B",
                      fontWeight: "600",
                      fontSize: "19px",
                      textAlign: "center",
                    }}
                  >
                    Invite User
                  </Typography>
                </Box>
                <Box>
                  <div className="useInviteWrap">
                    <label
                      for="exampleInputEmail1"
                      class="form-label userInviteLabel"
                    >
                      Company
                    </label>
                    <input
                      type="text"
                      class="form-control userInviteLabelplaceHolder userInviteLabelplaceHolder"
                      id="exampleInputEmail1"
                      placeholder="Company - 1"
                      value={inviteUser.name}
                    />
                  </div>
                  <div className="useInviteWrap">
                    <label
                      for="exampleInputEmail1"
                      class="form-label userInviteLabel"
                    >
                      Role
                    </label>
                    <input
                      type="text"
                      class="form-control userInviteLabelplaceHolder"
                      id="exampleInputEmail1"
                      placeholder="Admin"
                      value="Admin"
                    />
                  </div>
                  <div className="useInviteWrap">
                    <label
                      for="exampleInputEmail1"
                      class="form-label userInviteLabel"
                    >
                      First Name <span style={{ color: "#E31E24" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="first_name"
                      class="form-control userInviteLabelplaceHolder"
                      id="exampleInputEmail1"
                      placeholder="Enter First Name"
                      value={inviteUser.first_name}
                      onChange={handelChangeInviteUser}
                    />
                  </div>
                  <div className="useInviteWrap">
                    <label
                      for="exampleInputEmail1"
                      class="form-label userInviteLabel"
                    >
                      Last Name<span style={{ color: "#E31E24" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="last_name"
                      class="form-control userInviteLabelplaceHolder"
                      id="exampleInputEmail1"
                      placeholder="Enter Last Name"
                      value={inviteUser.last_name}
                      onChange={handelChangeInviteUser}
                    />
                  </div>
                  <div className="useInviteWrap">
                    <label
                      for="exampleInputEmail1"
                      class="form-label userInviteLabel"
                    >
                      Email Id<span style={{ color: "#E31E24" }}>*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      class="form-control userInviteLabelplaceHolder"
                      id="exampleInputEmail1"
                      placeholder="Enter Email Id"
                      value={inviteUser.email}
                      onChange={handelChangeInviteUser}
                    />
                  </div>
                </Box>
                <Box className="">
                  <button className="ClientManagement InviteUser-Button">
                    Invite User
                  </button>
                </Box>
              </Card>
            )}
          </ValidatorForm>
          {/* =============== Invite User Modal End =============== */}
          {/* =============== Company Details Start =============== */}
          {!isShimmercompanyData ? (
            companyData.map((e) => {
              return (
                <ExpandCompany
                  row={e}
                  handleOpenEditCompanyModal={handleOpenEditCompanyModal}
                  handleConfirmationModal={handleConfirmationModal}
                  handleOpenInviteModal={handleOpenInviteModal}
                  getcompanyData={getcompanyData}
                  openInviteUserModal={openInviteUserModal}
                  openAddcompany={openAddcompany}
                  openEditcompany={openEditcompany}
                  openDeleteModal={openDeleteModal}
                  accessClientManagement={accessClientManagement}
                  handleTostMsg={handleTostMsg}
                />
              );
            })
          ) : (
            <>
              {[...Array(5)].map((_, index) => (
                <ClientManageShimmer />
              ))}
            </>
          )}
          {/* =============== Company Details End =============== */}
        </Container>
        {isLoading && <Loader />}
      </div>
    </>
  );
};

export default ClientManagement;
