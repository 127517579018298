import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Card } from "@mui/material";
import "../Source/Source.css";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { useCubeQuery } from "@cubejs-client/react";
import { cubejsApi } from "../../Cubejs/CubejsApiWrapper";
import Mqqtdata from "../Mqqtdata";
import { CompactTagUnit, CompactTagValue } from "../../../components/CompactValue/CompactValue";
import { useSelector } from "react-redux";

const sliderSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 3,

  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,  // Adjusted breakpoint for more flexibility
      settings: {
        slidesToShow: 2,  // Ensure this is set to 2
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,  // Ensure this is set to 2
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 375,
      settings: {
        slidesToShow: 1,  // Ensure this is set to 2
        slidesToScroll: 1,
      },
    },
  ],
};

function ConsumptionSlider({ deviceData, confData, getDevice }) {
  const [tag, setTag] = useState("");
  const [tagValue, setTagValue] = useState([]);
  const [topics, setTopics] = useState([]);
  const [mqttTagData, setMqttTagData] = useState({});
  const [sliderKey, setSliderKey] = useState(0);

  console.log("deviceData", mqttTagData);
  const { selectUnit } = useSelector((state) => state.dateSliceprocessDashboardState);

  const tagName = confData.map((array) => array.map((obj) => obj.tag_name));
  const flatTagName = tagName.flat();

  useEffect(() => {
    if (deviceData.length) {
      const topicArray = deviceData.map((uid) => 'Hikar/DeviceUID/' + uid.device__machine__uid);
      setTopics(topicArray);
    }
  }, [deviceData]);

  useEffect(() => {
    // Force re-render of slider
    setSliderKey((prevKey) => prevKey + 1);
  }, [deviceData]);

  return (
    <>
      {deviceData.length ? <Mqqtdata setMqttTagData={setMqttTagData} topics={topics} /> : null}
      <Slider key={sliderKey} {...sliderSettings} className="my-slider-class">
        {deviceData
          ? deviceData.map((card, index) => {

            const tagValuefirst = mqttTagData[card.device__machine__uid] && confData.length ?
              mqttTagData[card.device__machine__uid].find((f) => f && f[confData[index] && confData[index][0] && confData[index][0].device_tag])
              : {};

            const tagValue1 = tagValuefirst && Object.values(tagValuefirst)[0] ? Object.values(tagValuefirst)[0] : 0;

            const tagValuesecond = mqttTagData[card.device__machine__uid] && confData.length ?
              mqttTagData[card.device__machine__uid].find((f) => f && f[confData[index] && confData[index][1] && confData[index][1].device_tag])
              : {};

            const tagValue2 = tagValuesecond && Object.values(tagValuesecond)[0] ? Object.values(tagValuesecond)[0] : 0;

            const tagValueThird = mqttTagData[card.device__machine__uid] && confData.length ?
              mqttTagData[card.device__machine__uid].find((f) => f && f[confData[index] && confData[index][2] && confData[index][2].device_tag])
              : {};

            const tagValue3 = tagValueThird && Object.values(tagValueThird)[0] ? Object.values(tagValueThird)[0] : 0;

            return (
              <div key={index}>
                <Card
                  style={{
                    width: "96%",
                  }}
                >
                  <h5
                    className="cardTitle"
                    style={{
                      opacity: "0.8",
                    }}
                  >
                    {card.device__device_name}
                  </h5>
                  <Table className="card-slider-table">
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          {confData[index] && confData[index].length > 0
                            ? (confData[index][0] && CompactTagUnit(selectUnit, confData[index][0].tag_unit)) || "-"
                            : "-"
                          }
                        </TableCell>
                        <TableCell>:</TableCell>
                        <TableCell>
                          {tagValue1 ? CompactTagValue(selectUnit, tagValue1) : 0}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {confData[index] && confData[index].length > 0
                            ? (confData[index][1] && CompactTagUnit(selectUnit, confData[index][1].tag_unit)) || "-"
                            : "-"
                          }
                        </TableCell>
                        <TableCell>:</TableCell>
                        <TableCell>
                          {tagValue2 ? CompactTagValue(selectUnit, tagValue2) : 0}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {confData[index] && confData[index].length > 0
                            ? (confData[index][2] && CompactTagUnit(selectUnit, confData[index][2].tag_unit)) || "-"
                            : "-"
                          }
                        </TableCell>
                        <TableCell>:</TableCell>
                        <TableCell>
                          {tagValue3 ? CompactTagValue(selectUnit, tagValue3) : 0}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Card>
              </div>
            );
          })
          : []}
      </Slider>
    </>
  );
}

export default ConsumptionSlider;
