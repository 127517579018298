import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts/highstock'; // Import Highstock module
import HighchartsReact from 'highcharts-react-official';
import HC_more from 'highcharts/highcharts-more';
import exporting from 'highcharts/modules/exporting';
import { useSelector } from 'react-redux';
import { images } from '../../../config';
import { useParams } from 'react-router-dom';
import { CompactTagUnit } from '../../../components/CompactValue/CompactValue';
import { ShimmerText } from "react-shimmer-effects";
import {ParetoChartShimmer} from "../../../components/Shimmer/ParetoChartShimmer/ParetoChartShimmer";

HC_more(Highcharts); // Initialize the 'highcharts-more' module
exporting(Highcharts); // Initialize the 'exporting' module

export function ParetoChart({ analyticsData ,Shimmer}) {

  console.log("analyticsDataanalyticsData",analyticsData)
  const { deviceUnitRedux, deviceColors } = useSelector((state) => state.overViewState);
  const {
    selectUnit
  } = useSelector((state) => state.dateSliceprocessDashboardState);
  const chartRef = useRef(null);
  const param = useParams();

  // const labels = analyticsData.length ? analyticsData[0] && analyticsData[0].data.map((e) => e.MIN_KEY) : [];

  console.log("deviceUnitRedux", deviceUnitRedux);

  const labels = analyticsData.length
  ? analyticsData.flatMap((e) => e.data.map((e1) => e1.MIN_KEY))
    : [];
  

  const yAxiosTitle = deviceUnitRedux.length ? deviceUnitRedux.filter((f) => f.plantId == param.id).length ? deviceUnitRedux.filter((f) => f.plantId == param.id)[0]["tagUnit"] : "" : "";


  const data = analyticsData.length
    ? analyticsData.map((row, index) => {
        const findColor = deviceColors.find((f) => f.deviceId === row.device_id && f.plantId == param.id);
        return {
          name: row.device_name,
          data: row.data.length ? row.data.map((row2) => parseFloat(row2.tag_value)) : [],
          color: findColor ? findColor.color : '',
        };
        
      })
    : [];


  const options = {
    chart: {
      type: 'column', // Use 'column' type for vertical bar chart
      events: {
        load: function () {
          const xAxis = this.xAxis[0];
          const visibleCategories = labels.slice(-5); // Show the last 5 categories initially
          xAxis.setExtremes(labels.length - visibleCategories.length, labels.length - 1, false);
          this.redraw();
        },
      },
    }, 
    title: {
      text: null,
    },
    legend: {
      enabled: false, // Disable legend
    },
    xAxis: {
      categories: labels,
    },
    yAxis: {
      stackLabels: {
        enabled: false, // Set to false to remove the stack labels
        style: {
          fontWeight: 'bold',
          color: 'gray',
        },
      },
      title: {
        text: CompactTagUnit(selectUnit ,yAxiosTitle),
      }
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        dataLabels: {
          enabled: false, // Set to false to remove the data labels
          format: '{point.y}',
        },
      },
    },
    series: data,
    scrollbar: {
      enabled: true, // Enable scrollbar
    },
    exporting: {
      enabled: true, // Disable exporting options
    },
    credits: {
      enabled: false, // Disable credits
    },
  };

  useEffect(() => {
    const chart = chartRef.current?.chart;
    if (chart) {
      chart.options.xAxis.min = labels.length - 5; // Set the initial visible range for the x-axis
      chart.options.xAxis.max = labels.length - 1;
      chart.redraw();
    }
  }, [analyticsData]);

  return (
    // <div style={{ width: '100%', height: '322px' }}>
    <>
      {!Shimmer ? analyticsData.length ? <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />:<img src={images.noDataFoundImg} className="notFoundDataImg" alt='No Data Found Img'/> :<ParetoChartShimmer barCount={12} className="ParetoChartShimmer-OverView"/> }
    </>
    // </div>
  );
}



{/* <ShimmerText line={17} gap={15}  /> */}