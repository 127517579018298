import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import "../Source/Source.css";
import ConsumptionSlider from "./ConsumptionSlider";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import Switch from "@mui/material/Switch";
import { useMemo, useRef, useState } from "react";
import "../Consumption/Consumption.css";
import { getDeviceSourceAPI, getTagID } from "../Source/services";
import {
  getDeviceConf,
  getPlantDeviceTag,
  exportConsumptionReportExcelApi,
  exportSourceReportExcelApi,
} from "./service";
import { useEffect } from "react";
import { LineChart } from "./LineChart";
import HighchartsGraph from "./LineChart2";
import { cubejsApi } from "../../Cubejs/CubejsApiWrapper";
import { useCubeQuery } from "@cubejs-client/react";
import CubeQueryComponent from "./ConsumptionGraphData";
import CubeQueryComponent1 from "./ConsumptionGraphData1";
import CubeQueryComponent2 from "./ConsumptionGraphData2";
import CubeQueryComponent3 from "./ConsumptionGraphData3";
import ConsumptionThresholdValue from "./ConsumptionThresholdValue";
import { setProcessDashboarData } from "./slice/processDashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import Every15minutesRemoveGraphdata from "./RemoveGraphData/Every15minutesRemoveGraphdata";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { images } from "../../../config";
import {
  CompactTagUnit,
  CompactTagValue,
} from "../../../components/CompactValue/CompactValue";
import DeviceListShimmer from "../../../components/Shimmer/DeviceListShimmer/DeviceListShimmer";
import { setProcessDashboarDateSet } from "../sliceProcessDashboard/dateSliceprocessDashboard";

const Consumption = () => {
  const [deviceData, setDeviceData] = useState([]);
  const [tagData, setTagData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [unitSearchQuery, setUnitSearchQuery] = useState("");
  const [selectTimeRange, setSelectTimeRange] = useState("live");
  const [openEnergyModal, setOpenEnergyModal] = useState(false);

  const { deviceIdRe, deviceUnit, deviceColor } = useSelector(
    (state) => state.processDashboardState
  );
  const { user_access } = useSelector((state) => state.userState);
  const { selectUnit } = useSelector(
    (state) => state.dateSliceprocessDashboardState
  );

  const dispatch = useDispatch();
  // console.log("deviceColor", deviceColor);

  const handleUnitSearchChange = (event) => {
    setUnitSearchQuery(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notify = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const [deviceId, setDeviceId] = useState([]);
  const [unitName, setUnitName] = useState([]);
  const [getDevice, setGetDevice] = useState([]);
  const [minValue, setMinValue] = useState([]);
  const [maxValue, setMaxValue] = useState([]);
  const [chartCubeData, setChartCubeData] = useState([]);
  const [average, setAverage] = useState(false);
  const [confData, setConfData] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [chartLineData, setChartLineData] = useState([]);
  const [minMax, setMinMax] = useState([]);
  const [realTimevalue, setRealTimevalue] = useState([]);
  const [chartLineData1, setChartLineData1] = useState([]);
  const [minMax1, setMinMax1] = useState([]);
  const [realTimevalue1, setRealTimevalue1] = useState([]);
  const [chartLineData2, setChartLineData2] = useState([]);
  const [minMax2, setMinMax2] = useState([]);
  const [realTimevalue2, setRealTimevalue2] = useState([]);
  const [chartLineData3, setChartLineData3] = useState([]);
  const [minMax3, setMinMax3] = useState([]);
  const [realTimevalue3, setRealTimevalue3] = useState([]);
  const [combineChartData, setCombineChartData] = useState([[], [], [], []]);
  const [combineRealTimeData, setCombinerealTimeData] = useState([]);
  const [combineMinMax, setCombineminMax] = useState([]);
  const [percentageValue, setPercentageValue] = useState([0, 0, 0, 0]);
  const [cubeThresholdValue, setCubeThresoldValue] = useState([]);
  const [cubeTagUnitSTKVA, setCubeTagUnitSTKVA] = useState(0);
  const [thresholdValue, setThresholdValue] = useState(0);
  const [averageValue, setAverageValue] = useState([]);
  const [isShimmerUnitlist, setisShimmerUnitlist] = useState(false);
  const [isShimmerdevicelist, setisShimmerdevicelist] = useState(false);

  const location = useLocation();
  const splitUrl = location.pathname.split("/");

  const plantDashboardAccess = user_access.length
    ? user_access.find(
      (user) => user.access_module?.access_module_name === "Plant Dashboard"
    )
    : null;
  const consumptionAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
        (f) => f.sub_module_name === "Consumption"
      )
      : null
    : null;

  const sourceAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
        (f) => f.sub_module_name === "Source"
      )
      : null
    : null;
  // Line-1 Chart data
  const handelCubeChartLineData = (value) => {
    setChartLineData((prev) => {
      // Create a shallow copy of the previous state
      const indexCopy = [...prev];
      //==========================new add code==============================
      if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
        indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
      }

      return [...indexCopy, value];
    });
    // setChartLineData((prev) => [...prev, value]);

    setRealTimevalue([value]);
  };

  // Line-2 Chart data
  const handelCubeChartLineData1 = (value) => {
    setChartLineData1((prev) => {
      // Create a shallow copy of the previous state
      const indexCopy = [...prev];
      //==========================new add code==============================
      if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
        indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
      }

      return [...indexCopy, value];
    });
    // setChartLineData1((prev) => [...prev, value]);
    setRealTimevalue1([value]);
    // setMinMax1([minmax]);
  };

  // Line-3 Chart data
  const handelCubeChartLineData2 = (value) => {
    setChartLineData2((prev) => {
      // Create a shallow copy of the previous state
      const indexCopy = [...prev];
      //==========================new add code==============================
      if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
        indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
      }

      return [...indexCopy, value];
    });
    // setChartLineData2((prev) => [...prev, value]);
    setRealTimevalue2([value]);
    // setMinMax2([minmax]);
  };

  // Line-4 Chart data
  const handelCubeChartLineData3 = (value) => {
    setChartLineData3((prev) => {
      // Create a shallow copy of the previous state
      const indexCopy = [...prev];
      //==========================new add code==============================
      if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
        indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
      }

      return [...indexCopy, value];
    });
    // setChartLineData3((prev) => [...prev, value]);
    setRealTimevalue3([value]);
    // setMinMax3([minmax]);
  };

  const handleTostMsg = () => {
    notifyError("You don't have access");
  };

  useEffect(() => {
    setCombinerealTimeData([
      realTimevalue,
      realTimevalue1,
      realTimevalue2,
      realTimevalue3,
    ]);
    // setCombineminMax([minMax, minMax1, minMax2, minMax3]);
  }, [
    chartLineData,
    chartLineData1,
    chartLineData2,
    chartLineData3,
    realTimevalue,
    realTimevalue1,
    realTimevalue2,
    realTimevalue3,
    minMax,
    minMax1,
    minMax2,
    minMax3,
  ]);

  // device percentage value
  useEffect(() => {
    if (combineChartData.length) {
      console.log("[LOG] COMBINE CHART DATA", combineChartData);
      const resultArray = combineChartData.map((subArray) => {
        if (subArray.length >= 2) {
          const lastValue = subArray[subArray.length - 1].tagValue;
          const secondLastValue = subArray[subArray.length - 2].tagValue;
          const percentage =
            ((lastValue - secondLastValue) / secondLastValue) * 100;
          // console.log("lastValue", lastValue);
          // console.log("secondLastValue", secondLastValue);
          return parseFloat(percentage) ? parseFloat(percentage) : 0;
        } else {
          return 0;
        }
      });

      setPercentageValue(resultArray);
      // console.log("resultArrayresultArray", resultArray);
    }
  }, [combineChartData]);

  useMemo(() => {
    if (getDevice.length == 1 && chartLineData.length >= 2) {
      const lastValue = parseFloat(
        chartLineData[chartLineData.length - 1].tagValue
      );
      const secondLastValue = parseFloat(
        chartLineData[chartLineData.length - 2].tagValue
      );
      const chartLineDataAverage = (lastValue + secondLastValue) / 2;
      // setAverageValue((prev) => [...prev, chartLineDataAverage]);
      setAverageValue((prev) => {
        // Create a shallow copy of the previous state
        const indexCopy = [...prev, chartLineDataAverage];
        //==========================new add code==============================
        if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
          indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
        }

        return indexCopy;
      });
    }
  }, [chartLineData]);

  useMemo(() => {
    if (
      getDevice.length == 2 &&
      chartLineData.length == chartLineData1.length
    ) {
      console.log(
        "chartLineDatachartLineData",
        chartLineData.length,
        chartLineData1.length
      );

      const device1 = chartLineData.length
        ? [chartLineData[chartLineData.length - 1]]
        : [];
      const device2 = chartLineData1.length
        ? [chartLineData1[chartLineData1.length - 1]]
        : [];
      // const device3 = chartLineData2.length ? [chartLineData2[chartLineData2.length - 1]] : [];
      // const device4 = chartLineData3.length ? [chartLineData3[chartLineData3.length - 1]] : [];

      const nonEmptyArrays = [device1, device2].filter((arr) => arr.length > 0);
      const sum = nonEmptyArrays.reduce(
        (acc, arr) => acc + (parseFloat(arr[0]?.tagValue) || 0),
        0
      ); // Safely access tagValue

      // Calculate the average
      const average =
        nonEmptyArrays.length > 0 ? sum / nonEmptyArrays.length : 0;

      // Format the average to two decimal places using toFixed(2)
      const formattedAverage = average;

      // setAverageValue((prev) => [...prev, formattedAverage]);
      setAverageValue((prev) => {
        // Create a shallow copy of the previous state
        const indexCopy = [...prev, formattedAverage];
        //==========================new add code==============================
        if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
          indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
        }

        return indexCopy;
      });
    }
  }, [chartLineData1, selectUnit]);

  useMemo(() => {
    if (
      getDevice.length == 3 &&
      chartLineData.length == chartLineData1.length &&
      chartLineData1.length == chartLineData2.length
    ) {
      const device1 = chartLineData.length
        ? [chartLineData[chartLineData.length - 1]]
        : [];
      const device2 = chartLineData1.length
        ? [chartLineData1[chartLineData1.length - 1]]
        : [];
      const device3 = chartLineData2.length
        ? [chartLineData2[chartLineData2.length - 1]]
        : [];
      // const device4 = chartLineData3.length ? [chartLineData3[chartLineData3.length - 1]] : [];

      const nonEmptyArrays = [device1, device2, device3].filter(
        (arr) => arr.length > 0
      );
      const sum = nonEmptyArrays.reduce(
        (acc, arr) => acc + (parseFloat(arr[0]?.tagValue) || 0),
        0
      ); // Safely access tagValue

      // Calculate the average
      const average =
        nonEmptyArrays.length > 0 ? sum / nonEmptyArrays.length : 0;

      // Format the average to two decimal places using toFixed(2)
      const formattedAverage = average;

      // setAverageValue((prev) => [...prev, formattedAverage]);
      setAverageValue((prev) => {
        // Create a shallow copy of the previous state
        const indexCopy = [...prev, formattedAverage];
        //==========================new add code==============================
        if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
          indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
        }

        return indexCopy;
      });
    }
  }, [chartLineData1, chartLineData2, selectUnit]);

  useMemo(() => {
    if (
      getDevice.length == 4 &&
      chartLineData.length == chartLineData1.length &&
      chartLineData1.length == chartLineData2.length &&
      chartLineData2.length == chartLineData3.length
    ) {
      const device1 = chartLineData.length
        ? [chartLineData[chartLineData.length - 1]]
        : [];
      const device2 = chartLineData1.length
        ? [chartLineData1[chartLineData1.length - 1]]
        : [];
      const device3 = chartLineData2.length
        ? [chartLineData2[chartLineData2.length - 1]]
        : [];
      const device4 = chartLineData3.length
        ? [chartLineData3[chartLineData3.length - 1]]
        : [];

      const nonEmptyArrays = [device1, device2, device3, device4].filter(
        (arr) => arr.length > 0
      );
      const sum = nonEmptyArrays.reduce(
        (acc, arr) => acc + (parseFloat(arr[0]?.tagValue) || 0),
        0
      ); // Safely access tagValue

      // Calculate the average
      const average =
        nonEmptyArrays.length > 0 ? sum / nonEmptyArrays.length : 0;

      // Format the average to two decimal places using toFixed(2)
      const formattedAverage = average;

      // setAverageValue((prev) => [...prev, formattedAverage]);
      setAverageValue((prev) => {
        // Create a shallow copy of the previous state
        const indexCopy = [...prev, formattedAverage];
        //==========================new add code==============================
        if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
          indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
        }

        return indexCopy;
      });
    }
  }, [chartLineData1, chartLineData2, chartLineData3, selectUnit]);

  // threshold value in st_kva
  useEffect(() => {
    const differentValue = combineRealTimeData
      .filter((arr) => arr.length > 0)
      .map(
        (arr, index) => parseFloat(arr[0].tagValue) - cubeThresholdValue[index]
      );

    // console.log("differentValuedifferentValue", cubeTagUnitSTKVA);
    if (
      differentValue.length &&
      cubeTagUnitSTKVA &&
      differentValue.every(
        (value) => typeof value === "number" && !isNaN(value)
      )
    ) {
      const kvaThreshold =
        (differentValue.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        ) /
          (cubeTagUnitSTKVA * 24)) *
        100;
      setThresholdValue(kvaThreshold ? kvaThreshold.toFixed(2) : 0);
    } else {
      console.error(
        "Invalid data in combineRealTimeData or cubeThresholdValue"
      );
    }
  }, [cubeThresholdValue]);

  const switchColors = [
    "#FEBB22",
    "#075571",
    "#002B2A",
    "#C99C00",
    "#69927A",
    "#872B81",
    "#F06292",
    "#29445A",
    "#CD4D4A",
    "#0C4562",
    "#544632",
    "#94655B",
    "#0F4C81",
    "#8B0000",
    "#001A30",
    "#0B648D",
    "#BC2F00",
    "#556830",
    "#FCA311",
    "#021116",
    "#808080",
    "#300338",
    "#634C23",
    "#841E62",
    "#F34424",
    "#003333",
    "#020000",
    "#305355",
    "#5C3A31",
    "#D9750C",
    "#123782",
    "#C60BC8",
    "#00838F",
    "#785049",
    "#FF8800",
    "#0B7C8E",
    "#08535E",
    "#0E3504",
    "#C48200",
    "#846748",
    "#EB9E79",
    "#9C27B0",
    "#A97E07",
    "#CE286D",
    "#8409DF",
    "#7D2264",
    "#0EB520",
    "#0E3504",
    "#1D733F",
    "#6E02B1",
  ];

  const navigate = useNavigate();

  const param = useParams();

  useEffect(() => {
    const splitUrl = location.pathname.split("/");
    if (splitUrl[1] == "consumption") {
      getDeviceData("Consumption");
    }

    if (splitUrl[1] == "source") {
      getDeviceData("Source");
    }
  }, []);

  // deviceData.forEach((device, index) => {
  //   console.log("Device at index", index, ":", device.device);
  // });

  const getDeviceData = async (type) => {
    setisShimmerdevicelist(true);
    setisShimmerUnitlist(true);
    const params = {
      device_type: type,
      plant_id: Number(param.id),
    };
    try {
      const resp = await getDeviceSourceAPI(params);
      if (resp.status == 200) {
        setTimeout(() => {
          setisShimmerdevicelist(false);
        }, 1000);
        setTimeout(() => {
          setisShimmerUnitlist(false);
        }, 1000);

        setDeviceData(resp.data.payload.device);
        setTagData(resp.data.payload.tag);
      }
    } catch (error) {
      setisShimmerdevicelist(false);
      setisShimmerUnitlist(false);
      console.log("error", error);
    }
  };
  const handleSearchData = () => { };
  useEffect(() => {
    const fetchData = async (type) => {
      const newConfData = await Promise.all(
        deviceData.map(async (device) => {
          const unit = await getDeviceConfData(device.device, type);
          return unit;
        })
      );
      setConfData(newConfData);
    };

    if (splitUrl[1] == "consumption") {
      fetchData("Consumption");
    }

    if (splitUrl[1] == "source") {
      fetchData("Source");
    }
  }, [deviceData]);

  const getDeviceConfData = async (deviceId, type) => {
    try {
      const param1 = {
        plant_id: param.id,
        company_id: param.companyId,
        device_type: type,
        device_id: deviceId,
      };

      const resp = await getDeviceConf(param1);
      if (resp.status === 200 || resp.status === 201) {
        return resp.data.payload.length
          ? resp.data.payload[0].tag_assigned
          : [];
      }
    } catch (error) {
      console.error("Error fetching device configuration:", error);
    }
  };

  const isCheckboxDisabled = (id) => {
    const selectedDevices = deviceIdRe.filter(
      (device) => device.plantId === param.id && device.type == splitUrl[1]
    );
    return (
      selectedDevices.length >= 4 &&
      !selectedDevices.some((device) => device.deviceId === id)
    );
  };

  const handleSelectDeviceId = (id, index, event, row) => {
    const existingIndex = deviceColor.findIndex(
      (color) => color.deviceId === row.device && color.type == splitUrl[1]
    );

    if (existingIndex !== -1) {
      const updatedColors = [...deviceColor];
      updatedColors.splice(existingIndex, 1);
      dispatch(
        setProcessDashboarData({
          deviceColor: updatedColors,
        })
      );
    } else {
      // Add the object to the state array if it doesn't exist
      dispatch(
        setProcessDashboarData({
          deviceColor: [
            ...deviceColor,
            {
              deviceId: row.device,
              dvName: row.device__device_name,
              color: event.target.name,
              type: splitUrl[1],
            },
          ],
        })
      );
    }

    const updatedDeviceIdRe = [...deviceIdRe]; // Create a copy of the array

    if (!Array.isArray(deviceIdRe)) {
      setDeviceId([]);
      dispatch(setProcessDashboarData({ deviceIdRe: [] }));
    }

    // Check if the selected device is already in the array
    const isSelected = updatedDeviceIdRe.some(
      (device) => device.deviceId === id && device.type == splitUrl[1]
    );

    if (isSelected) {
      // Remove the selected device from the array
      const indexToRemove = updatedDeviceIdRe.findIndex(
        (device) => device.deviceId === id && device.type == splitUrl[1]
      );
      if (indexToRemove !== -1) {
        updatedDeviceIdRe.splice(indexToRemove, 1);
        dispatch(setProcessDashboarData({ deviceIdRe: updatedDeviceIdRe }));
      }
    } else {
      // Add the selected device to the array
      updatedDeviceIdRe.push({
        plantId: param.id,
        deviceId: id,
        type: splitUrl[1],
      });
      dispatch(setProcessDashboarData({ deviceIdRe: updatedDeviceIdRe }));
    }
  };

  const handleSelectTagUnit = (id) => {
    const selectedUnit = deviceUnit.find(
      (unit) => unit.plantId === param.id && unit.type == splitUrl[1]
    );
    if (!Array.isArray(deviceUnit)) {
      dispatch(setProcessDashboarData({ deviceUnit: [] }));
    }

    if (selectedUnit && selectedUnit.tagUnit == id) {
      dispatch(
        setProcessDashboarData({
          deviceUnit: deviceUnit.filter(
            (unit) => unit.plantId !== param.id && unit.type == splitUrl[1]
          ),
        })
      );
    } else {
      const selectTag = deviceUnit.length
        ? deviceUnit.find((f) => f.plantId == param.id && f.type == splitUrl[1])
          ? true
          : false
        : false;
      if (selectTag) {
        return true;
      } else {
        dispatch(
          setProcessDashboarData({
            deviceUnit: [
              ...deviceUnit,
              { plantId: param.id, tagUnit: id, type: splitUrl[1] },
            ],
          })
        );
      }
    }
  };

  const getTagIDApi = async () => {
    const findPlantviseDeviceId = deviceIdRe
      .filter((f) => f.plantId == param.id && f.type == splitUrl[1])
      .map((e) => e.deviceId);
    try {
      const params = {
        device_id: findPlantviseDeviceId,
        tag_unit: deviceUnit.filter(
          (f) => f.plantId == param.id && f.type == splitUrl[1]
        )[0]["tagUnit"],
      };
      console.log("paramparamparam", params);
      const resp = await getTagID(params);

      if (resp.status === 200 && resp.data.payload.length) {
        setGetDevice(
          findPlantviseDeviceId.map((id) =>
            resp.data.payload.find((item) => item.device == id)
          )
        );
      }
    } catch (error) {
      // Handle the error here
      console.error("An error occurred while fetching tag IDs:", error);
    }
  };

  useEffect(() => {
    if (deviceIdRe.length && deviceUnit.length) {
      setChartLineData([]);
      setChartLineData1([]);
      setChartLineData2([]);
      setChartLineData3([]);
      setMinMax([]);
      setMinMax1([]);
      setMinMax2([]);
      setMinMax3([]);
      setRealTimevalue([]);
      setRealTimevalue1([]);
      setRealTimevalue2([]);
      setRealTimevalue3([]);
      setCombineChartData([[], [], [], []]);
      setCombineminMax([]);
      setCombinerealTimeData([]);
      setGetDevice([]);
      setAverageValue([]);
      setThresholdValue(0);
      getTagIDApi();
    } else {
      setChartLineData([]);
      setChartLineData1([]);
      setChartLineData2([]);
      setChartLineData3([]);
      setMinMax([]);
      setMinMax1([]);
      setMinMax2([]);
      setMinMax3([]);
      setRealTimevalue([]);
      setRealTimevalue1([]);
      setRealTimevalue2([]);
      setRealTimevalue3([]);
      setCombineChartData([[], [], [], []]);
      setCombineminMax([]);
      setCombinerealTimeData([]);
      setGetDevice([]);
      setAverageValue([]);
      setThresholdValue(0);
    }
  }, [deviceIdRe.length, deviceUnit.length, selectTimeRange]);

  const handleExportConsumptionReportExcel = async () => {
    if (!deviceIdRe.length && !deviceUnit.length) {
      notifyError("please select device or unit");
      return true;
    }

    const paramReq = {
      company: param.companyId,
      plant: param.id,
      time: combineChartData.length
        ? combineChartData[0].map((e) => e.tagTime)
        : [],
      device: deviceIdRe
        .filter((f) => f.plantId == param.id && f.type == splitUrl[1])
        .map((e) => e.deviceId),
      tag_unit: deviceUnit.filter(
        (f) => f.plantId == param.id && f.type == splitUrl[1]
      )[0]["tagUnit"],
      tag_value: combineChartData.length
        ? combineChartData.flatMap((array) => array.map((obj) => obj.tagValue))
        : [],
    };

    if (splitUrl[1] == "consumption") {
      const resp = await exportConsumptionReportExcelApi(paramReq);
      console.log("respresprespresp", resp.data);
      if (resp.data.success == true) {
        notify("Energy data exported successfully.");
      }
    } else {
      const resp = await exportSourceReportExcelApi(paramReq);
      console.log("respresprespresp", resp.data);
      if (resp.data.success == true) {
        notify("Energy data exported successfully.");
      }
    }
  };

  const getTagTime = () => {
    return new Date().toLocaleTimeString("en-US", {
      timeZone: "Asia/Kolkata",
      hour12: false,
    });
  };

  const executeCubeQuery = async () => {
    try {
      var currentTime;

      if (selectTimeRange == "1min") {
        console.log(
          "selectTimeRange.........................",
          selectTimeRange
        );
        currentTime = new Date().toLocaleTimeString("en-US", {
          timeZone: "Asia/Kolkata",
          hour12: false,
        });
      }

      if (selectTimeRange == "5min") {
        console.log(
          "selectTimeRange.........................",
          selectTimeRange
        );
        currentTime = new Date().toLocaleTimeString("en-US", {
          timeZone: "Asia/Kolkata",
          hour12: false,
        });
      }

      if (selectTimeRange == "15min") {
        currentTime = new Date().toLocaleTimeString("en-US", {
          timeZone: "Asia/Kolkata",
          hour12: false,
        });
      }

      if (selectTimeRange == "30min") {
        currentTime = new Date().toLocaleTimeString("en-US", {
          timeZone: "Asia/Kolkata",
          hour12: false,
        });
      }

      const chartGraph = await cubejsApi.load({
        dimensions: [
          "DeviceConfigurationTagconfiguration.latestValue",
          "DeviceConfigurationTagconfiguration.tagName",
          "DeviceConfigurationTagconfiguration.deviceId",
        ],
        filters: [
          {
            member: "DeviceConfigurationTagconfiguration.tagName",
            operator: "equals",
            values: getDevice.length
              ? getDevice.map((row) => row.tag_name)
              : [],
          },
        ],
        limit: 4,
      });

      const findPlantviseDeviceId = deviceIdRe
        .filter((f) => f.plantId == param.id && f.type == splitUrl[1])
        .map((e) => e.deviceId);

      const sortingSelectViseDeiveId = findPlantviseDeviceId.map((row) => {
        return chartGraph
          .tablePivot()
          ?.find(
            (id) => id["DeviceConfigurationTagconfiguration.deviceId"] == row
          );
      });
      setCombineChartData((prev) => {
        const zeroIndx = [...prev];

        // Create a shallow copy of the inner array you want to modify (assuming it's an array)
        let innerArrayCopy = [...zeroIndx[0]];
        let innerArrayCopy1 = [...zeroIndx[1]];
        let innerArrayCopy2 = [...zeroIndx[2]];
        let innerArrayCopy3 = [...zeroIndx[3]];

        // Push the new object into the inner array copy
        if (getDevice[0]) {
          if (
            innerArrayCopy[innerArrayCopy.length - 1]?.tagTime != getTagTime()
          ) {
            innerArrayCopy.push({
              tagValue:
                sortingSelectViseDeiveId?.[0]?.[
                "DeviceConfigurationTagconfiguration.latestValue"
                ],
              tagTime: getTagTime(),
            });
            handelCubeChartLineData(
              {
                tagValue:
                  sortingSelectViseDeiveId?.[0]?.[
                  "DeviceConfigurationTagconfiguration.latestValue"
                  ],
                tagTime: getTagTime(),
              },
              null
            );
          }
        }

        if (getDevice[1]) {
          if (
            innerArrayCopy1[innerArrayCopy1.length - 1]?.tagTime != getTagTime()
          ) {
            innerArrayCopy1.push({
              tagValue:
                sortingSelectViseDeiveId?.[1]?.[
                "DeviceConfigurationTagconfiguration.latestValue"
                ],
              tagTime: getTagTime(),
            });
            handelCubeChartLineData1(
              {
                tagValue:
                  sortingSelectViseDeiveId?.[1]?.[
                  "DeviceConfigurationTagconfiguration.latestValue"
                  ],
                tagTime: getTagTime(),
              },
              null
            );
          }
        }

        if (getDevice[2]) {
          if (
            innerArrayCopy2[innerArrayCopy2.length - 1]?.tagTime != getTagTime()
          ) {
            innerArrayCopy2.push({
              tagValue:
                sortingSelectViseDeiveId?.[2]?.[
                "DeviceConfigurationTagconfiguration.latestValue"
                ],
              tagTime: getTagTime(),
            });
            handelCubeChartLineData2(
              {
                tagValue:
                  sortingSelectViseDeiveId?.[2]?.[
                  "DeviceConfigurationTagconfiguration.latestValue"
                  ],
                tagTime: getTagTime(),
              },
              null
            );
          }
        }

        if (getDevice[3]) {
          if (
            innerArrayCopy3[innerArrayCopy3.length - 1]?.tagTime != getTagTime()
          ) {
            innerArrayCopy3.push({
              tagValue:
                sortingSelectViseDeiveId?.[3]?.[
                "DeviceConfigurationTagconfiguration.latestValue"
                ],
              tagTime: getTagTime(),
            });
            handelCubeChartLineData3(
              {
                tagValue:
                  sortingSelectViseDeiveId?.[3]?.[
                  "DeviceConfigurationTagconfiguration.latestValue"
                  ],
                tagTime: getTagTime(),
              },
              null
            );
          }
        }

        //==========================new add code==============================
        if (innerArrayCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
          innerArrayCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
        }

        if (innerArrayCopy1.length >= process.env.REACT_APP_GRAPH_LENGTH) {
          innerArrayCopy1.shift(); // Remove oldest entry if exceeds MAX_LENGTH
        }

        if (innerArrayCopy2.length >= process.env.REACT_APP_GRAPH_LENGTH) {
          innerArrayCopy2.shift(); // Remove oldest entry if exceeds MAX_LENGTH
        }

        if (innerArrayCopy3.length >= process.env.REACT_APP_GRAPH_LENGTH) {
          innerArrayCopy3.shift(); // Remove oldest entry if exceeds MAX_LENGTH
        }

        zeroIndx[0] = innerArrayCopy;
        zeroIndx[1] = innerArrayCopy1;
        zeroIndx[2] = innerArrayCopy2;
        zeroIndx[3] = innerArrayCopy3;

        return zeroIndx;
      });

      console.log("chartGraphchartGraphchartGraph", sortingSelectViseDeiveId);
    } catch (error) {
      // Handle any errors that occur during the cube query
      console.error("Cube query error:", error);
    }
  };

  useEffect(() => {
    if (selectTimeRange != "live" && getDevice.length) {
      console.log("kpkpkpkpkpkpkkpk");
      // Function to execute the cube query

      // Set interval to execute the cube query based on selectTimeRange
      let interval = null;
      switch (selectTimeRange) {
        case "1min":
          interval = setInterval(executeCubeQuery, 60000);
          break;
        case "5min":
          interval = setInterval(executeCubeQuery, 300000);
          break;
        case "15min":
          interval = setInterval(executeCubeQuery, 900000);
          break;
        default:
          clearInterval(interval); // Clear interval if selectTimeRange is not supported
      }

      // Execute the cube query immediately
      executeCubeQuery();

      // Clean up the interval when the component unmounts or when selectTimeRange changes
      return () => {
        clearInterval(interval);
      };
    }
  }, [getDevice, selectTimeRange]);

  const handleOpenEnergyModal = () => {
    setOpenEnergyModal((prev) => {
      const newState = !prev;
      console.log("openEnergy", newState);
      return newState;
    });
  };
  const modalRef = useRef();

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target) && !event.target.closest('.filter-img-class')) {
      setOpenEnergyModal(false);
    }
  };

  useEffect(() => {
    if (openEnergyModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openEnergyModal]);

  return (
    <>
      {/* ===========================old code hang=========================== */}
      {/* {getDevice.length && selectTimeRange == "live" && chartLineData.length ? (
        <Every15minutesRemoveGraphdata
          chartLineData={chartLineData}
          setChartLineData={setChartLineData}
          getDevice={getDevice}
          setCombineChartData={setCombineChartData}
          setAverageValue={setAverageValue}
          combineChartData={combineChartData}
        />
      ) : null} */}
      {getDevice.length ? (
        <CubeQueryComponent
          getDevice={getDevice}
          handelCubeChartLineData={handelCubeChartLineData}
          handelCubeChartLineData1={handelCubeChartLineData1}
          handelCubeChartLineData2={handelCubeChartLineData2}
          handelCubeChartLineData3={handelCubeChartLineData3}
          deviceId={deviceId}
          selectTimeRange={selectTimeRange}
          setCombineChartData={setCombineChartData}
          setCombineminMax={setCombineminMax}
          setCombinerealTimeData={setCombinerealTimeData}
          combineRealTimeData={combineRealTimeData}
        />
      ) : null}
      {/* {selectTimeRange != "live" ? (
        getDevice.length ? (
          getDevice[1] ? (
            <CubeQueryComponent1
              getDevice={getDevice}
              deviceId={deviceId}
              selectTimeRange={selectTimeRange}
              handelCubeChartLineData1={handelCubeChartLineData1}
                          />
          ) : null
        ) : null
      ) : null}
      {selectTimeRange != "live" ? (
        getDevice.length ? (
          getDevice[2] ? (
            <CubeQueryComponent2
              getDevice={getDevice}
              deviceId={deviceId}
              selectTimeRange={selectTimeRange}
              handelCubeChartLineData2={handelCubeChartLineData2}
                          />
          ) : null
        ) : null
      ) : null}
      {selectTimeRange != "live" ? (
        getDevice.length ? (
          getDevice[3] ? (
            <CubeQueryComponent3
              getDevice={getDevice}
              deviceId={deviceId}
              selectTimeRange={selectTimeRange}
              handelCubeChartLineData3={handelCubeChartLineData3}
                          />
          ) : null
        ) : null
      ) : null} */}
      {/* {deviceIdRe.length ? (
        <ConsumptionThresholdValue
          getDevice={getDevice}
          setCubeThresoldValue={setCubeThresoldValue}
          setCubeTagUnitSTKVA={setCubeTagUnitSTKVA}
          combineRealTimeData={combineRealTimeData}
        />
      ) : null} */}
      {/* <img src="" />
      <div>
        
      </div> */}
      <div className="page-wraper">
        <div className="page-header justify-content-between d-flex overview-table-header">
          <div
            onClick={() =>
              navigate(
                `/company/${param.companyId}/plant/plantprocessdashboard/${param.id}`
              )
            }
            className="page-btn-consumption"
          >
            <ArrowBackIcon />
            <span>
              {" "}
              {splitUrl[1] == "consumption" ? "Consumptions" : "Source"}
            </span>
          </div>

        </div>

        <div className="overview-card-header sticky-page">
          <div className="page-header d-flex justify-content-between">
            <div
              className="d-flex justify-content-between"
              style={{ width: "100%", flexWrap: "wrap" }}
            >
              <div
                onClick={() =>
                  navigate(
                    `/company/${param.companyId}/plant/plantprocessdashboard/${param.id}`
                  )
                }
                className="page-back-btn"
                style={{ marginTop: "-19px" }}
              >
                <ArrowBackIcon />
                <span>
                  {" "}
                  {splitUrl[1] == "consumption" ? "Consumptions" : "Source"}
                </span>
              </div>
              <div className="d-flex gap-2 justify-content-end compact-sec">
                <div>
                  <select
                    className="compact-btn"
                    value={selectUnit}
                    onChange={(e) =>
                      dispatch(
                        setProcessDashboarDateSet({
                          selectUnit: e.target.value,
                        })
                      )
                    }
                  >
                    <option value="k">Kilo</option>
                    <option value="m">Mega</option>
                    <option value="g">Giga</option>
                  </select>
                </div>
                <div>
                  <button
                    className="configration-btn confi-btn"

                    onClick={() => {
                      if (splitUrl[1] === "consumption") {
                        navigate(`/consumption-configration/company/${param.companyId}/plant/${param.id}`);
                      } else {
                        navigate(`/configration-source/source/company/${param.companyId}/plant/${param.id}`);
                      }
                    }}
                  >
                    <img
                      src={images.configration}
                      style={{
                        height: "18px",
                        width: "18px",
                        marginRight: "8px",
                      }}
                      alt=""
                    />
                    Configuration
                  </button>
                </div>
                {/* <div
                  onClick={() => handleOpenEnergyModal()}
                  className="filter-img-class"
                >
                  <img
                    src={images.filterImg}
                    alt=""
                    style={{ height: "36px" }}
                  />
                </div> */}
                <div
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent the event from propagating to the document
                    handleOpenEnergyModal();
                  }}
                  className="filter-img-class"
                >
                  <img
                    src={images.filterImg}
                    alt=""
                    style={{ height: "40px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-3">
          <ConsumptionSlider
            deviceData={deviceData}
            confData={confData}
            getDevice={getDevice}
          />
        </div>
        <div className="pt-2 consumption-grid-card consumption-fixHeight">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3}>
              <Box className="source-box consumer-box">
                <Box className="consumer-text-disc mt-2">
                  <h6 className="Title">Title</h6>
                  <h6
                    className="Title-desc"
                    style={{ marginBottom: "0 !important" }}
                  >
                    Energy Meter Trends - Real Time
                  </h6>
                </Box>

                {/* <Box className="consumer-text-disc mt-2">
                <h6 className="Title">Description</h6>
                <h6 className="Title-desc">Energy Meter Trends - Real Time</h6>
              </Box> */}

                <TextField
                  variant="outlined"
                  className="search-textfield"
                  placeholder="Search Device here"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: <SearchIcon />,
                    inputProps: {
                      style: { padding: "9px" },
                    },
                  }}
                />
                <div className="furnace-grid">
                  {(consumptionAccess?.is_viewer &&
                    consumptionAccess?.is_editor) ||
                    (!consumptionAccess?.is_viewer &&
                      consumptionAccess?.is_editor) ||
                    (consumptionAccess?.is_viewer &&
                      !consumptionAccess?.is_editor) ||
                    (sourceAccess?.is_viewer && sourceAccess?.is_editor) ||
                    (!sourceAccess?.is_viewer && sourceAccess?.is_editor) ||
                    (sourceAccess?.is_viewer && !sourceAccess?.is_editor) ? (
                    <Box className="Grid-text mt-2">
                      {!isShimmerdevicelist ? (
                        deviceData.length ? (
                          deviceData
                            .filter((e) =>
                              e.device__device_name
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase())
                            )
                            .map((e, index) => (
                              <Grid item xs={12} key={e.device__device_name}>
                                <Grid
                                  container
                                  alignItems="center"
                                  style={{
                                    background: "#efeffd",
                                    width: "100%",
                                    marginBottom: "7px",
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={8}
                                    style={{
                                      padding: "7px 12px",
                                      borderLeft: `4px solid ${switchColors[
                                        index % switchColors.length
                                        ]
                                        }`,
                                    }}
                                  >
                                    <h6 className="mt-2">
                                      {e.device__device_name}
                                    </h6>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    textAlign="right"
                                    className="grid-switch-unit"
                                  >
                                    <Switch
                                      style={{ float: "right" }}
                                      onClick={(event) =>
                                        handleSelectDeviceId(
                                          e.device,
                                          index,
                                          event,
                                          e
                                        )
                                      }
                                      name={switchColors[index]}
                                      disabled={isCheckboxDisabled(e.device)}
                                      checked={deviceIdRe.find(
                                        (f) =>
                                          f.deviceId == e.device &&
                                          f.type == splitUrl[1]
                                      )}
                                      sx={{
                                        "& .MuiSwitch-thumb": {
                                          bgcolor: "#828282",
                                        },
                                        "& .MuiSwitch-track": {
                                          bgcolor: "#D9D9E5 !important",
                                        },
                                        "& .Mui-checked": {
                                          "& .MuiSwitch-thumb": {
                                            bgcolor:
                                              switchColors[
                                              index % switchColors.length
                                              ],
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#E2D9F2 !important",
                                          },
                                        },
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))
                        ) : (
                          []
                        )
                      ) : (
                        <DeviceListShimmer itemCount={3} />
                      )}
                    </Box>
                  ) : (
                    <Box className="Grid-text mt-2">
                      {!isShimmerdevicelist ? (
                        deviceData.length ? (
                          deviceData
                            .filter((e) =>
                              e.device__device_name
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase())
                            )
                            .map((e, index) => (
                              <Grid item xs={12} key={e.device__device_name}>
                                <Grid
                                  container
                                  alignItems="center"
                                  style={{
                                    background: "#efeffd",
                                    width: "100%",
                                    marginBottom: "7px",
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={8}
                                    style={{
                                      padding: "7px 12px",
                                      borderLeft: `4px solid ${switchColors[
                                        index % switchColors.length
                                        ]
                                        }`,
                                    }}
                                  >
                                    <h6 className="mt-2">
                                      {e.device__device_name}
                                    </h6>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    textAlign="right"
                                    className="grid-switch-unit"
                                  >
                                    <Switch
                                      style={{ float: "right" }}
                                      onClick={handleTostMsg}
                                      name={switchColors[index]}
                                      disabled={isCheckboxDisabled(e.device)}
                                      checked={deviceIdRe.find(
                                        (f) =>
                                          f.deviceId == e.device &&
                                          f.type == splitUrl[1]
                                      )}
                                      sx={{
                                        "& .MuiSwitch-thumb": {
                                          bgcolor: "#828282",
                                        },
                                        "& .MuiSwitch-track": {
                                          bgcolor: "#D9D9E5 !important",
                                        },
                                        "& .Mui-checked": {
                                          "& .MuiSwitch-thumb": {
                                            bgcolor:
                                              switchColors[
                                              index % switchColors.length
                                              ],
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#E2D9F2 !important",
                                          },
                                        },
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))
                        ) : (
                          []
                        )
                      ) : (
                        <DeviceListShimmer itemCount={3} />
                      )}
                    </Box>
                  )}
                </div>
                <Box className="consumer-text-disc mt-2 ">
                  {/* <h6 className="Title">Description</h6>
                <h6 className="Title-desc">Units</h6> */}
                  {/* <div class="horizontal-line"></div> */}
                </Box>

                <TextField
                  variant="outlined"
                  className="search-textfield"
                  placeholder="Search Unit here"
                  value={unitSearchQuery}
                  onChange={handleUnitSearchChange}
                  InputProps={{
                    startAdornment: <SearchIcon />,
                    inputProps: {
                      style: { padding: "9px" },
                    },
                  }}
                />

                <div className="device-unit" style={{height:"196px"}}>
                  <Box className="Grid-text1 mt-2">
                    {!isShimmerUnitlist ? (
                      [
                        ...new Set(
                          tagData
                            .filter(
                              (e) =>
                                e.tag_unit &&
                                e.tag_unit
                                  .toLowerCase()
                                  .includes(unitSearchQuery.toLowerCase())
                            )
                            .map((e) => e.tag_unit)
                        ),
                      ].map((tagUnit) => (
                        <Grid item xs={12} key={tagUnit}>
                          {tagUnit && (
                            <Grid
                              container
                              alignItems="center"
                              style={{
                                background: "#efeffd",
                                width: "100%",
                                marginBottom: "7px",
                              }}
                            >
                              <Grid
                                item
                                xs={8}
                                className="mt-2"
                                style={{ padding: "7px 12px" }}
                              >
                                <h6>{tagUnit}</h6>
                              </Grid>
                              {(consumptionAccess?.is_viewer &&
                                consumptionAccess?.is_editor) ||
                                (!consumptionAccess?.is_viewer &&
                                  consumptionAccess?.is_editor) ||
                                (consumptionAccess?.is_viewer &&
                                  !consumptionAccess?.is_editor) ||
                                (sourceAccess?.is_viewer &&
                                  sourceAccess?.is_editor) ||
                                (!sourceAccess?.is_viewer &&
                                  sourceAccess?.is_editor) ||
                                (sourceAccess?.is_viewer &&
                                  !sourceAccess?.is_editor) ? (
                                <Grid
                                  item
                                  xs={4}
                                  textAlign="right"
                                  className="grid-switch-unit"
                                >
                                  <Switch
                                    checked={
                                      deviceUnit.find(
                                        (f) =>
                                          f.tagUnit == tagUnit &&
                                          f.plantId == param.id &&
                                          f.type == splitUrl[1]
                                      )
                                        ? true
                                        : false
                                    }
                                    style={{ float: "right" }}
                                    onClick={() => handleSelectTagUnit(tagUnit)}
                                    sx={{
                                      "& .MuiSwitch-thumb": {
                                        bgcolor: "#828282",
                                      },
                                      "& .MuiSwitch-track": {
                                        bgcolor: "#D9D9E5 !important",
                                      },
                                      "& .Mui-checked": {
                                        "& .MuiSwitch-thumb": {
                                          bgcolor: "#50C878",
                                        },
                                        "& .MuiSwitch-track": {
                                          bgcolor: "#CEE6E1 !important",
                                        },
                                      },
                                    }}
                                  />
                                </Grid>
                              ) : (
                                <Grid
                                  item
                                  xs={4}
                                  textAlign="right"
                                  className="grid-switch-unit"
                                >
                                  <Switch
                                    checked={
                                      deviceUnit.find(
                                        (f) =>
                                          f.tagUnit == tagUnit &&
                                          f.plantId == param.id &&
                                          f.type == splitUrl[1]
                                      )
                                        ? true
                                        : false
                                    }
                                    style={{ float: "right" }}
                                    onClick={handleTostMsg}
                                    sx={{
                                      "& .MuiSwitch-thumb": {
                                        bgcolor: "#828282",
                                      },
                                      "& .MuiSwitch-track": {
                                        bgcolor: "#D9D9E5 !important",
                                      },
                                      "& .Mui-checked": {
                                        "& .MuiSwitch-thumb": {
                                          bgcolor: "#50C878",
                                        },
                                        "& .MuiSwitch-track": {
                                          bgcolor: "#CEE6E1 !important",
                                        },
                                      },
                                    }}
                                  />
                                </Grid>
                              )}
                            </Grid>
                          )}
                        </Grid>
                      ))
                    ) : (
                      <DeviceListShimmer itemCount={3} />
                    )}
                  </Box>
                </div>

                <div>
                  <button
                    className="export-btn"
                    onClick={handleExportConsumptionReportExcel}
                  >
                    EXPORT ENERGY DATA
                  </button>
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={9} className="consumption-table-box">
              <Box className="source-table-box">
                <div style={{ paddingTop: "20px" }}>
                  <div className="d-flex justify-content-end">
                    {["kwh", "kvah", "kvarh"].includes(
                      deviceUnit.length
                        ? deviceUnit.find(
                          (f) =>
                            f.plantId == param.id && f.type == splitUrl[1]
                        )
                          ? deviceUnit
                            .find(
                              (f) =>
                                f.plantId == param.id && f.type == splitUrl[1]
                            )
                          ["tagUnit"].toLowerCase()
                          : ""
                        : ""
                    ) && (
                        <Box className="source-boxes">
                          <h6 style={{ color: "#50C878" }}>
                            Cum. :{" "}
                            {percentageValue.length
                              ? percentageValue.reduce(
                                (total, value) => total + value,
                                0
                              )
                                ? percentageValue
                                  .reduce((total, value) => total + value, 0)
                                  .toFixed(2)
                                : 0
                              : 0}
                            %
                          </h6>
                        </Box>
                      )}

                    <Box
                      className="source-boxes"
                      style={{ marginRight: "22px" }}
                    >
                      <h6>
                        Threshold:<b> {thresholdValue}%</b>
                      </h6>
                    </Box>
                    <div className="d-flex avg-switch">
                      <p className="average-toggle consumption-avg-toggle">
                        Average
                      </p>
                      {(consumptionAccess?.is_viewer &&
                        consumptionAccess?.is_editor) ||
                        (!consumptionAccess?.is_viewer &&
                          consumptionAccess?.is_editor) ||
                        (consumptionAccess?.is_viewer &&
                          !consumptionAccess?.is_editor) ||
                        (sourceAccess?.is_viewer && sourceAccess?.is_editor) ||
                        (!sourceAccess?.is_viewer && sourceAccess?.is_editor) ||
                        (sourceAccess?.is_viewer && !sourceAccess?.is_editor) ? (
                        <Switch
                          checked={average}
                          onChange={(e) => setAverage(e.target.checked)}
                          className="average-toggle-switch"
                          sx={{
                            "& .MuiSwitch-thumb": {
                              bgcolor: "#828282",
                            },
                            "& .MuiSwitch-track": {
                              bgcolor: "#D9D9E5 !important",
                            },
                            "& .Mui-checked": {
                              "& .MuiSwitch-thumb": {
                                bgcolor: "#50C878",
                              },
                              "& .MuiSwitch-track": {
                                bgcolor: "#CEE6E1 !important",
                              },
                            },
                          }}
                        />
                      ) : (
                        <Switch
                          // checked={average}
                          onClick={handleTostMsg}
                          className="average-toggle-switch"
                          sx={{
                            "& .MuiSwitch-thumb": {
                              bgcolor: "#828282",
                            },
                            "& .MuiSwitch-track": {
                              bgcolor: "#D9D9E5 !important",
                            },
                            "& .Mui-checked": {
                              "& .MuiSwitch-thumb": {
                                bgcolor: "#50C878",
                              },
                              "& .MuiSwitch-track": {
                                bgcolor: "#CEE6E1 !important",
                              },
                            },
                          }}
                        />
                      )}
                    </div>
                    {/* <FormControlLabel
                    value="start"
                    control={<Switch color="#45BF6D" />}
                    label="Average"
                    labelPlacement="start"
                  /> */}
                    <div className="d-flex avg-switch">
                      <h6 className="time-desc">Interval</h6>
                      {(consumptionAccess?.is_viewer &&
                        consumptionAccess?.is_editor) ||
                        (!consumptionAccess?.is_viewer &&
                          consumptionAccess?.is_editor) ||
                        (consumptionAccess?.is_viewer &&
                          !consumptionAccess?.is_editor) ||
                        (sourceAccess?.is_viewer && sourceAccess?.is_editor) ||
                        (!sourceAccess?.is_viewer && sourceAccess?.is_editor) ||
                        (sourceAccess?.is_viewer && !sourceAccess?.is_editor) ? (
                        <Box style={{ float: "right", marginRight: "20px" }}>
                          <select
                            className="form-input-class consumption-interval-toggle"
                            style={{ padding: "6px 8px", borderRadius: "4px" }}
                            id="selectedClass"
                            // defaultValue="all"
                            value={selectTimeRange}
                            onChange={(event) =>
                              setSelectTimeRange(event.target.value)
                            }
                            required
                          >
                            <option value="live" selected>
                              Live
                            </option>
                            <option value="1min">1 Min</option>
                            <option value="5min">5 Min</option>
                            <option value="15min">15 Min</option>
                          </select>
                        </Box>
                      ) : (
                        <Box
                          sx={{ minWidth: 200 }}
                          style={{ float: "right", marginRight: "20px" }}
                        >
                          <select
                            className="form-input-class"
                            style={{ padding: "6px 8px", borderRadius: "4px" }}
                            id="selectedClass"
                            // defaultValue="all"
                            // value={selectTimeRange}
                            onChange={handleTostMsg}
                            required
                          >
                            <option value="live" selected>
                              Live
                            </option>
                            <option value="1min">1 Min</option>
                            <option value="5min">5 Min</option>
                            <option value="15min">15 Min</option>
                          </select>
                        </Box>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{ height: "421px", width: "100%", padding: "20px" }}
                >
                  {/* <LineChart
                  selectedColors={deviceColor}
                  unitName={deviceUnit[0]}
                  chartCubeData={chartCubeData}
                  getDevice={getDevice}
                  average={average}
                  chartLineData={chartLineData}
                  combineChartData={combineChartData}
                // Pass the selected switch color to the LineChart component
                /> */}

                  <HighchartsGraph
                    selectedColors={deviceColor}
                    unitName={CompactTagUnit(
                      selectUnit,
                      deviceUnit.length
                        ? deviceUnit.filter(
                          (f) =>
                            f.plantId == param.id && f.type == splitUrl[1]
                        )[0]?.["tagUnit"] || ""
                        : ""
                    )}
                    chartCubeData={chartCubeData}
                    getDevice={getDevice}
                    average={average}
                    chartLineData={chartLineData}
                    combineChartData={combineChartData}
                    selectTimeRange={selectTimeRange}
                    averageValue={averageValue}
                    selectUnit={selectUnit}
                  // setCombineChartData={setCombineChartData}
                  />
                </div>
                <div>
                  <Table className="source-table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            fontWeight: "600",
                            width: "18%",
                            fontSize: "18px",
                            fontWeight: "800",
                          }}
                        >
                          {/* {deviceUnit[0]} */}
                          {CompactTagUnit(
                            selectUnit,
                            deviceUnit.length
                              ? deviceUnit.filter(
                                (f) =>
                                  f.plantId == param.id &&
                                  f.type == splitUrl[1]
                              )[0]?.["tagUnit"] || ""
                              : ""
                          )}
                        </TableCell>

                        {getDevice.length
                          ? getDevice.map((e, index) => {
                            const findColor = deviceColor.find(
                              (f) => f.deviceId == e.device
                            );
                            return (
                              <TableCell>
                                <div className="d-flex justify-content-between">
                                  <div>{e.device__device_name}</div>
                                  <div>
                                    {percentageValue[index] > 0 ? (
                                      <NorthIcon
                                        style={{
                                          color: findColor
                                            ? findColor.color
                                            : "",
                                        }}
                                      />
                                    ) : percentageValue[index] == 0 ? (
                                      ""
                                    ) : (
                                      <SouthIcon
                                        style={{
                                          color: findColor
                                            ? findColor.color
                                            : "",
                                        }}
                                      />
                                    )}{" "}
                                    {/* <SouthIcon style={{ color: findColor ? findColor.color : ""}} />{" "} */}
                                    {percentageValue[index] == 0
                                      ? "-"
                                      : percentageValue[index]
                                        ? percentageValue[index].toFixed(2)
                                        : "-"}{" "}
                                    %
                                  </div>
                                </div>
                              </TableCell>
                            );
                          })
                          : []}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ fontWeight: "500" }}>
                          Real Time
                        </TableCell>
                        {/* {combineChartData.length ? combineChartData.map((e) =>{}) } */}
                        {getDevice && getDevice.length
                          ? getDevice.map((row, i) => {
                            const tagValue =
                              combineRealTimeData[i]?.[0]?.tagValue;
                            return (
                              <TableCell>
                                {tagValue
                                  ? CompactTagValue(selectUnit, tagValue)
                                  : ""}
                              </TableCell>
                            );
                          })
                          : []}
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontWeight: "500" }}>
                          All Time Min.
                        </TableCell>
                        {getDevice && getDevice.length
                          ? getDevice.map((row, i) => {
                            const findMinValue = combineMinMax.length
                              ? combineMinMax.find((f) => row.id == f.tag_id)
                              : 0;
                            return (
                              <TableCell>
                                {findMinValue
                                  ? findMinValue.all_time_min_value
                                  : "-"}
                              </TableCell>
                            );
                          })
                          : []}
                        {/* {minMax.length
                        ? minMax.map((e) => {
                          return <TableCell>{e.minValue}</TableCell>;
                        })
                        : []} */}
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontWeight: "500" }}>
                          All Time Max.
                        </TableCell>
                        {getDevice && getDevice.length
                          ? getDevice.map((row, i) => {
                            const findMinValue = combineMinMax.length
                              ? combineMinMax.find((f) => row.id == f.tag_id)
                              : 0;
                            return (
                              <TableCell>
                                {findMinValue
                                  ? findMinValue.all_time_max_value
                                  : "-"}
                              </TableCell>
                            );
                          })
                          : []}
                      </TableRow>
                    </TableBody>
                  </Table>
                  <Table className="source-consumption-table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            fontWeight: "600",
                            width: "18%",
                            fontSize: "14px",
                            fontWeight: "800",
                          }}
                        >
                          {/* {deviceUnit[0]} */}
                          {CompactTagUnit(
                            selectUnit,
                            deviceUnit.length
                              ? deviceUnit.filter(
                                (f) =>
                                  f.plantId == param.id &&
                                  f.type == splitUrl[1]
                              )[0]?.["tagUnit"] || ""
                              : ""
                          )}
                        </TableCell>
                        <TableCell style={{ fontWeight: "700" }}>
                          Real Time
                        </TableCell>
                        <TableCell style={{ fontWeight: "700" }}>
                          All Time Min.
                        </TableCell>
                        <TableCell style={{ fontWeight: "700" }}>
                          All Time Max.
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {getDevice && getDevice.length
                        ? getDevice.map((device, index) => {
                          const findColor = deviceColor.find(
                            (f) => f.deviceId == device.device
                          );
                          const tagValue =
                            combineRealTimeData[index]?.[0]?.tagValue;
                          const findMinValue = combineMinMax.find(
                            (f) => device.id == f.tag_id
                          );

                          return (
                            <TableRow key={index}>
                              <TableCell>
                                <div className="d-flex justify-content-between">
                                  <div>{device.device__device_name}</div>
                                  <div>
                                    {percentageValue[index] > 0 ? (
                                      <NorthIcon
                                        style={{
                                          color: findColor
                                            ? findColor.color
                                            : "",
                                        }}
                                      />
                                    ) : percentageValue[index] == 0 ? (
                                      ""
                                    ) : (
                                      <SouthIcon
                                        style={{
                                          color: findColor
                                            ? findColor.color
                                            : "",
                                        }}
                                      />
                                    )}
                                    {percentageValue[index] == 0
                                      ? "-"
                                      : percentageValue[index]
                                        ? percentageValue[index].toFixed(2)
                                        : "-"}{" "}
                                    %
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell>
                                {tagValue
                                  ? CompactTagValue(selectUnit, tagValue)
                                  : ""}
                              </TableCell>
                              <TableCell>
                                {findMinValue
                                  ? findMinValue.all_time_min_value
                                  : "-"}
                              </TableCell>
                              <TableCell>
                                {findMinValue
                                  ? findMinValue.all_time_max_value
                                  : "-"}
                              </TableCell>
                            </TableRow>
                          );
                        })
                        : []}
                    </TableBody>
                  </Table>
                </div>
              </Box>
            </Grid>
          </Grid>
        </div>
        {openEnergyModal && (
          <div className="consumerCard" style={{ top: "23%" }}>
            <Box className="source-box consumer-card-box">
              <Box className="consumer-text-disc mt-2">
                <h6 className="Title">Title</h6>
                <h6
                  className="Title-desc"
                  style={{ marginBottom: "0 !important" }}
                >
                  Energy Meter Trends - Real Time
                </h6>
              </Box>

              {/* <Box className="consumer-text-disc mt-2">
          <h6 className="Title">Description</h6>
          <h6 className="Title-desc">Energy Meter Trends - Real Time</h6>
        </Box> */}

              <TextField
                variant="outlined"
                className="search-textfield"
                placeholder="Search Device here"
                value={searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: <SearchIcon />,
                  inputProps: {
                    style: { padding: "9px" },
                  },
                }}
              />
              <div className="furnace-grid">
                {(consumptionAccess?.is_viewer &&
                  consumptionAccess?.is_editor) ||
                  (!consumptionAccess?.is_viewer &&
                    consumptionAccess?.is_editor) ||
                  (consumptionAccess?.is_viewer &&
                    !consumptionAccess?.is_editor) ||
                  (sourceAccess?.is_viewer && sourceAccess?.is_editor) ||
                  (!sourceAccess?.is_viewer && sourceAccess?.is_editor) ||
                  (sourceAccess?.is_viewer && !sourceAccess?.is_editor) ? (
                  <Box className="Grid-text mt-2">
                    {!isShimmerdevicelist ? (
                      deviceData.length ? (
                        deviceData
                          .filter((e) =>
                            e.device__device_name
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())
                          )
                          .map((e, index) => (
                            <Grid item xs={12} key={e.device__device_name}>
                              <Grid
                                container
                                alignItems="center"
                                style={{
                                  background: "#efeffd",
                                  width: "100%",
                                  marginBottom: "7px",
                                }}
                              >
                                <Grid
                                  item
                                  xs={8}
                                  style={{
                                    padding: "7px 12px",
                                    borderLeft: `4px solid ${switchColors[index % switchColors.length]
                                      }`,
                                  }}
                                >
                                  <h6 className="mt-2">
                                    {e.device__device_name}
                                  </h6>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  textAlign="right"
                                  className="grid-switch-unit"
                                >
                                  <Switch
                                    style={{ float: "right" }}
                                    onClick={(event) =>
                                      handleSelectDeviceId(
                                        e.device,
                                        index,
                                        event,
                                        e
                                      )
                                    }
                                    name={switchColors[index]}
                                    disabled={isCheckboxDisabled(e.device)}
                                    checked={deviceIdRe.find(
                                      (f) =>
                                        f.deviceId == e.device &&
                                        f.type == splitUrl[1]
                                    )}
                                    sx={{
                                      "& .MuiSwitch-thumb": {
                                        bgcolor: "#828282",
                                      },
                                      "& .MuiSwitch-track": {
                                        bgcolor: "#D9D9E5 !important",
                                      },
                                      "& .Mui-checked": {
                                        "& .MuiSwitch-thumb": {
                                          bgcolor:
                                            switchColors[
                                            index % switchColors.length
                                            ],
                                        },
                                        "& .MuiSwitch-track": {
                                          bgcolor: "#E2D9F2 !important",
                                        },
                                      },
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          ))
                      ) : (
                        []
                      )
                    ) : (
                      <DeviceListShimmer itemCount={3} />
                    )}
                  </Box>
                ) : (
                  <Box className="Grid-text mt-2">
                    {!isShimmerdevicelist ? (
                      deviceData.length ? (
                        deviceData
                          .filter((e) =>
                            e.device__device_name
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())
                          )
                          .map((e, index) => (
                            <Grid item xs={12} key={e.device__device_name}>
                              <Grid
                                container
                                alignItems="center"
                                style={{
                                  background: "#efeffd",
                                  width: "100%",
                                  marginBottom: "7px",
                                }}
                              >
                                <Grid
                                  item
                                  xs={8}
                                  style={{
                                    padding: "7px 12px",
                                    borderLeft: `4px solid ${switchColors[index % switchColors.length]
                                      }`,
                                  }}
                                >
                                  <h6 className="mt-2">
                                    {e.device__device_name}
                                  </h6>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  textAlign="right"
                                  className="grid-switch-unit"
                                >
                                  <Switch
                                    style={{ float: "right" }}
                                    onClick={handleTostMsg}
                                    name={switchColors[index]}
                                    disabled={isCheckboxDisabled(e.device)}
                                    checked={deviceIdRe.find(
                                      (f) =>
                                        f.deviceId == e.device &&
                                        f.type == splitUrl[1]
                                    )}
                                    sx={{
                                      "& .MuiSwitch-thumb": {
                                        bgcolor: "#828282",
                                      },
                                      "& .MuiSwitch-track": {
                                        bgcolor: "#D9D9E5 !important",
                                      },
                                      "& .Mui-checked": {
                                        "& .MuiSwitch-thumb": {
                                          bgcolor:
                                            switchColors[
                                            index % switchColors.length
                                            ],
                                        },
                                        "& .MuiSwitch-track": {
                                          bgcolor: "#E2D9F2 !important",
                                        },
                                      },
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          ))
                      ) : (
                        []
                      )
                    ) : (
                      <DeviceListShimmer itemCount={3} />
                    )}
                  </Box>
                )}
              </div>
              <Box className="consumer-text-disc mt-2 ">
                {/* <h6 className="Title">Description</h6>
          <h6 className="Title-desc">Units</h6> */}
                {/* <div class="horizontal-line"></div> */}
              </Box>

              <TextField
                variant="outlined"
                className="search-textfield"
                placeholder="Search Unit here"
                value={unitSearchQuery}
                onChange={handleUnitSearchChange}
                InputProps={{
                  startAdornment: <SearchIcon />,
                  inputProps: {
                    style: { padding: "9px" },
                  },
                }}
              />

              <div className="device-unit" style={{height:"196px"}}>
                <Box className="Grid-text1 mt-2">
                  {!isShimmerUnitlist ? (
                    [
                      ...new Set(
                        tagData
                          .filter(
                            (e) =>
                              e.tag_unit &&
                              e.tag_unit
                                .toLowerCase()
                                .includes(unitSearchQuery.toLowerCase())
                          )
                          .map((e) => e.tag_unit)
                      ),
                    ].map((tagUnit) => (
                      <Grid item xs={12} key={tagUnit}>
                        {tagUnit && (
                          <Grid
                            container
                            alignItems="center"
                            style={{
                              background: "#efeffd",
                              width: "100%",
                              marginBottom: "7px",
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              className="mt-2"
                              style={{ padding: "7px 12px" }}
                            >
                              <h6>{tagUnit}</h6>
                            </Grid>
                            {(consumptionAccess?.is_viewer &&
                              consumptionAccess?.is_editor) ||
                              (!consumptionAccess?.is_viewer &&
                                consumptionAccess?.is_editor) ||
                              (consumptionAccess?.is_viewer &&
                                !consumptionAccess?.is_editor) ||
                              (sourceAccess?.is_viewer &&
                                sourceAccess?.is_editor) ||
                              (!sourceAccess?.is_viewer &&
                                sourceAccess?.is_editor) ||
                              (sourceAccess?.is_viewer &&
                                !sourceAccess?.is_editor) ? (
                              <Grid
                                item
                                xs={4}
                                textAlign="right"
                                className="grid-switch-unit"
                              >
                                <Switch
                                  checked={
                                    deviceUnit.find(
                                      (f) =>
                                        f.tagUnit == tagUnit &&
                                        f.plantId == param.id &&
                                        f.type == splitUrl[1]
                                    )
                                      ? true
                                      : false
                                  }
                                  style={{ float: "right" }}
                                  onClick={() => handleSelectTagUnit(tagUnit)}
                                  sx={{
                                    "& .MuiSwitch-thumb": {
                                      bgcolor: "#828282",
                                    },
                                    "& .MuiSwitch-track": {
                                      bgcolor: "#D9D9E5 !important",
                                    },
                                    "& .Mui-checked": {
                                      "& .MuiSwitch-thumb": {
                                        bgcolor: "#50C878",
                                      },
                                      "& .MuiSwitch-track": {
                                        bgcolor: "#CEE6E1 !important",
                                      },
                                    },
                                  }}
                                />
                              </Grid>
                            ) : (
                              <Grid
                                item
                                xs={4}
                                textAlign="right"
                                className="grid-switch-unit"
                              >
                                <Switch
                                  checked={
                                    deviceUnit.find(
                                      (f) =>
                                        f.tagUnit == tagUnit &&
                                        f.plantId == param.id &&
                                        f.type == splitUrl[1]
                                    )
                                      ? true
                                      : false
                                  }
                                  style={{ float: "right" }}
                                  onClick={handleTostMsg}
                                  sx={{
                                    "& .MuiSwitch-thumb": {
                                      bgcolor: "#828282",
                                    },
                                    "& .MuiSwitch-track": {
                                      bgcolor: "#D9D9E5 !important",
                                    },
                                    "& .Mui-checked": {
                                      "& .MuiSwitch-thumb": {
                                        bgcolor: "#50C878",
                                      },
                                      "& .MuiSwitch-track": {
                                        bgcolor: "#CEE6E1 !important",
                                      },
                                    },
                                  }}
                                />
                              </Grid>
                            )}
                          </Grid>
                        )}
                      </Grid>
                    ))
                  ) : (
                    <DeviceListShimmer itemCount={3} />
                  )}
                </Box>
              </div>

              <div>
                <button
                  className="export-btn"
                  onClick={handleExportConsumptionReportExcel}
                >
                  EXPORT ENERGY DATA
                </button>
              </div>
            </Box>
          </div>
        )}
      </div>
    </>
  );
};
export default Consumption;
