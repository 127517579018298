import React, { useEffect, useState } from "react";
import Mqqtdata from "../../../ProcessDashboard/Mqqtdata";

const MqttDataMachineDashboard = ({
  topics,
  tagData,
  setSliderRealTimeTagData,
  setMqttTagPreviousvalue,
  mqttTagPreviousvalue,
}) => {
  const [mqttTagData, setMqttTagData] = useState([]);
  console.log("mqttTagDatamqttTagData", tagData);

  useEffect(() => {
    const mergedArray = [].concat(...Object.values(mqttTagData));
    if (tagData.length) {
      const filteredArray = mergedArray.filter((obj) => {
        const device = obj.device__uid;
        const deviceTag = obj.device_tag;
        return tagData.some(
          (item) =>
            item.tag?.device?.machine_uid == device &&
            item.tag?.device_tag == deviceTag
        );
      });

      setSliderRealTimeTagData(filteredArray);

      setMqttTagPreviousvalue((prev) => {
        const prevValue = [...prev].map((row) => {
          const findTag = filteredArray.find(
            (item) => item.device_tag == row.dvName
          );
          if (findTag) {
            const checkTimeData = findTag.tagDateAndTime === row.dateAndTime;
            if (checkTimeData) {
              return row;
            }
            return {
              dvName: findTag.device_tag,
              prevValue: row.curValue,
              curValue: findTag.tagValue,
              dateAndTime: findTag.tagDateAndTime,
            };
          }
          return row;
        });
        return prevValue;
      });
    }
  }, [mqttTagData]);

  return (
    <>
      <Mqqtdata setMqttTagData={setMqttTagData} topics={topics} />
    </>
  );
};

export default MqttDataMachineDashboard;
