import React, { useEffect, useMemo, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Card,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import TagListComponent from "./TagListComponent/TagListComponent";
import GraphComponent from "./AnalyticsGraphComponent/GraphComponent";
import ReportsGraphComponent from "./ReportsGraphComponent/ReportsGraphComponent";
import { useParams, useNavigate } from "react-router-dom";
import {
  getDeviceByType,
  getDeviceDashboard,
  getDeviceUnits,
  getTagID,
} from "./service";
import { getTagList } from "../../../../SmartTag/services";
import dayjs, { Dayjs } from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { setVmsReportData } from "./Slice/VmsReportSlice";
import COCDatePicker from "../../../../../components/COCDatepicker/COCDatePicker";
import POPDatePicker from "../../../../../components/POPDatePicker/POPDatePicker";
import { getReportAPIComp } from "./getReportAPIComp";
import { getReportLineChartData } from "./getReportLineChartData";
import { getReportTableData } from "../service";
import { getDeviceType } from "../../../../ProcessDashboard/Source/services";
import { ToastContainer, toast } from "react-toastify";
import { SelectRangeArray } from "./SelectRangeArray";
import COCVmsDatePicker from "../../../VMSComponent/COCVmsDatePicker";
import POPVmsDatePicker from "../../../VMSComponent/POPVmsDatePicker";
import { getConsumptionDeviceData } from "../../../../ProcessDashboard/Consumption/service";
import { getDeviceByDashboard } from "../../../../SmartTag/services";
import { images } from "../../../../../config";

const VmsReports = () => {
  const [activeClass, setActiveClass] = useState("Analytics");
  const [activeTabClass, setActiveTabClass] = useState("Consumer");
  const [deviceData, setDeviceCOCData] = useState([]);
  const [tagUnitData, setTagUnitData] = useState([]);
  const [tagListData, setTagListData] = useState([]);
  const [cocGraphdata, setCocGraphData] = useState([]);
  const [cocLineChartdata, setCocLineChartData] = useState([]);
  const [averageCOCLineArray, setAverageCOCLineArray] = useState([]);
  const [averagePOPLineArray, setAveragePOPLineArray] = useState([]);
  const [popBarGraphData1, setPopBarGraphData1] = useState([]);
  const [popBarGraphData2, setPopBarGraphData2] = useState([]);
  const [popLineChartdata, setPopLineChartData] = useState([]);
  const [pop2LineChartdata, setPop2LineChartData] = useState([]);
  const [reportsTableData, setReportsTableData] = useState([]);
  const [selectTagIDReport1, setSelectTagIDReport1] = useState("none");
  const [selectTagIDReport2, setSelectTagIDReport2] = useState("none");
  const [reportsIntervalTime1, setReportIntervalTime1] = useState("1");
  const [reportLineChartData1, setReportLineChartData1] = useState([]);
  const [reportLineChartData2, setReportLineChartData2] = useState([]);
  const [deviceDataDetail, setDeviceData] = useState("");
  const [rangeModal, setRangeModal] = useState(false);
  const [rangeModalPOP, setRangeModalPOP] = useState(false);
  const [isShimmerCOC, setShimmerCOC] = useState(false);
  const [isShimmerPOP, setShimmerPOP] = useState(false);
  const [isShimmerLineChartCOC, setShimmerLineChartCOC] = useState(false);
  const [isShimmerLineChartPOP, setShimmerLineChartPOP] = useState(false);
  const [isShimmerLineChartReports, setShimmerLineChartReports] =
    useState(false);
  const [unitsData, setUnitsData] = useState([]);
  const [isShimmerdevicelist, setisShimmerdevicelist] = useState(false);

  const [rangeCOCValue, setRangeCOCValue] = useState("Set Range");
  const [rangePOPValue, setRangePOPValue] = useState("Set Range");
  const navigate = useNavigate();

  const param = useParams();
  const MAX_DATE_RANGE_DAYS = 365; // Maximum duration of one year in days

  console.log("reportsIntervalTime1",reportsIntervalTime1)
  const {
    VmsReportCOCSelectmachine,
    VmsReportCOCSelectUnit,
    VmsReportCOCSelectTag,
    VmsReportCOCStartDate,
    selectTimeRangeCOC,
    VmsReportCOCEndDate,
    VmsReportPOPStartDate1,
    VmsReportPOPEndDate1,
    VmsReportPOPStartDate2,
    VmsReportPOPEndDate2,
    VmsReportTime,
    VmsReportPOPTime,
    VmsReportPOPSelectmachine,
    VmsReportPOPSelectUnit,
    VmsReportPOPSelectTag,
    selectTimeRangePOP,
    reportsIntervalTime,
    reportIntervalTimePop,
    VmsReportReportsSelectmachine,
    VmsReportReportsSelectUnit,
    VmsReportReportsSelectTag,
    VmsReportReportsStartDate,
    VmsReportReportsEndDate,
    VmsReportReportsTime,
    vmsReportCOCRange,
    vmsReportPOPRange,
  } = useSelector((state) => state.VmsReportSlice);
  const dispatch = useDispatch();

  console.log("VmsReportCOCSelectmachine", VmsReportCOCSelectmachine);
  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });
  const { user_access } = useSelector((state) => state.userState);

  const plantDashboardAccess = user_access.length
    ? user_access.find(
        (user) => user.access_module?.access_module_name === "Plant Dashboard"
      )
    : null;

  const analyticsAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Analytics"
        )
      : null
    : null;

  const ReportAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Reports"
        )
      : null
    : null;

  // COC Date
  const startDateReCOC = VmsReportCOCStartDate
    ? dayjs(VmsReportCOCStartDate)
    : null;
  const endDateReCOC = VmsReportCOCEndDate ? dayjs(VmsReportCOCEndDate) : null;

  const startDatePOP = VmsReportPOPStartDate1
    ? dayjs(VmsReportPOPStartDate1)
    : null;
  const endDatePOP = VmsReportPOPEndDate1 ? dayjs(VmsReportPOPEndDate1) : null;

  const startDatePOP2 = VmsReportPOPStartDate2
    ? dayjs(VmsReportPOPStartDate2)
    : null;
  const endDatePOP2 = VmsReportPOPEndDate2 ? dayjs(VmsReportPOPEndDate2) : null;

  // Report Date

  const startDateReReports = VmsReportReportsStartDate
    ? dayjs(VmsReportReportsStartDate)
    : null;
  const endDateReReports = VmsReportReportsEndDate
    ? dayjs(VmsReportReportsEndDate)
    : null;

  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";
  const startDateFormatCOC = VmsReportCOCStartDate
    ? dayjs(VmsReportCOCStartDate).format(dateFormat)
    : "";
  const endDateFormatCOC = VmsReportCOCEndDate
    ? dayjs(VmsReportCOCEndDate).format(dateFormat)
    : "";

  const startTimeFormatCOC = VmsReportCOCStartDate
    ? dayjs(VmsReportCOCStartDate).format(timeFormat)
    : "";

  const endTimeFormatCOC = VmsReportCOCEndDate
    ? dayjs(VmsReportCOCEndDate).format(timeFormat)
    : "";

  // POP Format Date
  const startDateFormatPOP = VmsReportPOPStartDate1
    ? dayjs(VmsReportPOPStartDate1).format(dateFormat)
    : "";
  const endDateFormatPOP = VmsReportPOPEndDate1
    ? dayjs(VmsReportPOPEndDate1).format(dateFormat)
    : "";

  const startDateFormatPOP2 = VmsReportPOPStartDate2
    ? dayjs(VmsReportPOPStartDate2).format(dateFormat)
    : "";
  const endDateFormatPOP2 = VmsReportPOPEndDate2
    ? dayjs(VmsReportPOPEndDate2).format(dateFormat)
    : "";

  const startTimeFormatPOP = VmsReportPOPStartDate1
    ? dayjs(VmsReportPOPStartDate1).format(timeFormat)
    : "";

  const endTimeFormatPOP = VmsReportPOPEndDate1
    ? dayjs(VmsReportPOPEndDate1).format(timeFormat)
    : "";

  const startTimeFormatPOP2 = VmsReportPOPStartDate2
    ? dayjs(VmsReportPOPStartDate2).format(timeFormat)
    : "";

  const endTimeFormatPOP2 = VmsReportPOPEndDate2
    ? dayjs(VmsReportPOPEndDate2).format(timeFormat)
    : "";

  // Report Format Date

  const startDateFormatReports = VmsReportReportsStartDate
    ? dayjs(VmsReportReportsStartDate).format(dateFormat)
    : "";
  const endDateFormatReports = VmsReportReportsEndDate
    ? dayjs(VmsReportReportsEndDate).format(dateFormat)
    : "";

  const startTimeFormatReports = VmsReportReportsStartDate
    ? dayjs(VmsReportReportsStartDate).format(timeFormat)
    : "";

  const endTimeFormatReports = VmsReportReportsEndDate
    ? dayjs(VmsReportReportsEndDate).format(timeFormat)
    : "";

  // get device ID

  const getDeviceId = async () => {
    const params = {
      device: param.deviceId,
    };
    try {
      const resp = await getConsumptionDeviceData(params);
      if (resp.status === 200) {
        setDeviceData(resp.data.payload);
      }
    } catch (error) {
      console.error("An error occurred while fetching device data:", error);
      // Handle the error appropriately, e.g., show an error message to the user.
    }
  };

  useEffect(() => {
    getDeviceId();
  }, []);

  // get Device Type
  const getDeviceByPlant = async () => {
    const paramReq = {
      dashboard: 2,
      plant: param.plantId,
    };
    try {
      const resp = await getDeviceDashboard(paramReq);
      if (resp.status == 200) {
        console.log("resprespresp", resp);
        if (resp.data.payload.device.length) {
          setDeviceCOCData(resp.data.payload.device);
          const uniqueTagUnits = {};
          const filteredData = resp.data.payload.tag.filter((obj) => {
            if (!obj.tag_unit) return true; // Keep objects with no tag_unit property
            if (!uniqueTagUnits[obj.tag_unit]) {
              uniqueTagUnits[obj.tag_unit] = true;
              return true;
            }
            return false;
          });
          setTagUnitData(filteredData);
        }
      }
    } catch (error) {
      console.log("Error from GetSmartTagMachine", error);
    }
  };

  const handlegetUnits = async (id) => {
    const params = {
      device: id,
    };
    try {
      const resp = await getDeviceUnits(params);
      if (resp.status == 200 || resp.status == 201) {
        setUnitsData(resp.data.payload);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    getDeviceByPlant();
  }, []);

  const getDeviceByTagID = async (deviceId, tagUnit) => {
    setisShimmerdevicelist(true);
    try {
      const paramReq = {
        device_id: deviceId,
        tag_unit: tagUnit,
      };
      const resp = await getTagList(paramReq);
      if (resp.status == 200) {
        setTimeout(() => {
          setisShimmerdevicelist(false);
        }, 1000);

        setTagListData(resp.data.payload);
      }
    } catch (e) {
      setisShimmerdevicelist(false);

      console.log("error from getDeviceByTagID", e);
    }
  };

  useEffect(() => {
    const findPlantByCOCSelectmachine = VmsReportCOCSelectmachine.length
      ? VmsReportCOCSelectmachine.filter(
          (f) => f.deviceId == param.deviceId
        ).map((row) => row.machineId)
      : [];
    const findPlantByCOCSelectTagUnit = VmsReportCOCSelectUnit.length
      ? VmsReportCOCSelectUnit.filter((f) => f.deviceId == param.deviceId)
          .length
        ? VmsReportCOCSelectUnit.filter(
            (f) => f.deviceId == param.deviceId
          )?.map((row) => row.tagUnit) || ""
        : ""
      : "";

    const findPlantByPOPSelectmachine = VmsReportPOPSelectmachine.length
      ? VmsReportPOPSelectmachine.filter(
          (f) => f.deviceId == param.deviceId
        ).map((row) => row.machineId)
      : [];
    const findPlantByPOPSelectTagUnit = VmsReportPOPSelectUnit.length
      ? VmsReportPOPSelectUnit.filter((f) => f.deviceId == param.deviceId)
          .length
        ? VmsReportPOPSelectUnit.filter(
            (f) => f.deviceId == param.deviceId
          )?.map((row) => row.tagUnit) || ""
        : ""
      : "";

    const findPlantByReportsSelectmachine = VmsReportReportsSelectmachine.length
      ? VmsReportReportsSelectmachine.filter(
          (f) => f.deviceId == param.deviceId
        ).map((row) => row.machineId)
      : [];

    const findPlantByReportsSelectTagUnit = VmsReportReportsSelectUnit.length
      ? VmsReportReportsSelectUnit.filter((f) => f.deviceId == param.deviceId)
          .length
        ? VmsReportReportsSelectUnit.filter(
            (f) => f.deviceId == param.deviceId
          )?.map((row) => row.tagUnit) || ""
        : ""
      : "";

    if (
      findPlantByCOCSelectmachine.length &&
      findPlantByCOCSelectTagUnit &&
      activeTabClass == "Consumer" &&
      activeClass == "Analytics"
    ) {
      getDeviceByTagID(
        findPlantByCOCSelectmachine,
        findPlantByCOCSelectTagUnit
      );
    }

    if (
      findPlantByPOPSelectmachine.length &&
      findPlantByPOPSelectTagUnit &&
      activeTabClass == "Period" &&
      activeClass == "Analytics"
    ) {
      getDeviceByTagID(
        findPlantByPOPSelectmachine,
        findPlantByPOPSelectTagUnit
      );
    }

    if (
      findPlantByReportsSelectmachine.length &&
      findPlantByReportsSelectTagUnit &&
      activeClass == "Reports"
    ) {
      getDeviceByTagID(
        findPlantByReportsSelectmachine,
        findPlantByReportsSelectTagUnit
      );
    }

    if (
      findPlantByCOCSelectmachine &&
      activeTabClass == "Consumer" &&
      activeClass == "Analytics"
    ) {
      handlegetUnits(findPlantByCOCSelectmachine);
    } else {
      setUnitsData([]);
    }

    if (
      findPlantByPOPSelectmachine &&
      activeTabClass == "Period" &&
      activeClass == "Analytics"
    ) {
      handlegetUnits(findPlantByPOPSelectmachine);
    } else {
      setUnitsData([]);
    }
    
    if (findPlantByReportsSelectmachine && activeClass == "Reports") {
      handlegetUnits(findPlantByReportsSelectmachine);
    } else {
      setUnitsData([]);
    }
  }, [
    VmsReportCOCSelectmachine,
    VmsReportCOCSelectUnit,
    VmsReportPOPSelectmachine,
    VmsReportPOPSelectUnit,
    VmsReportReportsSelectmachine,
    VmsReportReportsSelectUnit,
    activeClass,
    activeTabClass,
  ]);

  // COC Handele Redux

  const handleSelectCOCMachine = (row) => {
    const existingIndex = VmsReportCOCSelectmachine.findIndex(
      (color) =>
        color.machineId === row.device && color.deviceId == param.deviceId
    );

    if (existingIndex !== -1) {
      const updateMachineId = [...VmsReportCOCSelectmachine];
      updateMachineId.splice(existingIndex, 1);
      dispatch(
        setVmsReportData({
          VmsReportCOCSelectmachine: updateMachineId,
        })
      );

      const findSelectedTagUnit = VmsReportCOCSelectUnit.length
        ? VmsReportCOCSelectUnit.filter((f) => f.deviceId != param.deviceId)
        : [];
      dispatch(
        setVmsReportData({
          VmsReportCOCSelectUnit: findSelectedTagUnit,
        })
      );

      const findSelectedTeg = VmsReportCOCSelectTag.length
        ? VmsReportCOCSelectTag.filter((f) => f.deviceId != param.deviceId)
        : [];
      dispatch(
        setVmsReportData({
          VmsReportCOCSelectTag: findSelectedTeg,
        })
      );
      setTagListData([]);
    } else {
      // Add the object to the state array if it doesn't exist
      dispatch(
        setVmsReportData({
          VmsReportCOCSelectmachine: [
            ...VmsReportCOCSelectmachine,
            {
              machineId: row.device,
              dvName: row.device__device_name,
              plantId: param.plantId,
              deviceId: param.deviceId,
            },
          ],
        })
      );
    }
  };

  const handleSelectCOCTagUnit = (row) => {
    const existingIndex = VmsReportCOCSelectUnit.findIndex(
      (color) =>
        color.tagUnit === row.tag_unit && color.deviceId == param.deviceId
    );

    if (existingIndex !== -1) {
      const updateMachineId = [...VmsReportCOCSelectUnit];
      updateMachineId.splice(existingIndex, 1);
      dispatch(
        setVmsReportData({
          VmsReportCOCSelectUnit: updateMachineId,
        })
      );

      const findSelectedTeg = VmsReportCOCSelectTag.length
        ? VmsReportCOCSelectTag.filter((f) => f.deviceId != param.deviceId)
        : [];
      dispatch(
        setVmsReportData({
          VmsReportCOCSelectTag: findSelectedTeg,
        })
      );
      setTagListData([]);
    } else {
      // Add the object to the state array if it doesn't exist
      dispatch(
        setVmsReportData({
          VmsReportCOCSelectUnit: [
            ...VmsReportCOCSelectUnit,
            {
              tagUnit: row.tag_unit,
              machineId: row.device,
              dvName: row.device__device_name,
              plantId: param.plantId,
              deviceId: param.deviceId,
            },
          ],
        })
      );
    }
  };

  const handleSelectCOCTag = (row, color) => {
    const existingIndex = VmsReportCOCSelectTag.findIndex(
      (color) => color.tagId === row.id && color.deviceId == param.deviceId
    );

    if (existingIndex !== -1) {
      const updateMachineId = [...VmsReportCOCSelectTag];
      updateMachineId.splice(existingIndex, 1);
      dispatch(
        setVmsReportData({
          VmsReportCOCSelectTag: updateMachineId,
        })
      );
    } else {
      // Add the object to the state array if it doesn't exist
      dispatch(
        setVmsReportData({
          VmsReportCOCSelectTag: [
            ...VmsReportCOCSelectTag,
            {
              tagId: row.id,
              tagUnit: row.tag_unit,
              machineId: row.device,
              dvName: row.device__device_name,
              plantId: param.plantId,
              deviceId: param.deviceId,
              color: color,
            },
          ],
        })
      );
    }
  };

  // POP Handele Redux

  const handleSelectPOPMachine = (row) => {
    const existingIndex = VmsReportPOPSelectmachine.findIndex(
      (color) =>
        color.machineId === row.device && color.deviceId == param.deviceId
    );

    if (existingIndex !== -1) {
      const updateMachineId = [...VmsReportPOPSelectmachine];
      updateMachineId.splice(existingIndex, 1);
      dispatch(
        setVmsReportData({
          VmsReportPOPSelectmachine: updateMachineId,
        })
      );

      const findSelectedTagUnit = VmsReportPOPSelectUnit.length
        ? VmsReportPOPSelectUnit.filter((f) => f.deviceId != param.deviceId)
        : [];

      dispatch(
        setVmsReportData({
          VmsReportPOPSelectUnit: findSelectedTagUnit,
        })
      );

      const findSelectedTeg = VmsReportPOPSelectTag.length
        ? VmsReportPOPSelectTag.filter((f) => f.deviceId != param.deviceId)
        : [];
      dispatch(
        setVmsReportData({
          VmsReportPOPSelectTag: findSelectedTeg,
        })
      );
      setTagListData([]);
    } else {
      // Add the object to the state array if it doesn't exist
      dispatch(
        setVmsReportData({
          VmsReportPOPSelectmachine: [
            ...VmsReportPOPSelectmachine,
            {
              machineId: row.device,
              dvName: row.device__device_name,
              plantId: param.plantId,
              deviceId: param.deviceId,
            },
          ],
        })
      );
    }
  };

  const handleSelectPOPTagUnit = (row) => {
    // const existingIndex = VmsReportPOPSelectUnit.findIndex(
    //   (color) =>
    //     color.tagUnit === row.tag_unit && color.deviceId == param.deviceId
    // );

    // // Check if the current switch is already selected
    // const isSelected = existingIndex !== -1;

    // // If it's selected, do nothing (all switches are off)
    // if (isSelected) {
    //   return;
    // }

    // // If it's not selected, turn off all other switches
    // const updatedMachineId = [
    //   {
    //     tagUnit: row.tag_unit,
    //     machineId: row.device,
    //     dvName: row.device__device_name,
    //     plantId: param.plantId,
    //     deviceId: param.deviceId,
    //   },
    // ];
    // dispatch(
    //   setVmsReportData({
    //     VmsReportPOPSelectUnit: updatedMachineId,
    //   })

    const existingIndex = VmsReportPOPSelectUnit.findIndex(
      (color) =>
        color.tagUnit === row.tag_unit && color.deviceId === param.deviceId
    );

    if (existingIndex !== -1) {
      // Create a copy of the array and remove the item at the existing index
      const updateMachineId = [...VmsReportPOPSelectUnit];
      updateMachineId.splice(existingIndex, 1);

      // Dispatch the updated array to the state
      dispatch(
        setVmsReportData({
          VmsReportPOPSelectUnit: updateMachineId,
        })
      );

      // Filter out the items from VmsReportPOPSelectTag that do not match the deviceId
      const findSelectedTag = VmsReportPOPSelectTag.filter(
        (f) => f.deviceId !== param.deviceId
      );

      // Dispatch the updated array to the state
      dispatch(
        setVmsReportData({
          VmsReportPOPSelectTag: findSelectedTag,
        })
      );

      // Clear the tag list data
      setTagListData([]);
    } else {
      // Add the new item to the state array
      dispatch(
        setVmsReportData({
          VmsReportPOPSelectUnit: [
            ...VmsReportPOPSelectUnit,
            {
              tagUnit: row.tag_unit,
              machineId: row.device,
              dvName: row.device__device_name,
              plantId: param.plantId,
              deviceId: param.deviceId,
            },
          ],
        })
      );
    }
    // Clear the selected tags and tag list
    dispatch(
      setVmsReportData({
        VmsReportPOPSelectTag: [],
      })
    );
    setTagListData([]);
  };

  const handleSelectPOPTag = (row, color) => {
    const existingIndex = VmsReportPOPSelectTag.findIndex(
      (color) => color.tagId === row.id && color.deviceId == param.deviceId
    );

    if (existingIndex !== -1) {
      const updateMachineId = [...VmsReportPOPSelectTag];
      updateMachineId.splice(existingIndex, 1);
      dispatch(
        setVmsReportData({
          VmsReportPOPSelectTag: updateMachineId,
        })
      );
    } else {
      // Add the object to the state array if it doesn't exist
      const oldDataTagSelected = [...VmsReportPOPSelectTag];

      const filterOldData = oldDataTagSelected.filter(
        (f) => f.deviceId != param.deviceId
      );
      dispatch(
        setVmsReportData({
          VmsReportPOPSelectTag: [
            ...filterOldData,
            {
              tagId: row.id,
              tagUnit: row.tag_unit,
              machineId: row.device,
              dvName: row.device__device_name,
              plantId: param.plantId,
              deviceId: param.deviceId,
              color: color,
            },
          ],
        })
      );
    }
  };

  // Reports handle Redux

  const handleSelectReportsMachine = (row) => {
    const existingIndex = VmsReportReportsSelectmachine.findIndex(
      (color) =>
        color.machineId === row.device && color.deviceId == param.deviceId
    );

    if (existingIndex !== -1) {
      const updateMachineId = [...VmsReportReportsSelectmachine];
      updateMachineId.splice(existingIndex, 1);
      dispatch(
        setVmsReportData({
          VmsReportReportsSelectmachine: updateMachineId,
        })
      );

      const finfSelectedTagUnit = VmsReportReportsSelectUnit.length
        ? VmsReportReportsSelectUnit.filter((f) => f.deviceId != param.deviceId)
        : [];
      dispatch(
        setVmsReportData({
          VmsReportReportsSelectUnit: finfSelectedTagUnit,
        })
      );

      const findSelectedTeg = VmsReportReportsSelectTag.length
        ? VmsReportReportsSelectTag.filter((f) => f.deviceId != param.deviceId)
        : [];
      dispatch(
        setVmsReportData({
          VmsReportReportsSelectTag: findSelectedTeg,
        })
      );
      setTagListData([]);
    } else {
      // Add the object to the state array if it doesn't exist
      dispatch(
        setVmsReportData({
          VmsReportReportsSelectmachine: [
            ...VmsReportReportsSelectmachine,
            {
              machineId: row.device,
              dvName: row.device__device_name,
              plantId: param.plantId,
              deviceId: param.deviceId,
            },
          ],
        })
      );
    }
  };

  const handleSelectReportsTagUnit = (row) => {
    const existingIndex = VmsReportReportsSelectUnit.findIndex(
      (color) =>
        color.tagUnit === row.tag_unit && color.deviceId == param.deviceId
    );

    if (existingIndex !== -1) {
      const updateMachineId = [...VmsReportReportsSelectUnit];
      updateMachineId.splice(existingIndex, 1);
      dispatch(
        setVmsReportData({
          VmsReportReportsSelectUnit: updateMachineId,
        })
      );

      const findSelectedTeg = VmsReportReportsSelectTag.length
        ? VmsReportReportsSelectTag.filter((f) => f.deviceId != param.deviceId)
        : [];
      dispatch(
        setVmsReportData({
          VmsReportReportsSelectTag: findSelectedTeg,
        })
      );
      setTagListData([]);
    } else {
      // Add the object to the state array if it doesn't exist
      dispatch(
        setVmsReportData({
          VmsReportReportsSelectUnit: [
            ...VmsReportReportsSelectUnit,
            {
              tagUnit: row.tag_unit,
              machineId: row.device,
              dvName: row.device__device_name,
              plantId: param.plantId,
              deviceId: param.deviceId,
            },
          ],
        })
      );
    }
  };

  const handleSelectReportsTag = (row, color) => {
    const existingIndex = VmsReportReportsSelectTag.findIndex(
      (color) => color.tagId === row.id && color.deviceId == param.deviceId
    );

    if (existingIndex !== -1) {
      const updateMachineId = [...VmsReportReportsSelectTag];
      updateMachineId.splice(existingIndex, 1);
      dispatch(
        setVmsReportData({
          VmsReportReportsSelectTag: updateMachineId,
        })
      );
    } else {
      // Add the object to the state array if it doesn't exist
      dispatch(
        setVmsReportData({
          VmsReportReportsSelectTag: [
            ...VmsReportReportsSelectTag,
            {
              tagId: row.id,
              tagName: row.tag_name,
              tagUnit: row.tag_unit,
              machineId: row.device,
              dvName: row.device__device_name,
              plantId: param.plantId,
              deviceId: param.deviceId,
              color: color,
            },
          ],
        })
      );
    }

    setSelectTagIDReport1("none");
    setSelectTagIDReport2("none");
  };

  // COC handel date
  const handleStartDate = (newValue) => {
    const durationInDays = endDateReCOC
    ? endDateReCOC.diff(newValue, "days")
      ? endDateReCOC.diff(newValue, "days") > 0
        ? endDateReCOC.diff(newValue, "days")
        : 1
      : 1
    : MAX_DATE_RANGE_DAYS;
  
    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(setVmsReportData({ VmsReportCOCStartDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }
  
    if (endDateReCOC && newValue.isBefore(endDateReCOC)) {
      dispatch(setVmsReportData({ VmsReportCOCStartDate: newValue }));
    } else if (!endDateReCOC) {
      dispatch(setVmsReportData({ VmsReportCOCStartDate: newValue }));
    } else if (endDateReCOC && newValue.isSame(endDateReCOC, "day")) {
      // Check for time validation if showTime is true
      if (VmsReportTime && (
        newValue.hour() > endDateReCOC.hour() ||
        (newValue.hour() === endDateReCOC.hour() && newValue.minute() >= endDateReCOC.minute())
      )) {
        dispatch(setVmsReportData({ VmsReportCOCStartDate: null }));
        notifyError("Start time must be before end time");
      } else {
        dispatch(setVmsReportData({ VmsReportCOCStartDate: newValue }));
      }
    } else {
      dispatch(setVmsReportData({ VmsReportCOCStartDate: null }));
      notifyError("End date must be greater than start date");
    }
    // dispatch(setVmsReportData({ VmsReportCOCStartDate: newValue }));
  };

  const handleEndDate = (newValue) => {
    const durationInDays = startDateReCOC
    ? startDateReCOC.diff(newValue, "days")
      ? startDateReCOC.diff(newValue, "days") > 0
        ? startDateReCOC.diff(newValue, "days")
        : 1
      : 1
    : MAX_DATE_RANGE_DAYS;
  
    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(setVmsReportData({ VmsReportCOCEndDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }
  
    if (startDateReCOC && newValue.isAfter(startDateReCOC)) {
      dispatch(setVmsReportData({ VmsReportCOCEndDate: newValue }));
    } else if (!startDateReCOC) {
      dispatch(setVmsReportData({ VmsReportCOCEndDate: newValue }));
    } else if (startDateReCOC && newValue.isSame(startDateReCOC, "day")) {
      // Check for time validation if showTime is true
      if (VmsReportTime && (
        newValue.hour() < startDateReCOC.hour() ||
        (newValue.hour() === startDateReCOC.hour() && newValue.minute() <= startDateReCOC.minute())
      )) {
        dispatch(setVmsReportData({ VmsReportCOCEndDate: null }));
        notifyError("End time must be after start time");
      } else {
        dispatch(setVmsReportData({ VmsReportCOCEndDate: newValue }));
      }
    } else {
      dispatch(setVmsReportData({ VmsReportCOCEndDate: null }));
      notifyError("Start date must be less than end date");
    }
    // dispatch(setVmsReportData({ VmsReportCOCEndDate: newValue }));
  };

  const handleToggleTime = () => {
    dispatch(setVmsReportData({ VmsReportTime: !VmsReportTime }));
  };

  // POP handle date
  const handlePOPStartDate = (newValue) => {
    const durationInDays = endDatePOP
    ? endDatePOP.diff(newValue, "days")
      ? endDatePOP.diff(newValue, "days") > 0
        ? endDatePOP.diff(newValue, "days")
        : 1
      : 1
    : MAX_DATE_RANGE_DAYS;
  
    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(setVmsReportData({ VmsReportPOPStartDate1: null }));
      notifyError("Date range must be within one year");
      return true;
    }
  
    if (endDatePOP && newValue.isBefore(endDatePOP)) {
      dispatch(setVmsReportData({ VmsReportPOPStartDate1: newValue }));
    } else if (!endDatePOP) {
      dispatch(setVmsReportData({ VmsReportPOPStartDate1: newValue }));
    } else if (endDatePOP && newValue.isSame(endDatePOP, "day")) {
      // Check for time validation if showTime is true
      if (VmsReportPOPTime && (
        newValue.hour() > endDatePOP.hour() ||
        (newValue.hour() === endDatePOP.hour() && newValue.minute() >= endDatePOP.minute())
      )) {
        dispatch(setVmsReportData({ VmsReportPOPStartDate1: null }));
        notifyError("Start time must be before end time");
      } else {
        dispatch(setVmsReportData({ VmsReportPOPStartDate1: newValue }));
      }
    } else {
      dispatch(setVmsReportData({ VmsReportPOPStartDate1: null }));
      notifyError("End date must be greater than start date");
    }
    // dispatch(setVmsReportData({ VmsReportPOPStartDate1: newValue }));
  };

  const handlePOPEndDate = (newValue) => {

    const durationInDays = startDatePOP
    ? startDatePOP.diff(newValue, "days")
      ? startDatePOP.diff(newValue, "days") > 0
        ? startDatePOP.diff(newValue, "days")
        : 1
      : 1
    : MAX_DATE_RANGE_DAYS;
  
    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(setVmsReportData({ VmsReportPOPEndDate1: null }));
      notifyError("Date range must be within one year");
      return true;
    }
  
    if (startDatePOP && newValue.isAfter(startDatePOP)) {
      dispatch(setVmsReportData({ VmsReportPOPEndDate1: newValue }));
    } else if (!startDatePOP) {
      dispatch(setVmsReportData({ VmsReportPOPEndDate1: newValue }));
    } else if (startDatePOP && newValue.isSame(startDatePOP, "day")) {
      // Check for time validation if showTime is true
      if (VmsReportPOPTime && (
        newValue.hour() < startDatePOP.hour() ||
        (newValue.hour() === startDatePOP.hour() && newValue.minute() <= startDatePOP.minute())
      )) {
        dispatch(setVmsReportData({ VmsReportPOPEndDate1: null }));
        notifyError("End time must be after start time");
      } else {
        dispatch(setVmsReportData({ VmsReportPOPEndDate1: newValue }));
      }
    } else {
      dispatch(setVmsReportData({ VmsReportPOPEndDate1: null }));
      notifyError("Start date must be less than end date");
    }
    // dispatch(setVmsReportData({ VmsReportPOPEndDate1: newValue }));
  };

  const handlePOPToggleTime = () => {
    dispatch(setVmsReportData({ VmsReportPOPTime: !VmsReportPOPTime }));
  };

  const handlePOP1StartDate = (newValue) => {
    const durationInDays = endDatePOP2
    ? endDatePOP2.diff(newValue, "days")
      ? endDatePOP2.diff(newValue, "days") > 0
        ? endDatePOP2.diff(newValue, "days")
        : 1
      : 1
    : MAX_DATE_RANGE_DAYS;
  
    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(setVmsReportData({ VmsReportPOPStartDate2: null }));
      notifyError("Date range must be within one year");
      return true;
    }
  
    if (endDatePOP2 && newValue.isBefore(endDatePOP2)) {
      dispatch(setVmsReportData({ VmsReportPOPStartDate2: newValue }));
    } else if (!endDatePOP2) {
      dispatch(setVmsReportData({ VmsReportPOPStartDate2: newValue }));
    } else if (endDatePOP2 && newValue.isSame(endDatePOP2, "day")) {
      // Check for time validation if showTime is true
      if (VmsReportPOPTime && (
        newValue.hour() > endDatePOP2.hour() ||
        (newValue.hour() === endDatePOP2.hour() && newValue.minute() >= endDatePOP2.minute())
      )) {
        dispatch(setVmsReportData({ VmsReportPOPStartDate2: null }));
        notifyError("Start time must be before end time");
      } else {
        dispatch(setVmsReportData({ VmsReportPOPStartDate2: newValue }));
      }
    } else {
      dispatch(setVmsReportData({ VmsReportPOPStartDate2: null }));
      notifyError("End date must be greater than start date");
    }
    // dispatch(setVmsReportData({ VmsReportPOPStartDate2: newValue }));
  };

  const handlePOP1EndDate = (newValue) => {
    const durationInDays = startDatePOP2
    ? startDatePOP2.diff(newValue, "days")
      ? startDatePOP2.diff(newValue, "days") > 0
        ? startDatePOP2.diff(newValue, "days")
        : 1
      : 1
    : MAX_DATE_RANGE_DAYS;
  
    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(setVmsReportData({ VmsReportPOPEndDate2: null }));
      notifyError("Date range must be within one year");
      return true;
    }
  
    if (startDatePOP2 && newValue.isAfter(startDatePOP2)) {
      dispatch(setVmsReportData({ VmsReportPOPEndDate2: newValue }));
    } else if (!startDatePOP2) {
      dispatch(setVmsReportData({ VmsReportPOPEndDate2: newValue }));
    } else if (startDatePOP2 && newValue.isSame(startDatePOP2, "day")) {
      // Check for time validation if showTime is true
      if (VmsReportPOPTime && (
        newValue.hour() < startDatePOP2.hour() ||
        (newValue.hour() === startDatePOP2.hour() && newValue.minute() <= startDatePOP2.minute())
      )) {
        dispatch(setVmsReportData({ VmsReportPOPEndDate2: null }));
        notifyError("End time must be after start time");
      } else {
        dispatch(setVmsReportData({ VmsReportPOPEndDate2: newValue }));
      }
    } else {
      dispatch(setVmsReportData({ VmsReportPOPEndDate2: null }));
      notifyError("Start date must be less than end date");
    }
  };

  // Report handle date
  const handleStartDateReports = (newValue) => {
    const durationInDays = endDateReReports
    ? endDateReReports.diff(newValue, "days")
      ? endDateReReports.diff(newValue, "days") > 0
        ? endDateReReports.diff(newValue, "days")
        : 1
      : 1
    : MAX_DATE_RANGE_DAYS;
  
    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(setVmsReportData({ VmsReportReportsStartDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }
  
    if (endDateReReports && newValue.isBefore(endDateReReports)) {
      dispatch(setVmsReportData({ VmsReportReportsStartDate: newValue }));
    } else if (!endDateReReports) {
      dispatch(setVmsReportData({ VmsReportReportsStartDate: newValue }));
    } else if (endDateReReports && newValue.isSame(endDateReReports, "day")) {
      // Check for time validation if showTime is true
      if (VmsReportReportsTime && (
        newValue.hour() > endDateReReports.hour() ||
        (newValue.hour() === endDateReReports.hour() && newValue.minute() >= endDateReReports.minute())
      )) {
        dispatch(setVmsReportData({ VmsReportReportsStartDate: null }));
        notifyError("Start time must be before end time");
      } else {
        dispatch(setVmsReportData({ VmsReportReportsStartDate: newValue }));
      }
    } else {
      dispatch(setVmsReportData({ VmsReportReportsStartDate: null }));
      notifyError("End date must be greater than start date");
    }
    // dispatch(setVmsReportData({ VmsReportReportsStartDate: newValue }));
  };

  const handleEndDateReports = (newValue) => {
    const durationInDays = startDateReReports
    ? startDateReReports.diff(newValue, "days")
      ? startDateReReports.diff(newValue, "days") > 0
        ? startDateReReports.diff(newValue, "days")
        : 1
      : 1
    : MAX_DATE_RANGE_DAYS;
  
    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(setVmsReportData({ VmsReportReportsEndDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }
  
    if (startDateReReports && newValue.isAfter(startDateReReports)) {
      dispatch(setVmsReportData({ VmsReportReportsEndDate: newValue }));
    } else if (!startDateReReports) {
      dispatch(setVmsReportData({ VmsReportReportsEndDate: newValue }));
    } else if (startDateReReports && newValue.isSame(startDateReReports, "day")) {
      // Check for time validation if showTime is true
      if (VmsReportReportsTime && (
        newValue.hour() < startDateReReports.hour() ||
        (newValue.hour() === startDateReReports.hour() && newValue.minute() <= startDateReReports.minute())
      )) {
        dispatch(setVmsReportData({ VmsReportReportsEndDate: null }));
        notifyError("End time must be after start time");
      } else {
        dispatch(setVmsReportData({ VmsReportReportsEndDate: newValue }));
      }
    } else {
      dispatch(setVmsReportData({ VmsReportReportsEndDate: null }));
      notifyError("Start date must be less than end date");
    }
    // dispatch(setVmsReportData({ VmsReportReportsEndDate: newValue }));
  };

  const handleToggleTimeReports = () => {
    dispatch(setVmsReportData({ VmsReportReportsTime: !VmsReportReportsTime }));
  };

  const handleSubmitRange = () => {
    dispatch(setVmsReportData({ vmsReportCOCRange: rangeCOCValue }));
    setRangeModal(false);
  };
  const handleSubmitRangePOP = () => {
    dispatch(setVmsReportData({ vmsReportPOPRange: rangePOPValue }));
    setRangeModalPOP(false);
  };

  // getReportParatoChat API
  const getReportParatoChatAPI = async (
    tagId,
    startDate,
    endDate,
    vmsReportCOCRange,
    setState,
    timeActive,
    startTime,
    endTime
  ) => {
    const paramReq = {
      company: param.companyId,
      plant: param.plantId,
      tag_id: tagId,
      range_selected: vmsReportCOCRange,
    };

    if (timeActive) {
      paramReq["start_date"] = `${startDate} ${startTime}`;
      paramReq["end_date"] = `${endDate} ${endTime}`;
    } else {
      paramReq["start_date"] = `${startDate} 00:00:00`;
      paramReq["end_date"] = `${endDate} 23:59:59`;
    }
    try {
      getReportAPIComp(paramReq, setState, setShimmerCOC);
    } catch (error) {
      console.log("errror", error);
    }
  };

  useEffect(() => {
    const findSelectedTag = VmsReportCOCSelectTag.length
      ? VmsReportCOCSelectTag.filter((f) => f.deviceId == param.deviceId).length
        ? VmsReportCOCSelectTag.filter((f) => f.deviceId == param.deviceId).map(
            (row) => row.tagId
          )
        : []
      : [];

    if (
      findSelectedTag.length &&
      startDateFormatCOC &&
      endDateFormatCOC &&
      vmsReportCOCRange !== "none" &&
      activeTabClass == "Consumer"
    ) {
      getReportParatoChatAPI(
        findSelectedTag,
        startDateFormatCOC,
        endDateFormatCOC,
        vmsReportCOCRange,
        setCocGraphData,
        VmsReportTime,
        startTimeFormatCOC,
        endTimeFormatCOC
      );
    } else {
      setCocGraphData([]);
    }
  }, [
    VmsReportCOCSelectTag,
    startDateFormatCOC,
    endDateFormatCOC,
    vmsReportCOCRange,
    activeTabClass,
    VmsReportTime,
    startTimeFormatCOC,
    endTimeFormatCOC,
  ]);

  const getReportParatoChatAPIPOP = async (
    tagId,
    startDate,
    endDate,
    vmsReportPOPRange,
    setState,
    timeActive,
    startTime,
    endTime
  ) => {
    const paramReq = {
      company: param.companyId,
      plant: param.plantId,
      tag_id: tagId,
      range_selected: vmsReportPOPRange,
    };

    if (timeActive) {
      paramReq["start_date"] = `${startDate} ${startTime}`;
      paramReq["end_date"] = `${endDate} ${endTime}`;
    } else {
      paramReq["start_date"] = `${startDate} 00:00:00`;
      paramReq["end_date"] = `${endDate} 23:59:59`;
    }
    try {
      getReportAPIComp(paramReq, setState, setShimmerPOP);
    } catch (error) {
      console.log("errror", error);
    }
  };

  useEffect(() => {
    const findSelectedTag = VmsReportPOPSelectTag.length
      ? VmsReportPOPSelectTag.filter((f) => f.deviceId == param.deviceId).length
        ? VmsReportPOPSelectTag.filter((f) => f.deviceId == param.deviceId).map(
            (row) => row.tagId
          )
        : []
      : [];
    if (
      findSelectedTag.length &&
      startDateFormatPOP &&
      endDateFormatPOP &&
      vmsReportPOPRange != "none" &&
      activeTabClass == "Period"
    ) {
      getReportParatoChatAPIPOP(
        findSelectedTag,
        startDateFormatPOP,
        endDateFormatPOP,
        vmsReportPOPRange,
        setPopBarGraphData1,
        VmsReportPOPTime,
        startTimeFormatPOP,
        endTimeFormatPOP
      );
    } else {
      setPopBarGraphData1([]);
    }
  }, [
    VmsReportPOPSelectTag,
    startDateFormatPOP,
    endDateFormatPOP,
    vmsReportPOPRange,
    activeTabClass,
    VmsReportPOPTime,
    startTimeFormatPOP,
    endTimeFormatPOP,
  ]);

  useEffect(() => {
    const findSelectedTag = VmsReportPOPSelectTag.length
      ? VmsReportPOPSelectTag.filter((f) => f.deviceId == param.deviceId).length
        ? VmsReportPOPSelectTag.filter((f) => f.deviceId == param.deviceId).map(
            (row) => row.tagId
          )
        : []
      : [];
    if (
      findSelectedTag.length &&
      startDateFormatPOP2 &&
      endDateFormatPOP2 &&
      vmsReportPOPRange != "none" &&
      activeTabClass == "Period"
    ) {
      getReportParatoChatAPIPOP(
        findSelectedTag,
        startDateFormatPOP2,
        endDateFormatPOP2,
        vmsReportPOPRange,
        setPopBarGraphData2,
        VmsReportPOPTime,
        startTimeFormatPOP2,
        endTimeFormatPOP2
      );
    } else {
      setPopBarGraphData2([]);
    }
  }, [
    VmsReportPOPSelectTag,
    startDateFormatPOP2,
    endDateFormatPOP2,
    vmsReportPOPRange,
    activeTabClass,
    VmsReportPOPTime,
    startTimeFormatPOP2,
    endTimeFormatPOP2,
  ]);

  // getReportParatoChat API
  const getReportLineChartAPI = async (
    tagId,
    startDate,
    endDate,
    startTime,
    endTime,
    setState,
    intervalTime,
    timeActive,
  ) => {
    const paramReq = {
      company: param.companyId,
      plant: param.plantId,
      tag_id: tagId,
      interval: Number(intervalTime),
    };

    // if (activeClass == "Reports") {
    //   paramReq["interval"] = Number(intervalTime);
    // } else {
    //   paramReq["interval"] = 1;
    // }

    if (timeActive) {
      paramReq["start_date"] = `${startDate} ${startTime}`;
      paramReq["end_date"] = `${endDate} ${endTime}`;
    } else {
      paramReq["start_date"] = `${startDate} 00:00:00`;
      paramReq["end_date"] = `${endDate} 23:59:59`;
    }
    try {
      getReportLineChartData(paramReq, setState, setShimmerLineChartCOC);
    } catch (error) {
      console.log("errror", error);
    }
  };

  // COC Line Chart
  useEffect(() => {
    const findSelectedTag = VmsReportCOCSelectTag.length
      ? VmsReportCOCSelectTag.filter((f) => f.deviceId == param.deviceId).length
        ? VmsReportCOCSelectTag.filter((f) => f.deviceId == param.deviceId).map(
            (row) => row.tagId
          )
        : []
      : [];
    if (
      findSelectedTag.length &&
      startDateFormatCOC &&
      endDateFormatCOC &&
      activeTabClass == "Consumer"
    ) {
      getReportLineChartAPI(
        findSelectedTag,
        startDateFormatCOC,
        endDateFormatCOC,
        startTimeFormatCOC,
        endTimeFormatCOC,
        setCocLineChartData,
        reportsIntervalTime,
        VmsReportTime
      );
    } else {
      setCocLineChartData([]);
    }
  }, [
    VmsReportCOCSelectTag,
    startDateFormatCOC,
    endDateFormatCOC,
    startTimeFormatCOC,
    endTimeFormatCOC,
    VmsReportTime,
    activeTabClass,
    reportsIntervalTime,
  ]);


  // useEffect(()=>{
  //   const findSelectedTag = VmsReportCOCSelectTag.length
  //   ? VmsReportCOCSelectTag.filter((f) => f.deviceId == param.deviceId).length
  //     ? VmsReportCOCSelectTag.filter((f) => f.deviceId == param.deviceId).map(
  //         (row) => row.tagId
  //       )
  //     : []
  //   : [];

  //   if(!findSelectedTag.length){
  //     setCocLineChartData([]);
  //   }
  // },[VmsReportCOCSelectTag, cocLineChartdata])

  const getReportLineChartAPIPOP = async (
    tagId,
    startDate,
    endDate,
    startTime,
    endTime,
    setState,
    timeActive,
    intervalTime,
    activeClass
  ) => {
    const paramReq = {
      company: param.companyId,
      plant: param.plantId,
      tag_id: tagId,
      interval: Number(intervalTime),
    };

    if (timeActive) {
      paramReq["start_date"] = `${startDate} ${startTime}`;
      paramReq["end_date"] = `${endDate} ${endTime}`;
    } else {
      paramReq["start_date"] = `${startDate} 00:00:00`;
      paramReq["end_date"] = `${endDate} 23:59:59`;
    }
    try {
      getReportLineChartData(paramReq, setState, setShimmerLineChartPOP);
    } catch (error) {
      console.log("errror", error);
    }
  };

  // POP Line Chart
  useEffect(() => {
    const findSelectedTag = VmsReportPOPSelectTag.length
      ? VmsReportPOPSelectTag.filter((f) => f.deviceId == param.deviceId).length
        ? VmsReportPOPSelectTag.filter((f) => f.deviceId == param.deviceId).map(
            (row) => row.tagId
          )
        : []
      : [];
    if (
      findSelectedTag.length &&
      startDateFormatPOP &&
      endDateFormatPOP &&
      activeTabClass == "Period"
    ) {
      getReportLineChartAPIPOP(
        findSelectedTag,
        startDateFormatPOP,
        endDateFormatPOP,
        startTimeFormatPOP,
        endTimeFormatPOP,
        setPopLineChartData,
        VmsReportPOPTime,
        reportIntervalTimePop
      );
    } else {
      setPopLineChartData([]);
    }
  }, [
    VmsReportPOPSelectTag,
    startDateFormatPOP,
    endDateFormatPOP,
    startTimeFormatPOP,
    endTimeFormatPOP,
    VmsReportPOPTime,
    activeTabClass,
    reportIntervalTimePop,
  ]);

  useEffect(() => {
    const findSelectedTag = VmsReportPOPSelectTag.length
      ? VmsReportPOPSelectTag.filter((f) => f.deviceId == param.deviceId).length
        ? VmsReportPOPSelectTag.filter((f) => f.deviceId == param.deviceId).map(
            (row) => row.tagId
          )
        : []
      : [];
    if (
      findSelectedTag.length &&
      startDateFormatPOP2 &&
      endDateFormatPOP2 &&
      activeTabClass == "Period"
    ) {
      getReportLineChartAPIPOP(
        findSelectedTag,
        startDateFormatPOP2,
        endDateFormatPOP2,
        startTimeFormatPOP2,
        endTimeFormatPOP2,
        setPop2LineChartData,
        VmsReportPOPTime,
        reportIntervalTimePop
      );
    } else {
      setPop2LineChartData([]);
    }
  }, [
    VmsReportPOPSelectTag,
    startDateFormatPOP2,
    endDateFormatPOP2,
    startTimeFormatPOP2,
    endTimeFormatPOP2,
    VmsReportPOPTime,
    activeTabClass,
    reportIntervalTimePop,
  ]);

  const getReportLineChartAPIReports = async (
    tagId,
    startDate,
    endDate,
    startTime,
    endTime,
    setState,
    timeActive,
    intervalTime,
    activeClass
  ) => {
    const paramReq = {
      company: param.companyId,
      plant: param.plantId,
      tag_id: tagId,
      interval: Number(intervalTime),
    };

    if (timeActive) {
      paramReq["start_date"] = `${startDate} ${startTime}`;
      paramReq["end_date"] = `${endDate} ${endTime}`;
    } else {
      paramReq["start_date"] = `${startDate} 00:00:00`;
      paramReq["end_date"] = `${endDate} 23:59:59`;
    }
    try {
      getReportLineChartData(paramReq, setState, setShimmerLineChartReports);
    } catch (error) {
      console.log("errror", error);
    }
  };
  // Report Line Chart
  useEffect(() => {
    if (
      selectTagIDReport1 != "none" &&
      startDateFormatReports &&
      endDateFormatReports &&
      activeClass == "Reports"
    ) {
      getReportLineChartAPIReports(
        [selectTagIDReport1],
        startDateFormatReports,
        endDateFormatReports,
        startTimeFormatReports,
        endTimeFormatReports,
        setReportLineChartData1,
        VmsReportReportsTime,
        reportsIntervalTime1,
        activeClass
      );
    } else {
      setReportLineChartData1([]);
    }
  }, [
    selectTagIDReport1,
    startDateFormatReports,
    endDateFormatReports,
    startTimeFormatReports,
    endTimeFormatReports,
    VmsReportReportsTime,
    reportsIntervalTime1,
    activeClass,
   
  ]);

  useEffect(() => {
    if (
      selectTagIDReport2 != "none" &&
      startDateFormatReports &&
      endDateFormatReports &&
      activeClass == "Reports"
    ) {
      getReportLineChartAPIReports(
        [selectTagIDReport2],
        startDateFormatReports,
        endDateFormatReports,
        startTimeFormatReports,
        endTimeFormatReports,
        setReportLineChartData2,
        VmsReportReportsTime,
        reportsIntervalTime1,
        activeClass
      );
    } else {
      setReportLineChartData2([]);
    }
  }, [
    selectTagIDReport2,
    startDateFormatReports,
    endDateFormatReports,
    startTimeFormatReports,
    endTimeFormatReports,
    VmsReportReportsTime,
    activeClass,
    reportsIntervalTime1,
  ]);

  // calculate average in COC LineChart
  useMemo(() => {
    if (cocLineChartdata.length == 1) {
      const chartDataTagValue = cocLineChartdata[0].data
        ? cocLineChartdata[0].data.map((item) => item.tag_value)
        : [];

      const averagesArray = [];

      if (chartDataTagValue.length && chartDataTagValue.length >= 2) {
        for (let i = 0; i < chartDataTagValue.length - 1; i++) {
          const average = (chartDataTagValue[i] + chartDataTagValue[i + 1]) / 2;
          averagesArray.push(average);
        }
      }
      console.log("averagesArray", averagesArray);
      setAverageCOCLineArray(averagesArray);
    }
  }, [cocLineChartdata]);

  // calculate average in COC LineChart

  function averageTagValues(arr) {
    // Find the maximum length of the data arrays
    const maxLength = arr.reduce(
      (max, obj) => Math.max(max, obj.data?.length),
      0
    );
    const averages = [];

    // Loop through each index up to the maximum length
    for (let i = 0; i < maxLength; i++) {
      let sum = 0;
      let count = 0;

      // Loop through each object in the array
      for (let j = 0; j < arr.length; j++) {
        const tagValue = arr[j].data[i] ? arr[j].data[i].tag_value : 0;
        sum += tagValue;
        count++;
      }

      // Calculate the average and push it to the averages array
      const average = parseFloat((sum / count).toFixed(2));
      averages.push(average);
    }

    return averages;
  }

  useMemo(() => {
    if (cocLineChartdata.length > 1) {
      const result = averageTagValues(cocLineChartdata);
      setAverageCOCLineArray(result);
    }
  }, [cocLineChartdata]);

  useEffect(() => {
    if (popLineChartdata.length && !pop2LineChartdata.length) {
      const chartDataTagValue = popLineChartdata[0].data
        ? popLineChartdata[0].data.map((item) => item.tag_value)
        : [];

      const averagesArray = [];

      if (chartDataTagValue.length && chartDataTagValue.length >= 2) {
        for (let i = 0; i < chartDataTagValue.length - 1; i++) {
          const average = (chartDataTagValue[i] + chartDataTagValue[i + 1]) / 2;
          averagesArray.push(average);
        }
      }
      console.log("averagesArray", averagesArray);
      setAveragePOPLineArray(averagesArray);
    }

    if (!popLineChartdata.length && pop2LineChartdata.length) {
      const chartDataTagValue = pop2LineChartdata[0].data
        ? pop2LineChartdata[0].data.map((item) => item.tag_value)
        : [];

      const averagesArray = [];

      if (chartDataTagValue.length && chartDataTagValue.length >= 2) {
        for (let i = 0; i < chartDataTagValue.length - 1; i++) {
          const average = (chartDataTagValue[i] + chartDataTagValue[i + 1]) / 2;
          averagesArray.push(average);
        }
      }
      console.log("averagesArray", averagesArray);
      setAveragePOPLineArray(averagesArray);
    }

    if (popLineChartdata.length && pop2LineChartdata.length) {
      const result = averageTagValues(
        popLineChartdata.concat(pop2LineChartdata)
      );
      setAveragePOPLineArray(result);
    }

    if (!popLineChartdata.length && !pop2LineChartdata.length) {
      setAveragePOPLineArray([]);
    }
  }, [popLineChartdata, pop2LineChartdata]);

  // Report Table Data
  const getReportTableDataApi = async (tagID, startDate, endDate, startTimeFormatReports,
    endTimeFormatReports,
    VmsReportReportsTime,) => {
    const paramReq = {
      tag_id: tagID,

    };
    if (VmsReportReportsTime) {
      paramReq["start_date"] = `${startDate} ${startTimeFormatReports}`;
      paramReq["end_date"] = `${endDate} ${endTimeFormatReports}`;
    } else {
      paramReq["start_date"] = `${startDate} 00:00:00`;
      paramReq["end_date"] = `${endDate} 23:59:59`;
    }

    try {
      const resp = await getReportTableData(paramReq);
      if (resp.status == 200 && resp.data.payload.length) {
        setReportsTableData(resp.data.payload);
      }
    } catch (error) {
      // Handle errors here
      console.error("Error fetching report table data:", error);
    }
  };

  useEffect(() => {
    const findSelectedTag = VmsReportReportsSelectTag.length
      ? VmsReportReportsSelectTag.filter((f) => f.deviceId == param.deviceId)
          .length
        ? VmsReportReportsSelectTag.filter(
            (f) => f.deviceId == param.deviceId
          ).map((row) => row.tagId)
        : []
      : [];
    if (
      findSelectedTag.length &&
      startDateFormatReports &&
      endDateFormatReports
    ) {
      getReportTableDataApi(
        findSelectedTag,
        startDateFormatReports,
        endDateFormatReports,
        startTimeFormatReports,
        endTimeFormatReports,
        VmsReportReportsTime,
      );
    }
  }, [VmsReportReportsSelectTag, startDateFormatReports, endDateFormatReports,startTimeFormatReports,endTimeFormatReports,VmsReportReportsTime]);

  const handleTabChange = (tab) => {
    setActiveTabClass(tab);
    setTagListData([]);
  };

  const handleMainTabChange = (tab) => {
    setActiveClass(tab);
    setTagListData([]);
  };
  const handleTostMsg = () => {
    notifyError("You don't have access");
  };

  return (
    <>
      <div className="page-wraper">
        <div className="page-header page-header-analytics">
          <div className="page-back-btn">
            <ArrowBackIcon
              onClick={() =>
                navigate(
                  `/submachine/company/${param.companyId}/plant/${param.plantId}/device/${param.deviceId}/machine/${param.machineId}`
                )
              }
            />
            <span
              onClick={() =>
                navigate(
                  `/submachine/company/${param.companyId}/plant/${param.plantId}/device/${param.deviceId}/machine/${param.machineId}`
                )
              }
            >
              {deviceDataDetail ? deviceDataDetail.device_name : ""} - Reports{" "}
            </span>
          </div>
          <div className="d-flex">
            <button
              className={`${
                activeClass == "Analytics" ? "Analytics-tabs" : "Reports-tabs"
              }`}
              onClick={() => handleMainTabChange("Analytics")}
            >
              Analytics
            </button>
            <button
              className={`${
                activeClass == "Reports" ? "Analytics-tabs" : "Reports-tabs"
              }`}
              onClick={() => handleMainTabChange("Reports")}
            >
              Reports
            </button>
          </div>
        </div>
        {activeClass == "Analytics" ? (
          <>
            <div className="analysis-tabs-btn pt-3">
              <div className="analytics-tabs-btn">
                <div className="d-flex">
                  <button
                    className={`${
                      activeTabClass == "Consumer"
                        ? "Analytics-tabs-overview"
                        : "Reports-tabs-overview"
                    }`}
                    // onClick={() =>
                    //     dispatch(
                    //         setOverviewData({ activeClassConsumer: "Consumer" })
                    //     )
                    // }
                    onClick={() => handleTabChange("Consumer")}
                    style={{ width: "210px", height: "50px" }}
                  >
                    Consumer over Consumer
                  </button>
                  <button
                    className={`${
                      activeTabClass == "Period"
                        ? "Analytics-tabs-overview"
                        : "Reports-tabs-overview"
                    }`}
                    // onClick={() =>
                    //     dispatch(
                    //         setOverviewData({ activeClassConsumer: "Period" })
                    //     )
                    // }
                    onClick={() => handleTabChange("Period")}
                    style={{ width: "210px", height: "50px" }}
                  >
                    Period over Period
                  </button>
                </div>
              </div>
              {activeTabClass == "Consumer" ? (
                <>
                  <div
                    className="analytics-aggregation analytics-datepicker "
                    style={{ justifyContent: "flex-end" }}
                  >
                    {(analyticsAccess?.is_viewer &&
                      analyticsAccess?.is_editor) ||
                    (!analyticsAccess?.is_viewer &&
                      analyticsAccess?.is_editor) ||
                    (analyticsAccess?.is_viewer &&
                      !analyticsAccess?.is_editor) ? (
                      <button
                        className="setvms-COCrange-popup"
                        onClick={() => {
                          setRangeCOCValue(vmsReportCOCRange);
                          setRangeModal(true);
                        }}
                      >
                        {vmsReportCOCRange ? vmsReportCOCRange : "Set Range"}
                      </button>
                    ) : (
                      <button
                        className="setvms-COCrange-popup"
                        onClick={handleTostMsg}
                      >
                        {vmsReportCOCRange ? vmsReportCOCRange : "Set Range"}
                      </button>
                    )}
                    {(analyticsAccess?.is_viewer &&
                      analyticsAccess?.is_editor) ||
                    (!analyticsAccess?.is_viewer &&
                      analyticsAccess?.is_editor) ||
                    (analyticsAccess?.is_viewer &&
                      !analyticsAccess?.is_editor) ? (
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="top"
                          control={
                            <Switch color="primary" checked={VmsReportTime} />
                          }
                          label="Time"
                          labelPlacement="top"
                          onChange={handleToggleTime}
                        />
                      </FormGroup>
                    ) : (
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="top"
                          control={
                            <Switch color="primary" checked={VmsReportTime} />
                          }
                          label="Time"
                          labelPlacement="top"
                          onChange={handleTostMsg}
                        />
                      </FormGroup>
                    )}

                    <COCVmsDatePicker
                      time={VmsReportTime}
                      startDateReFormat={startDateReCOC}
                      endDateReFormat={endDateReCOC}
                      access={analyticsAccess}
                      handleStartDate={handleStartDate}
                      handleEndDate={handleEndDate}
                      handleTostMsg={handleTostMsg}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="analytics-aggregation analytics-datepicker analyticsAgg-datePicker"
                    // style={{ justifyContent: "flex-end" }}
                  >
                    <div
                      className="abc1"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {(analyticsAccess?.is_viewer &&
                        analyticsAccess?.is_editor) ||
                      (!analyticsAccess?.is_viewer &&
                        analyticsAccess?.is_editor) ||
                      (analyticsAccess?.is_viewer &&
                        !analyticsAccess?.is_editor) ? (
                        <button
                          className="setvms-COCrange-popup"
                          onClick={() => {
                            setRangePOPValue(vmsReportPOPRange);
                            setRangeModalPOP(true);
                          }}
                        >
                          {vmsReportPOPRange ? vmsReportPOPRange : "Set Range"}
                        </button>
                      ) : (
                        <button
                          className="setvms-COCrange-popup"
                          onClick={handleTostMsg}
                        >
                          {vmsReportPOPRange ? vmsReportPOPRange : "Set Range"}
                        </button>
                      )}
                    </div>
                    {(analyticsAccess?.is_viewer &&
                      analyticsAccess?.is_editor) ||
                    (!analyticsAccess?.is_viewer &&
                      analyticsAccess?.is_editor) ||
                    (analyticsAccess?.is_viewer &&
                      !analyticsAccess?.is_editor) ? (
                      <div className="timeToggle">
                        <h6 style={{ marginBottom: "0px" }}>Time</h6>
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            value="top"
                            control={
                              <Switch
                                color="primary"
                                checked={VmsReportPOPTime}
                              />
                            }
                            // label="Time"
                            labelPlacement="top"
                            onChange={handlePOPToggleTime}
                            style={{ margin: "0px" }}
                          />
                        </FormGroup>
                      </div>
                    ) : (
                      <div className="timeToggle">
                        <h6 style={{ marginBottom: "0px" }}>Time</h6>
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            value="top"
                            control={
                              <Switch
                                color="primary"
                                checked={VmsReportPOPTime}
                              />
                            }
                            // label="Time"
                            labelPlacement="top"
                            onChange={handleTostMsg}
                            style={{ margin: "0px" }}
                          />
                        </FormGroup>
                      </div>
                    )}

                    <POPVmsDatePicker
                      time={VmsReportPOPTime}
                      startDateReFormat={startDatePOP}
                      endDateReFormat={endDatePOP}
                      startDate1ReFormat={startDatePOP2}
                      endDate1ReFormat={endDatePOP2}
                      handleStartDate={handlePOPStartDate}
                      handleEndDate={handlePOPEndDate}
                      handlePOP1StartDate={handlePOP1StartDate}
                      handlePOP1EndDate={handlePOP1EndDate}
                      access={analyticsAccess}
                      handleTostMsg={handleTostMsg}
                    />
                  </div>
                </>
              )}
              {rangeModal && (
                <Card
                  className="addDeviceModal-card"
                  style={{ width: "440px" }}
                >
                  <Box className="addDeviceModal-titleWrap">
                    <Typography
                      variant="h5"
                      gutterBottom
                      className="addDeviceModal-title"
                    >
                      Set Range
                    </Typography>
                    <img
                      src={images.closeIcon}
                      alt=""
                      style={{
                        width: "30px",
                        cursor: "pointer",
                        backgroundColor: "white",
                      }}
                      className="closeImg"
                      onClick={() => setRangeModal(false)}
                    />
                  </Box>

                  <Box className="addDeviceModal-innerWrap">
                    <Stack spacing={2} pt="10px" className="pt-4">
                      <TextField
                        id="filled-basic"
                        name="group_name"
                        label="Enter range"
                        variant="filled"
                        className="customeTextField deviceManagement"
                        type="number"
                        onChange={(e) => setRangeCOCValue(e.target.value)}
                        value={rangeCOCValue ? rangeCOCValue : null}
                      />
                    </Stack>
                    <Box className="accountSecurity-cardButtons deviceManagement">
                      <button
                        className="addDeviceBtn"
                        onClick={handleSubmitRange}
                      >
                        Submit
                      </button>
                    </Box>
                  </Box>
                </Card>
              )}
              {rangeModalPOP && (
                <Card
                  className="addDeviceModal-card"
                  style={{ width: "440px" }}
                >
                  <Box className="addDeviceModal-titleWrap">
                    <Typography
                      variant="h5"
                      gutterBottom
                      className="addDeviceModal-title"
                    >
                      Set Range
                    </Typography>
                    <img
                      src={images.closeIcon}
                      alt=""
                      style={{
                        width: "30px",
                        cursor: "pointer",
                        backgroundColor: "white",
                      }}
                      className="closeImg"
                      onClick={() => setRangeModalPOP(false)}
                    />
                  </Box>

                  <Box className="addDeviceModal-innerWrap">
                    <Stack spacing={2} pt="10px" className="pt-4">
                      <TextField
                        id="filled-basic"
                        name="group_name"
                        label="Enter range"
                        variant="filled"
                        className="customeTextField deviceManagement"
                        type="number" // Set the type to "number" to allow decimal input
                        // onChange={(e) =>
                        //   dispatch(
                        //     setVmsReportData({
                        //       vmsReportPOPRange: e.target.value,
                        //     })
                        //   )
                        // }
                        // value={vmsReportPOPRange}
                        onChange={(e) => setRangePOPValue(e.target.value)}
                        value={rangePOPValue ? rangePOPValue : null}
                      />
                    </Stack>
                    <Box className="accountSecurity-cardButtons deviceManagement">
                      <button
                        className="addDeviceBtn"
                        onClick={handleSubmitRangePOP}
                      >
                        Submit
                      </button>
                    </Box>
                  </Box>
                </Card>
              )}
            </div>

            <div className="pt-2 consumption-grid-card">
              {activeTabClass == "Consumer" ? (
                <TagListComponent
                  access={analyticsAccess}
                  handleTostMsg={handleTostMsg}
                  time={VmsReportTime}
                  selectTimeRangeCOC={selectTimeRangeCOC}
                  startDateFormatCOC={startDateFormatCOC}
                  endDateFormatCOC={endDateFormatCOC}
                  startTimeFormatCOC={startTimeFormatCOC}
                  endTimeFormatCOC={endTimeFormatCOC}
                  handleSelectMachine={handleSelectCOCMachine}
                  handleSelectTagUnit={handleSelectCOCTagUnit}
                  handleSelectTag={handleSelectCOCTag}
                  deviceData={deviceData}
                  deviceUnitData={unitsData}
                  isShimmerdevicelist={isShimmerdevicelist}
                  activeTabClass={activeTabClass}
                  activeClass={activeClass}
                  tagListData={tagListData}
                  VmsReportSelectmachine={
                    VmsReportCOCSelectmachine.length
                      ? VmsReportCOCSelectmachine.filter(
                          (f) => f.deviceId == param.deviceId
                        )
                      : []
                  }
                  VmsReportSelectTagUnit={
                    VmsReportCOCSelectUnit.length
                      ? VmsReportCOCSelectUnit.filter(
                          (f) => f.deviceId == param.deviceId
                        )
                      : []
                  }
                  VmsReportSelectTag={
                    VmsReportCOCSelectTag.length
                      ? VmsReportCOCSelectTag.filter(
                          (f) => f.deviceId == param.deviceId
                        )
                      : []
                  }
                />
              ) : (
                <TagListComponent
                  access={analyticsAccess}
                  time={VmsReportPOPTime}
                  selectTimeRangeCOC={selectTimeRangeCOC}
                  startDateFormatCOC={startDateFormatPOP}
                  endDateFormatCOC={endDateFormatPOP}
                  startTimeFormatCOC={startTimeFormatPOP}
                  endTimeFormatCOC={endTimeFormatPOP}
                  startDateFormatPOP2={startDateFormatPOP2}
                  endDateFormatPOP2={endDateFormatPOP2}
                  startTimeFormatPOP2={startTimeFormatPOP2}
                  endTimeFormatPOP2={endTimeFormatPOP2}
                  deviceData={deviceData}
                  deviceUnitData={unitsData}
                  activeTabClass={activeTabClass}
                  activeClass={activeClass}
                  tagListData={tagListData}
                  isShimmerdevicelist={isShimmerdevicelist}
                  handleSelectMachine={handleSelectPOPMachine}
                  handleSelectTagUnit={handleSelectPOPTagUnit}
                  handleSelectTag={handleSelectPOPTag}
                  VmsReportSelectmachine={
                    VmsReportPOPSelectmachine.length
                      ? VmsReportPOPSelectmachine.filter(
                          (f) => f.deviceId == param.deviceId
                        )
                      : []
                  }
                  VmsReportSelectTagUnit={
                    VmsReportPOPSelectUnit.length
                      ? VmsReportPOPSelectUnit.filter(
                          (f) => f.deviceId == param.deviceId
                        )
                      : []
                  }
                  VmsReportSelectTag={
                    VmsReportPOPSelectTag.length
                      ? VmsReportPOPSelectTag.filter(
                          (f) => f.deviceId == param.deviceId
                        )
                      : []
                  }
                />
              )}

              <GraphComponent
                activeTabClass={activeTabClass}
                Graphdata={cocGraphdata}
                LineGraphData={cocLineChartdata}
                tagID={
                  VmsReportCOCSelectTag.length
                    ? VmsReportCOCSelectTag.filter(
                        (f) => f.deviceId == param.deviceId
                      )
                    : []
                }
                tagSelectPOP={
                  VmsReportPOPSelectTag.length
                    ? VmsReportPOPSelectTag.filter(
                        (f) => f.deviceId == param.deviceId
                      )
                    : []
                }
                VmsReportSelectmachine={
                  VmsReportPOPSelectmachine.length
                    ? VmsReportPOPSelectmachine.filter(
                        (f) => f.deviceId == param.deviceId
                      )
                    : []
                }
                VmsReportSelectmachine1={
                  VmsReportCOCSelectmachine.length
                    ? VmsReportCOCSelectmachine.filter(
                        (f) => f.deviceId == param.deviceId
                      )
                    : []
                }
                averageArray={averageCOCLineArray}
                averagePOP={averagePOPLineArray}
                BarGraphData1={popBarGraphData1}
                BarGraphData2={popBarGraphData2}
                LineChartdata={popLineChartdata}
                LineChartdata2={pop2LineChartdata}
                isShimmerCOC={isShimmerCOC}
                isShimmerPOP={isShimmerPOP}
                isShimmerLineChartCOC={isShimmerLineChartCOC}
                isShimmerLineChartPOP={isShimmerLineChartPOP}
              />
            </div>
          </>
        ) : (
          <>
            <div className="d-flex justify-content-end pt-3">
              <div
                className="analytics-aggregation analytics-datepicker analyticsAgg-datePicker"
                style={{ justifyContent: "flex-end" }}
              >
                <h6 className="AGG-dropdown">Interval</h6>
                <Box sx={{ minWidth: 200 }} style={{ float: "right" }}>
                  {(ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                  (!ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                  (ReportAccess?.is_viewer && !ReportAccess?.is_editor) ? (
                    <select
                      className="form-input-class"
                      style={{
                        padding: "14px 8px",
                        borderRadius: "4px",
                        marginRight: "20px",
                      }}
                      id="selectedClass"
                      value={reportsIntervalTime1}
                      onChange={(e) => setReportIntervalTime1(e.target.value)}
                      required
                    >
                      <option value="1" selected disabled hidden>
                        1 min
                      </option>
                      <option value="1">1 Min</option>
                      <option value="3">3 Min</option>
                      <option value="5">5 Min</option>
                      <option value="15">15 Min</option>
                    </select>
                  ) : (
                    <select
                      className="form-input-class"
                      style={{
                        padding: "14px 8px",
                        borderRadius: "4px",
                        marginRight: "20px",
                      }}
                      id="selectedClass"
                      value={reportsIntervalTime1}
                      onChange={handleTostMsg}
                      required
                    >
                      <option value="1" selected disabled hidden>
                        1 min
                      </option>
                      <option value="1">1 Min</option>
                      <option value="3">3 Min</option>
                      <option value="5">5 Min</option>
                      <option value="15">15 Min</option>
                    </select>
                  )}
                </Box>
                {(ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                (!ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                (ReportAccess?.is_viewer && !ReportAccess?.is_editor) ? (
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      value="top"
                      control={
                        <Switch
                          color="primary"
                          checked={VmsReportReportsTime}
                        />
                      }
                      label="Time"
                      labelPlacement="top"
                      onChange={handleToggleTimeReports}
                    />
                  </FormGroup>
                ) : (
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      value="top"
                      control={
                        <Switch
                          color="primary"
                          checked={VmsReportReportsTime}
                        />
                      }
                      label="Time"
                      labelPlacement="top"
                      onChange={handleTostMsg}
                    />
                  </FormGroup>
                )}

                {/* vmsReport Report date */}
                <COCVmsDatePicker
                  time={VmsReportReportsTime}
                  startDateReFormat={startDateReReports}
                  endDateReFormat={endDateReReports}
                  handleStartDate={handleStartDateReports}
                  handleEndDate={handleEndDateReports}
                  access={ReportAccess}
                  handleTostMsg={handleTostMsg}
                />
              </div>
            </div>
            <div className="pt-2 consumption-grid-card">
              <TagListComponent
                access={ReportAccess}
                handleTostMsg={handleTostMsg}
                reportsIntervalTime1={reportsIntervalTime1}
                time={VmsReportReportsTime}
                startDateFormatCOC={startDateFormatReports}
                endDateFormatCOC={endDateFormatReports}
                startTimeFormatCOC={startTimeFormatReports}
                endTimeFormatCOC={endTimeFormatReports}
                deviceData={deviceData}
                deviceUnitData={unitsData}
                tagListData={tagListData}
                isShimmerdevicelist={isShimmerdevicelist}
                activeTabClass={activeTabClass}
                activeClass={activeClass}
                handleSelectMachine={handleSelectReportsMachine}
                handleSelectTagUnit={handleSelectReportsTagUnit}
                handleSelectTag={handleSelectReportsTag}
                VmsReportSelectmachine={
                  VmsReportReportsSelectmachine.length
                    ? VmsReportReportsSelectmachine.filter(
                        (f) => f.deviceId == param.deviceId
                      )
                    : []
                }
                VmsReportSelectTagUnit={
                  VmsReportReportsSelectUnit.length
                    ? VmsReportReportsSelectUnit.filter(
                        (f) => f.deviceId == param.deviceId
                      )
                    : []
                }
                VmsReportSelectTag={
                  VmsReportReportsSelectTag.length
                    ? VmsReportReportsSelectTag.filter(
                        (f) => f.deviceId == param.deviceId
                      )
                    : []
                }
              />
              <ReportsGraphComponent
                tableData={reportsTableData}
                selectedMachine={
                  VmsReportReportsSelectTag
                    ? VmsReportReportsSelectTag.filter(
                        (f) => f.deviceId == param.deviceId
                      )
                    : []
                }
                selectTagID1={selectTagIDReport1}
                selectTagID2={selectTagIDReport2}
                setSelectTag1={setSelectTagIDReport1}
                setSelectTag2={setSelectTagIDReport2}
                LineChartData1={reportLineChartData1}
                LineChartData2={reportLineChartData2}
                isShimmerLineChartReports={isShimmerLineChartReports}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default VmsReports;
