import React, { useEffect } from 'react';
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { images } from "../../../../config/images";
import { useNavigate, useParams } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import { getDepartment } from '../../service';
import { useState } from 'react';
import { ToastContainer, toast } from "react-toastify";
import { getDepartmentForManagement, getDepartmentRole } from '../../AlarmManagement/services';
import { createReportConfigure, updateReportConfigure } from '../service';
import { useSelector } from 'react-redux';

const ReportManagementUserList = (
    {
        activeClass,
        selectDeviceId,
        selectAGG,
        selectEmailSchedule,
        selectDateFormat,
        selectTimeFormat,
        selectUserId,
        setSelectUserId,
        departmentId,
        setDepartmentId,
        selectedRoles,
        setSelectedRoles,
        selectDeviceType,
        selectUserListObj,
        setSelectUserListObj,
        isConfigure
    }) => {

    const [departmentList, setDepartmentList] = useState([]);
    const [useDetailData, setUserDetailData] = useState([]);
    // const [selectUserId, setSelectUserId] = useState([])
    // const [departmentId, setDepartmentId] = useState("");
    // const [selectedRoles, setSelectedRoles] = useState("");

    const navigate = useNavigate();
    const param = useParams();

    const { is_super_admin, is_created_by_super_admin_user_company} = useSelector((state) => state.userState);

    console.log("selectUserListObjselectUserListObj", selectUserListObj);

    const notify = (message) =>
        toast.error(message, {
            theme: "colored",
            position: toast.POSITION.TOP_RIGHT,
            toastClassName: "custom-toast",
        });
    
    const label = { inputProps: { "aria-label": "Checkbox demo" } };

    // all userList select List
    const handelSelectUserList = (id) => {

        if (selectUserListObj.some(obj => obj.role == id)) {
            console.log("gjfdgjfdgjfk");
            setSelectUserListObj((prevState) => {
                const filteredList = prevState.filter(obj => obj.role != id);
                return [...filteredList];
            });
        } else {
            setSelectUserListObj((prevState) => {
                // Check if an object with the same departmentId already exists
                const existingObjIndex = prevState.findIndex(obj => obj.departmentId === departmentId);

                if (existingObjIndex !== -1) {
                    // Remove the existing object with the same departmentId
                    prevState.splice(existingObjIndex, 1);
                }


                const newObj = {
                    departmentId: departmentId,
                    role: id,
                    user: []
                };

                // Add the new object to the state
                return [...prevState, newObj];
            });
        } 
    };

  

    // handle User List 
const handleSelectUserListId = (id) => {
  let updatedUserListObj = [...selectUserListObj];
  const index = updatedUserListObj.findIndex((item) => item.departmentId === departmentId);

  if (index !== -1) {
    const item = updatedUserListObj[index];
    const userExists = item.user.some((userObj) => userObj === id);

    if (userExists) {
      const updatedUserArray = item.user.filter((userObj) => userObj !== id);
      updatedUserListObj[index] = { ...item, user: updatedUserArray };
    } else {
      const newUser = id;
      const updatedUserArray = [...item.user, newUser];
      updatedUserListObj[index] = { ...item, user: updatedUserArray };
        updatedUserListObj = updatedUserListObj.filter((f) => f.departmentId == departmentId)
    }
  }

  setSelectUserListObj(updatedUserListObj);
};









    // handle change CheckBox
    const handleCheckboxChange = (roleId) => {
        if (selectedRoles === roleId) {
            // Role ID already selected, deselect it
            setSelectedRoles("");
            setUserDetailData([]);
        } else {
            // Role ID not selected, select it
            setSelectedRoles(roleId);
            setSelectUserId([]);
            setUserDetailData([]);
        }
    };

    // useEffect(() => {
    //     if (param.setting != "setting") {
    //         setSelectedRoles("")
    //         setSelectUserId([]);
    //         setUserDetailData([])
    //     }
    // }, [departmentId])

    // handleChange Department
    const handleChangeDepartment = (id) => {
        setDepartmentId(id);
        // setSelectedRoles("")
        // setSelectUserId([]);
        setUserDetailData([])
    }

    useEffect(() => {
        getDepartmentList();
    }, []);

    //Get department list
    const getDepartmentList = async () => {
        const paramReq = {
            company: param.companyId
        }
        try {
            const response = await getDepartmentForManagement(paramReq);
            console.log("departmentList", response);
            if (response.status == 200 || response.status == 201) {
                setDepartmentList(response.data.payload);
                // if (!departmentId) {
                //   setDepartmentId(response.data.payload[0].id);
                // }
            }
        } catch (error) {
            notify(error.response.data.message);
        }
    };

    // user Detail
    useEffect(() => {
        handleUserDetailData();
    }, [selectUserListObj, departmentId, activeClass]);

    const handleUserDetailData = async () => {
        const params = {
            department: departmentId,
            role: selectUserListObj.find((f) => f.departmentId == departmentId)?.role,
        };
        try {
            const resp = await getDepartmentRole(params);
            if (resp.status == 200 || resp.status == 201) {
                setUserDetailData(resp.data.payload);
            }
        } catch (error) {
            console.log("handlesubmit", error);
        }
    };

    // select userId
    const handleSelectUserId = (id) => {
        if (!Array.isArray(selectUserId)) {
            setSelectUserId([]);
        }
        if (selectUserId.includes(id)) {
            setSelectUserId(selectUserId.filter((element) => element !== id));
        } else {
            setSelectUserId([...selectUserId, id]);
        }
    };

    // create User Configure
    
    const submitUserConfigure = async () => {
        const findUsers = selectUserListObj.filter(obj => obj.user.length > 0);
        try {
            const paramReq = {
                report: param.reportId,
                device: selectDeviceId,
                report_type: selectDeviceType,
                department: findUsers[0].departmentId,
                role: findUsers[0].role,
                agg: selectAGG,
                email_schedule: selectEmailSchedule,
                configuration_date: selectDateFormat,
                configuration_time: selectTimeFormat,
                user: findUsers[0].user
            };
            const resp = await createReportConfigure(paramReq);
            if (resp.status == 200 || resp.status == 201) {
                navigate("/reportmanagement")
            }
            // Handle the response here
        } catch (error) {
            // Handle the error here
            console.error('An error occurred:', error);
        }
    };

    const updateReportConfigureApi = async () => {
        const findUsers = selectUserListObj.filter(obj => obj.user.length > 0);
        try {
            const paramReq = {
                report: param.reportId,
                device: selectDeviceId,
                report_type: selectDeviceType,
                department: findUsers[0].departmentId,
                role: findUsers[0].role,
                agg: selectAGG,
                email_schedule: selectEmailSchedule,
                configuration_date: selectDateFormat,
                configuration_time: selectTimeFormat,
                user: findUsers[0].user
            };
            const resp = await updateReportConfigure(param.reportId ,paramReq);
            if (resp.status == 200 || resp.status == 201) {
                navigate("/reportmanagement")
            }
            // Handle the response here
        } catch (error) {
            // Handle the error here
            console.error('An error occurred:', error);
        }
    }


    return (
        <>
            {/*  */}
            <Grid container style={{ paddingTop: "20px" }}>
                <Grid item xs={12}>
                    <Card className="manageDepartment-card">
                        <Grid
                            container
                            style={{
                                paddingLeft: "30px",
                                paddingRight: "30px",
                                paddingTop: "30px",
                                paddingBottom: "30px",
                            }}
                        >
                            <Grid item sm={12} md={12} lg={4} xl={4}>
                                <Typography variant="subtitle2" gutterBottom>
                                    <b>Select Department</b>
                                </Typography>
                                <div className="manageDepartment-wrap">
                                    {departmentList.length ? departmentList.map((e, i) => {
                                        return (
                                            <div className="qqq">
                                                <div
                                                    className="manageDepartment-wrap mb-2"
                                                    onClick={() => handleChangeDepartment(e.id)}
                                                >
                                                    <div className={`manageDepartment-fieldWrap ${selectUserListObj.find((f)=> f.departmentId == e.id)?.user.length ?  "alarm-department-highlight" : ""}`}>
                                                        {departmentId == e.id && (
                                                            <img
                                                                src={images.activeDeviceimg}
                                                                alt=""
                                                                className="activeDepartmentimg"
                                                            />
                                                        )}
                                                        <div className="manageDepartment-field">
                                                            <Typography
                                                                variant="body2"
                                                                gutterBottom
                                                                style={{ margin: "0" }}
                                                            >
                                                                {is_super_admin || is_created_by_super_admin_user_company ? `${e.department_name}  -  ${e.company_name}` : e.department_name}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }) : []}
                                </div>
                            </Grid>
                            <Grid item sm={12} md={12} lg={4} xl={4}>
                                <Typography variant="subtitle2" gutterBottom>
                                    <b>Roles</b>
                                </Typography>
                                <div className="alarmMangementCard-useListCard1">
                                    {departmentList.length
                                        ? departmentList.map((e, i) => {
                                            if (e.id == departmentId) {
                                                return e.company_roles.map((e1) => {
                                                    return (
                                                        <div className="alarmManagementCard-Roleswrap">
                                                            <div>
                                                                <Checkbox
                                                                    {...label}
                                                                    defaultChecked={false}
                                                                    style={{
                                                                        color: "#1C407B",
                                                                    }}
                                                                    checked={selectUserListObj.find((f) => f.role == e1.id) ? true : false}
                                                                    onChange={() => handelSelectUserList(e1.id)}
                                                                />
                                                            </div>
                                                            <div>
                                                                <Typography
                                                                    variant="body2"
                                                                    gutterBottom
                                                                    className="m-0"
                                                                >
                                                                    {e1.role_name}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    );
                                                });
                                            }
                                        })
                                        : []}
                                </div>
                            </Grid>
                            <Grid item sm={12} md={12} lg={4} xl={4}>
                                <Typography variant="subtitle2" gutterBottom>
                                    <b>User Details</b>
                                </Typography>
                                <div className="alarmMangementCard-useListCard1">
                                    <Typography variant="body2" gutterBottom className='m-0' style={{padding:'14px'}}>
                                        User Name
                                    </Typography>
                                    {useDetailData.length
                                        ? useDetailData.map((row, index) => {
                                            return (
                                                <div className='alarmManagementCard-Roleswrap'>
                                                    <div>
                                                        <Checkbox
                                                            {...label}
                                                            style={{
                                                                color: "#1C407B",
                                                            }}
                                                            checked={selectUserListObj.find((f) => f.departmentId == departmentId)?.user.includes(row.id)}
                                                            onClick={() => handleSelectUserListId(row.id)}
                                                        />
                                                    </div>
                                                    <div>
                                                        <Typography variant="body2" gutterBottom className='m-0'>
                                                            {row.first_name} {row.last_name}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            )
                                        }) : []}
                                </div>
                                {
                                    isConfigure ?
                                        <button
                                            className={selectUserListObj.filter(obj => obj.user.length > 0)[0]?.user.length ? "Plantmanagemet submit" : "alarmManagement-submit-disable"}
                                            onClick={() => updateReportConfigureApi()}
                                        >
                                            Update
                                        </button>
                                        :
                                        <button
                                            className={selectUserListObj.filter(obj => obj.user.length > 0)[0]?.user.length ? "Plantmanagemet submit" : "alarmManagement-submit-disable"}
                                            onClick={() => submitUserConfigure()}
                                        >
                                            Submit
                                        </button>
                                }

                                
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid></Grid>
            </Grid>
        </>
    )
}
export default ReportManagementUserList;